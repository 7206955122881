import React, { useEffect, useState, useMemo } from "react";
import { Card } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import { process } from "./tarjetasDoc";

export const HeaderCard = (props) => {
  const [primera, setPrimera] = useState("");
  const [segunda, setSegunda] = useState("");

  const title = useMemo(() => process(props), [props]);

  const { oneLine } = props;

  useEffect(() => {
    const capitalize = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    if (title) {
      let splitTitle = title.split(" ");
      let legth = splitTitle.length;

      let primerasDos = splitTitle.slice(0, 3).join(" ");
      let resto = splitTitle.slice(3).join(" ");

      if (legth <= 3) {
        setPrimera(capitalize(splitTitle[0]));
        setSegunda(capitalize(splitTitle.slice(1).join(" ")));
        return;
      }
      setPrimera(capitalize(primerasDos));
      setSegunda(capitalize(resto));
    }
  }, [title]);

  return (
    <Card.Header
      style={{
        backgroundColor: "initial",
        paddingBottom: "0",
        border: "none",
        cursor: "pointer",
      }}
    >
      <Grid
        container
        // spacing={5000}
        align="center"
        alignItems="center"
        direction="column"
        style={{
          padding: "2rem 0",
        }}
      >
        {oneLine ? (
          <Grid item style={{ padding: "0" }} xs={12}>
            <span
              className="text_title_altblue mr-2"
              style={{ fontSize: "1.5rem", lineHeight: "30px" }}
            >
              {primera}
            </span>
            <span
              className="text_title_blue"
              style={{ fontSize: "1.5rem", lineHeight: "30px", textTransform: "lowercase" }}
            >
              {segunda}
            </span>
          </Grid>
        ) : (
          <>
            <Grid item style={{ padding: "0" }} xs={oneLine ? 6 : 12}>
              <span
                className="text_title_altblue"
                style={{ fontSize: "1.5rem", lineHeight: "30px" }}
              >
                {primera}
              </span>
            </Grid>
            <Grid
              item
              style={{ padding: "0", alignSelf: "baseline" }}
              xs={oneLine ? 6 : 12}
            >
              <span
                className="text_title_blue"
                style={{ fontSize: "1.5rem", lineHeight: "30px" }}
              >
                {segunda}
              </span>
            </Grid>
          </>
        )}
      </Grid>
    </Card.Header>
  );
};
