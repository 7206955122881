import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { updateModalCiec, updateRefDocuments } from '../redux/actions/modalCiecActions';
import Beneficios from '../assets/img/carousel/POPUP_Buro_FINSUS.jpg';
import ciec from '../assets/img/carousel/POPUP_CIEC_FINSUS.webp';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function Pop_up({ isDocuments=false }) {
  const dispatch = useDispatch(); 
  const { showModal, refDocuments } = useSelector(state => state.modalCiec);

	const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [load, setLoad] = useState(false);

  const history = useHistory();

  const handleClose = () => dispatch(updateModalCiec(false));

  useEffect(() => {
    if(!user){
      setLoad(true);
      return;
    }

    const { appliance } = user.idClient;  
	  let ciec = '';

    if(appliance === undefined || appliance.length === 0){
      // setLoad(true);
      return;
    }

    if (appliance.hasOwnProperty("idComercialInfo")){
      const { idComercialInfo } = appliance[appliance.length - 1];
	    ciec = idComercialInfo[idComercialInfo.length - 1].ciec;
    }

    if ((ciec === "" || ciec == null) && !refDocuments ){
      dispatch(updateModalCiec(true));
    }

    setLoad(true);

  },[])

  const IMGP = !isDocuments ? ciec : Beneficios;

    return load && (
      <>
        <Modal show={showModal} centered={true} onHide={handleClose}>
          {/* Boton Cerrar
          <Modal.Header closeButton>
          </Modal.Header>
          */}
          <Modal.Body>
              <img src={IMGP} width="100%" height="auto" alt="Informacion sobre ciec"/>
                <Button onClick={handleClose} style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  color: '#000',
                  fontSize: '30px',
                  position: 'absolute',
                  top: '-6%',
                  right: '-5%',
                  cursor: 'pointer'
                }} >
                  <FontAwesomeIcon icon={faTimes} size='1x' className="closeModalButton" />
                </Button>
          </Modal.Body>
          <Modal.Footer>
            {
				    isDocuments &&
				      <Button onClick={() => { 
                dispatch(updateRefDocuments(true));
                dispatch(updateModalCiec(false));
                history.push(`/mifiel/${user._id}`);

              }} block className="btn-blue-secondary">
					     Ir a firmar
      				</Button>
      			}
          </Modal.Footer>
          
        </Modal>
      </>
    );
  }
export default Pop_up;
