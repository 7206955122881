import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import SubtitleForm from "../components/Generic/SubtitleForm";
import { Row, Col, Button } from "react-bootstrap";
import generalInfoOptions from "../models/GeneralInfoModels";
import InputLabel from "../components/Generic/InputLabel";
import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { validateBuroExtForm } from "../components/Validate/validateBuroExtForm";
import useThemeData from "../utils/hooks/useThemeData";
import {
  renderFieldFull,
  renderSelectFieldFull,
  renderField,
  renderSelectField,
} from "../components/Generic/Fields";
import { updateLoader } from "../redux/actions/loaderActions";
import Axios from "../utils/axios";

let BuroExtForm = ({
  handleSubmit,
  valid,
  changeAddress,
  initialValues,
  setInitialValues,
  positionRef,
}) => {
  const dispatch = useDispatch();
  const [currentAddress, setCurrentAddress] = useState({
    extNumber: "",
    intNumber: "",
    registerDate: "",
    street: "",
    town: "",
    zipCode: "",
    sameAddress: "",
  });
  const [sameAddress, setSameAddress] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [creditCard, setCreditCard] = useState("");
  const [cpError, setCpError] = useState(false);
  const [colonias, setColonias] = useState([]);
  const [captcha, setCaptcha] = useState(true);
  const [show, setShow] = useState(false);
  const [state, setState] = useState("");
  const [municipality, setMunicipality] = useState("");

  const getAddress = async (zipCode) => {
    if (zipCode.length === 5) {
      try {
        const token =process.env.REACT_APP_SEPOMEXTOKEN
        const headers = {
          token: token,
        };
        const res = await Axios.get(`/api/info/${zipCode}`, { headers });
        if (res.data.success) {
          const { estado, municipio } = res.data.info;
          const colonias = res.data.info.asentamiento.map((datos) => datos);
          colonias.sort();
          setColonias(colonias);
          setState(estado);
          setMunicipality(municipio);
          setInitialValues({
            ...initialValues,
            zipCode,
            municipality: municipio,
            state: estado,
            colonias,
          });
          setCpError(false);
        } else if (res.error) {
          setCpError(true);
        }
      } catch (error) {
        setCpError(true);
        console.log("No hay CP");
      }
    } else {
      setInitialValues({
        ...initialValues,
        zipCode,
        state: "",
        municipality: "",
        colonias: [],
      });
    }
    dispatch(updateLoader(false));
  };

  const { theme, themeData } = useThemeData();

  function scroll(element) {
    const parentElement = element.parentElement;
    const containerElement = parentElement.parentElement;
    
    if (containerElement !== null) {
      
      containerElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    } else {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }

  function getErros() {
    const elements = document.getElementsByClassName("error");
    const errors = [];
    for (let elemnet of elements) {
      if (elemnet.innerText !== "") {
        errors.push(elemnet);
      }
    }
    return errors;
  }

  function onChangeCaptcha(value) {
    setCaptcha(!value);
  }

  React.useEffect(() => {
    if(captcha === false && disabled === false) {
      setShow(true);
    }
  }, [captcha, disabled]);

  const goToError = () => {
    const errors = getErros();

    for(let error of errors) {
      if(error !== null) {
        scroll(error);
        break;
      }
    }
  };

  if (!cpError && disabled && valid) {
    setDisabled(false);
  }
  if ((!disabled && !valid) || (!disabled && cpError)) {
    setDisabled(true);
  }
  

  const onlyLirycs = (nextValue, previousValue) =>
    /^([a-zñáéíóúü\s]{0,60})$/i.test(nextValue) ? nextValue : previousValue;
  const onlyNumbers = (nextValue, previousValue) =>
    /^\d+$/.test(nextValue) || nextValue.length === 0
      ? nextValue
      : previousValue;
  const LirycsNumbersDotComa = (nextValue, previousValue) =>
    /^([a-z ñáéíóú0-9,.]{0,45})$/i.test(nextValue) ? nextValue : previousValue;
  const upper = (value) => value && value.toUpperCase();

  return (
    <div>
      <form
        className="ml-auto mr-auto"
        style={{ maxWidth: "690px" }}
        onSubmit={handleSubmit}
      >
        <SubtitleForm
          subtitle="Información personal"
          // className=" text-form-dp"
        />
        <Row className="d-flex justify-content-center">
          <Col lg={4} md={4} sm={12}>
            <Field
              component={renderFieldFull}
              name="name"
              label="Nombre(s)"
              type="text"
              normalize={onlyLirycs}
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <Field
              component={renderFieldFull}
              name="lastname"
              label="Apellido paterno"
              normalize={onlyLirycs}
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <Field
              component={renderFieldFull}
              name="secondLastname"
              label="Apellido materno"
              normalize={onlyLirycs}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <Field
              component={renderFieldFull}
              name="rfcPerson"
              label="RFC de la persona"
              normalize={upper}
            />
          </Col>
          <Col lg={12} md={12} sm={12}>
            <label className="label-style mt-24">
              El número telefónico debe tener 10 dígitos
            </label>

            <Field
              component={renderField}
              label="Teléfono_Personal"
              type="text"
              name="phone"
              maxLength={10}
              minLength={10}
              normalize={onlyNumbers}
            />
          </Col>
          <Col lg={12} md={12} sm={12}>
            <Field
              component={renderFieldFull}
              name="email"
              label="Correo electrónico"
              type="email"
            />
          </Col>
        </Row>

        <Field
          component={renderSelectFieldFull}
          name="civilStatus"
          clases="mt-24"
        >
          <option value="">Estado civil...</option>
          {Object.keys(generalInfoOptions.civilStatusOptions).map(
            (key, index) => (
              <option value={key} key={key}>
                {generalInfoOptions.civilStatusOptions[key].value}
              </option>
            )
          )}
        </Field>

        <Row className="d-flex justify-content-center">
          <Col lg={6} md={6} sm={12}>
            
            <Field
              component={renderFieldFull}
              name="ocuppation"
              label="Ocupación"
              normalize={onlyLirycs}
            />
            <label className="label-style mt-2">
              empleado, dueño de negocio, independiente, etc...
            </label>
          </Col>

          <Col lg={6} md={6} sm={12}>
            <Field
              component={renderFieldFull}
              name="ingresosMensuales"
              label="Ingresos mensuales"
              normalize={onlyNumbers}
            />
          </Col>
        </Row>

        <SubtitleForm subtitle="Domicilio particular" className="mb-10 mt-24" />
        <Row className="d-flex justify-content-center">
          <Col lg={12} md={12} sm={12}>
            <Field
              component={renderFieldFull}
              label="Calle"
              name="street"
              val={!sameAddress ? "" : currentAddress.street}
              disabled={!sameAddress ? false : true}
              normalize={LirycsNumbersDotComa}
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Field
              component={renderFieldFull}
              label="Ext"
              name="extNumber"
              val={!sameAddress ? "" : currentAddress.extNumber}
              disabled={!sameAddress ? false : true}
            />
          </Col>

          <Col lg={6} md={6} sm={12}>
            <Field
              component={renderFieldFull}
              label="Int"
              name="intNumber"
              val={!sameAddress ? "" : currentAddress.intNumber}
              disabled={!sameAddress ? false : true}
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Field
              component={renderFieldFull}
              label="CP"
              name="zipCode"
              val={!sameAddress ? null : currentAddress.zipCode}
              disabled={!sameAddress ? false : true}
              onChange={(e, newValue, prevValue) => {
                if (newValue.hasOwnProperty("length")) {
                  if (newValue.length === 5) {
                    dispatch(updateLoader(true));
                  } else {
                    setCpError(false);
                    setColonias([]);
                  }
                }
                getAddress(newValue);
              }}
            />
            {cpError && (
              <span id="zipCode-error">
                <small className="error">Código postal no encontrado.</small>
              </span>
            )}
          </Col>

          <Col lg={6} md={6} sm={12}>
            <Field
              className="form-control custom-form-input brandonReg mt-24 mb-0"
              component={renderSelectField}
              name="town"
              val={!sameAddress ? "" : currentAddress.town}
              disabled={!sameAddress ? false : true}
              cls="mb-3 mt-24"
            >
              <option value="" selected disabled>
                Selecciona una colonia
              </option>
              {colonias.map((colonia, index) => {
                return (
                  <option
                    value={colonia}
                    selected={colonia === currentAddress.town ? true : false}
                    key={colonia + index}
                  >
                    {colonia}
                  </option>
                );
              })}
            </Field>
          </Col>

          <Col lg={6} md={6} sm={12}>
            <Field
              className="form-control custom-form-input mt-24 mb-0"
              component={renderFieldFull}
              cls="mb-3 mt-24"
              label="Estado"
              name="state"
              readOnly={true}
              val={!sameAddress ? "" : currentAddress.state}
            />
          </Col>

          <Col lg={6} md={6} sm={12}>
            <Field
              className="form-control custom-form-input mt-24 mb-0"
              component={renderFieldFull}
              name="municipality"
              cls="mb-3 mt-24"
              label="Municipio"
              readOnly={true}
              val={!sameAddress ? "" : currentAddress.municipality}
            />
          </Col>
        </Row>

        <SubtitleForm subtitle="¿Cuentas con alguno?" className="mt-30 mb-18" />
        <Row>
          <Col>
            <InputLabel label="crédito hipotecario" class="mt-18 text-msg-dp" />
            <Field
              component={renderSelectFieldFull}
              name="mortgageCredit"
              clases="mt-10"
            >
              <option value="">Selecciona...</option>
              <option value="1">Sí</option>
              <option value="0">No</option>
            </Field>
          </Col>

          <Col>
            <InputLabel label="crédito automotriz" class="mt-18 text-msg-dp" />
            <Field
              component={renderSelectFieldFull}
              name="carCredit"
              clases="mt-10"
            >
              <option value="">Selecciona...</option>
              <option value="YES">Sí</option>
              <option value="NO">No</option>
              <option value="YES2">Cerrado en los ultimos 2 años</option>
            </Field>
          </Col>

          <Col>
            <InputLabel label="tarjeta de crédito" class="mt-18 text-msg-dp" />
            <Field
              component={renderSelectFieldFull}
              name="creditCard"
              clases="mt-10"
              onChange={(event, newValue, previousValue) => {
                setCreditCard(newValue);
              }}
            >
              <option value="">Selecciona...</option>
              <option value="1">Sí</option>
              <option value="0">No</option>
            </Field>
          </Col>
        </Row>
        {(creditCard === "1" || initialValues.creditCard === "1") && (
          <div>
            <Field
              component={renderFieldFull}
              name="last4"
              maxLength="4"
              label="Últimos 4 dígitos de tu tarjeta de crédito"
            />
          </div>
        )}

        <div className="mt-3">
          <Field
            component={renderFieldFull}
            type="checkbox"
            label={`Autorizo a ${themeData ? themeData.razonSocial : " "} a consultar mis antecedentes crediticios por única ocasión ante las Sociedades de Información Crediticia que estime conveniente, declarando que conozco la naturaleza, alcance y uso que ${themeData ? themeData.razonSocial : ""} hará de tal información.`}
            name="tyc"
            big={true}
          />
        </div>

        <div className="recaptcha-container">
            <ReCAPTCHA
              sitekey="6Leao34mAAAAAFljRpcyEhLm8rHJaM5enL0bJ9r7"
              onChange={onChangeCaptcha}
            />
          </div>

          <div className="mt-30 text-dp fw300 fz20 text-center mb-10">
          <label className="fz15">
            Al continuar estás aceptando nuestros{" "}
            <a
              href="/terminos-y-condiciones"
              target="blank"
              style={{ color: "#000000" }}
            >
              términos y condiciones de uso
            </a>{" "}
            <br />y nuestro{" "}
            <a href="/privacidad" target="blank" style={{ color: "#000000" }}>
              aviso de privacidad
            </a>
          </label>
        </div>

        <div className="text-center" style={{ marginBottom: "50px" }}>
          {!show ? (
            <Button
              type="button"
              className="mt-50 btn-blue-general btn-gray-general"
              onClick={() => goToError()}
              style={{ width: "250px" }}
            >
              continuar
            </Button>
          ) : (
            <Button
              type="submit"
              className="mt-50 btn-blue-general"
              style={{ width: "250px" }}
            >
              continuar
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

BuroExtForm = reduxForm({
  form: "buroextform", // a unique identifier for this form
  validate: validateBuroExtForm, // <--- validation function given to redux-form
  enableReinitialize: true,
  keepDirtyOnReinitialize : true
})(BuroExtForm);

export default BuroExtForm;
