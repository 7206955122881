import React, { useState, useRef, useEffect } from "react";
import { withFormik, Form, ErrorMessage } from "formik";
import { Row, Col, Button, Container } from "react-bootstrap";
import {
  FieldText,
  FieldTextArea,
  FieldCheck,
  FieldTextHide,
} from "../components/Generic/Fields";
import { useDispatch } from "react-redux";
import BrokerValidations from "../components/Validate/brokersValidations";
import scroll from "../utils/scroll";
import { updateLoader } from "../redux/actions/loaderActions";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import upload from "../assets/img/underline_men/documentos_banner_upload.svg";

const Meta = (props) => {
  const [element, setElement] = useState(null);
  let ref = useRef();
  let accion = props.id.split("_")[1];
  const dispatch = useDispatch();

  const scrollToIne = () => {
    let ine = document.getElementById("req_BrokersPF");
    ine.scrollIntoView({ behavior: "smooth" });
  };
  

  useEffect(() => {
    setElement(ref.current);
  }, [ref]);

  useEffect(() => {
    if (element !== null) {
      element.addEventListener("metamap:exitedSdk", ({ detail }) => {
        scrollToIne();
        dispatch(updateLoader(false));
      });
      return () => {
        scrollToIne();
        element.removeEventListener("metamap:exitedSdk", ({ detail }) => {
          dispatch(updateLoader(false));
        });
      };
    }
  }, [element, dispatch]);

  useEffect(() => {
    if (element !== null) {
      element.addEventListener("metamap:userFinishedSdk", ({ detail }) => {
        scrollToIne();
        dispatch(updateLoader(false));
        props.changeValue("ine", detail.verificationId);
      });
      return () => {
        element.removeEventListener("metamap:userFinishedSdk", ({ detail }) => {
          dispatch(updateLoader(false));
        });
      };
    }
  }, [element, dispatch]);

  const handleCl = () => {
    setTimeout(() => {
      dispatch(
        updateLoader(
          true,
          "estamos procesando tus documentos y en breve te daremos una respuesta"
        )
      );
    }, 1000);
  };

  return (
    <metamap-button
      id={props.id}
      clientid="62f15ad24621d7001caa5472"
      flowId={props.flow}
      color="var(--primer-color)"
      metadata={JSON.stringify({
        socketId: props.socketId,
        canal: "Finsus",
        userId: props.idUser,
        accion: accion,
      })}
      ref={ref}
      style={{ display: "none" }}
      onClick={handleCl}
      debbug={false}
    />
  );
};


const handleClick = (e, more) => {
  if (more === true) {
    return;
  }
  // console.log(more);
  let id = e.currentTarget.id.split("_")[1];
  document.getElementById("mati_" + id).click();
};

const BrokersForms = (props) => {
  const goToError = () => {
    const nameError = document.getElementById("name-error");
    const emailError = document.getElementById("email-error");
    const mobilephoneError = document.getElementById("mobilephone-error");
    const zipError = document.getElementById("zip-error");
    const messageError = document.getElementById("message-error");
    const trmError = document.getElementById("trm-error");

    const errors = [
      nameError,
      emailError,
      mobilephoneError,
      zipError,
      messageError,
      trmError,
    ];
    for (const x in errors) {
      if (errors[x] != null) {
        scroll(errors[x].id);
        break;
      }
    }
  };

  const onChange = (value) => {
    setButton(!value);
  };

  const [button, setButton] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [ineGet, setIneGet] = useState(false);

  const changeValue = (name, value) => {
    if (name === "ine") {
      setIneGet(true);
    }
    props.setFieldValue(name, value);
  };

  if (disabled && props.isValid) {
    setDisabled(false);
  }
  if (!disabled && !props.isValid) {
    setDisabled(true);
  }

  const meta = {
    name: "BrokersPF",
    flow: "642b5e3b2cec33001c0c29ab",
    description: "otros documentos",
    step: 1,
  };

  return (

      <Container>
        <div
          className="title-coral fz42 mb-1 text-center mt-2"
          style={{ color: "var(--coral)" }}
          id="brokersForm"
        >
          <span style={{ color: "var(--navy)" }}> Conviértete </span>{" "}
          en Coach Finsus
        </div>
        <div className="metropolisReg fz21 text-center blackNavBlue">
          nuestro
          equipo de atención te contactará para que
          comiences ¡YA!
        </div>
        <br />
        <Form>
          <Row>
            <Col lg={12}>
              <FieldText
                name="name"
                placeholder="nombre completo"
                normalize="onlyLirycs"
                className="forceFullWidth"
              />
            </Col>

            <Col lg={4}>
              <FieldText
                name="email"
                placeholder="correo"
                normalize="onlyEmailWithoutSpace"
                className="forceFullWidth"
              />
            </Col>

            <Col lg={4}>
              <FieldText
                name="mobilephone"
                placeholder="whatsApp"
                normalize="onlyNumbers"
                maxLength={10}
                minLength={10}
                className="forceFullWidth"
              />
            </Col>

            <Col lg={4}>
              <FieldText
                name="rfc"
                placeholder="RFC"
                normalize="OnlyLirycsNumberstoUpper"
                maxLength={13}
                minLength={12}
                className="forceFullWidth"
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FieldTextArea
                name="message"
                placeholder="cuéntanos un poco sobre tu experiencia como broker"
                className="forceFullWidth"
              />
            </Col>
          </Row>

          <Row>
            <Col
              lg={12}
              className="mt-2 mb-2 brokerINE"
              {...(ineGet ? { style: { background: "var(--navy)" } } : {})}
              onClick={(e) => {
                handleClick(e, ineGet);
              }}
              id={"req_BrokersPF"}
            >
              {ineGet ? (
                <FontAwesomeIcon
                  icon={faCheck}
                  id={"req_BrokersPF_check"}
                  alt="icon"
                  className="ml-2 mr-2 dashboard-logo-white"
                  style={{ color: "#06c79c", fontSize: "1.2rem" }}
                />
              ) : (
                <img
                  src={upload}
                  id={"req_BrokersPF_IMG"}
                  alt="icon"
                  style={{
                    verticalAlign: "baseline",
                    maxWidth: "30px",
                    maxHeight: "30px",
                  }}
                  className=" ml-2 mr-1 dashboard-logo-white"
                />
              )}
              <span
                className="fz18 fw500 text-center ml-1"
                style={{
                  margin: "0",
                  padding: "0",
                  textJustify: "left",
                  textAlign: "left",
                  alignSelf: "stretch",
                  color: "#fff",
                  fontFamily: "coolvetica_rg",
                }}
              >
                Sube tu INE o Pasaporte
              </span>
              <Meta
                id={"mati_BrokersPF"}
                flow={meta.flow}
                changeValue={changeValue}
              />
            </Col>
          </Row>

          <FieldTextHide
            name="ine"
            placeholder="INE"
            className="forceFullWidth"
            type="hidden"
          />

          <Row>
            <div style={{ paddingLeft: "1rem", paddingRight: "1.5rem" }}>
              <div className="metropolisReg fz12 blackNavBlue  text-justify">
                <b>Finsus</b> se compromete a proteger y respetar tu privacidad,
                y solo usaremos tu información personal para administrar tu
                cuenta y proporcionar los servicios que nos solicitaste.
                consulta nuestro{" "}
                <a href="https://finsus.mx/aviso-de-privacidad/" target="_blank" rel="noopener noreferrer">
                  aviso de privacidad
                </a>
              </div>

              <Col lg={5} className="mt-2">
                <FieldCheck
                  name="trm"
                  label="entiendo*"
                  className="metropolisReg fz12 blackNavBlue"
                />
              </Col>

              <div className="metropolisReg fz12 blackNavBlue  text-justify mt-3">
                al hacer clic en enviar, aceptas que <b>Finsus</b> almacene y
                procese la información personal suministrada arriba para
                contactarte.
              </div>
            </div>
          </Row>

          <div className="recaptcha-container">
            <ReCAPTCHA
              sitekey="6Leao34mAAAAAFljRpcyEhLm8rHJaM5enL0bJ9r7"
              onChange={onChange}
            />
          </div>

          <div className="text-center mb-5">
            {!disabled && !button ? (
              <Button
                type="submit"
                className="mt-50 btn-coral-general"
                style={{ width: "300px" }}
              >
                enviar
              </Button>
            ) : (
              <Button
                type="button"
                className="mt-50 btn-blue-general btn-gray-general"
                style={{ width: "300px" }}
                onClick={() => {
                  props
                    .validateForm()
                    .then((errors) =>
                      props.setTouched({ ...props.touched, ...errors })
                    );
                  goToError();
                }}
              >
                enviar
              </Button>
            )}
          </div>
        </Form>
      </Container>

  );
};

export default withFormik({
  mapPropsToValues({ initialValues }) {
    return initialValues;
  },
  validate: BrokerValidations,
  handleSubmit(values, formikBag) {
    formikBag.setSubmitting(false);
    formikBag.props.handleSubmit(values);
  },
  // enableReinitialize: true,
  displayName: "BrokersForms",
})(BrokersForms);
