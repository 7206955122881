import React, { useState, useEffect } from "react";
import Title from "../Generic/Title";
import LoginForm from "../../forms/LoginForm";
import Loader from "../Loader/Loader";
import axios from "../../utils/axios";
import { useDispatch } from "react-redux";
import { loginAction } from "../../redux/actions/authActions";
import { updateLoader } from "../../redux/actions/loaderActions";
import { Modal } from "react-responsive-modal";
import { Row, Button, Col } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import Linea from "../../assets/img/linea_mb.svg";
import useKissmetrics from "../../utils/hooks/useKissmetrics";

const Login = (props) => {
  const dispatch = useDispatch();
  const logParams = useKissmetrics();
  const [open, setOpen] = useState(false);

  const onFormSubmit = async (data) => {
    dispatch(updateLoader(true));
    await axios
      .post("login", data)
      .then(({ data }) => {
        if (data.code === 200) {
          dispatch(loginAction(data));
          logParams("record", "LOGIN", { email: data.user.email });
          if (props.match.params.redirect) {
            window.location.replace(`/${props.match.params.redirect}`);
          } else {
            window.location.replace("/credito");
          }
        } else if (data.code === 401) {
          Swal.fire({
            icon: "Info",
            title: "Hola de nuevo",
            text: "Ha pasado mucho tiempo desde tu última visita, lamentablemente tu solicitud ya no es valida, por favor vuelve a registrarte",
            customClass: {
              title: "title-dp fz42",
              popup: "text-dp fz20",
              confirmButton: "btn-blue-general btn btn-primary p-0",
            },
            confirmButtonText: "aceptar",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.replace("/registrate");
            }
          } );
         }else {
          setOpen(true);
        }
      })
      .catch((err) => {
        setOpen(true);
        console.log(err.response);
      })
      .finally(() => {
        dispatch(updateLoader(false));
      });
  };

  const auth = sessionStorage.getItem("token");

  useEffect(() => {
    window.scrollTo(0, 0);
    logParams("record", "Visita", { page: "Login" });
  }, []);

  return (
    <div style={{position: "relative"}}  className="login-container">
      {auth && <Redirect to="/credito" />}
      <div className="container-fluid d-flex flex-column align-items-center lineaImg">
        <img src={Linea} alt="linea" className="linea-mb" />
      </div> 
      <div>
        <Loader />
        <Modal
          onClose={() => setOpen(false)}
          open={open}
          style={{ padding: "30px 40px!important", width: "auto!important" }}
        >
          <Row className="d-flex justify-content-center">
            <Col lg={6} sm={12} md={12} className="text-center">
              <div className="metropolisReg fz29 blueDark fw400">
                correo y/o contraseña incorrectos
              </div>
            </Col>
          </Row>
          <div className="text-center mt-30">
            <Button
              className="btn-blue-general ml-auto mr-auto"
              style={{ width: "250px" }}
              onClick={() => setOpen(false)}
            >
              aceptar
            </Button>
          </div>
        </Modal>
        <div
          className="container d-flex flex-column align-items-center"
          style={{ maxHeight: "600px", padding: "1rem" }}
        >
          <Title className="title-dp fz56 fw500" title="Inicia sesión" />
          <div className="mt-1 subtitle-dp fw300 fz20 mb-1 text-center">
            <label className="">
              ingresa tu correo y contraseña para comenzar
            </label>
          </div>
          <LoginForm handleSubmit={onFormSubmit} />
        </div>
      </div>
    </div>
  );
};

export default Login;
