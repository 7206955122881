import React, { useEffect, useState } from "react";
import { WebSocketContext } from "../../context/WebSocket";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Slider } from "@material-ui/core";
import { Button } from "react-bootstrap";
import ProposalModal from "../Appliance/ProposalModal";
import { formatNumber } from "../../utils/formatNumber.js";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Loader from "../Loader/Loader";
import Axios from "../../utils/axios";
import { updateLoader } from "../../redux/actions/loaderActions";
import { updateUserData } from "../../redux/actions/authActions";
import Swal from "sweetalert2";
import useKissmetrics from "../../utils/hooks/useKissmetrics.js";

const StyledSlider = styled(Slider)`
  color: #23223f;
  height: 8px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  & .MuiSlider-thumb {
    height: 20px;
    width: 20px;
    background-color: #23223f;
    border: 2px solid #fff;
    margin-top: -7px;
    margin-left: -10px;
    &:focus,
    &:hover,
    &:active {
      box-shadow: inherit;
    }
  }
  & .MuiSlider-rail {
    height: 8px;
    border-radius: 4px;
  }
  & .MuiSlider-track {
    height: 8px;
    border-radius: 4px;
  }
  & .MuiSlider-mark {
    display: none;
  }
  & .MuiSlider-markLabel {
    color: #ff5f54;
    font-size: 16px;
    fonst-weight: 600;
    font-family: "coolvetica_rg";
    margin-left: -10px;
  }
  & .MuiSlider-markLabelActive {
    color: #ff5f54;
  }
`;

const Proposal = ({ origin }) => {
  const [proposal, setProposal] = useState({});
  const [marks, setMarks] = useState([]);
  const [marksPlazo, setMarksPlazo] = useState([]);
  const [solicitud, setSolicitud] = useState(null);
  const [value, setValue] = useState(0);
  const [plazo, setPlazo] = useState(0);
  const [mensual, setMensual] = useState(0);
  const [tasa, setTasa] = useState(0);
  const [tipo, setTipo] = useState(null); // [null, "PM", "PFAE"
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const logParams = useKissmetrics();
  const dispatch = useDispatch();
  const {
    user: { user: currentUser },
  } = useSelector((state) => state);

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const solicitud = user?.idDistrito;
    const type = user?.idClient?.type;
    setTipo(type);
    setSolicitud(solicitud);
    const appliance = user?.idClient?.appliance[0];
    const proposal = appliance?.proposals;

    if (proposal) {
      const last = proposal.length - 1;
      setProposal(proposal[last]);
    }
  }, []);

  useEffect(() => {
    if (proposal) {
      const marks = [];
      const marksPlazo = [];
      const monto = proposal?.monto || 0;
      const plazo = proposal?.plazo || 0;
      const tasa = proposal?.tasa || 0;
      setPlazo(plazo);
      setValue(monto);
      setTasa(tasa);
      marks.push({
        value: 100000,
        label: `$${formatNumber(100000)}`,
      });
      marks.push({
        value: monto,
        label: `$${formatNumber(monto)}`,
      });

      setMarks(marks);
      const min = 6;
      const max = proposal?.plazo || 0;
      const diff = max - min;
      let stepsPlazo = 0;
      while (stepsPlazo <= diff) {
        marksPlazo.push({
          value: min + stepsPlazo,
          label: `${min + stepsPlazo} meses`,
        });
        stepsPlazo += 6;
      }
      // setPlazo(min);
      setMarksPlazo(marksPlazo);
    }
  }, [proposal]);

  useEffect(() => {
    if (value !== 0 && plazo !== 0) {
      const variacion = tipo === "PM" ? value : value * 1.0017;

      const porcentaje = tasa / 100;
      let mensual = (porcentaje * value * 0.58 + variacion) / plazo;

      mensual = mensual.toFixed(2);

      setMensual(mensual);
    }
  }, [value, plazo, tipo, tasa]);

  const CloseModal = (value) => {
    setShowModal(false);
  };

  const handleSliderChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

  const handlePlazoChange = (event, newValue) => {
    console.log(newValue);
    setPlazo(newValue);
  };

  const handleNext = () => {
    history.goBack();
  };

  const updateProposal = async () => {
    dispatch(updateLoader(true));
    logParams( "record", "Botón Oferta", null);
    const user = JSON.parse(sessionStorage.getItem("user"));
    const id = user?._id;
    const data = {
      howMuch: value,
    };

    await Axios.post(`api/amount/new/${id}`, data)
      .then((res) => {
        const { user } = res.data;
        dispatch(updateLoader(false));
        dispatch(updateUserData(user));
        logParams( "record", "Condiciones de Oferta", { 'Monto': proposal?.monto, 'Plazo': proposal?.plazo, 'Tasa': proposal?.tasa, 'Comisión': proposal?.comision });
        logParams( "record", "Monto de la Oferta", value);
        sessionStorage.setItem("user", JSON.stringify(res.data.user));
        Swal.fire({
          title: "¡Felicidades!",
          text: "Continuaremos el analisis con el monto solicitado, en breve nos pondremos en contacto contigo para avanzar con tu proceso, si aun no has terminado de llenar tu solicitud, es importante que lo hagas para que podamos continuar con tu proceso",
          icon: "success",
          customClass: {
            title: "title-dp fz22",
            popup: "text-dp fz14",
            confirmButton: "btn-blue-general btn btn-primary p-0 Oferta_Aceptada​",
          },
          confirmButtonText: "aceptar",
        }).then((result) => {
          if (result.isConfirmed) {
            handleNext();
          }
        });
      })
      .catch((error) => {
        console.log("Error de servicio", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Ocurrió un error al enviar la información, por favor intenta de nuevo",
        });
      })
      .finally(() => {
        dispatch(updateLoader(false));
      });
  };

  const mensaje = encodeURIComponent(
    `Hola, tengo dudas sobre mi crédito pre-autorizado, mi solicitud es la ${solicitud} y mi nombre es `
  );

  return (
    <>
      <ProposalModal show={showModal} close={CloseModal} approved={true} />
      <Loader />
      <Grid container style={{ maxWidth: "80%", margin: "auto" }}>
        <Grid item xs={12} className="title-dp text-center fz900">
          <h1>¡Felicidades!</h1>
        </Grid>
        <Grid
          item
          xs={12}
          className="text-center"
          style={{ maxWidth: "90%", margin: "auto" }}
        >
          <div
            className="text-dp-gray fw300 fz20 mb-3"
            style={{ textAlign: "center" }}
          >
            Tienes una propuesta de crédito pre-autorizada hasta por un monto de{" "}
            <br />
            <span className="fw600">
              ${formatNumber(proposal?.monto || 0)}
            </span>{" "}
            pesos a <span className="fw600">{proposal?.plazo} meses</span> con
            una tasa de interés del{" "}
            <span className="fw600">{proposal?.tasa}% anual</span> y comisión
            por apertura del{" "}
            <span className="fw600">{proposal?.comision}%</span>
          </div>
        </Grid>
        <Grid item xs={12} className="text-center">
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ textAlign: "center" }}
            >
              <span className="title-dp fw600 fz20 mb-3">
                Selecciona el monto que deseas solicitar
              </span>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="text-dp-gray fw300 fz20 mb-3">
                <span className="fw600">${formatNumber(value)} MXN</span>
              </div>

              <StyledSlider
                value={value}
                step={proposal?.monto / 10}
                min={100000}
                max={proposal?.monto}
                marks={marks}
                style={{ width: "60%", margin: "auto" }}
                onChange={handleSliderChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="text-center mt-5">
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ textAlign: "center" }}
            >
              <span className="title-dp fw600 fz20 mb-3">
                Tu pago mensual aproximado sería de
              </span>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="text-dp-gray fw300 fz20 mb-3">
                <span className="fw600">${formatNumber(mensual)} MXN</span>
              </div>
              <div
                className="text-dp-gray fw300"
                style={{ width: "60%", margin: "auto" }}
              >
                <span className="fw600">
                  <small className="text-dp-gray fw300">
                    *El montó de tu pago mensual puede variar de acuerdo a la
                    fecha de disposición, montos sujetos a validación de crédito
                  </small>
                  <br />
                  {/* <small className="text-dp-gray fw600">
                    *Sujeto a análisis crediticio
                  </small> */}
                </span>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className="text-center mt-5"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            maxWidth: "70%",
          }}
        >
          <Button
            type="button"
            className={"btn-blue-general"}
            onClick={() => updateProposal()}
            style={{
              minWidth: "300px",
              minHeight: "60px",
              height: "fit-content",
            }}
          >
            Acepto la oferta, continuar
          </Button>
          <span
            className="text-dp fw300 fz20 mb-3 mt-3"
            style={{ cursor: "pointer" }}
            onClick={() => handleNext()}
          >
            regresar
          </span>
          <span className="text-dp-gray fw300 fz16 mb-3 mt-3">
            ¿Tienes preguntas? <br />
            Comunicate con nosotros y uno de nuestros asesores con gusto te va a
            ayudar <br />
            <br />
            <span className="fw600 mt-2 mb-1">
              Horario de atención: Lunes a Viernes de 9:00 a 18:00 hrs
            </span>{" "}
            <br />
            <a
              href={`https://api.whatsapp.com/send?phone=525621109079&text=${
                mensaje || ""
              }&source=&data=&app_absent=`}
              target="blank"
            >
              Whatsapp 56 2110 9079
            </a>
            <br />
            <a href="mailto:creditodigital@finsus.mx">
              {" "}
              creditodigital@finsus.mx{" "}
            </a>{" "}
            <br />
          </span>
        </Grid>
      </Grid>
    </>
  );
};

export default Proposal;

const Plazo = ({ proposal, marksPlazo, plazo, handlePlazoChange }) => {
  return (
    <Grid item xs={12} className="text-center mt-5">
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ textAlign: "center" }}
        >
          <span className="title-dp fw600 fz20 mb-3">
            Selecciona el plazo que deseas solicitar
          </span>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className="text-dp-gray fw300 fz20 mb-3">
            <span className="fw600">{plazo} meses</span>
          </div>
          {/* <Input
                value={value}
                margin="dense"
                onChange={handleInputChange}
                inputProps={{
                  step: proposal?.monto / 10,
                  min: 100000,
                  max: proposal?.monto,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              /> */}
          <StyledSlider
            value={plazo}
            step={6}
            min={6}
            max={proposal?.plazo}
            marks={marksPlazo}
            style={{ width: "60%", margin: "auto" }}
            onChange={handlePlazoChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

{
  /* <Grid container className="mt-4">
            <Grid
              item
              xs={12}
              sm={6}
              className="text-center text-dp-gray fw300 fz14 mb-3"
            >
              <h3>Propuesta de crédito</h3>
              <p>Revisa bien tu propuesta</p>
              <div>
                <span
                  style={{
                    color: "#007bff",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowModal(true)}
                >
                  Ver Propuesta
                </span>
              </div>
              {responseSignature && (
                <div className="signature__preview">
                  <img
                    src={responseSignature}
                    alt=""
                    style={{ maxWidth: "500px" }}
                  />
                </div>
              )}
            </Grid>

          </Grid> */
}
