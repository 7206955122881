import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SignupFormBrokers from "../../forms/SignupFormBrokers";
import "react-toastify/dist/ReactToastify.css";
import "../../css/signup.css";
import Loader from "../Loader/Loader";
import { updateLoader } from '../../redux/actions/loaderActions';
import { singUpAction } from '../../redux/actions/authActions';
import { updateToast } from '../../redux/actions/appActions';
import axios from '../../utils/axios';
import StepSignup from "../Appliance/StepSignup";
import {publicIpv4} from "public-ip";
import { useHistory } from 'react-router-dom';
import { Grid } from "@material-ui/core";
import { Banner } from "../metamap/Banner";
import useKissmetrics from "../../utils/hooks/useKissmetrics";

const getVersionImage = () => {
  const currentSize = document.getElementsByTagName("body")[0].clientWidth;
  return currentSize < 775 ? 1 : 0;
};

const SignupBrokers = props => {
  const toast = useSelector(state => state.app.toast);
  const [ownerId] = useState(props.match.params.ownerId ? props.match.params.ownerId : '');
  const [theme, setTheme] = React.useState(null);
  const [versionImage, setVersionImage] = useState(getVersionImage());
  const [errorEmail, setErrorEmail] = useState("");
  const [errorBroker, setErrorBroker] = useState("");
  const [initialValues, setInitialValues] = useState({});
  const history = useHistory();

  window.addEventListener("resize", () => setVersionImage(getVersionImage()));
  const baners = ["a11rz6ucs5hjxmgp9gpv", "brvzbh57otlwu80yxb4g"];
  const logParams = useKissmetrics();

  useEffect(() => {
		setTheme('finsus');
    logParams("record", "Visita", { page: "Registro Brokers" });
	  }, []);

    useEffect(() => {
      if (!theme) return;
      if (theme === "finsus") {
        setTheme("finsus  crédito digital");
      }
    }, [theme]);


  useEffect(() => {
    if(ownerId !== ''){
      setInitialValues({
        brokercode: ownerId
      });
      logParams("record", "BROKER", { brokercode: ownerId });
    }
  },[ownerId]);

  const dispatch = useDispatch();

  let onFormSubmit = async (data) => {
    data.channel = 'Brokers';
    dispatch ( updateToast(toast, "register") );
    dispatch( updateLoader(true) );
    let ipV4 = await publicIpv4();
    data.ipV4 = ipV4;

    const res = await axios.post('signin', data);
    if(res.data.code === 403){
      setErrorBroker(res.data.msg)
    }
    else if (res.data.code === 500){
      setErrorEmail(res.data.msg)
    } else {
      logParams("record", "Nuevo Registro", { email: data.email, coachId: ownerId, rfc: data.rfc });
      setErrorEmail("")
      setErrorBroker("")
      dispatch( singUpAction(data) );
    }
    dispatch (updateLoader(false));
  }

  if (sessionStorage.getItem("nameUser")) {
    setTimeout(() => {
      history.push("/home");
    }, 5000)

  }
  else {
     window.scrollTo(0, 0)
    return (
      <div className="">
        <Loader />

        {
          ownerId !== '322586238' &&
        <>
        <Banner baners={baners[versionImage]}>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "center" }}
            >
              <span
                style={{ color: "white" }}
                className={`coolvetica fw300 ${
                  versionImage === 1 ? "fz32" : "fz48"
                }`}
              >
                {" "}
                Bienvenido a finsus{" "}
              </span>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "center" }}
              className="mt-5"
            >
              <span
                style={{ color: "white", display: "block", lineHeight: "0.5" }}
                className={`metropolisReg fw400 ${
                  versionImage === 1 ? "fz70" : "fz100"
                }`}
              >
                {" "}
                créd<span className="clipText">i</span>to{" "}
              </span>
              <span
                style={{ 
                  color: "white", 
                  textAlign: "end",
                  display: "inline-block",
                  wordSpacing: "0.5rem",
                  // width: versionImage === 1 ? "65%" : "40%",
                }}
                className={`coolvetica fw400 hdwh ${
                  versionImage === 1 ? "fz70" : "fz100"
                }`}
              >
                {" "}
                dp{" "}
              </span>
            </Grid>
          </Grid>
        </Banner>

        <div className=" text-dp fw300 fz20 text-center mb-30">
          <p className="gray50">Te ayudamos a ofrecer las mejores opciones de crédito a tus clientes <br/> <label className="blue-primary">&nbsp;#AsíDeFinsus&nbsp;</label></p>
        </div> 

        <div className="mt-50">
          <StepSignup/>
        </div>

        </>
        }

        <SignupFormBrokers handleSubmit={onFormSubmit} initialValues={initialValues} ownerId={ownerId} errorEmail={errorEmail} setErrorEmail={setErrorEmail}/>
      </div>
    );
  }
};

export default SignupBrokers;
