import React from "react";
import isAuthenticated from "./utils/isAuthenticated";
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Landing from "./components/Landing/Landing";
import CIECcomponent from "./components/ciec/ciec";
import RecoverPassword from "./components/RecoverPassword/RecoverPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import Signup from "./components/Signup/Signup";
import SignupBrokers from "./components/Brokers/SignupBrokers";
import RegistroExitoso from "./components/Registro/RegistroExitoso";
import Login from "./components/Login/Login";
import Home from "./components/Home/Home";
import GeneralInfo from "./components/Appliance/GeneralInfo";
import ComercialInfo from "./components/Appliance/ComercialInfo";
import Amount from "./components/Appliance/Amount";
import Appliance from "./components/Appliance/Appliance";
// import Credit from "./components/Credit/Credit";
import Credit from "./components/Credit/Credit_copy";
import Brokers from "./components/Brokers/Brokers";
import CPBrokers from "./components/Brokers/cpBrokers";
import ThankyouPage from "./components/Brokers/ThankyouPage";
import Counter from "./components/Counter/Counter";
import Solicitud from "./components/Solicitud/Solicitud";
import Propuestas from "./components/Propuestas/Propuestas";
import Dashboard from "./components/Dashboard/Dashboard";
import DeleteComp from "./components/delete/delate";
import WaitPage from "./components/waitPage/WaitPage";
import NewDoc from "./components/metamap/NewDoc";
import Owners from "./components/admin/owners";
import LoginAdmin from "./components/admin/loginAdmin";
import SettingsCards from "./components/admin/SettingsCards";
import AdminLanding from "./components/admin/AdminLanding";
import Consultas from "./components/admin/consultas";
import NextPageP from "./components/waitPage/NextPage";
import CiecWait from "./components/ciec/CiecWait";
import BuroExt from "./components/Appliance/BuroExt";
import MiFiel from "./components/waitPage/MiFiel";
import MiFielExt from "./components/waitPage/MiFielExt";
import Proposal from "./components/Credit/proposal";
import ContactWithView from "./components/Appliance/ContactWith";
import CreditoCopia from "./components/Appliance/frontCopia";
import KYC from "./components/Appliance/KYC";
import PantallaAuthCode from "./components/auth/PantallaAuth";
import { Signatures } from "./components/Credit/Signatures";
import Incode from "./components/Incode/Incode";
import TYC from "./components/TYC/TYC";
import MembersInfo from "./components/Appliance/MembersInfo";
import NuevaAlianza from "./components/admin/NuevaAlianza";
import Alianzas from "./components/admin/Alianzas";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() === true ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

const routes = [
  {
    path: "/",
    component: Login,
    key: "LANDING",
  },
  {
    path: "/login/:redirect?",
    component: Login,
    key: "LOGIN",
  },
  {
    path: "/admin/login",
    component: LoginAdmin,
    key: "LOGINADMIN",
  },
  {
    path: "/descargar",
    component: CreditoCopia,
    key: "CREDITOCOPIA",
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    key: "RESETPASSWORD",
  },
  {
    path: "/recover-password/:hash",
    component: RecoverPassword,
    key: "RECOVERPASSWORD",
  },
  {
    path: "/registrate/:financialPartner?/:ownerId?",
    component: Signup,
    key: "SIGNUP",
  },
  {
    path: "/registroempresascaptacion",
    component: Signup,
    key: "SIGNUPCAPTACION",
  },
  {
    path: "/delete",
    component: DeleteComp,
    key: "DELETECOMP",
  },
  {
    path: "/registroexitoso",
    component: RegistroExitoso,
    key: "REGISTROEXITOSO",
  },
  {
    path: "/contador",
    component: Counter,
    key: "COUNTER",
  },
  {
    path: "/dashboard/:token",
    component: Dashboard,
    key: "DASHBOARD",
  },
  {
    path: "/brokers-registro",
    component: Brokers,
    key: "BROKERS",
  },
  {
    path: "/registro/:camp?",
    component: CPBrokers,
    key: "BROKECAMP",
  },
  {
    path: "/authcode/:idAppliance",
    component: PantallaAuthCode,
    key: "AUTHCODE",
  },
  {
    path: "/solicitud_enviada_brokers",
    component: ThankyouPage,
    key: "THANKYOU",
  },
  {
    path: "/solicitudBrokers/:ownerId?",
    component: SignupBrokers,
    key: "SIGNUPBROKERS",
  },
  {
    path: "/renewciec/:id?",
    component: CIECcomponent,
    key: "CIEC",
  },
  {
    path: "/buro-ext",
    component: BuroExt,
    key: "BUROEXT",
  },
  {
    path: "/proposal/:rfc?",
    component: Proposal,
    key: "PROPOSAL",
  },
  {
    path: "/proposal/signature/:rfc?",
    component: Signatures,
    key: "SIGNATURES",
  },
  {
    path: "/widget/:id",
    component: MiFielExt,
    key: "MIFIELEXT",
  },
  {
    path: "/kyc/:id",
    component: KYC,
    key: "KYC",
  },
  {
    path: "/credito",
    component: Credit,
    key: "CREDIT",
    private: true,
  },
  {
    path: "/credito/solicitud/:idAppliance",
    component: Appliance,
    key: "APPLIANCE",
    private: true,
  },
  {
    path: "/micuenta",
    component: Home,
    key: "HOME",
    private: true,
  },
  {
    path: "/estatus_solicitud",
    component: Solicitud,
    key: "SOLICITUD",
    private: true,
  },
  {
    path: "/propuestas",
    component: Propuestas,
    key: "PROPUESTAS",
    private: true,
  },
  {
    path: "/ciec/:idAppliance",
    component: CiecWait,
    key: "CIECWAIT",
    private: true,
  },
  {
    path: "/elige-monto/:idAppliance",
    component: Amount,
    key: "AMOUNT",
    private: true,
  },
  {
    path: "/datos-comerciales/:idAppliance",
    component: ComercialInfo,
    key: "COMERCIALINFO",
    private: true,
  },
  {
    path: "/informacion-comercial/:idAppliance",
    component: ComercialInfo,
    key: "COMERCIALINFO",
    private: true,
  },
  {
    path: "/informacion-general/:idAppliance",
    component: MembersInfo,
    key: "GENERALINFO",
    private: true,
  },
  {
    path: "/referencias/:idAppliance",
    component: ContactWithView,
    key: "WAITPAGE",
    private: true,
  },
  {
    path: "/auth/:idAppliance",
    component: NextPageP,
    key: "NEXTPAGEP",
    private: true,
  },
  {
    path: "/mifiel/:idAppliance",
    component: MiFiel,
    key: "MIFIEL",
    private: true,
  },
  {
    path: "/documentos/:idAppliance",
    component: NewDoc,
    key: "NEWDOC",
    private: true,
  },
  {
    path: "/admin",
    component: AdminLanding,
    key: "ADMINLANDING",
    private: true,
  },
  {
    path: "/admin/consultas",
    component: Consultas,
    key: "CONSULTAS",
    private: true,
  },
  {
    path: "/admin/control",
    component: SettingsCards,
    key: "SETTINGSCARDS",
    private: true,
  },
  {
    path: "/admin/owners",
    component: Owners,
    key: "OWNERS",
    private: true,
  },
  {
    path: "/admin/alianzas",
    component: Alianzas,
    key: "ALIANZAS",
    private: true,
  },
  {
    path: "/verification",
    component: Incode,
    key: "INCODE",
    private: false,
  },
  {
    path: "/tyc",
    component: TYC,
    key: "TYC",
    private: false,
  }
];

export default function Routes() {
  return (
    <Switch>
      {routes.map((route) => {
        if (route.private) {
          return (
            <PrivateRoute
              key={route.key}
              path={route.path}
              exact
              component={route.component}
            />
          );
        } else {
          return (
            <Route
              key={route.key}
              path={route.path}
              exact
              component={route.component}
            />
          );
        }
      })}
      <Redirect to="/" />
    </Switch>
  );
}
