import React, {useEffect} from "react";
    
const HubspotCalendar = props => {
    // const { region, portalId, formId } = props;
    const { open } = props;
    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';

        script.addEventListener('load', () => {
            if(window.hbspt) {
                console.log('hbspt loaded');
            }
        });

        const hubspotCalendar = document.getElementById('hubspotCalendar');

        if(!hubspotCalendar) {
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            }
        };

        hubspotCalendar.innerHTML = '';
        hubspotCalendar.appendChild(script);

        return () => {
            hubspotCalendar.innerHTML = '';
        }

    }, []);

    if(!open) return null;

    return (
        <div>
            
        </div>
    );
};

export default HubspotCalendar;