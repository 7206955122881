import {
    SET_USER_DATA,
    UPDATE_USER_DATA
} from '../types/userTypes';

const initialState = {
    user: JSON.parse(sessionStorage.getItem("user")) || null
};

export default function(state = initialState, action){
    switch(action.type){
        case SET_USER_DATA:
        case UPDATE_USER_DATA:
            return {
                ...state,
                user: action.payload
            }
        default:
            return state;
    }
}