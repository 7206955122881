import {metaRFC} from './validateRFC.js';

export const validateCiec = (values) => {
    let errors = {};
    
    if (!values.rfc) {
        errors.rfc = "Ingresa tu RFC";
    }

    if (!values.ciec) {
        errors.ciec = "Ingresa tu CIEC";
    } else if (values.ciec.length < 8) {
        errors.ciec = "El CIEC debe tener al menos 8 caracteres";
    } else if(values.checkCiec !== undefined && values.checkCiec) {
        if (values.ciecOrigen !== "") {
            errors.ciec = "La CIEC no coincide con la registrada";
        }
    }
    if (!values.tyc) {
        errors.ciec = "Debes aceptar los términos y condiciones";
    }

    console.log(errors);
    return errors;
}
