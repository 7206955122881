import React, { useState, useEffect } from "react";
import Title from "../Generic/Title";
import GeneralInfoForm from "../../forms/GeneralInfoForm";
import GeneralInfoFormNew from "../../forms/GeneralInfoFormNew";
import { useSelector, useDispatch } from "react-redux";
import CustomModal from "../Generic/CustomModal";
import { execToast } from "../../utils/ToastUtils";
import { buroPrueba } from "../../redux/actions/buroActions";
import { WebSocketContext } from "../../context/WebSocket";
import generalInfoOptions from "../../models/GeneralInfoModels";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Grid,
} from "@material-ui/core";
import { scrollToTop } from "../../utils/scroll";
// Components
import Steps from "./Steps";
import { updateDate } from "../../redux/actions/appActions";
import { updateLoader } from "../../redux/actions/loaderActions";
import axios from "../../utils/axios";
import axiosBase from "axios";
import Loader from "../Loader/Loader";
import { useHistory } from "react-router";
import Axios from "../../utils/axios";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { updateUserData } from "../../redux/actions/authActions";
import useAxios from "../../utils/hooks/useStatus";
import useKissmetrics from "../../utils/hooks/useKissmetrics";

const analisis = axiosBase.create({
  baseURL: "https://rest-server-n.herokuapp.com/api",
});

const GeneralInfo = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const toast = useSelector((state) => state.app.toast);
  const buro = useSelector((state) => state.buro);
  const docs = useSelector((state) => state.documents);
  const [initialValues, setInitialValues] = useState({});
  const [members, setMembers] = useState([]);
  const [memberNumber, setMemberNumber] = useState(1);
  const [porcentajeGeneral, setPorcentajeGeneral] = useState(0);
  const [solicitud, setSolicitud] = useState("");
  const [socketId, setSocketId] = useState(null);
  const [realOwner, setRealOwner] = useState(false); // [true, false]
  const [dataIne, setDataIne] = useState({});
  const [currentDate, setCurrentDate] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [nuevo, setNuevo] = useState(false);
  const [owner, setOwner] = useState(false);

  const { socket } = React.useContext(WebSocketContext);
  const user = JSON.parse(sessionStorage.getItem("user"));

  // let $user = JSON.parse(sessionStorage.getItem("user"));
  const cargando = useAxios(user._id);
  const logParams = useKissmetrics();

  useEffect(() => {
    if (docs.hasOwnProperty("ineGet")) {
      setDataIne(docs);
    }
  }, [docs]);

  useEffect(() => {
    scrollToTop();
    dispatch(buroPrueba());
  }, []);

  const verifyAppliance = (array) => {
    if (typeof array !== "object") return false;
    return array.length === 0 ? false : array[array.length - 1];
  };

  const verify = (object, property) => {
    return object.hasOwnProperty(property);
  };

  function getAge(dateString) {
    const today = new Date();
    const birthDate = new Date(dateString);
    const age = today.getFullYear() - birthDate.getFullYear();

    return age;
  }

  function getDataRfc(rfc, t) {
    if (rfc === undefined || rfc === null || rfc === "") {
      const user = JSON.parse(sessionStorage.getItem("user"));
      const type = user.idClient.type;
      if (type !== "PM") {
        rfc = user.rfc;
      } else {
        return;
      }
    }
    let fecha = rfc.substring(4, 10);
    let year = fecha.substring(0, 2);
    let month = fecha.substring(2, 4);
    let day = fecha.substring(4, 6);

    if (day < 10) {
      day = `0${day}`;
    }

    let birthDate = `${day}/${month}/${year}`;
    let age = getAge(birthDate);
    setInitialValues({
      ...initialValues,
      day,
      month,
      year,
      age,
    });
    if (t === true) {
      return {
        day,
        month,
        year,
        age,
      };
    }
  }

  useEffect(() => {
    let user = JSON.parse(sessionStorage.getItem("user"));
    const idClient = user.idClient;
    let appliance = verifyAppliance(idClient.appliance);
    let idComercialInfo = verify(appliance, "idComercialInfo");
    let idFiscal = verify(appliance, "idFiscal");
    let rfcPerson = "";

    if (idFiscal) {
      let fiscal = appliance.idFiscal;
      if (fiscal.hasOwnProperty("rfcPerson")) {
        rfcPerson = fiscal.rfcPerson;
        getDataRfc(rfcPerson);
      }
      return;
    }

    if (idComercialInfo) {
      if (idClient.type !== "PM") {
        let comercial = appliance.idComercialInfo;
        if (comercial.hasOwnProperty("rfc")) {
          rfcPerson = comercial.rfc;
          getDataRfc(rfcPerson);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (members.length > 0) {
      let porcentaje = 0;
      let real = false;
      members.forEach((member) => {
        porcentaje += parseInt(member.percentage);
        if (Object.keys(member.roles).length > 0) {
          if (member.roles[3] === true) {
            member.Apoderado = true;
            real = true;
          }
        }
      });
      setPorcentajeGeneral(porcentaje);
      setRealOwner(real);
    } else if (members.length === 0) {
      setPorcentajeGeneral(0);
      setMemberNumber(0);
      setRealOwner(false);
      // setShowForm(true);
      setSolicitud("");
    }
  }, [members]);

  const ordenarMembers = (index, member) => {
    let membersArray = [...members];
    if (index === 0) {
      membersArray[index] = member;
      setMembers(membersArray);
      return;
    }
    if (index === members.length - 1) {
      membersArray[index] = member;
      setMembers(membersArray);
      return;
    } else {
      membersArray[index] = member;
      setMembers(membersArray);
      return;
    }
  };

  const onFormSubmit = async (dataForm) => {
    dispatch(updateLoader(true));
    logParams("record", "Continuar datos del socio", null);
    setShowToast(true);
    const user = JSON.parse(sessionStorage.getItem("user"));
    const type = user.idClient.type;
    if (type === "PM") {
      const rfc = dataForm.rfcPerson;
      const dataRFC = getDataRfc(rfc, true);
      dataForm = { ...dataForm, ...dataRFC };
    } else {
      const rfc = user.rfc || dataForm.rfcPerson;
      const dataRFC = getDataRfc(rfc, true);
      dataForm = { ...dataForm, ...dataRFC };
    }
    const id = user._id;
    const idClient = user.idClient;
    if (solicitud !== "") {
      dataForm._id = solicitud;
    }
    let birthDate = new Date(
      `${dataForm.day}/${dataForm.month}/${dataForm.year}`
    );

    const unixBith = new Date(
      Date.UTC(dataForm.year, dataForm.month - 1, dataForm.day)
    );
    const UNIX = unixBith.getTime();

    const member = {
      ...dataForm,
      birthDate: birthDate,
      UNIX: UNIX,
      index: memberNumber,
      porcentaje: porcentajeGeneral,
    };

    if (members.length > 0) {
      ordenarMembers(dataForm.index, member);
      const porcentaje = parseInt(member.percentage);
      setPorcentajeGeneral(porcentajeGeneral + porcentaje);
    } else {
      setMembers([member]);
    }
    let score = null;

    // console.log(score);

    let data = { ...member };
    if (idClient.appliance.length > 0) {
      const appliance = idClient.appliance[idClient.appliance.length - 1];

      await axios
        .post(`api/info-general/new/${id}`, data)
        .then(async (res) => {
          // console.log(res.data);
          const data = res.data;
          if (data.hasOwnProperty("user")) {
            sessionStorage.setItem("user", JSON.stringify(data.user));
            dispatch(updateUserData(data.user));
          }
          if (data.hasOwnProperty("members")) {
            setMembers(data.members.members);
          }
          if (type !== "PM") {
            await axios
              .get(`/api/info-general/members/${id}`)
              .catch((err) => {
                console.log(err);
              })
              .finally(() => {
                dispatch(updateLoader(false));
                history.push(`/auth/${id}`);
              });
          } else {
            setShowForm(false);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(updateLoader(false));
        });
    }
    dispatch(updateLoader(false));
  };

  function handleInitialValues(values) {
    Object.keys(values).map((key) => {
      setInitialValues((I) => ({ ...I, [key]: values[key] }));
    });
  }

  useEffect(() => {
    const getInitialValues = async () => {
      const user = JSON.parse(sessionStorage.getItem("user"));
      const idClient = user.idClient;
      let members = false;
      if (idClient.appliance.length > 0) {
        const appliance = idClient.appliance[idClient.appliance.length - 1];
        if (appliance.hasOwnProperty("idGeneralInfo")) {
          const idGeneralInfo = appliance.idGeneralInfo;
          Object.keys(idGeneralInfo).map((key) => {
            if (
              idGeneralInfo[key] === null ||
              idGeneralInfo[key] === undefined
            ) {
              handleInitialValues({ [key]: "" });
            } else {
              switch (key) {
                case "curp":
                  const curp = idGeneralInfo[key].toUpperCase();
                  handleInitialValues({ curp });
                  break;
                case "contactWith":
                  let params = {};
                  const { contactWith } = idGeneralInfo;
                  contactWith.forEach((contact, index) => {
                    const { name, phone, relative } = contact;
                    params = {
                      ...params,
                      [`name${index + 1}`]: name,
                      [`phone${index + 1}`]: phone,
                      [`relative${index + 1}`]: relative,
                    };
                  });

                  handleInitialValues(params);
                  break;
                case "creditCard":
                case "mortgageCredit":
                  idGeneralInfo[key] === true
                    ? handleInitialValues({ [key]: "1" })
                    : handleInitialValues({ [key]: "0" });
                  // setCreditCard(idGeneralInfo.creditCard === true ? "1" : "0");
                  break;
                case "members":
                  if (user.idClient.type !== "PM") {
                    const member = idGeneralInfo.members[0];
                    setMemberNumber(0);
                    if (member === undefined) {
                      let empty = {};
                      empty.email = user.email;
                      handleInitialValues(empty);
                      return;
                    }
                    if (member._id !== undefined) {
                      setSolicitud(member._id);
                    }
                    member.carCredit === "true"
                      ? (member.carCredit = "YES")
                      : (member.carCredit = "NO");
                    // console.log(member);
                    member.email = user.email;
                    // member.creditCard = member.creditCard === true ? "1" : "0";
                    handleInitialValues({ ...idGeneralInfo[key][0] });
                  } else {
                    setMemberNumber(0);
                    setMembers(idGeneralInfo[key]);
                  }
                  if (idGeneralInfo.members.length !== 0) {
                    members = true;
                  }
                  break;
                default:
                  handleInitialValues({ [key]: idGeneralInfo[key] });
                  break;
              }
            }
          });
        } else {
          setMemberNumber(0);
          if (user.idClient.type !== "PM") {
            setInitialValues({ percentage: 100 });
            setPorcentajeGeneral(100);
          } else {
            setPorcentajeGeneral(0);
          }
          setShowForm(true);
        }
      }

      if (!members) {
        setShowForm(true);
      }
    };

    const date = new Date();
    const currentDate = date.toISOString().slice(0, 10);
    const dateArray = currentDate.split("-");
    const newDate = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;

    setCurrentDate(newDate);
    getInitialValues();
  }, []);

  const headers = [
    "Nombre",
    "RFC",
    // "Rol",
    "Participación Accionaria",
    "E-mail",
    "Teléfono",
  ];

  function handleForm(index) {
    if (members.length === 0) return;
    const member = members[index];
    setMemberNumber(index);

    const { street, extNumber, intNumber, zipCode, town, municipality, state } =
      member.address || {};
    const data = {
      ...member,
      street,
      extNumber,
      intNumber,
      zipCode,
      town,
      municipality,
      state,
    };
    handleInitialValues(data);
    if (member._id !== undefined) {
      setSolicitud(member._id);
    }
    const porcentaje = parseInt(member.percentage);
    setPorcentajeGeneral(porcentajeGeneral - porcentaje);
    setShowForm(true);
  }

  function handleFormNew(acc) {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const idClient = user.idClient;
    const appliance = idClient.appliance[idClient.appliance.length - 1];
    const general = appliance.idGeneralInfo;
    // const members = general.members;

    switch (acc) {
      case "add":
        logParams("record", "Agregar socio​", null);
        setShowForm(true);
        setDataIne({});
        setMemberNumber(members.length);
        setInitialValues({});
        setNuevo(false);
        setSolicitud("");
        break;
      case "close":
        setShowForm(false);
        setInitialValues({});
        setSolicitud("");
        checkMembers();
        break;
      case "next":
        const member = members[memberNumber + 1];
        if (member === undefined) {
          setMemberNumber(members.length);
          const data = {};
          handleInitialValues(data);
          return;
        }
        const {
          street,
          extNumber,
          intNumber,
          zipCode,
          town,
          municipality,
          state,
        } = member.address;
        const data = {
          ...member,
          street,
          extNumber,
          intNumber,
          zipCode,
          town,
          municipality,
          state,
        };
        handleInitialValues(data);
        setMemberNumber(memberNumber + 1);
        break;
      default:
        break;
    }
  }

  function checkMembers() {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const idClient = user.idClient;
    const appliance = idClient.appliance[idClient.appliance.length - 1];
    const general = appliance.idGeneralInfo;
    if (general.members === undefined) return;
    const members = general.members;
    if (members.length > 0) {
      setMembers(members);
    } else {
      setMembers([]);
    }
  }

  useEffect(() => {
    if (user.idClient.type !== "PM") {
      setShowForm(true);
      setPorcentajeGeneral(100);
    }
  }, [members]);

  async function handleDocumentos() {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const { prefix } = user;
    const rolesCompeltos = {
      0: false,
      // 3: false,
    };
    const idClient = user.idClient;
    const appliance = idClient.appliance[idClient.appliance.length - 1];
    const general = appliance.idGeneralInfo;
    const members = general.members;
    members.forEach((member) => {
      if (member.roles !== undefined) {
        for (let key in member.roles) {
          if (member.roles[key] !== null) {
            if (member.roles[key] === true && rolesCompeltos[key] === false) {
              rolesCompeltos[key] = true;
            }
          }
        }
      }
    });

    let esOnbording = false;

    if (prefix && prefix !== "") {
      esOnbording = user.flujo === 1 ? true : false;
    }
    const roles = Object.values(rolesCompeltos);
    const rolesCompletos = roles.every((role) => role === true);
    if (!rolesCompletos) {
      const Apoderado = rolesCompeltos[0];
      // const Propietario = rolesCompeltos[3];
      let rolesFaltantes = [];
      if (!Apoderado) {
        rolesFaltantes.push("Representante Legal/Apoderado");
      }

      let rolesFaltantesString = "";
      rolesFaltantes.forEach((role, index) => {
        if (index === 0) {
          rolesFaltantesString = role;
        } else {
          rolesFaltantesString = `${rolesFaltantesString}, ${role}`;
        }
      });
      Swal.fire({
        title: "¡Atención!",
        text: `Debes de asignar almenos un: ${rolesFaltantesString}`,
        icon: "warning",
        confirmButtonText: "Aceptar",
        customClass: {
          title: "subtitle form fz22",
          popup: "text-dp fz18",
          confirmButton: "btn-blue-general btn btn-primary",
        },
      });
    } else {
      dispatch(updateLoader(true));
      await axios
        .get(`/api/info-general/members/${user._id}`)
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(updateLoader(false));
        });

      if (!esOnbording) {
        history.push(`/auth/${user._id}`);
      } else {
        history.push(`/documentos/${user._id}`);
      }
    }
  }

  const title =
    user.idClient.type !== "PM"
      ? "Datos personales"
      : "Datos Socios/Apoderados";

  return (
    <div className="container mt-3" style={{ minWidth: "85%" }}>
      <Loader />
      <Steps />
      {/* <ToastContainer /> */}
      {!showForm && (
        <>
          <div className="text-center mb-2">
            <Title title={title} className="title-dp fz42" />
          </div>
          <div className="mt-1 text-dp fw300 fz14 text-center mb-1">
            <p className="gray50">
              Por favor, registra a todos los socios que cuenten con {""}{" "}
              <span className="title-dp fw600 fz18"> 25% </span> o más de
              participación accionaria{" "}
              <em>
                (cumpliendo con un minimo del {""}{" "}
                <span className="title-dp fw600 fz18"> 76% </span> del cuadro
                accionario)
              </em>
              . Así como también registrar a los principales
              Apoderados/Representantes Legales. <br /> Asegurate de que la
              información sea la correcta, ya que se les contactará para validar
              la información.
            </p>
          </div>
          <div className="text-center" style={{ marginBottom: "20px" }}>
            <Button
              className="btn-blue-documents mb-2 mr-2"
              onClick={() => history.push("/credito")}
            >
              Regresar
            </Button>

            {members.length > 0 && (
              <Button
                className="btn-blue-documents mb-2"
                onClick={() => handleFormNew("add")}
                id="Agregar_socio​"
              >
                Agregar Socio
              </Button>
            )}
          </div>
        </>
      )}
      {showForm ? (
        <>
          <GeneralInfoForm
            today={new Date()}
            changeDate={updateDate}
            handleSubmit={(data) => {
              window.scroll(0, 0);
              onFormSubmit(data);
            }}
            setInitialValues={handleInitialValues}
            initialValues={initialValues}
            // positionRef={props.location.position_ref}
            currentDate={currentDate}
            handleForm={handleFormNew}
            nuevo={nuevo}
            memberNumber={memberNumber}
            porcentajeGeneral={porcentajeGeneral}
            socketId={socketId}
            dataIne={dataIne}
            realOwner={realOwner}
          />
          <CustomModal
            modalName="generalInfoError"
            message="Error al subir los archivos. Favor de regresar a la pantalla de inicio y continúa tu solicitud."
          />
        </>
      ) : (
        
          <TableContainer
            component={Paper}
            className="mb-3 shadow2"
            style={{ borderRadius: "10px", border: "none" }}
          >
            <Table aria-label="simple table">
              <TableHead style={{ backgroundColor: "#F2F2F2" }}>
                <TableRow>
                  {headers.map((header, index) => (
                    <TableCell
                      key={header + index}
                      align="center"
                      className="title-dp_clamp  fz16"
                      // colSpan={2}
                    >
                      {header}
                    </TableCell>
                  ))}
                  <TableCell
                    align="center"
                    className="title-dp fz16"
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {members &&
                  members.length > 0 &&
                  members.map((member, index) => (
                    <TableRow
                      key={index}
                      style={{
                        border: "none",
                        "&:nth-of-type(odd)": {
                          backgroundColor: "#F2F2F2",
                        },
                      }}
                    >
                      <TableCell
                        align="center"
                        className="text-dp"
                        style={{ borderRight: "1px solid #E0E0E0" }}
                      >
                        {member.type && member.type === "PM" ? (
                          <>{member.razonSocial}</>
                        ) : (
                          <>
                            {member.name} {member.lastname} {member.secondName}{" "}
                            {member.secondLastname}
                            {/* <span className="ml-1">
                          {member.Apoderado && (
                            <Chip
                              label="Apoderado"
                              className="chip-dp"
                              style={{ backgroundColor: "#619C2A" }}
                            />
                          )}
                        </span> */}
                          </>
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        className="title-dp_clamp"
                        style={{ borderRight: "1px solid #E0E0E0" }}
                      >
                        {member.type && member.type === "PM" ? (
                          <>{member.rfcSocioPM}</>
                        ) : (
                          <>{member.rfcPerson}</>
                        )}
                      </TableCell>
                      {/* <TableCell
                        align="center"
                        className="text-dp"
                        style={{ borderRight: "1px solid #E0E0E0" }}
                      >
                        {member.roles !== undefined &&
                          Object.keys(member.roles).map((key, index) => {
                            if (member.roles[key] === true) {
                              return (
                                <Chip
                                  key={index}
                                  label={generalInfoOptions.getRoles(key) || ""}
                                  className="chip-dp"
                                />
                              );
                            }
                          })}
                      </TableCell> */}
                      <TableCell
                        align="center"
                        className="text-dp"
                        style={{ borderRight: "1px solid #E0E0E0a6" }}
                        colSpan={1}
                      >
                        {member.percentage}
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-dp"
                        style={{
                          borderRight: "1px solid #E0E0E0",
                        }}
                      >
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={12}
                            style={{
                              textAlign: "center",
                              color: member.emailConfirmation
                                ? "#619C2A"
                                : "inherit",
                            }}
                          >
                            {member.email || "Sin correo"}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              textAlign: "center",
                              color: member.emailConfirmation
                                ? "#619C2A"
                                : "#f8542f",
                            }}
                          >
                            {
                              member.type !== "PM" &&
                              <>
                            {member.emailConfirmation ? (
                              <i className="fas fa-check-circle"></i>
                            ) : (
                              <i className="fas fa-times-circle"></i>
                            )}
                            
                            <span className="ml-1">
                              {!member.emailConfirmation
                                ? "Sin confirmar"
                                : "Confirmado"}
                            </span>
                            </>
                            }
                          </Grid>

                        </Grid>
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-dp"
                        style={{
                          borderRight: "1px solid #E0E0E0",
                          minWidth: "250px",
                        }}
                      >
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={12}
                            style={{
                              textAlign: "center",
                              color: member.phoneConfirmation
                                ? "#619C2A"
                                : "inherit",
                            }}
                          >
                            {member.phone || "Sin teléfono"}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              textAlign: "center",
                              color: member.phoneConfirmation
                                ? "#619C2A"
                                : "#f8542f",
                            }}
                          >
                            {
                              member.type !== "PM" &&
                              <>
                            {member.phoneConfirmation ? (
                              <i className="fas fa-check-circle"></i>
                            ) : (
                              <i className="fas fa-times-circle"></i>
                            )}
                            
                            <span className="ml-1">
                              {!member.phoneConfirmation
                                ? "Sin confirmar"
                                : "Confirmado"}
                            </span>
                            </>
                            }
                          </Grid>
                        </Grid>
                      </TableCell>

                      <TableCell align="center" className="text-dp">
                        <Button
                          variant="secondary"
                          className="btn-blue-documents"
                          style={{ width: "100%" }}
                          // className="btn btn-primary"
                          onClick={() => handleForm(index)}
                        >
                          Editar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        
      )}

      {members.length > 0 && porcentajeGeneral >= 76 && !showForm ? (
        <div className="d-flex justify-content-center">
          <Button
            className="btn-blue-documents mt-1 mb-5"
            onClick={() => handleDocumentos()}
            style={{ width: "50%" }}
          >
            continuar
          </Button>
        </div>
      ) : (
        !showForm && (
          <div className="d-flex justify-content-center">
            <Button
              className="btn-blue-general mt-30 disabled"
              onClick={() => handleFormNew("add")}
              style={{ width: "50%" }}
            >
              agregar socios
            </Button>
          </div>
        )
      )}
    </div>
  );
};

export default GeneralInfo;
