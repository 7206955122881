import React, { useState, useEffect, version } from "react";
import { Link, NavLink } from "react-router-dom";
import { Button, Carousel } from "react-bootstrap";
import DPBanner from "./Banner";
import { Grid, Typography } from "@material-ui/core";
import "../../../css/header.css";
import "../../../css/general.css";
import breakpoints from "../../../assets/theme/breakpoints";
import firstImage from "../../../assets/img/carousel/banner_home_FINSUS_web.webp";
import firstImageMobie from "../../../assets/img/carousel/banner_home_FINSUS_mobil-02.webp";
import { text } from "@fortawesome/fontawesome-svg-core";
// images[numImage][NormalImage, Mobile Image]
const images = [firstImage, firstImageMobie];

const getHeight = () => {
  let isMobile = window.matchMedia(
    "only screen and (max-width: 760px)"
  ).matches;
  let isTablet = window.matchMedia(
    "only screen and (max-width: 1024px)"
  ).matches;
  let isDesktop = window.matchMedia(
    "only screen and (min-width: 1025px)"
  ).matches;

  if (isMobile) {
    return 1;
  } else if (isTablet) {
    if (window.innerWidth < 950 && window.innerHeight < 767) {
      return 1;
    } else {
      return 2;
    }
  } else if (isDesktop) {
    if (window.innerWidth > 1080) {
      return 0;
    } else {
      return 2;
    }
  }
};

const Header = (props) => {
  const [versionImage, setVersionImage] = useState(props.estado);
  const [height, setHeight] = useState(getHeight());
  const [indexImage, setIndexImage] = useState(0);
  const [mobileView, setMobileView] = useState({});

  useEffect(() => {
    // funcion que cambia el estado de la barra de navegacion
    function displayMobileNavbar() {
      if (
        window.innerWidth > breakpoints.values.md &&
        window.innerWidth < breakpoints.values.lg
      ) {
        setMobileView({
          isMobile: false,
          isTablet: true,
        });
      } else if (window.innerWidth < breakpoints.values.md) {
        setMobileView({
          isMobile: true,
          isTablet: false,
        });
      } else {
        setMobileView({
          isMobile: false,
          isTablet: false,
        });
      }
    }

    /** 
             se agrega un evento al objeto window que se dispara cuando se cambia el tamaño de la ventana
            */
    window.addEventListener("resize", displayMobileNavbar);

    // se llama la funcion para que se ejecute cuando se carga la pagina
    displayMobileNavbar();

    // se elimina el evento cuando se desmonta el componente
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  const handleSelect = (selectedIndex, e) => {
    setIndexImage(selectedIndex);
  };

  useEffect(() => {
    // setVersionImage(props.estado);
    switch (height) {
      case 0:
        setVersionImage(0);
        break;
      case 1:
        props.estado === 0 ? setVersionImage(0) : setVersionImage(1);
        break;
      case 2:
        setVersionImage(0);
        break;
      default:
        setVersionImage(0);
        break;
    }
    setHeight(getHeight());
  }, [height, props.estado]);

  const styleWEB = {
    maxHeight: "100vh",
    overflow: "hidden",
    margin: "0 auto",
    padding: "0 2.5rem 0 2.5rem",
    border: "0",
  };
  const styleWEBM = {
    maxHeight: "85vh",
    overflow: "hidden",
    margin: "0 auto",
    padding: "0",
    border: "0",
    padding: "0 1rem 0 1rem",
  };

  const styleWEBT = {
    height: "auto",
    maxHeight: "80vh",
    width: "100vw",
    overflow: "hidden",
  };

  const styleMOBILE = {
    position: "absolute",
    top: "20%",
    width: "90%",
    left: "5%",
    textAlign: "center",
    fontSize: "2rem",
  };
  const styleweb = {
    position: "absolute",
    top: "14%",
    width: "40%",
    left: "7%",
  };

  const styleSubTitleWEB = {
    paddingLeft: "calc(30% + 1rem)",
    fontSize: "1rem",
  };

  const styleSubTitleMOBILE = {
    paddingLeft: "0",
    fontSize: "1rem",
  };

  const pasos = [
    "Crea tu cuenta",
    "Sube tus documentos",
    "Firma tu crédito",
    "Recibe tu dinero en una cuenta Finsus en menos de 24 horas",
  ];

  const { isTablet, isMobile } = mobileView;

  const textPyme = () => {
    return (
      <Grid item xs={12} lg={6} md={6}>
        <Grid
          container
          direction="column"
          px={{ xs: 3, lg: 12 }}
          sx={isMobile ? { marginTop: "2rem" } : null}
        >
          <Grid item sx={isMobile ? { textAlign: "center" } : null}>
            <div>
              <span
                className="header-title"
                style={!isMobile ? styleweb : styleMOBILE}
              >
                Democratizando el crédito en México <br />
                <span
                  className="header-text"
                  style={!isMobile ? styleSubTitleWEB : styleSubTitleMOBILE}
                >
                  Así de fácil, así de Finsus
                </span>
              </span>
            </div>
          </Grid>
          <Grid item mt={3}>
            <span
              className="header-text"
              style={{ position: "absolute", top: "45%", left: "10%" }}
            >
              {pasos.map((paso, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1.2rem",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "1rem",
                        color: "#F2F2F2",
                        marginRight: ".5rem",
                      }}
                    >
                      {index + 1}.
                    </div>
                    <span style={{ fontSize: "1rem", color: "#F2F2F2" }}>
                      {paso}
                    </span>
                  </div>
                );
              })}
            </span>
          </Grid>
          <Grid
            item
            mt={4}
            style={
              isMobile
                ? {
                    position: "absolute",
                    bottom: "10%",
                    left: "50%",
                    transform: "translate(-50%,-5%)",
                  }
                : {
                    position: "absolute",
                    bottom: "10%",
                    left: "20%",
                    transform: "translate(-50%,-5%)",
                  }
            }
            component={Link}
            to="/registrate"
          >
            <Button className="header-button fz18 btn_register bluePrimary">
              comenzar solicitud
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <DPBanner banners={images} mobileView={mobileView} component={textPyme()} />
  );
};

export default Header;
