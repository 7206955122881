import React, { useState, useEffect } from "react";

import "../css/general.css";
import { procedencia, tipoPago } from "../models/AmountModels";
import Title from "../components/Generic/Title";
import InputLabel from "../components/Generic/InputLabel";
import { validateKYC } from "../components/Validate/ValidateKYC";
import { Field, Form, withFormik } from "formik";
import { InfoSharp } from "@material-ui/icons";
import {
  FieldSelect,
  FieldText,
  FieldTextArea
} from "../components/Generic/Fields";
import GeneralInfoForm from "./GeneralInfoForm";
import { ButtonSubmit } from "../components/Generic/ButtonSubmit";
import { Col, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import Modal from "react-responsive-modal";
import { useSelector, useDispatch } from "react-redux";

const FormModal = (props) => {
  const { open, onCloseModal, onConfirmModal, index, porcentajeGeneral } =
    props;
  const [dataIne, setDataIne] = useState({});
  const docs = useSelector((state) => state.documents);
  const initialValues = { index: index };

  useEffect(() => {
    if (docs.hasOwnProperty("ineGet")) {
      setDataIne(docs);
    }
  }, [docs]);

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      styles={{ modal: { padding: "2rem", borderRadius: "10px", top: "0" } }}
      center
    >
      <GeneralInfoForm
        initialValues={initialValues}
        handleForm={onCloseModal}
        memberNumber={index}
        dataIne={dataIne}
        porcentajeGeneral={porcentajeGeneral}
      />
    </Modal>
  );
};

const KYCForm = (props) => {
  const [memebers, setMembers] = useState([]);
  const [open, setOpen] = useState(false);
  const [origen, setOrigen] = useState(false);
  const [otros, setOtros] = useState(false);
  const [porcentajeGeneral, setPorcentajeGeneral] = useState(0);

  const { isSubmitting, isValid, values, setFieldValue } = props;

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const idClient = user.idClient;
    const appliance = idClient.appliance[0];
    const generalInfo = appliance.idGeneralInfo;
    const members = generalInfo.members;

    if (members) {
      setMembers(members);
    }

    let porcentaje = 0;
    members.forEach((member) => {
      porcentaje += parseInt(member.percentage);
    });

    setPorcentajeGeneral(porcentaje);
  }, []);

  useEffect(() => {
    if (open) {
      Swal.fire({
        title: "Persona Politicamente Expuesta",
        text: "Una persona políticamente expuesta (PPE) es aquella que desempeña o ha desempeñado funciones públicas destacadas en un país extranjero, así como sus familiares y allegados.",
        confirmButtonText: "Entendido",
        customClass: {
          title: "subtitle form fz24",
          popup: "text-dp fz16",
          confirmButton: "btn-blue-general btn btn-primary",
        },
      }).then((result) => {
        if (result.value) {
          setOpen(false);
        }
      });
    }
  }, [open]);

  useEffect(() => {
    if (values.procedencia === "2") {
      setOrigen(true);
    } else {
      setOrigen(false);
    }

    if (values.propietario === "otro") {
      setOtros(true);
    } else {
      setOtros(false);
    }
  }, [values]);

  function handleCloseModal() {
    setFieldValue("propietario", "");
    setOtros(false);
  }

  return (
    <>
      <Form className="ml-auto mr-auto" style={{ maxWidth: "690px" }}>
        <div className="text-center">
          <Title title="Preguntas Adicionales" className="title-dp fz42" />
        </div>
        <div className="text-center">
          <label className="text-dp fz20 fw500 ml-auto mt-2 mb-1">
            cuéntanos un poco más
          </label>
        </div>
        <InputLabel label="¿Platicanos más a detalle cuál es el destino del crédito?" class="mt-18"  />
        <FieldTextArea name="destino" className="text-dp" placeholder="Ejemplo: Compra de maquinaria, compra de inventario, etc."/>
        <InputLabel label="¿Cuál es el origen de los recursos con los qué se pagara el crédito?" class="mt-18" />
        <FieldSelect name="procedencia" className="text-dp">
          <option value="">Elige...</option>
          {Object.keys(procedencia).map((value, i) => (
            <option value={value} key={i}>
              {procedencia[value]}
            </option>
          ))}
        </FieldSelect>
        {origen && (
          <FieldText
            name="procedenciaOtro"
            className="text-dp mt-18"
            label={"Especifica"}
          />
        )}

        <InputLabel label="¿Cómo sera la forma de pago?" class="mt-18" />
        <FieldSelect name="tipoPago" className="text-dp">
          <option value="">Elige...</option>
          {Object.keys(tipoPago).map((value, i) => (
            <option value={value} key={i}>
              {tipoPago[value]}
            </option>
          ))}
        </FieldSelect>

        <InputLabel
          label="¿La empresa ha sido objeto de alguna sanción o investigación?"
          class="mt-18"
        />

        <FieldSelect name="manifestacion" className="text-dp">
            <option value="">Elige...</option>
            <option value="true">Si</option>
            <option value="false">No</option>
          </FieldSelect>

        <InputLabel
          label="¿Quién es el propietario real de la sociedad?"
          class="mt-18"
        />
        <FieldSelect name="propietario" className="text-dp">
          <option value="">Elige...</option>
          {memebers.map((value, i) => (
            <option value={value._id} key={i}>
              {value.name}
            </option>
          ))}
          <option value="otro">Otro</option>
        </FieldSelect>

        <div className={`fz16 metropolisReg mt-18 mb-1`}>
          ¿Alguno de los socios/representantes o alguno de sus familiares
          cercanos es una{" "}
          <span
            className="title-dp fz16"
            style={{ cursor: "pointer" }}
            onClick={() => setOpen(true)}
          >
            persona políticamente expuesta?
            <InfoSharp className="ml-1" style={{ fontSize: "1.2rem" }} />
          </span>
        </div>

          <FieldSelect name="ppe" className="text-dp">
            <option value="">Elige...</option>
            <option value="true">Si</option>
            <option value="false">No</option>
          </FieldSelect>


        <div className="text-center" style={{ marginBottom: "50px" }}>
          <ButtonSubmit
            isSubmitting={isSubmitting}
            isValid={isValid}
            id={"Envío_PLD​"}
            onClick={async () => {
              await Object.keys(props.values).forEach((field) =>
                props.setFieldTouched(field, true)
              );
            }}
          />
        </div>
      </Form>
      <FormModal
        open={otros}
        onCloseModal={() => handleCloseModal()}
        index={memebers.length}
        porcentajeGeneral={porcentajeGeneral}
      />
    </>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    destino: props.initialValues.destino || "",
    procedencia: props.initialValues.procedencia || "",
    tipoPago: props.initialValues.tipoPago || "",
    procedenciaOtro: props.initialValues.procedenciaOtro || "",
    manifestacion: props.initialValues.manifestacion || "",
    propietario: props.initialValues.propietario || "",
    ppe: props.initialValues.ppe || "",
  }),
  validate: validateKYC,
  enableReinitialize: true,
  handleSubmit(values, formikBag) {
    formikBag.setSubmitting(false);
    formikBag.props.handleSubmit(values);
  },
  displayName: "KYCForm",
})(KYCForm);
