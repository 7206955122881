import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import '../../css/dashboard.css';
import ColocadoSound from '../../assets/sounds/Colocado.mp3';
import GeneralSound from '../../assets/sounds/General.mp3';
import { ToastContainer, toast } from "react-toastify";
import LogoFinsus from '../../assets/img/logo_dp/logo_Azul_finsus.svg';
import {WebSocketContext} from "../../context/WebSocket";
import { useParams } from 'react-router-dom';
var calculated = false;

const Dashboard = () => {

  const [section, setSection] = useState(0);
  const [mes, setMes] = useState("");
  let { token } = useParams();

  let today = new Date();
  let months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  
  let date = today.getDate() + ' de ' + months[(today.getMonth()+1)-1] + ' de ' + today.getFullYear();

  const [Colocado] = useState(new Audio(ColocadoSound));
  const [General] = useState(new Audio(GeneralSound));
  const [theme, setTheme] = useState(null);
  const [show, setShow] = useState(true);

  useEffect(() => {
    console.log(token);
  }, [token]);

  useEffect(() => {
		const theme = sessionStorage.getItem("theme");
		if (!theme) {
		  setInterval(() => {
			const theme = sessionStorage.getItem("theme");
			if (theme) {
			  setTheme(theme);
			}
		  }, 100);
		}
		setTheme(theme);
	  }, []);

    useEffect(() => {
      const date = new Date();
      const month = date.getMonth();

      switch (month) {
        case 0:
          setMes("Enero");
          break;
        case 1:
          setMes("Febrero");
          break;
        case 2:
          setMes("Marzo");
          break;
        case 3:
          setMes("Abril");
          break;
        case 4:
          setMes("Mayo");
          break;
        case 5:
          setMes("Junio");
          break;
        case 6:
          setMes("Julio");
          break;
        case 7:
          setMes("Agosto");
          break;
        case 8:
          setMes("Septiembre");
          break;
        case 9:
          setMes("Octubre");
          break;
        case 10:
          setMes("Noviembre");
          break;
        case 11:
          setMes("Diciembre");
          break;
        default:
          break;
      }
    }, []);

  const { connect, socket } = useContext(WebSocketContext);

  const [hubspotInfo, setHubspotInfo] = useState({
    Colocado: 0,
    ColocadoFormatted: 0,
    Pymes: 0,
    Brokers: 0,
    Alianzas: 0,
    Solicitudes: 0,
    TasaProm: 1,
    KR: 1,
    Cartera: 41.08,
    Rentabilidad: 0,
  });

  useEffect(() => {
    connect({
      'origin': 'hubspotInfo'
    });
  }, []);

  useEffect(() => {
    if(!socket && !show){
      setTimeout(connect({
        'origin': 'hubspotInfo'
      }), 1);
    }
    else {
      contador();
    }
  }, [show]);

  const sleep = m => new Promise(r => setTimeout(r, m));

  useEffect(() => {
    if (socket) {
      socket.on('hubspotInfo', async(callback) => {
        callback.data.ColocadoFormatted = callback.data.Colocado;
        callback.data.Colocado = callback.data.ColocadoFormatted.replace(/[$,.]/g, "");
        callback.data.Cartera = callback.data.Cartera ? callback.data.Cartera : 41.08;
        callback.data.Mora = callback.data.Mora ? callback.data.Mora : 0.06;
        setHubspotInfo(callback.data);

        if(callback.difference.length){
          if(callback.difference.includes('Colocado')){
            // toast.success("Colocamos un nuevo crédito 💰");
            Colocado.play();
            await sleep(Colocado.duration * 1000);
          }
          
          if(callback.difference.includes('Pymes')){
            // toast.warning("Logramos apoyar a una nueva pyme 🏢");
            General.play();
            await sleep(General.duration * 1100);
          }

          if(callback.difference.includes('Brokers')){
            // toast.info("Un nuevo broker se nos ha unido 👨‍💼");
            General.play();
            await sleep(General.duration * 1200);
          }

          if(callback.difference.includes('Alianzas')){
            // toast.error("Formalizamos una nueva alianza 🤝");
            General.play();
            await sleep(General.duration * 1300);
          }

          if(callback.difference.includes('Solicitudes')){
            // toast.info("Recíbimos una nueva solicitud 📝");
            General.play();
            await sleep(General.duration * 1400);
          }
        }
      });

    }
  }, [socket]);

  const contador = () => {
    const counters = document.querySelectorAll('.counter');
    // const speed = 500;
    counters.forEach(counter => {
      const updateCount = () => {
        const target = counter.getAttribute('data-target');
        const targetValue = parseInt(hubspotInfo[target]);
        const count = counter.innerText === '--' ? 0 : +counter.innerText;
        // const inc = targetValue / speed;

        if (targetValue > 0 || target === "Mora") {
          if (count < targetValue) {
            if (targetValue > 10000) {
              counter.innerText = parseInt(count + (targetValue / 50));
            }
            else if (targetValue > 500 && targetValue < 10000) {
              counter.innerText = parseInt(count + (targetValue / 100));
            }
            else {
              counter.innerText = count + 1;
            }
            setTimeout(updateCount, 1);
          }
          else {
            if (target === "Colocado") {
              counter.innerText = hubspotInfo.ColocadoFormatted + " M";
            } else if (target === "Mora") {
              counter.innerText = hubspotInfo.Mora + " %";
            } else if (target === "MetaNumero" || target === "LogradoNumero") {
              counter.innerText = "#" + hubspotInfo[target];
            } else if (target === "MetaCantidad" || target === "LogradoCantidad") {
              counter.innerText = "$" + hubspotInfo[target] + " M";
            }
            else {
              target === "Cartera" ? counter.innerText = "$" + hubspotInfo.Cartera + " M" :
              counter.innerText = hubspotInfo[target];
            }
          }
        }
      };
      updateCount();
    });
    calculated = true;
  }

  useEffect(() => {
    if (hubspotInfo && !show) {
        contador();
    }
  }, [hubspotInfo]);

  useEffect(() => {
    if (!show){
      setTimeout(() => {
        document.getElementById("dashboard-container").style.visibility = 'hidden';
        document.getElementById("dashboard-container").style.opacity = '0';
      }, 9000)

      setTimeout(() => {
        if(section === 2) {
          setSection(0);
        } else {
        setSection(section => section + 1);
        }
      }, 10000)
      
      setTimeout(() => {
        document.getElementById("dashboard-container").style.visibility = 'visible';
        document.getElementById("dashboard-container").style.opacity = '1';
      }, 10500)

      contador();
    }
  }, [section, show]);



  return(
    <>
      <div id="dashboard" className="dashboard">
        <video id="video-dashboard" src="https://distrito-pyme-media.s3.us-west-2.amazonaws.com/back.mp4" autoPlay muted loop>
        </video>

        <Modal show={show} backdrop="static" keyboard={false} centered size={"lg"}>
          <Modal.Header>
            <Modal.Title className="m-auto">¡Aviso!</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center text-justify"><strong>{theme}</strong> reproducirá algunos sonidos que estarán conectados al entorno en tiempo real</Modal.Body>
          <Modal.Footer>
              <Button className="btn-blue-general m-auto" style={{ width: '180px' }} onClick={() => setShow(false)}>
                Aceptar
              </Button>
          </Modal.Footer>
        </Modal>

        <ToastContainer />


        <Container className="dashboard-container container-xl-fluid">
          <div id="dashboard-container" >
            {
              section === 0 &&
              <>
              <Row className="d-flex align-items-center">
                  <div className="text-center" style={{ width: '100%', fontWeight: 'bold' }}>
                    <span className="dashboard-label label-Historico">Historico</span>
                  </div>
                </Row>
              <Row className="d-flex align-items-center">
                <Col md={6} className="mb-3">
                  <div className="text-center">
                    <label className="dashboard-label label-solicitudes">Solicitudes</label>
                    <div className="dashboard-data">
                      <span className="counter" data-target="Solicitudes">0</span>
                    </div>
                  </div>
                </Col>

                <Col md={6} className="mb-3">
                  <div className="text-center">
                    <label className="dashboard-label label-pymes">Pre Autorizados</label>
                    <div className="dashboard-data">
                      <span className="counter" data-target="Pymes">0</span>
                    </div>
                  </div>
                </Col>

                <Col md={6} className="mb-3">
                  <div className="text-center">
                    <label className="dashboard-label label-brokers">Créditos Exitosos</label>
                    <div className="dashboard-data">
                      <span className="counter" data-target="Brokers">0</span>
                    </div>
                  </div>
                </Col>

                <Col md={6} className="mb-3">
                  <div className="text-center">
                    <label className="dashboard-label label-financieros">Monto Colocado</label>
                    <div className="dashboard-data">
                      <span className="counter" data-target="Colocado">0</span>
                    </div>
                  </div>
                </Col>
              </Row>
              </>
            }
            {
              section === 1 &&
              <>
              <Row className="d-flex align-items-center">
                  <div className="text-center" style={{ width: '100%', fontWeight: 'bold' }}>
                    <span className="dashboard-label label-Historico">Objetivo {mes}</span>
                  </div>
                  <Col md={4} className="mb-3">
                  <div className="text-center">
                    <label className="dashboard-label label-pymes">Meta</label>
                    <div className="dashboard-data mt-5 mb-5">
                      <span className="counter" data-target="MetaNumero">0</span>
                    </div>
                    <div className="dashboard-data">
                      <span className="counter" data-target="MetaCantidad">0</span>
                    </div>
                  </div>
                </Col>
                  <Col md={4} className="mb-3">
                  <div className="text-center">
                    <label className="dashboard-label label-pymes">Logrado</label>
                    <div className="dashboard-data mt-5 mb-5">
                      <span className="counter" data-target="LogradoNumero">0</span>
                    </div>
                    <div className="dashboard-data">
                      <span className="counter" data-target="LogradoCantidad">0</span>
                    </div>
                  </div>
                </Col>
                  <Col md={4} className="mb-3">
                  <div className="text-center">
                    <label className="dashboard-label label-pymes">%</label>
                    <div className="dashboard-data mt-5 mb-5">
                      <span className="counter" data-target="porNumeo">0</span>
                    </div>
                    <div className="dashboard-data">
                      <span className="counter" data-target="PorCantidad">0</span>
                    </div>
                  </div>
                </Col>
                </Row>
              </>
            }

            {
              section === 2 &&
              <>
              <Row className="d-flex align-items-center">
                  <div className="text-center" style={{ width: '100%', fontWeight: 'bold' }}>
                    <span className="dashboard-label label-Historico">Cartera</span>
                  </div>
                </Row>
              <Row id="cartera" className="d-flex align-items-center">
                
                <Col sm={6} className="mb-10">
                  <div className="text-center">
                    <label className="dashboard-label label-cartera">Tasa Prom Pond</label>
                    <div className="dashboard-data">
                      <span className="counter" data-target="TasaProm">0</span>
                    </div>
                  </div>
                </Col>
                <Col sm={6} className="mb-10">
                  <div className="text-center">
                    <label className="dashboard-label label-kr">KR</label>
                    <div className="dashboard-data">
                      <span className="counter" data-target="KR">0</span>
                    </div>
                  </div>
                </Col>
                <Col sm={6} className="mb-10">
                  <div className="text-center">
                    <label className="dashboard-label label-cartera">Cartera Activa</label>
                    <div className="dashboard-data">
                      <span className="counter" data-target="Cartera">0</span>
                    </div>
                  </div>
                </Col>
                <Col sm={6} className="mb-10">
                  <div className="text-center">
                    <label className="dashboard-label label-rentabilidad">Rentabilidad Neta</label>
                    <div className="dashboard-data">
                      <span className="counter" data-target="Rentabilidad">0</span>
                    </div>
                  </div>
                </Col>
              </Row>
              </>
            }
          </div>
          <div className="dashboard-label-date">
            <div className="dashboard-label-date text-center">
            <img src="https://finsus.mx/wp-content/uploads/2023/09/Logo-Finsus_800x.png" className="dashboard-logo-white dashboard-logo mb-1" alt='logo' />
            Digital Pyme
            </div>
            {date}
            
          </div>
        </Container>

        

        
      </div>

      <style>{"\
          #clgo{\
              display: none !important;\
          }\
          #clgo-wsp{\
              display: none !important;\
          }\
          #navbar-dp{\
              display: none !important;\
          }\
          #footer-dp{\
              display: none !important;\
          }\
          body{\
            background-color: #ffff !important;\
          }\
      "}</style>
  
    </>
  );
}

export default Dashboard;