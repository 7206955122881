import React, { useEffect, useState } from "react";
import { Form, withFormik } from 'formik';
import { FieldText, FieldPassword } from '../components/Generic/Fields';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "../css/signup.css";
import { validateSignup } from "../components/Validate/ValidateSignup";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../assets/theme/config";
import { ButtonSubmit } from "../components/Generic/ButtonSubmit";

const passwordTooltip = (
  <Tooltip className="tooltip-password">
    Crea tu contraseña para regresar más tarde
  </Tooltip>
);

const SignupForm = (props) => {
  const { ownerId, setFieldValue, isValid, errorEmail, setErrorEmail, isSubmitting } = props;

  const [button, setButton] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
	const ShowHide = () => setShowPassword(visible => !visible);
  const onChange = value => {setButton(!value)};
  const data = config("finsus");

  useEffect(() => {
    if(ownerId) {
      setFieldValue('brokercode', ownerId);
    }
  }, [ownerId]);

  useEffect(() => {

    if(!button) {
      setFieldValue('captcha', true);
    } else {
      setFieldValue('captcha', false);
    }
  }, [button]);

  useEffect(() => {
    if(errorEmail !== '') {
      setFieldValue('emailRegistered', errorEmail);
    } else {
      setFieldValue('emailRegistered', '');
    }
  }, [errorEmail]);
  
  return (
    <Form className="container mr-auto ml-auto" style={{ maxWidth: "690px" }} id="broker-signup">
      <FieldText name="rfc" id="RFC_empresa" placeholder="RFC de tu empresa o negocio" normalize="OnlyLirycsNumberstoUpper"  csty={"regInput"}/>
      <FieldText name="email" id="Correo_electrónico​" placeholder="Correo electrónico" normalize="onlyTextAndNumbersWithoutSpace" customError={errorEmail} setCustomError={setErrorEmail} csty={"regInput"}/>
      <FieldText name="email_confirm" placeholder="Confirmar correo electrónico" normalize="onlyTextAndNumbersWithoutSpace" csty={"regInput"}/>
      <FieldText name="phone" placeholder="Teléfono" label="El número telefónico debe tener 10 dígitos" normalize="onlyNumbers" maxLength={10} csty={"regInput"}/>

      <OverlayTrigger placement="top" trigger="focus" overlay={passwordTooltip}>
        <FieldPassword name="password" placeholder="Crea una contraseña" setShowPassword={ShowHide} showPassword={showPassword} csty={"regInput"}/>
      </OverlayTrigger>

      {ownerId && <FieldText name="brokercode" id="Coach_Id​" placeholder="Código brokers" label="Código brokers" readOnly csty={"regInput"}/>}

      <div className="recaptcha-container">
        <ReCAPTCHA sitekey="6Leao34mAAAAAFljRpcyEhLm8rHJaM5enL0bJ9r7" onChange={onChange} />
      </div>

      <div className="mt-30 text-dp fw300 fz20 text-center mb-10">
        <label className="fz15">
          Al continuar estás aceptando nuestros{" "}
          <a
            href={data.social.terminos}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#000000" }}
          >
            términos y condiciones de uso
          </a>{" "}
          <br />y nuestro{" "}
          <a href={data.social.aviso} target="_blank" rel="noopener noreferrer" style={{ color: "#000000" }}>
            aviso de privacidad
          </a>
        </label>
      </div>

      <div className="text-center mb-5">
      <div className="text-center" style={{ marginBottom: "50px" }}>
        <ButtonSubmit isSubmitting={isSubmitting} isValid={isValid} onClick={async() => {
        await Object.keys(props.values).forEach((field) => props.setFieldTouched(field, true));
        }}/>
      </div>
      </div>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ 
    rfc: '',
    email: '',
    email_confirm: '',
    phone: '',
    password: '',
    brokercode: '',
    captcha: false,
    emailRegistered: '',
  }),
  validate: validateSignup, 
  enableReinitialize: true,
  handleSubmit(values, formikBag){
      formikBag.setSubmitting(false);
      formikBag.props.handleSubmit(values);
  },
  displayName: 'SignupForm'
})(SignupForm);