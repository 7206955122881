import { RFCValido, metaRFC } from "./validateRFC";
export const validateGeneralInfo = (values) => {
  const errors = {};
  const user = JSON.parse(sessionStorage.getItem("user"));
  const idClient = user.idClient;
  const type = idClient.type;
  const broker = sessionStorage.getItem('broker');
  const regex = /^([a-z&]{3,4})(\d{2})(\d{2})(\d{2})([0-9a-z]{3})$/i;

  if(values.type !== "PM"){

  if (!values.name) {
    errors.name = "Ingresa tu nombre";
  } else if (!/^[A-Za-záéíóúñ\s]+$/g.test(values.name)) {
    errors.name = "Nombre inválido";
  }

  if (!values.lastname) {
    errors.lastname = "Ingresa tu apellido paterno";
  } else if (!/^([a-zñáéíóúü\s]{0,60})$/i.test(values.lastname)) {
    errors.lastname = "Apellido inválido";
  }

  if (!values.secondLastname) {
    errors.secondLastname = "Ingresa tu apellido materno";
  } else if (!/^([a-zñáéíóúü\s]{0,60})$/i.test(values.secondLastname)) {
    errors.secondLastname = "Apellido inválido";
  }

    if (!values.email) {
      errors.email = "Ingresa tu correo electrónico";
    } else if (
      !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(values.email)
    ) {
      errors.email = "Ingresa un correo electrónico válido";
    }

  if (!values.phone) {
    errors.phone = "Ingresa el teléfono de tu domicilio";
  } else if (
    !/^[0-9]\d+$/.test(values.phone) ||
    values.phone.length > 10 ||
    values.phone.length < 10
  ) {
    errors.phone = "Ingresa un número de teléfono válido";
  }

  if (values.phone === broker && broker !== "") {
    errors.phone = "EL numero no pude ser el del broker";
  }

  // if (!values.curp) {
  //   errors.curp = "Ingresa tu CURP";
  // } else if (
  //   !/^[A-Z]{4}(\d{6})([A-Z|\d]{8})$/.test(values.curp) ||
  //   values.curp.length > 18 ||
  //   values.curp.length < 18
  // ) {
  //   errors.curp = "Ingresa un CURP válido";
  // }

  if (type === "PM") {
    // if (!values.rfcString) {
    //   errors.rfcString = "Ingresa tu RFC";
    // } else if (values.rfcString.length !== 10) {
    //   errors.rfcString = "Ingresa un RFC válido";
    // }

    // if(!values.homoclave){
    //   errors.homoclave = "Ingresa tu homoclave";
    // } else if(values.homoclave.length !== 3){
    //   errors.homoclave = "Ingresa una homoclave válida";
    // }


    if (!values.rfcPerson) {
      errors.rfcPerson = "Ingresa tu RFC Personal";
    } else {

      if (!regex.test(values.rfcPerson)) {
        errors.rfcPerson = "Ingresa un RFC válido";
      }
    }

    if(!values.percentage){
      errors.percentage = "Ingresa un porcentaje";
    }

  }


  return errors;

  } else {

    if(!values.razonSocial || values.razonSocial === "") {
      errors.razonSocial = "Ingresa tu razón social";
    } 

    if(!values.rfcSocioPM || values.rfcSocioPM === "") {
      errors.rfcSocioPM = "Ingresa tu RFC";
    }else if (values.rfcSocioPM.length !== 12) {
      errors.rfcSocioPM = "Ingresa un RFC válido";
    } else if (!regex.test(values.rfcSocioPM)) {
      errors.rfcSocioPM = "Ingresa un RFC válido";
    }

    return errors;

  }
};
