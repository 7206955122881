import { useEffect, useRef, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { HeaderCard } from "./HeaderCard";
import { FileDrop } from "react-file-drop";
import Axios from "../../utils/axios";
import { useDispatch } from "react-redux";
import { updateLoader } from "../../redux/actions/loaderActions";
import Swal from "sweetalert2";

const UploadFileOtros = ({ show, handleClose, tags, socketId }) => {
  const [docFiles, setDocFiles] = useState([]);
  const dispatch = useDispatch();

  const onFileInputChange = (e) => {
    const files = e.target.files;
    const newFiles = [];
    for(const file of files) {

    const name = file.name;
    const type = file.type;
    const size = file.size;
    const lastModified = file.lastModified;

    newFiles.push({ name, type, size, lastModified, file });
    }
    setDocFiles([...docFiles, ...newFiles]);
  };

  const handleError = () => {
    dispatch(updateLoader(false));
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Ocurrió un error al subir tus archivos",
    });
  };


  const handleSuccess = () => {
    dispatch(updateLoader(false));
    Swal.fire({
      icon: "success",
      title: "Éxito",
      titleText: "Gracias por subir tus archivos",
      text: "Estamos procesando tu información",
    });
  };

  const handleUpload = (files) => {

    handleClose();

    dispatch(updateLoader(true, "estamos subiendo tus archivos..."));
    
    const user = JSON.parse(sessionStorage.getItem("user"));
    const appliance = user.idClient.appliance[0]
    const idDocument = appliance?.idDocuments?._id;
    
    const notSaved = files.filter((doc) => !doc.saved);
    if (notSaved.length > 0) {

      const formData = new FormData();
      for (const doc of notSaved) {
        formData.append("file", doc.file);
      }

      formData.append("idDocument", idDocument);
      formData.append("socketId", socketId);

      Axios.post(`api/metamap/sendDocuments/${user._id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          handleSuccess();
          if (res.status === 200) {
            const newDocs = docFiles.map((doc) => {
              if (!doc.saved) {
                return { ...doc, saved: true };
              }
              return doc;
            });
            setDocFiles(newDocs);
          }
        })
        .catch((err) => {
          console.log(err);
          handleError();
        });
    }
  };

  useEffect(() => {

    socketId && console.log("UploadFileOtros mounted", socketId);
    return () => {
      setDocFiles([]);
      console.log("UploadFileOtros unmounted");
    };

  }, [socketId]);

  useEffect(() => {
    // console.log(tags)
    if (tags && Object.keys(tags).length > 0) {
      const others = tags.others;
      if (others) {
        const newDocs = others.map((doc) => {
          return { name: doc.name, saved: true };
        });
        setDocFiles(newDocs);
      }
    }
  }, [tags]);

  const inputRef = useRef(null);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "rgba(0, 0, 0, 0.5)",
      }}
      centered
      contentClassName="modal-ext"
      id="modal-ext"
      //   container={document.getElementById("root")}
    >
      <HeaderCard title="Sube tu archivo" oneLine={true} />
      <Modal.Body className="text-center">
      <input
        className="d-none"
        type="file"
        accept=".pdf"
        ref={inputRef}
        onChange={onFileInputChange}
        multiple
      />
      <FileDrop onDrop={(e) => onFileInputChange({ target: { files: e } })}>
        <div className="dnd">
        {/* <img src={image} className="clip-image" alt="Clip" /> */}
            <label
              className="drop-content"
              onClick={(e) => inputRef.current.click(e)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  inputRef.current.click();
                }
              }}
            >
              Adjunta tu archivo{" "}
              <span className="d-none d-lg-inline">(o arrastra aquí)</span>
            </label>
          </div>
        </FileDrop>

        <div className="text-center mt-3">
          {docFiles.length > 0 &&
            docFiles.map((doc, index) => (
              <div
                key={doc.name}
                className="d-flex justify-content-between align-items-center"
              >
                <p className="text-dp">{doc.name.length > 15 ? `${doc.name.substring(0, 15)}...` : doc.name}</p>
                {doc.saved ? (
                  <i className="fas fa-check-circle text-success" />
                ) : (
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() =>
                      setDocFiles(docFiles.filter((_, i) => i !== index))
                    }
                  >
                    Eliminar
                  </Button>
                )}
              </div>
            ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        
        <Button variant="secondary" className="btn-gray-general" onClick={handleClose}>
          Cerrar
        </Button>
        {docFiles.length > 0 && (
          <Button variant="primary"  style={{
            minWidth: "300px",
          }} className="btn-blue-documents" onClick={() => handleUpload(docFiles)}>
            Subir
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default UploadFileOtros;
