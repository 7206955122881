import React, { useState, useLayoutEffect, useEffect } from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'
import isAuthenticated from '../../utils/isAuthenticated'
import '../../css/nav-bar.css'
import config from '../../assets/theme/config';
import Whatsapp from '../../assets/img/redes_sociales/icono_WA.svg';
import useThemeData from '../../utils/hooks/useThemeData';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDataAction } from "../../redux/actions/userActions";

const NavBar = props => {
	const [isOpen, setIsOpen] = useState(false);
	const [showHome, setShowHome] = useState(false);
	const [showEstatus, setShowEstatus] = useState(false);
    const [routes, setRoutes] = useState(null);
	const dispatch = useDispatch();
	const { user: {user} } = useSelector((state) => state);

	const partners = [
		'pagaloop',
		'impulsomx',
		'visoor',
		'montanofinanzas',
		'dg-impulsando',
		'se1',
		'eurorep',
		'oficinabajio1',
		'asubia',
		'crediyes',
		'halo',
		'tuempresa',
		'crediexpo',
		'322586238',
		'finsus',
];

	useLayoutEffect(() => {
		if (isAuthenticated()){
			dispatch( getUserDataAction() );

			let idClient = null;
			let appliance = null;
			
			if(user.hasOwnProperty("idClient") && user.idClient !== null){
				idClient = user.idClient;

			if (idClient.hasOwnProperty("type")){
				setShowEstatus(true);
			}
			if (idClient.appliance !== undefined && idClient.appliance.length > 0){
				appliance = idClient.appliance[idClient.appliance.length - 1];
	
				if(user.idClient.appliance[0].hasOwnProperty("idGeneralInfo") && user.idClient.appliance[0].hasOwnProperty("idComercialInfo") && user.idClient.appliance[0].hasOwnProperty("idDocuments")){
					user.flujo !== 0? setShowHome(false) : setShowHome(true);
				}
			}
			}

		}
	}, [])

	const {theme, themeData} = useThemeData();

	  useEffect(() => {
		if (!theme) return;
		setRoutes(config(theme).routes);
	  }, [theme]);
	
	const history = useHistory();
	const location = useLocation();
	const close = () => setIsOpen(false);
	let classDefault = "hvr-underline-from-left  text-center fz16 blackNav nav-btn heigth-45 solicita-butto metropolisReg";
	let classDefaultLink = "text-center coolvetica fz16 gray50 heigth-45 nav-btn d-flex";
	const [showWhatsAppBtn] = useState(partners.filter(partner => partner === 'impulsomx').includes(location.pathname.toLowerCase().split("/")[2]))
	// const [showWhatsAppBtn] = useState(partners.filter(partner => partner !== 'visoor').includes(location.pathname.toLowerCase().split("/")[2]))
	
	//console.log(location.pathname.split("/")[2].toLowerCase());

	return (
		<div id="navbar-dp" className="pos-f-t">
		 <Navbar collapseOnSelect className="nav-bar" expand="lg" bg="light" expanded={isOpen}>
			{
				themeData &&
			<>
		 	<Navbar.Brand href = {partners.includes(location.pathname.toLowerCase().split("/")[2]) || location.pathname.split("/").length > 3 || location.pathname === '/brokersCP' || location.pathname.includes("/solicitudBrokers") ?   "#" : "https://finsus.mx/credito-digital-pyme/"}><img src="https://finsus.mx/wp-content/uploads/2023/09/Logo-Finsus_800x.png" alt="Distrito Pyme Logo" className="nav-logo"/></Navbar.Brand>
			</>
			}
			 {(location.pathname !== '/brokers' && location.pathname !== '/brokersCP' && location.pathname !== "/registro-brokers" && !/^((\/registroexitoso)(\/[a-z]+))/gi.test(location.pathname)) && !partners.includes(location.pathname.toLowerCase().split("/")[2]) && !location.pathname.includes("/solicitudBrokers") && !location.pathname.includes("/solicitudbrokers") && !location.pathname.includes("/registro") &&
				<>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setIsOpen(!isOpen)}/>
					{isAuthenticated() === false &&
						<Navbar.Collapse>
							<Nav className="ml-auto ">
								{/* <Nav.Link onClick={close} as={NavLink} to="/brokers" id="quieroSerBorker" className="text-center fz16 blackNav nav-btn heigth-45 solicita-butto metropolisReg">quiero ser broker</Nav.Link> */}
								{/* <Nav.Link onClick={close} as={NavLink} to="/solicitudBrokers" className="text-center fz16 blackNav nav-btn heigth-45 solicita-butto metropolisReg">soy broker</Nav.Link> */}
								{/* <Nav.Link onClick={close} as={NavLink} to="/nuestros-aliados" className="text-center fz16 blackNav nav-btn heigth-45 solicita-butto metropolisReg">aliados</Nav.Link> */}
								{/* <Nav.Link  onClick={close} href="/#simulador" id="ymb-dp-nav-simulator" className="hvr-underline-from-left  text-center fz16 blackNav nav-btn heigth-45 solicita-butto metropolisReg">Simulador</Nav.Link> */}
								{/* <Nav.Link  onClick={close} href="/#howWorks" id="ymb-dp-nav-howWorks" className="hvr-underline-from-left  text-center fz16 blackNav nav-btn heigth-45 solicita-butto metropolisReg">¿Cómo funciona?</Nav.Link> */}
								{/* <Nav.Link  onClick={close} href="/#about" id="ymb-dp-nav-about" className="hvr-underline-from-left  text-center fz16 blackNav nav-btn heigth-45 solicita-butto metropolisReg">Nosotros</Nav.Link> */}
								{/* <Nav.Link  onClick={close} href="https://distritocasa.com/" id="ymb-dp-nav-howWorks" target="_blank" className="hvr-underline-from-left  text-center fz16 blackNav nav-btn heigth-45 solicita-butto metropolisReg">distrito casa</Nav.Link> */}
								{
									routes !==null && routes.navbar.length > 0 && routes.navbar.map((item, index) => {
										
											if(item.path) {
												return (
													<Nav.Link key={item.name} onClick={close} as={NavLink} to={item.path} id={item.id} className={classDefault}>{item.name}</Nav.Link>
												)
											} else {
												return (
													<Nav.Link key={item.name} onClick={close} href={item.href} target="_blank" id={item.id} className={classDefault}>{item.name}</Nav.Link>
												)
											}
									}
									)
								}
								{location.pathname != '/brokers' && location.pathname != '/solicitud_enviada_brokers' && location.pathname != '/alianza' && 
									<>
										<Nav.Link  onClick={close} as={NavLink} to="/login" id="btn-nav-login" className="text-center fz16 blackNav nav-btn heigth-45 solicita-butto metropolisReg">inicia sesión</Nav.Link>
										<Nav.Link  onClick={close} as={NavLink} to="/registrate" id="btn-nav-register" className="text-center fz16 nav-btn btn_register nav-btn-rad primary heigth-95 w-360 ml-auto mr-auto" style={{textDecoration: "none"}}>comenzar solicitud</Nav.Link>
									</>
								}
							</Nav>
						</Navbar.Collapse>
					}
					{isAuthenticated() === true && 
					<Navbar.Collapse id="basic-navbar-nav">	
						<Nav className="ml-auto">
							{
								showEstatus &&
								<Link onClick={close} to="/estatus_solicitud" className={ (props.url === 'estatus_solicitud') ? classDefault+' nav_bar_active': classDefault}>estatus de solicitud</Link>
							}
							{ 
								showHome &&
								<Link onClick={close} to="/micuenta" className={ (props.url === 'micuenta') ? classDefault+' nav_bar_active': classDefault}>mi cuenta</Link>
							}
							<Nav.Link onClick={close} href="/" className="text-center blackBlue heigth-45 metropolisReg"> <Button className="logout fz-12" id="btn-sign-off" onClick={() => {
								sessionStorage.clear();
								window.location.reload()
							}}>cerrar sesión</Button></Nav.Link>
						</Nav>
					</Navbar.Collapse>
					}
				</>
			}
		 </Navbar>
		 
		 {
				showWhatsAppBtn &&

				<div className="nav-bar-icon-whatsapp" style={{ zIndex: "10001" }}>
							<a href="https://api.whatsapp.com/send?phone=525539480196&text=Hola%2c%20%c2%bfpodr%c3%adan%20ayudarme%3f&source=&data=" target="_blank" rel="noopener noreferrer"><i><img src={Whatsapp} alt="Whatsapp Distrito Pyme" className="nav-bar-icon-whatsapp"/></i></a>
				</div>
			}
		</div>
	)
}

export default NavBar
