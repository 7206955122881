import ColorSwitch from "./ColorSwitch";
import { useState } from "react";
import ColorButton from "./ColorButton";

const SelectedAddress = ({ onSuccess }) => {
  const [value, setValue] = useState(false);

  const handleChange = (event) => {
    setValue(event.target.checked);
  };

  const handleNext = () => {
    onSuccess(value, "selectedaddress");
  };

  return (
    <div
      className="container d-flex flex-column m-5 text-center align-items-center"
      style={{ height: "100%" }}
    >
      <h3>¿El domicilio de tu INE es tu domicilio actual?</h3>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-center">
          <span className="mr-3 h3">No</span>
          <ColorSwitch
            checked={value}
            onChange={handleChange}
            name="domicilio"
          />
          <span className="ml-3 h3">Sí</span>
        </div>
      </div>
      <ColorButton className="mt-5" onClick={handleNext}>
        Continuar
      </ColorButton>
    </div>
  );
};

export default SelectedAddress;
