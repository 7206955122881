import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Title from "../Generic/Title";
import SignupForm from "../../forms/SignupForm";
import "react-toastify/dist/ReactToastify.css";
import "../../css/signup.css";
import Loader from "../Loader/Loader";
import { updateLoader } from "../../redux/actions/loaderActions";
import { singUpAction } from "../../redux/actions/authActions";
import { updateToast } from "../../redux/actions/appActions";
import axios from "../../utils/axios";
import StepSignup from "../Appliance/StepSignup";
import { publicIpv4 } from "public-ip";
import { Carousel } from "react-bootstrap";
import Swal from "sweetalert2";
import { Banner } from "../metamap/Banner";
import { Grid } from "@material-ui/core";
import useKissmetrics from "../../utils/hooks/useKissmetrics";
import useAlianza from "../../utils/hooks/useAlianza";

const getVersionImage = () => {
  const currentSize = document.getElementsByTagName("body")[0].clientWidth;
  return currentSize < 775 ? 1 : 0;
};

const financialPartner = (partner) => {
  switch (partner.toUpperCase()) {
    case "IMPULSOMX":
      return {
        image: [],
        text: [
          "Solicita tu crédito Pyme a través de nuestro aliado tecnológico Distrito Pyme.",
          "El proceso es fácil, rápido y podrás recibir respuesta en menos de 15 min.",
        ],
        dealstage: "2753634",
        prefix:
          process.env.REACT_APP_CONFIGURATION === "localhost" ||
          process.env.REACT_APP_CONFIGURATION === "development"
            ? "FormImpulsoDev - "
            : "FormImpulso - ",
        channel: "Impulso",
      };
    case "VISOOR":
      return {
        image: [],
        text: [
          "Solicita un crédito para tu empresa o negocio a través de nuestro aliado Distrito Pyme.",
          "Podrás recibir las mejores opciones de crédito en menos de 15 min con un proceso fácil, rápido y sin papeleos.",
        ],
        prefix:
          process.env.REACT_APP_CONFIGURATION === "localhost" ||
          process.env.REACT_APP_CONFIGURATION === "development"
            ? "VisoorDev - "
            : "Visoor - ",
        channel: "Visoor",
        brokercode: "36408310", //Luis Fer - DP
      };
    case "PAGALOOP":
      return {
        image: [],
        text: [
          "Solicita un crédito para tu empresa o negocio a través de nuestro aliado Distrito Pyme.",
          "Podrás recibir las mejores opciones de crédito en menos de 15 min con un proceso fácil, rápido y sin papeleos.",
        ],
        prefix:
          process.env.REACT_APP_CONFIGURATION === "localhost" ||
          process.env.REACT_APP_CONFIGURATION === "development"
            ? "PagaloopDev - "
            : "Pagaloop - ",
        channel: "Pagaloop",
        brokercode: "36408310", //Luis Fer - DP
      };

    case "MONTANOFINANZAS":
      return {
        image: [],
        text: [
          "Solicita un crédito para tu empresa o negocio a través de nuestro aliado Distrito Pyme.",
          "Podrás recibir las mejores opciones de crédito en menos de 15 min con un proceso fácil, rápido y sin papeleos.",
        ],
        prefix:
          process.env.REACT_APP_CONFIGURATION === "localhost" ||
          process.env.REACT_APP_CONFIGURATION === "development"
            ? "MontanoFinanzasDev - "
            : "MontanoFinanzas - ",
        channel: "MontanoFinanzas",
        brokercode: "163808949", //
      };

    case "DG-IMPULSANDO":
      return {
        image: [],
        text: [
          "Solicita un crédito para tu empresa o negocio a través de nuestro aliado Distrito Pyme.",
          "Podrás recibir las mejores opciones de crédito en menos de 15 min con un proceso fácil, rápido y sin papeleos.",
        ],
        prefix:
          process.env.REACT_APP_CONFIGURATION === "localhost" ||
          process.env.REACT_APP_CONFIGURATION === "development"
            ? "DG-ImpulsandoDev - "
            : "DG-Impulsando - ",
        channel: "DG-Impulsando",
        brokercode: "153537413", //Israel De Agustín DG-impulsando
      };
    case "SE1":
      return {
        image: [],
        text: [
          "Solicita un crédito para tu empresa o negocio a través de nuestro aliado Distrito Pyme.",
          "Podrás recibir las mejores opciones de crédito en menos de 15 min con un proceso fácil, rápido y sin papeleos.",
        ],
        prefix:
          process.env.REACT_APP_CONFIGURATION === "localhost" ||
          process.env.REACT_APP_CONFIGURATION === "development"
            ? "SE1Dev - "
            : "SE1 - ",
        channel: "SE1",
        brokercode: "180238861", //
      };
    case "EUROREP":
      return {
        image: [],
        text: [
          // 'Solicita un crédito o arrendamiento para adquirir la maquinaria o equipo de nuestras marcas exclusivas',
          // 'podrás recibir las mejores opciones de financiamiento en menos de 15 min con un proceso fácil, rápido y sin papeleos.'
        ],
        prefix:
          process.env.REACT_APP_CONFIGURATION === "localhost" ||
          process.env.REACT_APP_CONFIGURATION === "development"
            ? "EUROREPDEV - "
            : "EUROREP - ",
        channel: "EUROREP",
        brokercode: "189986678", //
      };

    case "OFICINABAJIO1":
      return {
        image: [],
        text: [
          "Solicita un crédito para tu empresa o negocio a través de nuestro aliado Distrito Pyme.",
          "Podrás recibir las mejores opciones de crédito en menos de 15 min con un proceso fácil, rápido y sin papeleos.",
        ],
        prefix:
          process.env.REACT_APP_CONFIGURATION === "localhost" ||
          process.env.REACT_APP_CONFIGURATION === "development"
            ? "OficinaBajioDev - "
            : "OficinaBajio - ",
        channel: "Oficinabajio1",
        brokercode: "194040244", //
      };

    case "ASUBIA":
      return {
        image: [],
        text: [
          "Solicita un crédito para tu empresa o negocio a través de nuestro aliado Distrito Pyme.",
          "Podrás recibir las mejores opciones de crédito en menos de 15 min con un proceso fácil, rápido y sin papeleos.",
        ],
        prefix:
          process.env.REACT_APP_CONFIGURATION === "localhost" ||
          process.env.REACT_APP_CONFIGURATION === "development"
            ? "AsubiaDev - "
            : "Asubia - ",
        channel: "Asubia",
        brokercode: "195842644", //
      };

    case "CREDIYES":
      return {
        image: [],
        text: [
          "Solicita un crédito para tu empresa o negocio a través de nuestro aliado Distrito Pyme.",
          "Podrás recibir las mejores opciones de crédito en menos de 15 min con un proceso fácil, rápido y sin papeleos.",
        ],
        prefix:
          process.env.REACT_APP_CONFIGURATION === "localhost" ||
          process.env.REACT_APP_CONFIGURATION === "development"
            ? "CrediYesDev - "
            : "CrediYes - ",
        channel: "Crediyes",
        brokercode: "199142035", //
      };

    case "HALO":
      return {
        image: [],
        text: [
          "Solicita un crédito para tu empresa o negocio a través de nuestro aliado Distrito Pyme.",
          "Podrás recibir las mejores opciones de crédito en menos de 15 min con un proceso fácil, rápido y sin papeleos.",
        ],
        prefix:
          process.env.REACT_APP_CONFIGURATION === "localhost" ||
          process.env.REACT_APP_CONFIGURATION === "development"
            ? "HaloDev - "
            : "Halo - ",
        channel: "Halo",
        brokercode: "206611329", //
      };

    case "TUEMPRESA":
      return {
        image: [],
        text: [
          "Solicita un crédito para tu empresa o negocio a través de nuestro aliado Distrito Pyme.",
          "Podrás recibir las mejores opciones de crédito en menos de 15 min con un proceso fácil, rápido y sin papeleos.",
        ],
        prefix:
          process.env.REACT_APP_CONFIGURATION === "localhost" ||
          process.env.REACT_APP_CONFIGURATION === "development"
            ? "HaloDev - "
            : "Halo - ",
        channel: "TuEmpresa",
      };
    case "CREDIEXPO":
      return {
        image: [],
        text: [
          "Solicita un crédito para tu empresa o negocio a través de nuestro aliado Distrito Pyme.",
          "Podrás recibir las mejores opciones de crédito en menos de 15 min con un proceso fácil, rápido y sin papeleos.",
        ],
        prefix:
          process.env.REACT_APP_CONFIGURATION === "localhost" ||
          process.env.REACT_APP_CONFIGURATION === "development"
            ? "CrediexpoDev - "
            : "Crediexpo - ",
        channel: "Crediexpo",
        brokercode: "36408310", //Luis Fer - DP
      };

    case "FINSUS":
      return {
        image: [],
        text: [
          "Solicita un crédito para tu empresa o negocio a través de nuestro aliado Distrito Pyme.",
          "Podrás recibir las mejores opciones de crédito en menos de 15 min con un proceso fácil, rápido y sin papeleos.",
        ],
        prefix:
          process.env.REACT_APP_CONFIGURATION === "localhost" ||
          process.env.REACT_APP_CONFIGURATION === "development"
            ? "FinsusDev - "
            : "Finsus - ",
        channel: "Finsus",
        brokercode: "410161434", //Luis Fer - DP
      };

    default:
      return false;
  }
};

const Signup = (props) => {
  const [versionImage, setVersionImage] = useState(getVersionImage());
  const [baners, setBaners] = useState(["a11rz6ucs5hjxmgp9gpv", "brvzbh57otlwu80yxb4g"]);
  const [partner, setPartner] = useState(false);
  const toast = useSelector((state) => state.app.toast);
  const [errorEmail, setErrorEmail] = useState("");
  const [flujo, setFlujo] = useState("pyme");
  const [text, setText] = useState("");
  const [ownerId, setOwnerId] = useState(
    props.match.params.ownerId ? props.match.params.ownerId : ""
  );
  const [initialValues, setInitialValues] = useState({});
  const [theme, setTheme] = useState(null);

  const dispatch = useDispatch();
  const logParams = useKissmetrics();
  const Aliados = useAlianza();

  const [alianzas, handleSearch] = useAlianza();

  window.addEventListener("resize", () => setVersionImage(getVersionImage()));

  useEffect(() => {
    window.scrollTo(0, 0);
    const flujo = sessionStorage.getItem("flujo");
    if (flujo) {
      setFlujo(flujo);
    }
    logParams("record", "Visita", { page: "Registro" });
  }, []);

  useEffect(() => {
    if (ownerId != "") {
      setInitialValues({
        brokercode: ownerId,
      });
    }
  }, [ownerId]);

  useEffect(() => {

    if (props.location.pathname === "/registroempresascaptacion") {
      sessionStorage.setItem("flujo", "captacion");
      setFlujo("captacion");

    }

  }, [props.location.pathname]);

  useEffect(() => {
    if (props.match.params.financialPartner !== undefined) {
      handleSearch(props.match.params.financialPartner);
      setPartner(props.match.params.financialPartner);
    }
  }, [props.match.params.financialPartner]);

  useEffect(() => {
    const theme = sessionStorage.getItem("theme");
    setTheme("finsus");
  }, []);

  useEffect(() => {
    if (!theme) return;
    if (theme === "finsus") {
      setTheme("Finsus  crédito digital");
    }
  }, [theme]);

  useEffect(() => {

    const { status, prefijo, descripcion, imagenApp, imagenWeb, brokerCode } = alianzas;
    if(status === true){
      setInitialValues({
        brokercode: brokerCode,
      });
      setOwnerId(brokerCode);
      setPartner(prefijo);
      setBaners([imagenWeb, imagenApp]);
      setText(descripcion);
    }
  }, [alianzas]);

  let onFormSubmit = async (data) => {
    if (partner) {
      let empresarial = partner.toLowerCase().includes("empresarial");
      let partnerFields = {
        prefix:
          process.env.REACT_APP_CONFIGURATION === "localhost" ||
          process.env.REACT_APP_CONFIGURATION === "development"
            ? `${partner}Dev - `
            : `${partner} - `,
        channel: partner,
        flujo: empresarial ? 2 : 0,
      };
      data = { ...data, ...partnerFields };

      if (ownerId) {
        data.brokercode = ownerId;
      }
      
    } else if (flujo === "captacion") {
      let onbordingFields = {
        prefix: process.env.REACT_APP_CONFIGURATION === "localhost" ||
        process.env.REACT_APP_CONFIGURATION === "development"
          ? `Captacion Dev - `
          : `Captacion - `,
        channel: "Captacion",
        flujo: 1,
        dealstage: "177872033",
      };
      data = { ...data, ...onbordingFields };
    } else {
      data.channel = "Online";
    }

    dispatch(updateToast(toast, "register"));
    dispatch(updateLoader(true));
    let ipV4 = await publicIpv4();
    data.ipV4 = ipV4;

    const res = await axios.post("signin", data);
    if (res.data.code === 500) {
      const rfcField = document.getElementById("rfc");
      const scrollOptions = {
        top: rfcField.offsetTop - 100,
        behavior: "smooth",
      };
      rfcField.focus();
      window.scrollTo(scrollOptions);
      Swal.fire({
        icon: "error",
        title: "Usuario ya registrado",
        text: `${res.data.msg}`,
        customClass: {
          title: "subtitle form fz22",
          popup: "text-dp fz18",
          confirmButton: "btn-blue-general btn btn-primary",
        },
      });
      setErrorEmail(res.data.msg);
    } else {
      setErrorEmail("");
      logParams("record", "Nuevo Registro", {
        email: data.email,
        coachId: ownerId || "",
        rfc: data.rfc,
      });
      dispatch(singUpAction(data));
    }
    dispatch(updateLoader(false));
  };

  

  const styleLetras = () => {
    if (flujo === "pyme") {
      return versionImage === 1 ? "fz32" : "fz48";
    } else {
      return versionImage === 1 ? "fz50" : "fz70";
    }
  };

  const styleOn = () => {
    if(flujo !== "pyme"){
      return "d-flex align-items-center justify-content-center";
    }
  };

  if (sessionStorage.getItem("nameUser")) {
    setTimeout(() => {
      window.location = "/micuenta";
    }, 5000);
    //return (<RegistroExitoso />);
  } else {
    return (
      <>
        <Loader />
        <div
          style={{
            position: "relative",
            maxHeight: "80vh",
            overflow: "hidden",
          }}
        >
          <Banner baners={baners[versionImage]} />
          
          <Grid
            container
            // justify="center"
            // alignItems="center"
            style={{
              position: "absolute",
              top: "10%",
              left: "0",
              width: "100%",
              height: "100%",
            }}
            spacing={0}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "center" }}
              className={`mt-2 ${styleOn()}`}
            >
              <span
                style={{ color: "white" }}
                className={`coolvetica fw300 ${styleLetras()}`}
              >
                {" "}
                Bienvenido a finsus{" "}
              </span>
            </Grid>

            {flujo === "pyme" && text === "" && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ textAlign: "center" }}
                // className="mt-1"
              >
                <span
                  style={{
                    color: "white",
                    display: "block",
                    lineHeight: "0.5",
                  }}
                  className={`metropolisReg fw400 fz70`}
                >
                  {" "}
                  créd<span className="clipText">i</span>to{" "}
                </span>
                <span
                  style={{
                    color: "white",
                    textAlign: "end",
                    display: "inline-block",
                    wordSpacing: "0.5rem",
                    // width: versionImage === 1 ? "65%" : "40%",
                  }}
                  className={`coolvetica fw400 hdwh fz70`}
                >
                  {" "}
                  dp{" "}
                </span>
              </Grid>
            )}
          </Grid>
        </div>
        <div className="container mt-1">
          <div
            className={`text-dp fw300 ${
              versionImage === 1 ? "fz22" : "fz32"
            } text-center mb-30`}
          >
            <p className="">
              
              {
                text !== ""
                  ? text
                  : "te ayudamos a crecer tu negocio"
              }
              <br /> {" "}
              <span style={{ fontWeight: "bold", color: "#000000ba" }}>
                &nbsp;#AsíDeFinsus&nbsp;
              </span>
            </p>
          </div>
          <div className="mt-50">
            <StepSignup />
          </div>
        </div>

        <div className="container">
          <SignupForm
            handleSubmit={onFormSubmit}
            initialValues={initialValues}
            ownerId={ownerId}
            errorEmail={errorEmail}
            setErrorEmail={setErrorEmail}
            flujo={flujo}
          />
        </div>
      </>
    );
  }
};

export default Signup;
