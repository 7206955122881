import { Button, withStyles } from "@material-ui/core";

const ColorButton = withStyles((theme) => ({
  root: {
    "fontFamily": "Metropolis-Regular",
    "borderRadius": 8,
    "color": "#FFFFFF",
    "backgroundColor": "#FF5F54",
    "fontSize": 16,
    "fontWeight": 400,
    "&:hover": {
      backgroundColor: "#23223F",
    },
    "&:disabled": {
      backgroundColor: "#FF5F54",
      color: "#FFFFFF",
      opacity: 0.5,
    },
  },
}))(Button);

export default ColorButton;
