import {
  Button,
  Checkbox,
  FormControlLabel,
  withStyles,
} from "@material-ui/core";
import useQuery from "../../utils/useQuery";
import { useEffect, useState } from "react";
import Phone from "../Phone/phone";
import AuthCode from "../auth/AuthCode";
import Loader from "../Loader/Loader";
import { Modal } from "react-responsive-modal";
import { useDispatch } from "react-redux";
import { updateLoader } from "../../redux/actions/loaderActions";
import Axios from "../../utils/axios";

const ColorButton = withStyles((theme) => ({
  root: {
    borderRadius: 8,
    color: "#FFFFFF",
    backgroundColor: "#FF5F54",
    "&:hover": {
      backgroundColor: "#23223F",
    },
  },
}))(Button);

const ColorCheckbox = withStyles((theme) => ({
  root: {
    color: "#FF5F54",
    "&:hover": {
      backgroundColor: "#ff5f544d",
    },
  },
  colorSecondary: {
    "&.Mui-checked": {
      color: "#FF5F54",
    },
  },
}))(Checkbox);

const TYC = () => {
  const params = useQuery();
  const id = params.get("id");
  const dispatch = useDispatch();
  const [phase, setPhase] = useState(id ? "init" : "error");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState({
    buro: false,
    tyc: false,
  });

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const endpoint = `/incode/getTycsStatus?id=${id}`;
        const response = await Axios.get(endpoint);
        if (response.data.ok) {
          if (response.data.status) return setPhase("success");
          else return setPhase("tycs");
        } else {
          console.error(response.data);
          return setPhase("error");
        }
      } catch (err) {
        console.error(err);
        setPhase("error");
      }
    };
    fetchStatus();
  }, []);

  const onSubmit = async (values) => {
    try {
      loader(true);
      const endpoint = "/otp/sendCode";
      setPhoneNumber(values.phoneNumber);
      const response = await Axios.post(endpoint, {
        phone: values.phoneNumber,
      });
      if (!response.data.ok) {
        console.error(response.data);
        throw new Error("No se pudo enviar el código");
      }
      setPhase("nip");
      loader(false);
    } catch (err) {
      console.error(err);
      setPhase("error");
      loader(false);
    }
  };

  const date = new Date().toLocaleString("es-MX", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const handleCheck = (e) => {
    setCheck({
      ...check,
      [e.target.name]: e.target.checked,
    });
  };

  const loader = (load) => {
    dispatch(updateLoader(load));
  };

  useEffect(() => {
    if (phase === "confirmMember") {
      loader(true);
      const fetchConfirmMember = async () => {
        try {
          const endpoint = "/info-general/members/confirm";
          const response = await Axios.post(endpoint, {
            type: "tycs",
            id,
            phone: phoneNumber,
          });

          if (response.data.code !== 200) {
            console.error(response.data);
            return setPhase("error");
          }
          loader(false);
          setTimeout(() => setPhase("success"), 1000);
        } catch (err) {
          console.error(err);
          setPhase("error");
        }
      };
      fetchConfirmMember();
    }
  });

  const phases = {
    init: <Init />,
    tycs: (
      <Tycs
        date={date}
        handleCheck={handleCheck}
        check={check}
        setPhase={setPhase}
      />
    ),
    phone: <Phone onSubmit={onSubmit} loader={loader} />,
    nip: (
      <Nip
        setOpen={setOpen}
        setPhase={setPhase}
        phoneNumber={phoneNumber}
        loader={loader}
        onSubmit={onSubmit}
        open={open}
      />
    ),
    confirmMember: <ConfirmMember />,
    success: <Success />,
    memberConfirmed: <Success />,
    error: <Error />,
  };

  return phases[phase];
};

const Init = () => (
  <div
    className="d-flex flex-column text-center justify-content-center"
    style={{ height: "100%" }}
  >
    <h1 className="mb-3">Cargando...</h1>
  </div>
);

const Tycs = ({ check, handleCheck, date, setPhase }) => (
  <>
    <div
      className="container d-flex flex-column justify-content-center m-5"
      style={{ height: "100%" }}
    >
      <FormControlLabel
        required
        labelPlacement="end"
        control={
          <ColorCheckbox
            name="buro"
            checked={check.buro}
            onChange={handleCheck}
          />
        }
        label={
          <span>
            Hoy siendo {date}, autorizo a Financiera Sustentable de México S.A.
            de C.V., S.F.P. de C.V. (FINSUS) a consultar mis antecedentes
            crediticios por única ocasión ante las Sociedades de Información
            Crediticia que estime conveniente, declarando que conozco la
            naturaleza, alcance y uso que se hará de tal información.
          </span>
        }
      />
      <FormControlLabel
        required
        control={
          <ColorCheckbox
            name="tyc"
            checked={check.tyc}
            onChange={handleCheck}
          />
        }
        label={
          <span>
            Al continuar estás aceptando nuestros
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="App-link"
              href="https://distrito-pyme-documents-dev.s3.us-west-2.amazonaws.com/1689024421843-TyC_julio%202023%20Finsus%20credito%20digital.pdf"
            >
              {" "}
              términos y condiciones de uso
            </a>{" "}
            y nuestro
            <a
              className="App-link"
              href="https://finsus.mx/aviso-de-privacidad/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              aviso de privacidad
            </a>
            .
          </span>
        }
      />
      <div className="center-items mt-5 mb-5">
        <ColorButton
          onClick={() => setPhase("phone")}
          disabled={!(check.buro && check.tyc)}
          variant="contained"
          color="secondary"
        >
          Firmar con NIP
        </ColorButton>
      </div>
    </div>
  </>
);

const Nip = ({ setOpen, setPhase, phoneNumber, loader, onSubmit, open }) => (
  <div className="container d-flex flex-column justify-content-center">
    <Loader />
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      center
      classNames={{ modal: "modalcontentAdmin" }}
    >
      <div className="text-center metropolisReg">
        <h2 className="text-center title-dp fw500">
          porfavor revisa tu numero
        </h2>
        <p>{`+52 ${phoneNumber} es correcto?`}</p>
        <div className="d-flex justify-content-around">
          <button className="formFieldButton" onClick={() => setOpen(false)}>
            si
          </button>

          <button
            className="formFieldButton"
            onClick={() => {
              setPhase("phone");
              setOpen(false);
            }}
          >
            cambiar
          </button>
        </div>
      </div>
    </Modal>
    <AuthCode
      loader={loader}
      resendCode={onSubmit}
      phoneNumber={phoneNumber}
      setPhase={setPhase}
      setOpen={setOpen}
    />
  </div>
);

const ConfirmMember = () => (
  <div
    className="d-flex flex-column justify-content-center text-center"
    style={{ height: "100%" }}
  >
    <h1>¡Ya casi!</h1>
    <h3>
      Estamos realizando los últimos pasos en nuestros servidores,
      <br /> por favor espera unos segundos.
    </h3>
  </div>
);

const Error = () => (
  <div
    className="d-flex flex-column justify-content-center text-center p-5"
    style={{ height: "100%" }}
  >
    <h1>¡Ups!</h1>
    <h3>
      Ha ocurrido un error durante el proceso de la solicitud,
      <br /> por favor recarga la página.
    </h3>
    <h3>Si el problema persiste, por favor contacta a soporte.</h3>
  </div>
);

const Success = () => (
  <div
    className="d-flex flex-column text-center justify-content-center"
    style={{ height: "100%" }}
  >
    <div>
      <h1 className="mb-3">¡Gracias!</h1>
      <h3>El proceso ha terminado.</h3>
      <h3>Pronto recibirás noticias nuestras.</h3>
    </div>
  </div>
);

export default TYC;
