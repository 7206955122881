import { Form, withFormik } from "formik";
import SubtitleForm from "../Generic/SubtitleForm";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { FieldSelect, FieldText } from "../Generic/Fields";
import Axios from "../../utils/axios";
import { useEffect, useMemo, useState } from "react";

const processedValues = (values) => {
  const { postalCode } = values.addressComponents;
  return postalCode;
};

const ConfirmCSFData = (props) => {
  const { values, initialValues } = props;
  const pValues = useMemo(() => processedValues(values), [values]);
  const [colonias, setColonias] = useState([]);
  const [colonyError, setColonyError] = useState(null);

  const handleColonySearch = async () => {
    try {
      const cp = pValues;
      if (cp.length === 5) {
        const res = await Axios.get(`/private/api/info/copomex/${cp}`, {
          headers: {
            tokensecret: process.env.REACT_APP_TOKEN_BACK,
          },
        });
        if (!res.data.success) {
          setColonyError("No se encontraron colonias con el CP");
          let err = new Error(res.data.message);
          err.response = res;
          throw err;
        }
        const colonias = res.data.info.asentamiento.map((datos) => datos);
        setColonias(colonias);
      }
    } catch (err) {
      if (err.response?.data) console.log(err.response?.data);
      setColonyError(
        err.response?.data?.message ||
          "Algo salió mal. Por favor, intenta de nuevo."
      );
    }
  };

  useEffect(() => {
    setColonyError(null);
    if (pValues?.length === 5) {
      handleColonySearch();
    }
  }, [pValues]);

  return (
    <Form className="ml-auto mr-auto" style={{ maxWidth: "690px" }}>
      <div className="">
        <SubtitleForm
          subtitle="Datos generales"
          className="mb-10 text-center"
        />
        {!!props.initialValues.razonSocial && (
          <Row>
            <Col>
              <FieldText
                name="razonSocial"
                label={"Razón social"}
                disabled={true}
                className="text-left"
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <FieldText
              name="rfc"
              label={"RFC"}
              disabled={true}
              className="text-left"
            />
          </Col>
        </Row>
        {!!props.initialValues.fullName && (
          <Row>
            <Col>
              <FieldText
                name="fullName"
                label={"Nombre completo"}
                disabled={true}
                className="text-left"
              />
            </Col>
          </Row>
        )}
        <SubtitleForm
          subtitle="Dirección fiscal"
          className="mb-10 mt-10 text-center"
        />
        <Row>
          <Col>
            <FieldText
              name="addressComponents.street"
              label={"Calle"}
              disabled={true}
              className="text-left"
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <FieldText
              name="addressComponents.interiorNumber"
              label={"Número interior"}
              className="text-left"
              normalize="LirycsNumbersDotComa"
            />
          </Col>
          <Col md={6} sm={12}>
            <FieldText
              name="addressComponents.exteriorNumber"
              label={"Número exterior"}
              className="text-left"
              normalize="LirycsNumbersDotComa"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FieldText
              name="addressComponents.postalCode"
              label={"Código postal"}
              customError={colonyError}
              disabled={
                !!initialValues.addressComponents.postalCode &&
                colonias.length !== 0
              }
              className="text-left"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <FieldSelect
              name="addressComponents.colony"
              className="text-left mt-4"
              label="Colonia"
            >
              <option value={""}>Seleccione una colonia...</option>
              {colonias.map((colonia, index) => (
                <option value={colonia} key={colonia + index}>
                  {colonia}
                </option>
              ))}
            </FieldSelect>
          </Col>
        </Row>
        <Row>
          <Col>
            <FieldText
              name="addressComponents.city"
              label={"Delegación/Municipio"}
              disabled={true}
              className="text-left"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FieldText
              name="addressComponents.state"
              label={"Estado"}
              disabled={true}
              className="text-left"
            />
          </Col>
        </Row>
      </div>
      <Row className="justify-content-center">
        <Button variant="primary" type="submit" disabled={props.sending}>
          {props.sending ? (
            <Spinner animation="border" size="sm" />
          ) : (
            "Confirmar"
          )}
        </Button>
      </Row>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    ...props.initialValues,
    razonSocial: props.initialValues?.corporateName || "",
    rfc: props.initialValues?.rfcNumber,
    fullName: props.initialValues?.fullName,
    addressComponents: !!props.initialValues?.addressComponents
      ? {
          ...props.initialValues?.addressComponents,
          exteriorNumber: "",
          interiorNumber: "",
        }
      : {
          street: "",
          postalCode: "",
          city: "",
          state: "",
          colony: "",
          exteriorNumber: "",
          interiorNumber: "",
        },
  }),
  validateOnMount: true,
  mapPropsToTouched: () => ({
    addressComponents: { colony: true },
  }),
  validate: (values) => {
    let errors = {};

    errors.addressComponents = {};
    if (!!!values.addressComponents.postalCode?.trim())
      errors.addressComponents.postalCode = "Ingresa un Código Postal";

    if (!!!values.addressComponents.colony?.trim())
      errors.addressComponents.colony = "Selecciona una colonia";

    if (!!!values.addressComponents.exteriorNumber?.trim())
      errors.addressComponents.exteriorNumber = "Ingresa el número exterior";

    if (Object.keys(errors.addressComponents).length === 0)
      delete errors.addressComponents;
    return errors;
  },
  handleSubmit: (values, { props }) => {
    props.handleUpload({ values });
  },
})(ConfirmCSFData);
