import React, { useState, useEffect, useRef } from "react";
import SubtitleForm from "../components/Generic/SubtitleForm";
import { Row, Col, Button } from "react-bootstrap";
import generalInfoOptions from "../models/GeneralInfoModels";
import InputLabel from "../components/Generic/InputLabel";
import { useDispatch } from "react-redux";
import { validateGeneralInfo } from "../components/Validate/ValidateGeneralInfo";
import Axios from "../utils/axios";
import { updateLoader } from "../redux/actions/loaderActions";
import useThemeData from "../utils/hooks/useThemeData";
import { FieldArray, Form, withFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import upload from "../assets/img/Info.png";
import { IconButton, styled } from "@material-ui/core";
import { ArrowBackRounded } from "@material-ui/icons";

import {
  FieldSelect,
  FieldText,
  FieldCheck,
  FieldPorcentaje,
} from "../components/Generic/Fields";
import { ButtonSubmit } from "../components/Generic/ButtonSubmit";
import { WebSocketContext } from "../context/WebSocket";
import {
  RFCFiltraAcentos,
  homonimia,
  RFCDigitoVerificador,
} from "../components/Validate/validateRFC";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginRight: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Meta = (props) => {
  const [element, setElement] = useState(null);
  let { socket, socketId } = React.useContext(WebSocketContext);
  let ref = useRef();
  let accion = props.accion;
  const dispatch = useDispatch();

  const scrollToIne = () => {
    let ine = document.getElementById("req_BrokersPF");
    if (!ine) {
      return;
    }
    ine.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    setElement(ref.current);
  }, [ref]);

  useEffect(() => {
    if (element !== null) {
      element.addEventListener("metamap:exitedSdk", ({ detail }) => {
        scrollToIne();
        dispatch(updateLoader(false));
      });
      return () => {
        scrollToIne();
        element.removeEventListener("metamap:exitedSdk", ({ detail }) => {
          dispatch(updateLoader(false));
        });
      };
    }
  }, [element, dispatch]);

  useEffect(() => {
    if (element !== null) {
      element.addEventListener("metamap:userFinishedSdk", ({ detail }) => {
        scrollToIne();
        // dispatch(updateLoader(false));
        // console.log(detail);
        // props.changeValue("ine", detail.verificationId);
      });
      return () => {
        element.removeEventListener("metamap:userFinishedSdk", ({ detail }) => {
          scrollToIne();
          // dispatch(updateLoader(false));
        });
      };
    }
  }, [element, dispatch]);

  const handleCl = () => {
    setTimeout(() => {
      dispatch(
        updateLoader(
          true,
          "estamos procesando tus documentos y en breve te daremos una respuesta"
        )
      );
    }, 1000);
  };

  if (!socket) {
    return <div id={props.id}></div>;
  }

  const metamap = JSON.stringify({
    socketId: socket.id,
    canal: "Finsus",
    userId: props.userId,
    accion: accion,
  });

  return (
    <metamap-button
      id={props.id}
      clientid="62f15ad24621d7001caa5472"
      flowId={props.flow}
      color="var(--primer-color)"
      metadata={metamap}
      ref={ref}
      style={{ display: "none" }}
      onClick={handleCl}
      debbug={false}
    />
  );
};

const handleClick = (e, more) => {
  if (more === true) {
    return;
  }

  let id = e.currentTarget.id.split("_")[1];
  document.getElementById("mati_" + id).click();
};

let GeneralInfoForm = (props) => {
  const {
    isValid,
    isSubmitting,
    initialValues,
    setInitialValues,
    positionRef,
    setFieldValue,
    currentDate,
    handleForm,
    memberNumber,
    values,
    socketId,
    nuevo,
    porcentajeGeneral,
    dataIne,
  } = props;

  const dispatch = useDispatch();
  const [porcentaje, setPorcentaje] = useState(0);
  const [porcentajeError, setPorcentajeError] = useState("");
  const [nombre, setNombre] = useState({});
  const [curp, setCurp] = useState("");
  const [clicked, setClicked] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [homonimi, setHomonimi] = useState("");
  const [type, setType] = useState("");
  const [realOwner, setRealOwner] = useState(false);
  const [Apoderado, setApoderado] = useState(false);
  const [guardado, setGuardado] = useState(false);
  const [changeRFC, setChangeRFC] = useState(false);
  const [dis, setDis] = useState(false);

  /**Intentar pasar la direccion por aqui y/o buscar por que no se pasa */
  //let lastAddress = props.currentAddress;
  const user = JSON.parse(sessionStorage.getItem("user"));

  const { themeData } = useThemeData();

  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  const meta = {
    name: "devPruebas",
    flow: "635bfc4f15a32c001cf0214f",
    description: "otros documentos",
    socketId: socketId,
    step: 1,
    accion: "INE",
    userId: user._id,
  };

  useEffect(() => {
    const {
      name,
      lastname,
      secondLastname,
      curp,
      percentage,
      ine,
      realOwner,
      Apoderado,
      homoclave,
      type,
      id, 
      rfcString,
      homonimia,
    } = values;

    const handleRFC = (rfc) => {
      setFieldValue("rfcPerson", rfc + homoclave);
    };

    if (name && lastname && secondLastname) {
      setNombre({ name, lastname, secondLastname });
    }
    setHomonimi(homoclave);
    curp && setCurp(curp);
    percentage && setPorcentaje(percentage);
    setRealOwner(realOwner);
    setApoderado(Apoderado);
    setType(type ? type : "PF");

    if(changeRFC && rfcString && rfcString !== "") {
      handleRFC(rfcString);
    }

    if(changeRFC && homoclave && homoclave !== "") {
      setFieldValue("rfcPerson", rfcString + homoclave);
    }

    if(id && id !== ""){
      setGuardado(true);
    }
  }, [values]);

  useEffect(() => {
    if(changeRFC){
      return;
    }

    if (clicked === true) {
      const rfcString = curp.substring(0, 10);
      let rfc = rfcString + homonimi;
      setFieldValue("rfcPerson", rfc);
      return;
    }

    if (curp && curp.length <= 10) {
      setFieldValue("rfcString", curp);
      setFieldValue("homoclave", "");
    } else if (
      nombre.name &&
      nombre.lastname &&
      nombre.secondLastname &&
      curp &&
      curp.length > 10
    ) {
      const nombresinacentos = RFCFiltraAcentos(nombre.name);
      const apellidopat = RFCFiltraAcentos(nombre.lastname);
      const apellidomat = RFCFiltraAcentos(nombre.secondLastname);

      let rfc = curp.substring(0, 10);
      rfc = rfc + homonimia(apellidopat, apellidomat, nombresinacentos);
      rfc = RFCDigitoVerificador(rfc);

      const homoclave = rfc.substring(10, 13);
      const rfcString = curp.substring(0, 10);
      setFieldValue("homoclave", homoclave);
      setFieldValue("rfcPerson", rfc);
      setFieldValue("rfcString", rfcString);
    }
  }, [nombre, curp, homonimia, clicked, changeRFC]);

  useEffect(() => {
    const total = parseInt(porcentaje) + parseInt(porcentajeGeneral);
    console.log("total", total);
    if(total >= 100 && porcentaje === 0) {
      console.log("entra");
      setDis(true);
      setFieldValue("percentage", "0");
    } else {
      console.log("no entra");
    }

    if (total > 100) {
      setPorcentajeError("La suma de los porcentajes no puede ser mayor a 100");
    } else {
      setPorcentajeError("");
    }
  }, [porcentaje, porcentajeGeneral]);

  const roles = generalInfoOptions.roles;
  const rowClassName =
    "d-flex align-items-space-between " +
    (memberNumber !== 0 ? "justify-content-between" : "justify-content-center");

  const handleType = () => {
    if (type === "PM") {
      setFieldValue("type", "PF");
      setType("PF");
    } else {
      setFieldValue("type", "PM");
      setFieldValue("roles", { 0: false, 1: true, 2: false });
      setType("PM");
    }
  };

  const handleString = (e) => {
    setChangeRFC(true);
    console.log("e", e.target.value);
    e.target.value = e.target.value.toUpperCase();
    setFieldValue("rfcString", e.target.value);
  };

  return (
    <Form
      className="ml-auto mr-auto"
      style={{ maxWidth: "690px" }}
      form="general-info-form"
    >
      {
        memberNumber === 0 && guardado && (
          <Row className="d-flex align-items-space-between justify-content-between">
            <Col lg={12} md={12} sm={12}>
            <span
                  className="title-dp fz16"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleForm("close")}
                >
                  <ArrowBackRounded
                    className="ml-1"
                    style={{ fontSize: "2rem" }}
                  />
                </span>
            </Col>
          </Row>
        )
      }

      {user.idClient.type === "PM" && !memberNumber !== 0 && (
        <>
          <Row className={rowClassName}>
            {memberNumber !== 0 && (
              <Col lg={2} md={2} sm={12}>
                <span
                  className="title-dp fz16"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleForm("close")}
                >
                  <ArrowBackRounded
                    className="ml-1"
                    style={{ fontSize: "2rem" }}
                  />
                </span>
              </Col>
            )}

            {
              !guardado &&
            <Col lg={10} md={10} sm={12}>
              <Button
                className="btn-blue-documents mb-5"
                onClick={() => handleType()}
                style={{ width: "100%" }}
              >
                {type === "PM"
                  ? "Ingresar socio Persona Física"
                  : "Ingresar socio Persona Moral"}
              </Button>
            </Col>
            }
          </Row>
          <Row className="d-flex align-items-space-between justify-content-between">
            <Col lg={12} md={12} sm={12}>
              <SubtitleForm
                subtitle={
                  user.idClient.type === "PM"
                    ? memberNumber === 0
                      ? "Datos del principal accionista y representante legal"
                      : `${memberNumber + 1}° accionista`
                    : "Sobre ti"
                }
                className="mb-10 text-center"
              />
            </Col>
          </Row>
        </>
      )}

      {type !== "PM" ? (
        <div>
          {user.idClient.type !== "PM" && (
            <Row className="d-flex align-items-space-between justify-content-between">
              <Col lg={12} md={12} sm={12}>
                <SubtitleForm
                  subtitle={"Datos Personales"}
                  className="mb-10 text-center"
                />
              </Col>
            </Row>
          )}

          <Row className="d-flex justify-content-center">
            <Col lg={4} md={4} sm={12}>
              <FieldText
                name="name"
                placeholder="Nombre(s)"
                normalize="onlyLirycs"
              />
            </Col>
            <Col lg={4} md={4} sm={12}>
              <FieldText
                name="lastname"
                placeholder="Apellido paterno"
                normalize="onlyLirycs"
              />
            </Col>
            <Col lg={4} md={4} sm={12}>
              <FieldText
                name="secondLastname"
                placeholder="Apellido materno"
                normalize="onlyLirycs"
              />
            </Col>
          </Row>

          {/* <FieldText
            placeholder="CURP"
            name="curp"
            normalize="OnlyLirycsNumberstoUpper"
          /> */}

          <FieldText
            name="edad"
            placeholder="edad"
            normalize="onlyNumbers"
            type="hidden"
          />
          <FieldText
            name="ine"
            placeholder="ine"
            normalize="onlyLirycs"
            type="hidden"
          />
          <FieldText
            name="type"
            placeholder="type"
            normalize="onlyLirycs"
            type="hidden"
          />

          <Row className="d-flex justify-content-center">
            <Col lg={6} md={6} sm={12}>
              <FieldText
                placeholder="Teléfono Personal"
                label={"El número telefónico debe tener 10 dígitos"}
                name="phone"
                maxLength={10}
                minLength={10}
                normalize="onlyNumbers"
                mt="mt-0"
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <FieldText
                name="email"
                placeholder="Correo electrónico"
                normalize="onlyTextAndNumbersWithoutSpace"
                mt="mt-26"
              />
            </Col>
          </Row>

          {user.idClient.type === "PM" && (
            <>
              <Row className="d-flex">
                <Col
                  lg={4}
                  md={4}
                  sm={12}
                  className="d-flex justify-content-center"
                >
                  <FieldText
                    name="rfcPerson"
                    placeholder="RFC de la persona"
                    normalize="OnlyLirycsNumberstoUpper"
                    maxLength={13}
                    // csty="custom-form-input_right"
                    // style={{ maxWidth: "200px" }}
                    // customChange={(e) => {
                    //   handleString(e);
                    // }}
                  />

                  {/* <FieldText
                    name="homoclave"
                    normalize="OnlyLirycsNumberstoUpper"
                    maxLength={3}
                    csty="custom-form-input_left"
                    style={{ maxWidth: "100px" }}
                    customChange={(e) => {
                      e.target.value = e.target.value.toUpperCase();
                      setClicked(true);
                      setFieldValue("homoclave", e.target.value);
                    }}
                  /> */}
                </Col>
                <Col lg={2} md={4} sm={12}>
                  <FieldPorcentaje
                    name="percentage"
                    placeholder={"Porcentaje"}
                    label="Porcentaje de participación"
                    normalize="onlyNumbers"
                    maxLength={4}
                    style={{ maxWidth: "100px" }}
                    customError={porcentajeError}
                    disabled={dis}
                  />
                </Col>
              </Row>

              <SubtitleForm
                subtitle="Rol en la empresa"
                className="mb-10 mt-24"
              />
              <FieldArray name="roles" id="Rol_en_la_empresa">
                {({ push, remove }) => (
                  <Row className="d-flex justify-content-around">
                    {Object.keys(roles).map((key, index) => {
                      return (
                        <Col
                          lg={6}
                          md={6}
                          sm={12}
                          key={index}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <FieldCheck
                            key={index}
                            type="checkbox"
                            label={roles[key].value}
                            name={`roles.${key}`}
                            big={true}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                )}
              </FieldArray>
            </>
          )}

          <div className="text-center" style={{ marginBottom: "50px" }}>
            <ButtonSubmit
              isSubmitting={isSubmitting}
              isValid={isValid}
              id={"Continuar_datos_del_negocio_2​"}
              general={user.idClient.type === "PM" ? true : false}
              onClick={async () => {
                await Object.keys(props.values).forEach((field) => {
                  if (
                    typeof props.values[field] === "object" &&
                    props.values[field] !== null
                  ) {
                    Object.keys(props.values[field]).forEach((nestedField) => {
                      if (
                        !["registerDate", "_id", "__v"].includes(nestedField)
                      ) {
                        props.setFieldTouched(`${field}.${nestedField}`, true);
                      }
                    });
                  } else {
                    props.setFieldTouched(field, true);
                  }
                });
              }}
            />
          </div>
        </div>
      ) : (
        <div>
          <Row className="d-flex justify-content-center">
            <Col lg={12} md={12} sm={12}>
              <FieldText
                name="razonSocial"
                placeholder="Razón Social de la empresa"
              />
            </Col>

            <FieldText
              name="type"
              placeholder="type"
              normalize="onlyLirycs"
              type="hidden"
            />

            <Col lg={6} md={6} sm={12}>
              <FieldText
                name="rfcSocioPM"
                placeholder="RFC de la empresa"
                normalize="SpecialCharsToUpper"
                maxLength={12}
                minLength={12}
              />
            </Col>

            <Col lg={6} md={6} sm={12}>
              <FieldPorcentaje
                name="percentage"
                placeholder={"Porcentaje"}
                label="Porcentaje de participación"
                normalize="onlyNumbers"
                maxLength={4}
                customError={porcentajeError}
                disabled={dis}
              />
            </Col>
          </Row>

          <SubtitleForm subtitle="Rol en la empresa" className="mb-10 mt-24" />
          <FieldArray name="roles" id="Rol_en_la_empresa">
            {({ push, remove }) => (
              <Row className="d-flex justify-content-around">
                {Object.keys(roles).map((key, index) => {
                  return (
                    <>
                      {key === "1" && (
                        <Col
                          lg={6}
                          md={6}
                          sm={12}
                          key={index}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <FieldCheck
                            key={index}
                            type="checkbox"
                            label={roles[key].value}
                            name={`roles.${key}`}
                            big={true}
                            disabled={true}
                          />
                        </Col>
                      )}
                    </>
                  );
                })}
              </Row>
            )}
          </FieldArray>

          <div className="text-center" style={{ marginBottom: "50px" }}>
            <ButtonSubmit
              isSubmitting={isSubmitting}
              isValid={isValid}
              id={"Continuar_datos_del_negocio_2​"}
              general={user.idClient.type === "PM" ? true : false}
              onClick={async () => {
                await Object.keys(props.values).forEach((field) => {
                  if (
                    typeof props.values[field] === "object" &&
                    props.values[field] !== null
                  ) {
                    Object.keys(props.values[field]).forEach((nestedField) => {
                      if (
                        !["registerDate", "_id", "__v"].includes(nestedField)
                      ) {
                        props.setFieldTouched(`${field}.${nestedField}`, true);
                      }
                    });
                  } else {
                    props.setFieldTouched(field, true);
                  }
                });
              }}
            />
          </div>
        </div>
      )}
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    name: props.initialValues.name || "",
    lastname: props.initialValues.lastname || "",
    secondLastname: props.initialValues.secondLastname || "",
    email: props.initialValues.email || "",
    curp: props.initialValues.curp || "",
    edad: props.initialValues.edad || "",
    rfcPerson: props.initialValues.rfcPerson || "",
    phone: props.initialValues.phone || "",
    name1: props.initialValues.name1 || "",
    phone1: props.initialValues.phone1 || "",
    relative1: props.initialValues.relative1 || "",
    name2: props.initialValues.name2 || "",
    phone2: props.initialValues.phone2 || "",
    relative2: props.initialValues.relative2 || "",
    sameAddress: props.initialValues.sameAddress || "",
    rfcString: props.initialValues.rfcString || "",
    homoclave: props.initialValues.homoclave || "",
    percentage: props.initialValues.percentage || "",
    Apoderado: props.initialValues.Apoderado || false,
    realOwner: props.initialValues.realOwner || false,
    index: props.memberNumber || 0,
    id: props.initialValues._id || "",
    roles: props.initialValues.roles || {
      0: false,
      1: false,
      2: false,
    },
    ine: props.initialValues.ine || "",
    razonSocial: props.initialValues.razonSocial || "",
    rfcSocioPM: props.initialValues.rfcSocioPM || "",
    type: props.initialValues.type || "",
  }),
  validate: validateGeneralInfo,
  enableReinitialize: true,
  handleSubmit(values, formikBag) {
    formikBag.setSubmitting(false);
    formikBag.props.handleSubmit(values);
  },
  displayName: "GeneralInfoForm",
})(GeneralInfoForm);
