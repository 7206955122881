import logoFinsus from '../../assets/img/logo_dp/logo_Azul_finsus.svg'
import logo  from '../../assets/img/logo-dp.png'

const Finsus = {
    name: "Finsus",
    social: {
        facebook: "https://www.facebook.com/financierasustentablemexico",
        instagram: "https://www.instagram.com/finsusapp/",
        linkedin: "https://www.linkedin.com/company/finsus/",
        twitter: "https://twitter.com/FinsusApp",
        email: "mailto:info@finsus.mx",
        youtube: "https://www.youtube.com/@FinsusDigital",
        blog: "https://blog.finsus.mx/",
        aviso: "https://finsus.mx/aviso-de-privacidad/",
        terminos: "https://distrito-pyme-documents-dev.s3.us-west-2.amazonaws.com/1689024421843-TyC_julio%202023%20Finsus%20credito%20digital.pdf",
        costos: "https://cdn.finsus.mx/embed/46ad22f1976472d6299d0ebf77e31428fe1681892453/Tablas-de-costos-y-comisiones-de-nuestros-Productos-de-Credito.pdf"
    },
    address: "Avenida Insurgentes Sur No. 1458 Piso 12, Colonia Actipan, C.P. 03920, Alcaldía Benito Juárez, CDMX", 
    extra: "Unidad Especializada de Atención a Usuarios de FINSUS (UNE) (55) 56614915 une@finsus.mx Lunes a viernes de 9:00 a 18:00 horas",
    phone: "55 3603 7000",
    logo: logoFinsus,
    logoAlt: "Logo Finsus",
    razonSocial: "Distrito pyme S.A. de C.V. (FINSUS)",
    colors: {
        prymariColor: "#283460",
        secondaryColor: "#0095CE",
    },
    routes: {
        navbar: []
    }
}

const Pyme = {
    name: "Distrito Pyme",
    social: {
        facebook: "https://www.facebook.com/distritopyme",
        instagram: "https://www.instagram.com/distritopyme/",
        linkedin: "https://www.linkedin.com/company/distritopyme/",
        twitter: "https://twitter.com/distritopyme",
        youtube: "https://www.youtube.com/@FinsusDigital",
        blog: "https://distritopyme.com.mx/",
        terminos: "/terminos-y-condiciones",
        aviso: "/privacidad",
        email: "mailto:contacto@distritopyme.com",
    },
    address: "las arboledas, tlalnepantla, estado de méxico, 54026, méxico",
    phone: "55 8661 9486",
    logo: logo,
    logoAlt: "Logo Distrito Pyme",
    razonSocial: "Distrito Pyme S.A. de C.V.",
    colors: {
        prymariColor: "#023473",
        secondaryColor: "#F24C5A",
    },
    routes: {
        navbar: [
            {
                name: "quiero ser broker",
                path: "/brokers",
                id: "broker",
            },
            {
                name : "soy broker",
                path: "/solicitudBrokers",
                id: "solicitudBrokers",
            },
            {
                name: "aliados",
                path: "/nuestros-aliados",
                id: "aliados",
            },
            {
                name: "distrito casa",
                href: "https://distritocasa.com/",
                id: "distritoCasa",
            },
        ]
    }
}

const config = (theme) => {
    switch (theme) {
        case "finsus":
            return Finsus
        default:
            return Pyme
    }
}

export default config