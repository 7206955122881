import { useEffect, useState, useRef } from "react";
import ColorButton from "./ColorButton";

const Location = ({ handleLoading, incode, onError, onSuccess, session }) => {
  const [isAgree, setIsAgree] = useState(false);
  const [location, setLocation] = useState("");
  
  const handleAgree = () => {
    if (!isAgree) return setIsAgree(true);
    onSuccess(null, "location");
  };
  useEffect(() => {
    if (isAgree) {
      handleLoading(true);
      incode
        .sendGeolocation({
          token: session.token,
        })
        .then(async (res) => {
          setLocation(res.location);
        })
        .catch((err) => {
          setIsAgree(false);
          onError(err, "location");
        })
        .finally(() => {
          handleLoading(false);
        });
    }
  }, [isAgree]);
  return (
    <div
      className="container d-flex flex-column m-5 text-center align-items-center"
      style={{ height: "100%" }}
    >
      {!!location ? (
        <p>Tu ubicación es: {location}</p>
      ) : (
        <p>Requerimos tu ubicación, por favor acepta los permisos.</p>
      )}
      <ColorButton onClick={handleAgree}>Continuar</ColorButton>
    </div>
  );
};

export default Location;
