import React from "react";
import { useDispatch } from "react-redux";
import Title from "../Generic/Title";
import ResetPasswordForm from "../../forms/ResetPasswordForm";
import CustomModal from "../Generic/CustomModal";

import axios from "../../utils/axios";
import Loader from "../Loader/Loader";

import {
  updateLoader
} from '../../redux/actions/loaderActions'
import {
  updateModal
} from '../../redux/actions/modalActions'

const ResetPassword = (props) => {

  const dispatch = useDispatch();

  const onFormSubmit = async (payload) => {
    dispatch( updateLoader(true) );
    let validateHash = await axios.get("/validate_resetHash/" + payload.email);

    if(validateHash.data.code === 200){
      dispatch( updateModal('reset_password', 'Hash de recuperación activo, verifique su correo electrónico.') );
    }
    else{
      let {data} = await axios.post("/forgot_password", payload);

      if(data.code === 500){
        dispatch( updateModal('reset_password', 'Correo electrónico incorrecto') );
      }

      if(validateHash.data.code === 403){
        dispatch( updateModal('reset_password', 'Hash de recuperación vencido, se le generará otro. Verifique su correo electrónico.') );
      }

      if(data.code === 200){
        dispatch( updateModal('reset_password', 'Hash de recuperación creado exitosamente. verifique su correo electrónico.') );
      }
    }
    dispatch( updateLoader(false) );
  };
  window.scrollTo(0, 0);

  return (
    <div>
      <Loader/>

      <CustomModal modalName="reset_password"/>
      <div
        className="container mt-30 mb-30 d-flex flex-column align-items-center"
        style={{ height: "600px" }}
      >
        <Title
          className="fz56 margin-top-titulo text-center blue-primary coolvetica fw500"
          title="Restablecer Contraseña"
        />

          <>
            <div className="mt-0 metropolisReg fw300 fz20 text-center mb-2 reduce-line">
              <label className="gray50">
                Te enviaremos un correo electrónico con instrucciones para
                restablecer tu contraseña.
              </label>
            </div>
            <ResetPasswordForm onSubmit={(data) => onFormSubmit(data)} />
          </>
      </div>
    </div>
  );
}

export default ResetPassword;

