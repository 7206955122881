const goToError = () => {
    //Todos los errores 
    
    // const errors = document.querySelectorAll('small.error');

    // if (errors.length) {
    //   for (let x in errors) {
    //     errors[x].parentElement.parentElement.scrollIntoView({ block: "start", behavior: 'smooth' });
    //     break;
    //   }
    // }

    const error = document.querySelector(".error");

    if (error) {
        error.parentElement.parentElement.scrollIntoView({ block: "start", behavior: 'smooth' });
    }
};

export default goToError;