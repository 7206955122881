import Axios from "./axios";

const whatIsClient = async () => {
    // try {
    //     const response = await Axios.get("theme");
    //     return response.data.theme;
    // } catch (error) {
    //     console.log("Ha ocurrido un error", error);
    //     console.log(error);
    //     return "distrito pyme";
    // }
    return "finsus";
}

const whatIsClientSync = () => {
    const theme = sessionStorage.getItem("theme");
    if (theme) {
        console.log(theme);
        // return theme;
    }
    try {
        const response = Axios.get("theme");
        response.then((res) => {
            console.log(res);
            return res.data.theme;
        }).catch((err) => {
            console.log(err);
            return "distrito pyme";
        });
        // return response.data.theme;
    }
    catch (error) {
        console.log(error);
        return "distrito pyme";
    }
}



export {whatIsClient, whatIsClientSync};