export const ValidateDataConfirm = (values) => {
  const errors = {};

  if (!values.name) {
    errors.names = "Por favor, ingresa tu nombre";
  }

  if (!values.email) {
    errors.email = "Por favor, ingresa tu correo.";
  } else if (
    !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(values.email)
  ) {
    errors.email = "Por favor, ingresa un correo válido.";
  }

  if (!values.curp) {
    errors.curp = "Por favor, ingresa tu CURP.";
  } else if (!testCurp(values.curp)) {
    errors.curp = "Por favor, ingresa un CURP válido.";
  }

  if (!values.rfcPerson) {
    errors.rfcPerson = "Por favor, ingresa tu RFC.";
  } else if (!/^[A-Z]{4}(\d{6})([A-Z|\d]{3})$/.test(values.rfcPerson)) {
    errors.rfcPerson = "Por favor, ingresa un RFC válido.";
  }

  if (!values.typeOfProperty)
    errors.typeOfProperty = "Por favor, selecciona un tipo de inmueble.";

  if (!values.street) errors.street = "Por favor, ingresa tu calle.";

  if (!values.exteriorNumber)
    errors.exteriorNumber = "Por favor, ingresa tu número exterior.";

  if (!!!values.colony) errors.colony = "Por favor, ingresa tu colonia.";

  if (!values.city) errors.city = "Por favor, ingresa tu ciudad.";

  if (!values.postalCode)
    errors.postalCode = "Por favor, ingresa tu código postal.";

  if (!values.state) errors.state = "Por favor, ingresa tu estado.";

  if (!values.civilStatus)
    errors.civilStatus = "Por favor, selecciona tu estado civil.";

  console.log(errors);

  return errors;
};

const testCurp = (curp) => {
  return /^[A-Z]{4}(\d{6})([A-Z|\d]{8})$/.test(curp) || CSSRule.length !== 18;
};
