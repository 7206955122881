import Axios from "../utils/axios";

const Members = {
    getMembers: async(id) => {
        try {
            const response = await Axios.get(`api/members/${id}`);
            return response.data;
        } catch (error) {
            console.error(error);
        } 
    }
}

export default Members;