import { useEffect, useRef } from "react";

const OTP = ({ handleNavbar, incode, onError, onSuccess, session }) => {
  const containerRef = useRef();
  const isMounted = useRef(false);

  handleNavbar(false);

  const handleLog = (log) => {
    if (log.type === "otpNoAttemptsLeft") {
      log.message =
        "Se ha alcanzado el número maximo de intentos para ingresar el NIP. Por favor, recarga la página e intenta de nuevo.";
      return onError(log, "otp");
    }
    if (log.type === "otpPassed") {
      return onSuccess(log, "otp");
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      return;
    }
    incode.renderOTP(
      containerRef.current,
      {
        token: session,
        numberOfTries: 3,
        otpTimeout: 60000,
        useSMS: true,
      },
      {
        onSuccess: () => {},
        onError: () => {},
        onLog: handleLog,
      }
    );
    isMounted.current = true;
  }, []);

  return (
    <div style={{ height: "90vh", position: "relative" }} ref={containerRef} />
  );
};

export default OTP;
