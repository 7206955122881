import React, { useState } from "react";
import { Formik, Field, Form, useField } from "formik";
import { Container, Grid, Paper, Button, Chip } from "@material-ui/core";
import { FieldText, FieldFile } from "../Generic/Fields";
import Swal from "sweetalert2";

const paperStyle = {
  padding: 20,
  minHeight: "50vh",
  //   maxHeight: "70vh",
  width: "100%",
  margin: "auto",
  overflow: "auto",
};

const NuevaAlianza = (props) => {
  //   const [_, meta, helpers] = useField("file");
  //   const { error } = meta;
  const { onSubmit } = props;
  const [imagenWebPreview, setImagenWebPreview] = useState(null);
  const [imagenCelularPreview, setImagenCelularPreview] = useState(null);

  const handleImageChange = (e, setPreview, setFieldValue, field) => {
    const file = e.target.files[0];
    if (file) {
      //   setPreview(URL.createObjectURL(file));
      setPreview({
        name: file.name,
        preview: URL.createObjectURL(file),
      });
      setFieldValue(field, file);
    }
  };

  const handlePreview = (preview) => {
    Swal.fire({
      imageUrl: preview,
      imageAlt: "Custom image",
    });
  };
  return (
    <Container>
      {/* <Paper elevation={10} style={paperStyle}> */}
        <div
          className="title-dp fz42 fw500 mb-1 text-center mt-2"
          style={{
            color: "#213970",
          }}
        >
          Alianzas
        </div>
        <Formik
          initialValues={{
            nombreAlianza: "",
            url: "",
            prefijo: "",
            descripcion: "",
            brokerCode: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.nombreAlianza) {
              errors.nombreAlianza = "Requerido";
            }
            if (!values.url) {
              errors.url = "Requerido";
            }
            if (!values.prefijo) {
              errors.prefijo = "Requerido";
            }
            if (!values.descripcion) {
              errors.descripcion = "Requerido";
            }
            if (!values.imagenWeb) {
              errors.imagenWeb = "Requerido";
            }
            if (!values.imagenApp) {
              errors.imagenApp = "Requerido";
            }
            if (!values.brokerCode) {
              errors.brokerCode = "Requerido";
            }
            return errors;
          }}
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          {({ errors, touched, isValid, setFieldValue }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FieldText
                    id="nombreAlianza"
                    name="nombreAlianza"
                    label="Nombre de la Alianza"
                    placeholder="Nombre de la Alianza"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FieldText
                    id="url"
                    name="url"
                    label="URL"
                    placeholder="URL"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FieldText
                    id="prefijo"
                    name="prefijo"
                    label="Prefijo"
                    placeholder="Prefijo"
                  />
                </Grid>

                <Grid item xs={6}>
                  <FieldText
                    id="brokerCode"
                    name="brokerCode"
                    label="Broker Code"
                    placeholder="Broker Code"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FieldText
                    id="descripcion"
                    name="descripcion"
                    label="Descripción"
                    placeholder="Descripción"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        component="label"
                        style={{
                          borderRadius: "1.5rem",
                          backgroundColor: "#23223F",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        Subir Banner Web
                        <FieldFile
                          id="imagenWeb"
                          name="imagenWebpew"
                          label=""
                          accept=".jpg,.png"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              setImagenWebPreview,
                              setFieldValue,
                              "imagenWeb"
                            )
                          }
                        />
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        component="label"
                        style={{
                          borderRadius: "1.5rem",
                          backgroundColor: "#23223F",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        Subir Banner Celular
                        <FieldFile
                          id="imagenCelular"
                          name="imagenCelularpew"
                          label=""
                          accept=".jpg,.png"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              setImagenCelularPreview,
                              setFieldValue,
                              "imagenApp"
                            )
                          }
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  {imagenWebPreview && (
                    <div>
                      <Chip
                        label={imagenWebPreview.name}
                        onDelete={() => setImagenWebPreview(null)}
                        onClick={() => handlePreview(imagenWebPreview.preview)}
                      />
                    </div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {imagenCelularPreview && (
                    <Chip
                      label={imagenCelularPreview.name}
                      onDelete={() => setImagenCelularPreview(null)}
                      onClick={() =>
                        handlePreview(imagenCelularPreview.preview)
                      }
                    />
                  )}
                </Grid>

                {/* <button type="submit">Submit</button>
                 */}
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!isValid}
                    style={{
                      borderRadius: "1.5rem",
                      backgroundColor: isValid ? "#23223F" : "#ccc",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      {/* </Paper> */}
    </Container>
  );
};

export default NuevaAlianza;
