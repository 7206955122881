import { useEffect, useRef } from "react";
import Loading from "./Loading";

const FaceMatch = ({
  existingUser,
  incode,
  liveness,
  onError,
  onSuccess,
  session,
}) => {
  const containerRef = useRef();
  const isMounted = useRef(false);

  const handleOnSuccess = async (event) => {
    onSuccess(event, "facematch");
  };

  const handleOnError = (error) => {
    onError(error, "facematch");
  };

  useEffect(() => {
    if (isMounted.current) {
      return <Loading />;
    }
    incode.renderFaceMatch(containerRef.current, {
      existingUser,
      liveness,
      onError: handleOnError,
      onSuccess: handleOnSuccess,
      token: session,
    });

    isMounted.current = true;

    return () => {
      if (isMounted.current) {
        isMounted.current = false;
      }
    };
  }, [onSuccess, onError, session]);

  return <div style={{ height: "100vh" }} ref={containerRef}></div>;
};

export default FaceMatch;
