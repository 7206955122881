import React, { useEffect, useState, useCallback } from "react";
import io from "socket.io-client";
import Info from "../BrokersLanding/Info/Info";
import Cards from "../BrokersLanding/Cards/Cards";
import Comunity from "../BrokersLanding/Comunity/Comunity";
import { Alert } from "react-bootstrap";
import BrokersForms from "../../forms/BrokersForm";
import axios from "axios";
import BANNER_WEB from "../../assets/img/brokers-landing/BANNER_Web1.webp";
import BANNER_MOVIL from "../../assets/img/brokers-landing/BANNER_Mob.webp";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateLoader } from "../../redux/actions/loaderActions";
import Loader from "../Loader/Loader";

const images = [BANNER_WEB, BANNER_MOVIL];

const getVersionImage = () => {
  const currentSize = document.getElementsByTagName("body")[0].clientWidth;
  return currentSize < 775 ? 1 : 0;
};

const Brokers = () => {
  const location = useLocation();
  const [version, setVersion] = useState(getVersionImage());
  const [socket, setSocket] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState({
    show: false,
    msg: "",
  });
  useEffect(() => {
    window.addEventListener("resize", () => setVersion(getVersionImage()));
    return () => {
      window.removeEventListener("resize", () => setVersion(getVersionImage()));
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const image = document.getElementById("banner");
    const button = document.getElementById("buttonRegistro");
    const form = document.getElementById("brokersForm");
    if (form && button) {
      button.addEventListener("click", () => {
        form.scrollIntoView({ behavior: "smooth" });
      });
    }

    const handleImageLoad = () => {
        if (image && button) {
            const imageWidth = image.width;
            const imageHeight = image.height;
            const buttonWidth = button.offsetWidth;
            const buttonHeight = button.offsetHeight;
            const buttonLeft = (imageWidth - buttonWidth) / 5;
            const buttonTop = (imageHeight - buttonHeight) * 0.7;
            button.style.left = `${buttonLeft}px`;
            button.style.top = `${buttonTop}px`;
        }
    };
        if (image && button) {
            image.addEventListener("load", handleImageLoad);
        }


    window.addEventListener("resize", () => {
      if (image && button) {
        const imageWidth = image.width;
        const imageHeight = image.height;
        const buttonWidth = button.offsetWidth;
        const buttonHeight = button.offsetHeight;
        const buttonLeft = (imageWidth - buttonWidth) / 5;
        const buttonTop = (imageHeight - buttonHeight) * 0.7;
        button.style.left = `${buttonLeft}px`;
        button.style.top = `${buttonTop}px`;
      }
    });

    return () => {
      window.removeEventListener("resize", () => {
        if (image && button) {
          const imageWidth = image.width;
          const imageHeight = image.height;
          const buttonWidth = button.offsetWidth;
          const buttonHeight = button.offsetHeight;
          const buttonLeft = (imageWidth - buttonWidth) / 5;
          const buttonTop = (imageHeight - buttonHeight) * 0.7;
          button.style.left = `${buttonLeft}px`;
          button.style.top = `${buttonTop}px`;
        }
      });
    };
  }, []);

  const initialValues = {
    name: "",
    email: "",
    mobilephone: "",
    rfc: "",
    message: "",
    trm: false,
    ine: "",
  };

  const handleSubmit = async (values) => {
    dispatch(updateLoader(true));
    let path = location.pathname.split("/");
    let campaign = path[path.length - 1];
    const canal = campaign ? campaign : "pyme-brokers-campaña";
    const prefix = campaign ? `${campaign + " -"}` : "Campaña PYME - ";
    const dataForm = { ...values, canal, prefix };

    const waitTime = 30000;

    const Axios = axios.create({
      baseURL: `${process.env.REACT_APP_BACKEND}`,
      headers: {
        token: `${sessionStorage.getItem("token")}`,
      },
      timeout: waitTime,
    });

    let { data } = await Axios.post("broker", dataForm)
      .catch((err) => {
        if (err.code === "ECONNABORTED") {
          history.push("/solicitud_enviada_brokers");
          return { data: { code: 200 } };
        } else {
          history.push("/solicitud_enviada_brokers");
          return { data: { code: 200 } };
        }
      })
      .finally(() => {
        dispatch(updateLoader(false));
      });

    if (data.code === 200) {
      window.scrollTo(0, 0);
      history.push("/solicitud_enviada_brokers");
    } else {
      setError({
        show: true,
        msg: data.msg,
      });
      setTimeout(() => {
        setError({
          msg: "",
          show: false,
        });
      }, 5000);
    }

    dispatch(updateLoader(false));
  };

  return (
    <>
      <Loader />

      <div
        style={{
          maxHeight: version === 0 ? "85vh" : "70vh",
          overflow: "hidden",
          display: "grid",
          placeItems: "center",
          position: "relative", // Add this line to make the position absolute work
        }}
      >
        <img
          className="d-block w-100"
          src={images[version]}
          alt="brokersbanner"
          style={{ gridArea: "image" }}
          id="banner"
        />
        <button
          id="buttonRegistro"
          style={{
            position: "absolute",
            width: "250px",
            gridArea: "button",
          }}
          className="btn-blue-general mt-30 btn btn-primary"
        >
            Regístrate
        </button>
      </div>
      <div className="brokers-container container-fluid">
        <div style={{ backgroundColor: "var(--black04)", margin: "0" }}>
          <Info />
        </div>
        <Cards />
        {/* <AlliesLanding estado={version}/> */}
        {/* <Comunity hubspotInfo={hubspotInfo} estado={version}/> */}
        {/* <Testimonio /> */}

        <BrokersForms
          initialValues={initialValues}
          handleSubmit={handleSubmit}
        />

        {error.show && (
          <Alert variant="danger">
            <strong>{error.msg}</strong>
          </Alert>
        )}
      </div>

      <style>
        {
          "\
                #clgo{\
                display: none !important;\
                }\
                #clgo-wsp{\
                display: none !important;\
                }\
                "
        }
      </style>
    </>
  );
};

export default Brokers;
