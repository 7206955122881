import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { buroPrueba } from "../../redux/actions/buroActions";
import { updateLoader } from "../../redux/actions/loaderActions";
import Loader from "../Loader/Loader";
import "./newdoc.css";
import { Grid } from "@material-ui/core";
import TarjetaDoc from "./tarjetasDoc";
import DocumentsModal from "../Appliance/DocumentsModal";
import Axios from "../../utils/axios";
import useSnack from "../../utils/hooks/useSnack";
import { WebSocketContext } from "../../context/WebSocket";
import { updateUserData } from "../../redux/actions/authActions";
import { Banner } from "./Banner";

const verifyAppliance = (array) => {
  if (typeof array !== "object") return false;
  return array.length === 0 ? false : array[array.length - 1];
};

const checkScore = (score) => {
  if (score === undefined) return false;
  if (score === null) return false;
  if (score === "") return false;

  if (score.includes("ERROR")) return false;

  const num = parseInt(score);

  if (num < 0) return false;

  console.log(num);
  if (num > 525) return true;

  return false;
};

const verify = (object, property) => {
  return object.hasOwnProperty(property);
};

const getVersionImage = () => {
  const currentSize = document.getElementsByTagName("body")[0].clientWidth;
  return currentSize < 775 ? 1 : 0;
};

const NewDoc = () => {
  const [uid, setUid] = useState(null);
  const [user, setUser] = useState(null);
  const [typePerson, setTypePerson] = useState("");
  const [warranty, setWarranty] = useState("");
  const [buro, setBuro] = useState(null);
  const [initialValues, setValues] = useState({
    oficialID: [],
    proofAddress: [],
    bankStatements: [],
    constitutiveAct: [],
    otherActs: [],
    financialStatements: [],
    rfc: [],
    // acomplishOpinion : [],
    lastDeclarations: [],
    facturacion: [],
    others: [],
    cventerprise: [],
    proofAddressMainFounders: [],
    collectionReportSaleTerminals: [],
    localContractLease: [],
    guaranteeStatement: [],
    guaranteeFixedAssets: [],
    status: false,
  });
  const [status, setStatus] = useState(false);
  const [ciec, setCiec] = useState(false);
  const [show, setShow] = useState(true);
  const [fiel, setFiel] = useState(false);
  const [idClient, setIdClient] = useState(null);
  const [docID, setDocID] = useState(null);
  const [loader, setLoader] = useState(null);
  const [unykoo, setUnykoo] = useState(null);
  const history = useHistory();
  const toast = useSelector((state) => state.app.toast);
  const buroRedux = useSelector((state) => state.buro);
  const [consulta, setConsulta] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [versionImage, setVersionImage] = useState(getVersionImage());
  const baners = [
    "banner_UnPaso_FINSUS_Mesa_de_trabajo_1_yrvkgn.jpg",
    "banner_UnPaso_FINSUS_mobil-02_crgzwr",
  ];

  const dispatch = useDispatch();
  const { notify } = useSnack();
  let { socket, socketId } = React.useContext(WebSocketContext);

  window.addEventListener("resize", () => setVersionImage(getVersionImage()));

  useEffect(() => {
    const $user = JSON.parse(sessionStorage.getItem("user"));
    if ($user) {
      setUser($user);
      setIdClient($user.idClient);
      setTypePerson($user.idClient.type);
      setWarranty(null);
    } else {
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }

    const idClient = user.idClient;
    let { ciecCorrecta } = idClient.appliance[0]?.idFiscal || false;
    // console.log(ciecCorrecta);
    if (
      ciecCorrecta !== undefined &&
      ciecCorrecta !== null &&
      ciecCorrecta === true
    ) {
      console.log("ciec correcta");
      setCiec(true);
    } else {
      console.log("ciec incorrecta");
      setCiec(false);
    }
  }, [user]);

  useEffect(() => {
    if (!user) {
      return;
    }
    if (user.idClient.appliance[0].hasOwnProperty("idDocuments")) {
      for (const key in user.idClient.appliance[0].idDocuments) {
        if (
          user.idClient.appliance[0].idDocuments.hasOwnProperty(key) &&
          key !== "__v" &&
          key !== "_id"
        ) {
          if (
            user.idClient.appliance[0].idDocuments[key].length > 0 &&
            user.idClient.appliance[0].idDocuments[key] !== undefined
          ) {
            initialValues[key] = user.idClient.appliance[0].idDocuments[key];
          }
        } else if (key === "status") {
          setStatus(user.idClient.appliance[0].idDocuments[key]);
        }
      }
      setStatus(user.idClient.appliance[0].idDocuments.status);
      setDocID(user.idClient.appliance[0].idDocuments._id);
    }
  }, [user, initialValues]);

  useEffect(() => {
    if (!socket) {
      console.log("no hay socket");
    } else {
      console.log("soket conectado");
    }
  }, [socket]);

  const updateUser = (user) => {
    sessionStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  };

  useEffect(() => {
    const updateInitialValues = (name, value) => {
      for (const val of value) {
        if (initialValues[name] !== undefined) {
          if (initialValues[name].length > 0) {
            if (initialValues[name].indexOf(val) === -1) {
              initialValues[name].push(val);
            }
          } else {
            initialValues[name].push(val);
          }
        }
      }
      setValues({ ...initialValues });
    };

    if (socket) {
      socket.on("updateUser", (data) => {
        const { documentUpdate, userUpdate } = data;
        for (const key in documentUpdate) {
          if (
            Object.hasOwn(documentUpdate, key) &&
            !["__v", "_id", "status"].includes(key)
          ) {
            updateInitialValues(key, documentUpdate[key]);
          } else if (key === "status") {
            setStatus(documentUpdate[key]);
          }
        }
        // dispatch(updateUserData(userUpdate));
        updateUser(userUpdate);
        dispatch(updateLoader(false));
      });
    }
  }, [socket, dispatch, initialValues]);

  useEffect(() => {
    if (socket) {
      socket.on("doc_error", (data) => {
        // console.log(data);
        alert(data);
      });
    }
    return () => socket?.off("doc_error");
  }, [socket, uid]);

  const btnclass = (st) => {
    if (st) {
      return "w-100 btn_doc fz16 bluePrimary btn btn-primary";
    } else {
      return "w-100 btn_doc_disabled fz16 bluePrimary btn btn-primary";
    }
  };

  useEffect(() => {
    if (!socket) {
      return;
    }
    if (typePerson && user) {
      let minreq = minRequired(typePerson);
      let req = [...minreq];
      let $status = true;
      for (let i = 0; i < req.length; i++) {
        if (initialValues.hasOwnProperty(req[i])) {
          if (initialValues[req[i]].length === 0) {
            $status = false;
            break;
          }
        }
      }
      setStatus($status);
    }
  }, [user, status, ciec, warranty, typePerson, initialValues, socket]);

  const minRequired = (key) => {
    switch (key) {
      case "PF":
        return ["oficialID", "proofAddressMainFounders", "bankStatements"];
      case "PFAE":
        return [
          "oficialID",
          "proofAddress",
          "proofAddressMainFounders",
          "bankStatements",
        ];
      case "PM":
        return [
          "oficialID",
          "rfc",
          "proofAddress",
          "proofAddressMainFounders",
          "bankStatements",
          "constitutiveAct",
        ];
      default:
        return [];
    }
  };
  const handleUpload = async () => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const flujo = user.flujo;
    const red = flujo === 0 ? "referencias" : "kyc";
    dispatch(updateLoader(true));

    if (
      JSON.parse(sessionStorage.getItem("user")).idClient.appliance[0]
        .idDocuments === undefined ||
      JSON.parse(sessionStorage.getItem("user")).idClient.appliance[0]
        .idDocuments === null
    ) {
      dispatch(updateLoader(false));
      history.push(`/${red}/` + user._id);
      return;
    }

    if (
      JSON.parse(sessionStorage.getItem("user")).idClient.appliance[0]
        .idDocuments.status === true &&
      idClient.appliance.length > 0 &&
      status === true
    ) {
      const appliance = idClient.appliance[idClient.appliance.length - 1];

      const applianceRequest = await Axios.put(
        `api/appliance/${appliance._id}`,
        { status: true }
      );
      if (!applianceRequest.data.hasOwnProperty("error")) {
        sessionStorage.setItem(
          "user",
          JSON.stringify(applianceRequest.data.user)
        );
        setUser(JSON.parse(sessionStorage.getItem("user")));
      }
      history.push(`/${red}/` + user._id);
    } else {
      history.push(`/${red}/` + user._id);
    }
    dispatch(updateLoader(false));
  };

  const handleBack = async () => {
    dispatch(
      updateLoader(true, "estamos actualizando el estado de tus documentos")
    );
    await Axios.get(`api/meta/data`).then((res) => {
      // console.log(res.data);
    });
    dispatch(updateLoader(false));
  };

  useEffect(() => {
    let inicio = false;
    for (const value in initialValues) {
      if (initialValues[value] === status) {
        inicio = initialValues[value];
      } else if (initialValues[value].length === 0) {
        inicio = false;
      } else {
        inicio = true;
      }
    }

    if (inicio) {
      notify("documentos");
    } else {
      notify("documentosInicio");
    }

    // notify("documentosError");
  }, []);

  if (!socket)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Loader />
      </div>
    );

  return (
    <>
      <Loader />
      <Banner baners={baners[versionImage]} />
      <DocumentsModal />

      <Grid container justify="center" align="center" className="mt-2">
        {Array.from(Array(1)).map((_, index) => (
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{ padding: "1rem" }}
            key={index}
          >
            <TarjetaDoc
              index={1}
              typePerson={typePerson}
              initialValues={initialValues}
              setValues={setValues}
              setStatus={setStatus}
              socket={socket}
              socketId={socket.id}
              uid={uid}
              warranty={warranty}
              user={user}
              setUser={setUser}
              ciec={ciec}
              setShow={setShow}
              setLoader={setLoader}
            />
          </Grid>
        ))}
        <Grid item xs={11} md={5} lg={5} style={{ padding: "1rem" }}>
          <Button className={btnclass(status)} onClick={handleUpload}>
            {status ? "enviar solicitud" : "subir despues"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default NewDoc;
