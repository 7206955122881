export const validateKYC = values => {
    let errors = {};

    if (!values.procedencia) {
      errors.procedencia = "Elige una opción";
    } else if (values.procedencia === "2" && !values.procedenciaOtro) {
      errors.procedencia = "Espesiica el origen de los recursos";
      errors.procedenciaOtro = "Espesiica el origen de los recursos";
    }

    if (!values.tipoPago) {
      errors.tipoPago = "Elige una opción";
    }
  
    if(values.manifestacion === ''){
        errors.manifestacion = "Elige una opción";
    }

  
    if (!values.propietario) {
      errors.propietario = "Elige una opción";
    }

    if(values.ppe === ''){
        errors.ppe = "Elige una opción";
    }
  
    return errors;
  };
  