import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import goToError from "../../utils/goToError";

export const ButtonSubmit = (props) => {
	const { isSubmitting, isValid, onClick, general, id } = props;
	const [classButton, setClassButton] = useState("btn-blue-general mt-30");

	useEffect(() => {
		if (isValid) {
			setClassButton("btn-blue-general mt-30");
		} else {
			setClassButton("btn-blue-general mt-30 disabled");
		}
	}, [isValid]);

	return (
		<Button
			type={isValid ? "submit" : "button"}
			disabled={isSubmitting}
			className={classButton}
			onClick={() => {
				onClick && onClick();
				goToError();
			}}
			id={id}
			style={{ width: "250px" }}
		>
			{!general ? "Continuar" : "Guardar"}
		</Button>
	);
};
