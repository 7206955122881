const capitalize = (n, l, sl) => {
  if (!n && !l && !sl) return "";
  const names = n.trim().split(" ");
  const lastNames = l.trim().split(" ");
  const secondLastnames = sl.trim().split(" ");
  let namesFormatted = "";
  let lastnameFormatted = "";
  let secondLastnameFormatted = "";
  names.forEach((name, index) => {
    namesFormatted +=
      index === 0
        ? name.trim().charAt(0).toUpperCase() +
          name.trim().slice(1).toLowerCase()
        : " " +
          name.trim().charAt(0).toUpperCase() +
          name.trim().slice(1).toLowerCase();
  });
  lastNames.forEach((name, index) => {
    lastnameFormatted +=
      index === 0
        ? name.trim().charAt(0).toUpperCase() +
          name.trim().slice(1).toLowerCase()
        : " " +
          name.trim().charAt(0).toUpperCase() +
          name.trim().slice(1).toLowerCase();
  });
  secondLastnames.forEach((name, index) => {
    secondLastnameFormatted +=
      index === 0
        ? name.trim().charAt(0).toUpperCase() +
          name.trim().slice(1).toLowerCase()
        : " " +
          name.trim().charAt(0).toUpperCase() +
          name.trim().slice(1).toLowerCase();
  });
  const fullName = `${!!namesFormatted ? namesFormatted : ""} ${
    !!lastnameFormatted ? lastnameFormatted : ""
  } ${!!secondLastnameFormatted ? secondLastnameFormatted : ""}`.trim();

  return fullName;
};

export default capitalize;