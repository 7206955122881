import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import useThemeData from "../../utils/hooks/useThemeData";
import ModalComponent from "../ModalTemp/ModalTemp";
import { useRef } from "react";


const Text = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <div
        style={{
          // textAlign: "center",
          fontSize: "1.2rem",
          fontFamily: "Metropolis-Regular",
          padding: "20px 20px 0px 20px",
        }}
      >
        <h1>
          <span
            className="text_title_altblue text_title_blue"
            style={{ fontSize: "2rem", lineHeight: "1rem" }}
          >
            ¿Sabes que es la CIEC y para qué sirve?
          </span>
        </h1>
        <p>
          La CIEC se ha convertido en una herramienta muy importante al
          solicitar crédito pyme, la cual te ayudará a tener acceso a las
          mejores tasas, montos y plazos, ¡haciendo tu proceso de crédito 80
          veces más rápido!
          <br />
        </p>
        <p>
          Conocida como contraseña del SAT, la CIEC (clave de identificación
          electrónica confidencial) es una combinación alfanumérica de 8 dígitos{" "}
          <span className="title-dp fz20 fw500">
            que da acceso al portal del SAT.
          </span>
          <br />
        </p>
        <p>
          Al compartir tu CIEC con Finsus podrás dejar atrás esas
          largas filas en los bancos y papeleos interminables ya que con esta
          clave{" "}
          <span className="title-dp fz20 fw500">
            {" "}
            nos ayudarás a realizar un análisis de tu empresa en minutos y así,
            ofrecerte la mejor propuesta de crédito.
          </span>
        </p>
        <br />
        <p className="title-dp fz20 fw500">
          Recuerda, utilizaremos tu CIEC solo para CONSULTA y es 100% segura de
          compartir en tu proceso de solicitud de crédito con FINSUS
        </p>
        <br />
        <p>
          Al momento de compartir tu CIEC con FINSUS viajamos al SAT de manera{" "}
          <span className="title-dp fz20 fw500">SEGURA </span>encriptando tu
          contraseña para obtener de forma automatizada:
        </p>
        <br />
        <ul className="title-dp fz20 fw500" style={{ textAlign: "left" }}>
          <li>Tu Constancia de situación fiscal</li>
          <li>Tu Declaración de impuestos</li>
          <li>Facturación</li>
          <li> Opinión de cumplimiento</li>
        </ul>
        <br />
        <p>
          ¡Todo esto en segundos!
          <br />
          Ahora es más fácil que nunca ser parte de esta nueva era digital
        </p>
        <p>
          Esto es solo el inicio de la nueva era de servicios financieros <br />
          Bienvenido al futuro digital, FINSUS.
        </p>
      </div>
    </div>
  );
};

export const Video = ({ version, changeOpen, open }) => {
  const { theme } = useThemeData();
  const [openp, setOpenp] = useState(false);
  const [prev, setPrev] = useState(false);
  const [url, setUrl] = useState("https://www.youtube.com/watch?v=zfpsVpQwNtg");
  const player = useRef(null);

  useEffect(() => {
    if (open) {
      setOpenp(true);
    }
  }, [open]);

  const handleClose = () => {
    setOpenp(false);
    changeOpen(false);
  };

  useEffect(() => {
    
    const checkConection = async () => {
      let tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      let firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      if (window.YT) {
        console.log("YT");
      } else {

        setPrev(true);
      }
    };

    checkConection();

  }
  , [url]);

  useEffect(() => {
    if(!prev) return;

    if(player.current){
      player.current.showPreview();
    }
  }
  , [prev]);

  useEffect(() => {
    if (theme === "finsus") {
      setUrl("https://youtu.be/hIlld6E-hpc");
    } else {
      setUrl("https://www.youtube.com/watch?v=zfpsVpQwNtg");
    }
  }, [theme]);

  return {
    0: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <ModalComponent open={openp} setOpen={handleClose}>
          <Text />
        </ModalComponent>
        <ReactPlayer
          width="100%"
          height="400px"
          style={{ maxWidth: "1000" }}
          url={url}
          ref={player}
          light={prev}
          onError={() => {
            const linkTo = document.createElement("a");
            linkTo.href = "https://www.youtube.com/watch?v=zfpsVpQwNtg";
            linkTo.target = "_blank";
            linkTo.click();
          }}
          onPlay={() => {
            console.log("play");  
          }}
        />
      </div>
    ),
    1: (
      <Modal
        show={open}
        onHide={() => changeOpen(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <ReactPlayer
            width="100%"
            height="400px"
            style={{ maxWidth: "1000" }}
            url={url}
          />
        </Modal.Body>
      </Modal>
    ),
  }[version];
};
// export const ModalComponent = ({ video, changeOpen, open }) => {
//   return (
//     <Modal
//       show={open}
//       onHide={() => changeOpen(false)}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Body>
//         <Video />
//       </Modal.Body>
//     </Modal>
//   );
// };
