import React from "react";
import { Field, ErrorMessage } from "formik";
import Cleave from "cleave.js/react";
import { Buffer } from "buffer";

export const renderField = ({
  input,
  label,
  type,
  maxLength,
  value = "",
  minLength,
  df,
  readOnly,
  cls,
  disabled,
  meta: { touched, error, warning },
}) => {
  return (
    <div className={cls}>
      <div className="input-container">
        {label === "RFC" && sessionStorage.type === "PF" ? (
          <input
            className="form-control custom-form-input text-dp mt-1 mb-0"
            {...input}
            value={value || input.value}
            placeholder={label}
            type={type}
            maxLength="10"
            disabled={disabled}
          />
        ) : label === "RFC" && sessionStorage.type === undefined ? (
          <input
            className="form-control custom-form-input text-dp mt-1 mb-0"
            {...input}
            value={value || input.value}
            placeholder={label}
            type={type}
            maxLength={maxLength}
            minLength={minLength}
            autoComplete="off"
            disabled={disabled}
            readOnly={readOnly}
            style={
              error
                ? {
                    backgroundImage:
                      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F44335' viewBox='0 0 12 12'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23F44335' stroke='none'/%3E%3C/svg%3E\")",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: `right .12rem center`,
                    backgroundSize: "1rem 1rem",
                  }
                : null
            }
          />
        ) : label === "RFC" &&
          (sessionStorage.type === "RIF" || sessionStorage.type === "PFAE") ? (
          <input
            className="form-control custom-form-input text-dp mt-1 mb-0"
            {...input}
            value={value || input.value}
            placeholder={label}
            type={type}
            maxLength="13"
            disabled={disabled}
            readOnly={readOnly}
          />
        ) : label === "RFC de tu empresa o negocio" &&
          sessionStorage.type === "PM" ? (
          <input
            className="form-control custom-form-input text-dp mt-1 mb-0"
            {...input}
            value={value || input.value}
            placeholder={label}
            type={type}
            maxLength="12"
            disabled={disabled}
            readOnly={readOnly}
          />
        ) : label === "Teléfono" ? (
          <input
            className="form-control custom-form-input text-dp mb-0"
            value={value}
            {...input}
            placeholder={label + " de tu negocio"}
            type={type}
            maxLength="10"
          />
        ) : label === "Teléfono_Personal" ? (
          <input
            className="form-control custom-form-input text-dp mb-0"
            value={value}
            {...input}
            placeholder={"Ingresa tú número celular"}
            type={type}
            maxLength="10"
          />
        ) : label === "CP" ? (
          <input
            className="form-control custom-form-input text-dp mt-1 mb-0"
            {...input}
            value={value || input.value}
            placeholder={label}
            type={type}
            maxLength="5"
          />
        ) : label === "secret" ? (
          <input
            className="form-control custom-form-input text-dp mt-1 mb-0 d-none"
            {...input}
            value={value || input.value}
            placeholder={label}
            type={type}
            maxLength="5"
          />
        ) : (
          <input
            className="form-control custom-form-input text-dp mt-1 mb-0"
            {...input}
            value={value || input.value}
            placeholder={label}
            type={type}
          />
        )}
      </div>
      {((touched || error) && (
        <span id={input.name + "-error"}>
          <small className="error">{error}</small>
        </span>
      )) ||
        (warning && <span id={input.name + "-error"}>{warning}</span>)}
    </div>
  );
};

export const renderSelectField = ({
  input,
  label,
  type,
  disabled,
  df,
  cls,
  meta: { touched, error, warning },
  children,
  val,
}) => (
  <div className={cls}>
    {disabled ? (
      <div className="input-container">
        <select
          {...input}
          disabled={disabled}
          value={val}
          className="form-control custom-form-input text-dp mt-1"
        >
          {children}
        </select>
      </div>
    ) : (
      <div className="input-container">
        <select
          {...input}
          disabled={disabled}
          className="form-control custom-form-input text-dp mt-1"
        >
          {children}
        </select>
      </div>
    )}

    {((touched || error) && (
      <span id={input.name + "-error"}>
        <small className="error">{error}</small>
      </span>
    )) ||
      (warning && <span id={input.name + "-error"}>{warning}</span>)}
  </div>
);

//Reduccion de Codigo

export const renderFieldTextAreaFull = ({ input, label, val }) => (
  <div>
    <div className="input-container">
      <textarea
        className="form-control custom-form-input text-dp mt-24"
        {...input}
        placeholder={label}
        defaultValue={val}
      ></textarea>
    </div>
  </div>
);

export const renderFieldFull = ({
  input,
  label,
  type,
  maxLength,
  val,
  disabled,
  readOnly,
  big,
  err,
  meta: { touched, error, warning },
  hide,
}) => (
  <div>
    <div className="input-container" style={hide ? { display: "none" } : null}>
      {type !== "checkbox" &&
        (val ? (
          <input
            className="form-control custom-form-input text-dp mt-24"
            {...input}
            value={val ? val : ""}
            placeholder={label}
            type={type}
            maxLength={maxLength}
            disabled={disabled ? disabled : false}
            readOnly={readOnly ? readOnly : false}
          />
        ) : label === "CP" ? (
          <input
            className="form-control custom-form-input text-dp mt-24 "
            {...input}
            placeholder={label}
            type={type}
            maxLength="5"
            disabled={disabled ? disabled : false}
          />
        ) : label === "CIEC" ? (
          <input
            className="form-control custom-form-input text-dp mt-24 "
            {...input}
            id={label}
            placeholder={label}
            type="password"
            maxLength="8"
            minLength="8"
            autoComplete="off"
            disabled={disabled ? disabled : false}
            style={
              err
                ? {
                    backgroundImage:
                      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F44335' viewBox='0 0 12 12'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23F44335' stroke='none'/%3E%3C/svg%3E\")",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: `right .12rem center`,
                    backgroundSize: "1rem 1rem",
                  }
                : null
            }
          />
        ) : label === "edad" ? (
          <input className="diplay-none" />
        ) : (
          <input
            className="form-control custom-form-input text-dp mt-24"
            {...input}
            placeholder={label}
            type={type}
            maxLength={maxLength}
            disabled={disabled ? disabled : false}
            readOnly={readOnly ? readOnly : false}
          />
        ))}
      {type === "checkbox" && (
        <div className="d-flex">
          {big ? (
            <input type="checkbox" className="form-checkbox" {...input} />
          ) : (
            <input
              type="checkbox"
              style={{ width: "15px", height: "15px", marginTop: "5px" }}
              {...input}
            />
          )}
          <label className="fz16 text-dp text-msg-dp ml-2">{label}</label>
        </div>
      )}
    </div>
    {((touched || error) && (
      <span id={input.name + "-error"}>
        <small className="error">{error}</small>
      </span>
    )) ||
      (warning && <span id={input.name + "-error"}>{warning}</span>)}
  </div>
);

export const renderSelectFieldFull = ({
  input,
  label,
  type,
  disabled,
  value,
  clases,
  meta: { touched, error, warning },
  children,
}) => (
  <div>
    <div className="input-container">
      <select
        {...input}
        className={`form-control custom-form-input text-dp ${clases}`}
      >
        {children}
      </select>
    </div>
    {(touched || error) !==
    "Debes tener entre 18 y 71 años para poder continuar" ? (
      <span id={input.name + "-error"}>
        <small className="error">{error}</small>
      </span>
    ) : (
      (
        <span id={input.name + "-error"}>
          <small className="error extend-error">{error}</small>
        </span>
      ) ||
      (warning && <span>{warning}</span>)
    )}
  </div>
);

/*
 * FORMIK
 */

const onlyLirycs = (e, form) =>
  /^([a-z ñáéíóú]{0,60})$/i.test(e.target.value) && e.target.value.length > 0
    ? form.setFieldValue(e.target.name, e.target.value)
    : form.setFieldValue(
        e.target.name,
        e.target.value.substring(0, e.target.value.length - 1)
      );

const onlyTextAndNumbersWithoutSpace = (e, form) =>
  /^$|^[^\s]*[\w-\.\@]+$/i.test(e.target.value) && e.target.value.length > 0
    ? form.setFieldValue(e.target.name, e.target.value)
    : form.setFieldValue(
        e.target.name,
        e.target.value.substring(0, e.target.value.length - 1)
      );

const onlyNumbers = (e, form) =>
  /^\d+$/.test(e.target.value) && e.target.value.length > 0
    ? form.setFieldValue(e.target.name, e.target.value)
    : form.setFieldValue(
        e.target.name,
        e.target.value.substring(0, e.target.value.length - 1)
      );

const onlyNumbersDotComa = (e, form) =>
  /^[0-9]{1,2}([,.][0-9]{1,2})?$/.test(e.target.value) &&
  e.target.value.length > 0
    ? form.setFieldValue(e.target.name, e.target.value)
    : form.setFieldValue(
        e.target.name,
        e.target.value.substring(0, e.target.value.length - 1)
      );

const LirycsNumbersDotComa = (e, form) =>
  /^([a-z ñáéíóú0-9,.]{0,45})$/i.test(e.target.value) &&
  e.target.value.length > 0
    ? form.setFieldValue(e.target.name, e.target.value)
    : form.setFieldValue(
        e.target.name,
        e.target.value.substring(0, e.target.value.length - 1)
      );

const OnlyLirycsNumberstoUpper = (e, form) =>
  /^[a-zA-Z0-9]+$/i.test(e.target.value) && e.target.value.length > 0
    ? form.setFieldValue(e.target.name, e.target.value.toUpperCase())
    : form.setFieldValue(
        e.target.name,
        e.target.value.substring(0, e.target.value.length - 1)
      );

const SpecialCharsToUpper = (e, form) =>
  /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/i.test(e.target.value) &&
  e.target.value.length > 0
    ? form.setFieldValue(e.target.name, e.target.value.toUpperCase())
    : form.setFieldValue(
        e.target.name,
        e.target.value.substring(0, e.target.value.length - 1)
      );

const UpperCase = (e, form) =>
  form.setFieldValue(e.target.name, e.target.value.toUpperCase());

const defaultNormalize = (e, form) => {
  form.setFieldValue(e.target.name, e.target.value);
};

const normalizeFn = (fn, e, form) => {
  switch (fn) {
    case "onlyLirycs":
      onlyLirycs(e, form);
      break;
    case "onlyTextAndNumbersWithoutSpace":
      onlyTextAndNumbersWithoutSpace(e, form);
      break;
    case "onlyNumbers":
      onlyNumbers(e, form);
      break;
    case "onlyNumbersDotComa":
      onlyNumbersDotComa(e, form);
      break;
    case "LirycsNumbersDotComa":
      LirycsNumbersDotComa(e, form);
      break;
    case "OnlyLirycsNumberstoUpper":
      OnlyLirycsNumberstoUpper(e, form);
      break;
    case "SpecialCharsToUpper":
      SpecialCharsToUpper(e, form);
      break;
    case "UpperCase":
      UpperCase(e, form);
      break;
    default:
      form.setFieldValue(e.target.name, e.target.value);
      break;
  }
};

export const FieldText = ({
  name,
  className,
  placeholder,
  label,
  normalize,
  customChange,
  customError,
  setCustomError,
  type,
  center,
  csty,
  style,
  mt,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field, form: { setFieldValue } }) => (
        <div
          className={`form-group ${className} ${mt || "mt-24"}`}
          id={field.name}
        >
          {label && (
            <label
              className="label-style"
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "90%",
              }}
            >
              {label}
            </label>
          )}
          <input
            className={`form-control text-dp ${
              csty ? csty : " custom-form-input"
            }`}
            {...field}
            {...props}
            placeholder={placeholder}
            type={type || "text"}
            onChange={(e) => {
              normalizeFn(normalize, e, { setFieldValue });
              customChange && customChange(e);
              setCustomError && setCustomError("");
            }}
            style={
              customError
                ? {
                    backgroundImage:
                      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F44335' viewBox='0 0 12 12'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23F44335' stroke='none'/%3E%3C/svg%3E\")",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: `right .5rem center`,
                    backgroundSize: "1rem 1rem",
                  }
                : style || null
            }
          />
          {customError ? (
            <span>
              <small className="error mt-1">{customError}</small>
            </span>
          ) : (
            <ErrorMessage
              name={field.name}
              render={(msg) => (
                <span>
                  <small className="error mt-1">{msg}</small>
                </span>
              )}
            />
          )}
        </div>
      )}
    </Field>
  );
};

export const FieldCleave = ({
  name,
  className,
  placeholder,
  normalize,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field, form: { setFieldValue } }) => (
        <div className={`form-group ${className}`}>
          <Cleave
            className="form-control custom-form-input text-dp mt-24"
            {...field}
            {...props}
            placeholder={placeholder}
            type="text"
            onChange={(e) => setFieldValue(field.name, e.target.rawValue)}
            options={{
              prefix: "$",
              numeral: true,
              numeralThousandsGroupStyle: "thousand",
              numericOnly: true,
              rawValueTrimPrefix: " ",
            }}
          />
          <ErrorMessage
            name={field.name}
            render={(msg) => (
              <span>
                <small className="error mt-1">{msg}</small>
              </span>
            )}
          />
        </div>
      )}
    </Field>
  );
};
export const FieldTextHide = ({
  name,
  label,
  className,
  placeholder,
  maxLength,
  normalize,
  labelFooter,
  props,
}) => {
  return (
    <Field name={name}>
      {({ field, form: { setFieldValue } }) => (
        <div className="form-group">
          {label && (
            <div className="fz16 metropolisReg mb-2 text-left">{label}</div>
          )}
          <input
            className={`form-control custom-form-input text-dp ${className}`}
            placeholder={placeholder}
            type="hidden"
            maxLength={maxLength}
            onKeyUp={(e) => {
              normalizeFn(normalize, e, { setFieldValue });
            }}
            {...field}
            {...props}
          />
          {labelFooter && <div className="label-footer">{labelFooter}</div>}
          <ErrorMessage
            name={field.name}
            render={(msg) => (
              <div id={`${field.name}-error`} className="error mt-1">
                {msg}
              </div>
            )}
          />
        </div>
      )}
    </Field>
  );
};

export const FieldTextArea = ({
  name,
  label,
  className,
  placeholder,
  maxLength,
  labelFooter,
  props,
}) => {
  return (
    <Field name={name}>
      {({ field }) => (
        <div className="form-group">
          {label && (
            <div className="fz16 metropolisReg mb-2 text-left">{label}</div>
          )}
          <textarea
            className={`form-control custom-form-input text-dp ${className}`}
            placeholder={placeholder}
            type="text"
            maxLength={maxLength}
            {...field}
            {...props}
          ></textarea>
          {labelFooter && <div className="label-footer">{labelFooter}</div>}
          <ErrorMessage
            name={field.name}
            render={(msg) => (
              <div id={`${field.name}-error`} className="error mt-1">
                {msg}
              </div>
            )}
          />
        </div>
      )}
    </Field>
  );
};

export const FieldPassword = ({
  name,
  className,
  placeholder,
  setShowPassword,
  showPassword,
  csty,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field }) => (
        <div className={`input-container form-group ${className}`}>
          <div
            className="input-group form-group center-flex mt-24 mb-2"
            style={{ position: "relative" }}
          >
            <input
              // className="form-control custom-form-input text-dp center-flex-input"
              className={`form-control text-dp ${
                csty ? csty : "center-flex-input custom-form-input"
              }`}
              {...field}
              {...props}
              placeholder={placeholder}
              type={showPassword ? "text" : "password"}
            />
            <i
              className={`fa ${showPassword ? "fa-eye" : "fa-eye-slash"} 
              ${csty ? "iconPsw" : "icon icon-style pt-1"}
              `}
              onClick={setShowPassword}
            ></i>
          </div>
          <ErrorMessage
            name={field.name}
            render={(msg) => (
              <span>
                <small className="error mt-1">{msg}</small>
              </span>
            )}
          />
        </div>
      )}
    </Field>
  );
};

export const FieldSelect = ({
  name,
  label,
  onChange,
  props,
  children,
  labelFooter,
  className,
}) => {
  return (
    <Field name={name}>
      {({ field, form: { setFieldValue } }) => (
        <div className={`form-group ${className}`}>
          {label && <label className="label-style">{label}</label>}
          <select
            {...field}
            className="form-control custom-form-input text-dp"
            {...props}
            onChange={(e) => {
              setFieldValue(field.name, e.target.value);
              onChange && onChange(e);
            }}
          >
            {children}
          </select>
          {labelFooter && <div className="label-footer">{labelFooter}</div>}
          <ErrorMessage
            name={field.name}
            render={(msg) => <div className="error mt-1">{msg}</div>}
          />
        </div>
      )}
    </Field>
  );
};

export const FieldCheck = ({ name, cls, label, props, disabled }) => {
  return (
    <Field name={name}>
      {({ field }) => (
        <div className={`form-group`} style={{ ...cls }}>
          <div className="form-check">
            <input
              id={field.name}
              className="form-check-input text-dp"
              type="checkbox"
              checked={field.value}
              disabled={disabled}
              {...field}
              {...props}
            />
            <label
              className="form-check-label fz16 metropolisReg text-justify"
              htmlFor={field.name}
            >
              {label}
            </label>
          </div>
          <ErrorMessage
            name={field.name}
            render={(msg) => <div className="error mt-1">{msg}</div>}
          />
        </div>
      )}
    </Field>
  );
};

export const FieldRadio = ({ name, value, label, props }) => (
  <div className="form-group">
    <div className="form-check">
      <label className="form-check-label fz16 metropolisReg">
        <Field
          className="form-check-input"
          type="radio"
          name={name}
          value={value}
          {...props}
        />
        {label}
      </label>
      <ErrorMessage
        name={name}
        render={(msg) => <div className="error mt-1">{msg}</div>}
      />
    </div>
  </div>
);

export const FieldPorcentaje = ({
  name,
  className,
  placeholder,
  customError,
  label,
  setCustomError,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field, form: { setFieldValue } }) => (
        <div className={` form-group  ${className}`} style={{ width: "100%" }}>
          <input
            className=" form-control custom-form-input text-dp mt-24"
            placeholder={placeholder}
            type="text"
            {...field}
            {...props}
            onChange={(e) => {
              if (e.target.value <= 100) {
                setFieldValue(field.name, e.target.value);
                setCustomError && setCustomError("");
              }
            }}
          />
          {label && <label className="label-style">{label}</label>}
          {customError ? (
            <span>
              <small className="error mt-1">{customError}</small>
            </span>
          ) : (
            <ErrorMessage
              name={field.name}
              render={(msg) => (
                <span>
                  <small className="error mt-1">{msg}</small>
                </span>
              )}
            />
          )}
        </div>
      )}
    </Field>
  );
};

export const FieldDate = ({
  name,
  className,
  placeholder,
  label,
  disabled,
  ...props
}) => (
  <Field name={name}>
    {({ field }) => (
      <div className={`form-group ${className} mt-24`} id={field.name}>
        {label && <label className="label-style">{label}</label>}
        <input
          className={`form-control custom-form-input text-dp ${className}`}
          placeholder={placeholder}
          type="date"
          disabled={disabled}
          {...field}
          {...props}
        />
        <ErrorMessage
          name={field.name}
          render={(msg) => (
            <div id={`${field.name}-error`} className="error mt-1">
              {msg}
            </div>
          )}
        />
      </div>
    )}
  </Field>
);

export const FieldFile = ({
  name,
  className,
  placeholder,
  label,
  disabled,
  ...props
}) => (
  <Field name={name}>
    {({ field }) => (
      <div className={`form-group ${className} mt-24`} id={field.name}>
        {label && <label className="label-style">{label}</label>}
        <input
          className={`form-control custom-form-input text-dp ${className}`}
          placeholder={placeholder}
          type="file"
          hidden
          disabled={disabled}
          {...field}
          {...props}
        />
        <ErrorMessage
          name={field.name}
          render={(msg) => (
            <div id={`${field.name}-error`} className="error mt-1">
              {msg}
            </div>
          )}
        />
      </div>
    )}
  </Field>
);
