import React, { createContext, useEffect, useMemo } from "react";
import  { useDispatch } from "react-redux";
import useSnack from "../utils/hooks/useSnack";
import io from "socket.io-client";
import { setProposal } from "../redux/actions/simulatorActions";
import {updateFile} from "../redux/actions/documentsActions";
import {updateUserData} from "../redux/actions/authActions";
import { updateLoader } from "../redux/actions/loaderActions";

export const WebSocketContext = createContext(null);

export const  WebSocketProvider = ({ children }) => {
    const url = `${process.env.REACT_APP_BACKEND}`;
    const [socket, setSocket] = React.useState(null);
    const [socketId, setSocketId] = React.useState(null);

    const dispatch = useDispatch();
    const { notify } = useSnack();

    const connect = (query) => {
        const newSocket = io.connect(url, {
            transports: ['websocket'],
            autoConnect: true,
            forceNew: true,
            query: query
        });
        setSocket(newSocket);
    };

    useEffect(() => {
        if (!socket && sessionStorage.getItem('user')) {
            const user = JSON.parse(sessionStorage.getItem("user"));
            connect({
                'idU': user._id
            });
        }

        return () => {
            socket?.disconnect();
        }
    }, []);

    useEffect(() => {
        if(socket) {
            setSocketId(socket.id);
            socket.on('forceDisconnect', () => {
                socket.disconnect();
                sessionStorage.clear();
                window.location.reload();
            });
    
            socket.on('connect', () => {
                console.log('connected');
                // setSocketId(socket.id);
            });
    
            socket.on('disconnect', () => {
                console.log('disconnected');
            });
    
            socket.on('connect_error', (err) => {
                console.log(err);
            });
    
            socket.on('analizeProposal', (data) => {
                console.log('analizeProposal', data);
                if (data.event === 'pre_autorizados' || data.event === 'autorizados') {
                    dispatch(updateUserData());
                    dispatch(setProposal(data.proposal));
                }
                notify(data.event)
            });
    
            socket.on('signature', (data) => {
                console.log('signature');
                dispatch(updateFile(data.file));
            });
    
            socket.on('esperando', (data) => {
                console.log('esperando', data);
                dispatch(updateFile(data));
            });

            socket.on('ine', (data) => {
                // console.log('ine', data);
                dispatch(updateFile(data));
                dispatch(updateLoader(false));
            });
        }
    }, [socket]);

    const sendRFC = (data) => {
        socket.emit('sendRFC', {
            rfc: data,
            socketId: socket.id
        });
    };

    const ws = useMemo(() => ({
        connect,
        socket,
        sendRFC,
        socketId
    }), [connect, socket, sendRFC, socketId]);

    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    );

}