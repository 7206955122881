import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { withStyles, Container, Input } from "@material-ui/core";
import Axios from "../../utils/axios";

const getSize = () => {
  const currentSize = document.getElementsByTagName("body")[0].clientWidth;
  if (currentSize < 768) {
    return 0;
  } else if (currentSize < 1600) {
    return 1;
  } else {
    return 2;
  }
};

const Item = withStyles({
  root: {
    backgroundColor: "transparent",
    padding: "10px 15px",
    borderRadius: "5px",
    color: "#23223f",
    font: "normal normal normal 2rem/2.64rem coolvetica_rg",

    "&:focus": {
      backgroundColor: "#FF5F54",
    },
  },
})(Input);

const Circule = ({
  radius,
  color,
  strokeWidth,
  strokeDasharray,
  strokeDashoffset,
  style,
}) => {
  return (
    <svg height={radius * 2} width={radius * 2} style={style}>
      <circle
        cx={radius}
        cy={radius}
        r={radius - strokeWidth / 2}
        stroke={color}
        fill={color}
        strokeWidth={strokeWidth}
        strokeDasharray={strokeDasharray}
        strokeDashoffset={strokeDashoffset}
      />
    </svg>
  );
};

const AuthCode = (props) => {
  const [pincode, setPinCode] = useState("");
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [timer, setTimer] = useState(60);
  const [size, setSize] = useState(0);
  const [codePhase, setCodePhase] = useState("_");
  const [style, setStyle] = useState({
    container: {},
    item: {},
    circule: 0,
    styleCircule: {},
  });
  const phases = {
    _: { msg: "", className: "m-0" },
    sending: {
      msg: "Validando NIP.",
      className: "m-3 text-secondary",
    },
    validated: { msg: "NIP correcto.", className: "m-3 text-success" },
    failed: {
      msg: "NIP incorrecto, por favor intenta de nuevo.",
      className: "m-3 text-danger",
    },
    error: {
      msg: "Ha ocurrido un problema mientras se validaba el nip. Por favor, inténtalo más tarde.",
      className: "m-3 text-danger",
    },
  };

  useEffect(() => {
    let interval = null;
    if (disabled) {
      interval = setInterval(() => {
        setTimer((timer) => timer - 1);
      }, 1000);
    }
    if (timer === 0) {
      setDisabled(false);
      setTimer(60);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [disabled, timer]);

  useEffect(() => {
    if (pincode.length === 5) {
      setCodePhase("sending");
      const fetchCompareCode = async () => {
        try {
          const endpoint = "otp/compareCode";
          const response = await Axios.post(endpoint, {
            phone: props.phoneNumber,
            code: pincode,
          });
          if (!response.data.ok) throw new Error(response.data.msg);
          setCodePhase("validated");
          setTimeout(() => props.setPhase("confirmMember"), 1500);
        } catch (err) {
          console.error(err);
          setCodePhase("error");
        }
      };
      fetchCompareCode();
    }
  }, [pincode]);

  const handleChange = (event) => {
    setError(false);
    const { id, value } = event.target;
    const next = parseInt(id.charAt(id.length - 1));
    if (value.length === 1) {
      if (next < 4) {
        document.getElementById("pincode" + (next + 1)).focus();
      }
    }

    if (value.length === 0) {
      if (next >= 1) {
        document.getElementById("pincode" + (next - 1)).focus();
      }
    }
    setPinCode((prevState) => {
      const newState = prevState.split("");
      newState[id.charAt(id.length - 1)] = value;
      return newState.join("");
    });
  };

  useEffect(() => {
    const style = (casei) => {
      let style = {
        container: {},
        item: {},
        circule: 0,
        styleCircule: {},
      };
      switch (casei) {
        case 0:
          style.container = {
            maxWidth: "90vw",
            overflow: "hidden",
          };
          style.item = {
            width: "120%",
          };
          style.circule = 320;
          style.styleCircule = {
            position: "absolute",
            top: "-5%",
            left: "50%",
            transform: "translate(-50%, 0%)",
            zIndex: "-1",
            maxWidth: "100%",
          };
          break;
        case 1:
          style.container = {
            maxWidth: "90%",
            height: "60vh",
          };
          style.item = {
            width: "60%",
          };
          style.circule = 400;
          style.styleCircule = {
            position: "absolute",
            top: "-45%",
            left: "50%",
            transform: "translate(-50%, 0%)",
            zIndex: "-1",
            maxWidth: "100%",
          };
          break;
        case 2:
          style.container = {
            maxWidth: "60%",
          };
          style.item = {
            width: "100%",
          };
          style.circule = 600;
          style.styleCircule = {
            position: "absolute",
            top: "-57%",
            left: "50%",
            transform: "translate(-50%, 0%)",
            zIndex: "-1",
            maxWidth: "100%",
          };
          break;
        default:
          style.container = {
            maxWidth: "90%",
          };
          style.item = {
            width: "60%",
          };
          style.circule = 400;
          style.styleCircule = {
            position: "absolute",
            top: "-57%",
            left: "50%",
            transform: "translate(-50%, 0%)",
            zIndex: "-1",
            maxWidth: "100%",
          };
          break;
      }
      return style;
    };

    setSize(getSize());
    setStyle(style(getSize()));

    window.addEventListener("resize", () => {
      setSize(getSize());
      setStyle(style(getSize()));
    });
  }, []);

  let width;
  if (size === 0) {
    width = { width: "90%" };
  } else if (size === 1) {
    width = { width: "47%" };
  } else {
    width = { width: "60%" };
  }

  return (
    <Container
      style={style.container}
      className="d-flex flex-column justify-content-center align-items-center navy mb-5"
    >
      <Circule
        color={"#f2f2f2"}
        radius={style.circule}
        strokeWidth={2}
        style={style.styleCircule}
      />

      <div className="fz42 text-center title-dp fw500 d-flex flex-column">
        <span className="navy mb-2">código de verificación</span>
      </div>

      <div style={{ textAlign: "center" }} className="mb-3">
        ingresa el nip que se envió a tu teléfono
      </div>
      <Row
        style={style ? style.item : {}}
        className="justify-content-center align-items-center"
      >
        {Array.from(Array(5).keys()).map((row) => (
          <Col style={size === 0 ? { padding: ".3rem" } : {}} key={row}>
            <Item
              id={`pincode${row}`}
              variant="outlined"
              onKeyUp={handleChange}
              inputProps={{
                maxLength: 1,
                style: { textAlign: "center" },
                width: "100%",
              }}
              error={error}
            />
          </Col>
        ))}
      </Row>

      <div className={phases[codePhase].className}>{phases[codePhase].msg}</div>

      <Row
        className="mt-4 align-items-center justify-content-around "
        style={width}
      >
        <div
          className="mb-2"
          style={{ textAlign: "center", cursor: "pointer" }}
          onClick={() => {
            props.setOpen(true);
          }}
        >
          ¿no recibiste el código?
        </div>

        <div style={{ textAlign: "center" }}>
          <button
            onClick={() => {
              setDisabled(true);
              props.resendCode({ phoneNumber: props.phoneNumber });
            }}
            className={
              disabled ? "formFieldButton_disabled" : "formFieldButton_alt"
            }
            disabled={disabled}
          >
            {disabled ? `reenviar en ${timer}s` : "reenviar"}
          </button>
        </div>
      </Row>
    </Container>
  );
};

export default AuthCode;
