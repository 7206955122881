import Steps from './Steps';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import AmountForm from '../../forms/AmountForm';
import CustomModal from '../Generic/CustomModal';
import { execToast } from '../../utils/ToastUtils';
import axios from '../../utils/axios';
import Loader from "../Loader/Loader";
import { updateLoader } from '../../redux/actions/loaderActions';
import useSnack from '../../utils/hooks/useSnack';
import {WebSocketContext} from "../../context/WebSocket";
import { useHistory } from 'react-router-dom';

const Amount = props => {
	const toast = useSelector(state => state.app.toast);
    const dispatch = useDispatch();
	const [showToast, setShowToast] = useState(false);
	const [initialValues, setInitialValues] = useState({});
	let typePerson = "";

	const { socket } = React.useContext(WebSocketContext);
	const history = useHistory();

	const { notify } = useSnack();

	const onFormSubmit = async (data) => {
		if(!JSON.parse(sessionStorage.getItem('user')).idClient.type){
			return 0;
		}
		window.scrollTo(0, 0);
		dispatch( updateLoader(true) );
		setShowToast(true);
		const user = JSON.parse(sessionStorage.getItem('user'));
		const id = user._id;
		const idClient = user.idClient;
		// Si ya tienen una solicitud, se actualiza
		if (idClient.appliance.length > 0){
			const appliance = idClient.appliance[idClient.appliance.length - 1];
			if (appliance.hasOwnProperty("idAmount")){
				const amount = appliance.idAmount;
				const id = amount._id;
				try {
					const res = await axios.put(`api/amount/${id}`,data,{
						headers:{
							token: sessionStorage.getItem('token')
						}
					});
					if (res.data.hasOwnProperty("user")){
						sessionStorage.setItem('user', JSON.stringify(res.data.user));
					}
					history.push(`/informacion-comercial/${user._id}`);
				} catch (error) {
					console.log("Error de servicio",error);
				}
			} else {
				try {
					const res = await axios.post(`api/amount/${id}`,data,{
						headers:{
							token: sessionStorage.getItem('token')
						}
					});
					if (res.data.hasOwnProperty("user")){
							sessionStorage.setItem('user', JSON.stringify(res.data.user));
					}
					history.push(`/informacion-comercial/${user._id}`);
					
				} catch (error) {
					console.log("Error de servicio",error);
				}	
			}
		} else {
			try {
				const res = await axios.post(`api/amount/${id}`,data,{
					headers:{
						token: sessionStorage.getItem('token')
					}
				});
				if (res.data.hasOwnProperty("user")){
						sessionStorage.setItem('user', JSON.stringify(res.data.user));
				}
				history.push(`/informacion-comercial/${user._id}`);
				
			} catch (error) {
				console.log("Error de servicio",error);
			}	
		}
		dispatch( updateLoader(false) );
	}

	useEffect(() => {
		if (showToast) {
		  notify("negocio");
		  setShowToast(false);
		}
	   // eslint-disable-next-line react-hooks/exhaustive-deps
	   }, [showToast]);
	
	useEffect(() => {
		window.scrollTo(0, 0);
		dispatch (updateLoader(true)) ;
		const getData = async () => {
			const user = JSON.parse(sessionStorage.getItem('user'));

			const idClient = user.idClient;
			// Si ya tienen una solicitud, se actualiza
			let amount = {}
			if (idClient.appliance.length > 0){
				const appliance = idClient.appliance[idClient.appliance.length - 1];
				if (appliance.hasOwnProperty("idAmount")){
					amount = appliance.idAmount;
					setInitialValues(amount);
				}

			}

			if(user.idClient.type){
				setInitialValues({					
					...amount,
					personType: user.idClient.type
				});
			}

		}
		if (!toast.first) {
			execToast('first');
		}
		getData();
		dispatch (updateLoader(false)) ;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	useEffect(() => {
		notify("monto");
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
		return (
			<div className="container mt-3" >
				<Loader />
				<Steps />
				<CustomModal
					modalName="amountError"
					message="Error al enviar los datos. Favor de intentarlo de nuevo"
				/>
				<AmountForm
					handleSubmit={onFormSubmit}
					initialValues={initialValues}
					typePerson={typePerson}
				></AmountForm>
			</div>
		);
}


export default Amount;
