import React, { useState, useEffect } from "react";
import {
  FieldSelect,
  FieldText,
  FieldCheck,
  FieldCleave,
  FieldPorcentaje,
} from "../components/Generic/Fields";
import { Form, withFormik } from "formik";
import { ButtonSubmit } from "../components/Generic/ButtonSubmit";
import generalInfoOptions from "../models/GeneralInfoModels";
import SubtitleForm from "../components/Generic/SubtitleForm";

const ContactWithValidation = (values) => {
  const erros = {};

  if (values.type === "FAMILY") {
    if (!values.name) {
      erros.name = "Ingresa el nombre de tu familiar";
    } else if (!/^[A-Za-záéíóúñ\s]+$/g.test(values.name)) {
      erros.name = "Nombre inválido";
    }

    if (!values.relative) {
      erros.relative = "Selecciona el parentesco";
    }
  } else {
    if (!values.comercialName) {
      erros.comercialName = "Ingresa el nombre de la empresa";
    } else if (!/^[A-Za-záéíóúñ\s]+$/g.test(values.comercialName)) {
      erros.comercialName = "Nombre inválido";
    }

    if (!values.contactName) {
      erros.contactName = "Ingresa el nombre del contacto";
    } else if (!/^[A-Za-záéíóúñ\s]+$/g.test(values.contactName)) {
      erros.contactName = "Nombre inválido";
    }
  }

  if (!values.phone) {
    erros.phone = "Ingresa un número de teléfono";
  } else if (
    !/^[0-9]\d+$/.test(values.phone) ||
    values.phone.length > 10 ||
    values.phone.length < 10
  ) {
    erros.phone = "Ingresa un número de teléfono válido";
  }

  return erros;
};

const ContactWithForm = (props) => {
  const [type, setType] = useState("FAMILY");
  const [title, setTitle] = useState("Familiar");

    const { isSubmitting, isValid } = props;

  useEffect(() => {
    if (props.values.type !== undefined) {
      setType(props.values.type);
      switch (props.values.type) {
        case "FAMILY":
          setTitle("Familiar");
          break;
        case "CLIENT":
          setTitle("Cliente");
          break;
        case "PROVIDER":
          setTitle("Proveedor");
          break;
        default:
          setTitle("Familiar");
          break;
      }
    }
  }, [props.values.type]);

  return (
    <Form>
      <SubtitleForm subtitle={title} className="mb-10 mt-24 text-center" />
      {type === "FAMILY" ? (
        <>
          <FieldText label="Nombre" name="name" placeholder="Nombre" required />
          <FieldSelect name="relative" label="" required>
            <option value="">Parentesco...</option>
            {Object.keys(generalInfoOptions.familyMemberOptions).map(
              (key, index) => (
                <option value={key} key={index}>
                  {generalInfoOptions.familyMemberOptions[key].value}
                </option>
              )
            )}
          </FieldSelect>
        </>
      ) : (
        <>
          <FieldText
            label="Nombre de la empresa"
            name="comercialName"
            placeholder="Nombre de la empresa"
            required
          />
          <FieldText
            label="Nombre del contacto"
            name="contactName"
            placeholder="Nombre del contacto"
            required
          />
        </>
      )}
      <FieldText
        placeholder="Teléfono Personal"
        label={"El número telefónico debe tener 10 dígitos"}
        name="phone"
        maxLength={10}
        minLength={10}
        normalize="onlyNumbers"
      />
      <div className="text-center">
        <ButtonSubmit isSubmitting={isSubmitting}
          isValid={isValid}
          onClick={async () => {
            await Object.keys(props.values).forEach((field) =>
              props.setFieldTouched(field, true)
            );
          }}
        />
      </div>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    type: props.type,
    index: props.index,
    name: props.initialValues.name || "",
    relative: props.initialValues.relative || "",
    comercialName: props.initialValues.comercialName || "",
    contactName: props.initialValues.contactName || "",
    phone: props.initialValues.phone || "",
  }),
  validate: ContactWithValidation,
  enableReinitialize: true,
  handleSubmit(values, formikBag) {
    formikBag.setSubmitting(false);
    formikBag.props.handleSubmit(values);
  },
})(ContactWithForm);
