import React, { useEffect, useState } from "react";
import Axios from "../../utils/axios";
import {
  Container,
  Grid,
  Paper,
  TableRow,
  TableContainer,
  TableHead,
  withStyles,
  TableCell,
  Table,
  TableBody,
  Button,
  Chip,
  Switch,
} from "@material-ui/core";
import Modal from "react-responsive-modal";
import NuevaAlianza from "./NuevaAlianza";
import { Cloudinary } from "@cloudinary/url-gen";
import Swal from "sweetalert2";
import AlianzaBoddy from "./AlianzaBoddy";

const paperStyle = {
  padding: 20,
  minHeight: "50vh",
  maxHeight: "70vh",
  width: "100%",
  margin: "auto",
  overflow: "auto",
};

const TableCellStyle = withStyles({
  root: {
    fontFamily: "Metropolis-Regular",
    fontSize: "14px",
    color: "#000000",
    textAlign: "center",
    maxWidth: "300px",
  },
})(TableCell);

const TableBodyStyle = withStyles({
  root: {
    overflow: "auto",
    maxHeight: "100%",
  },
})(TableBody);

const opcionesAlianzas = [
  {
    value: "nombre",
    label: "Nombre",
  },
  {
    value: "Broker",
    label: "Broker",
  },
  {
    value: "url",
    label: "URL",
  },
  {
    value: "prefijo",
    label: "Prefijo",
  },
  {
    value: "descripcion",
    label: "Descripción",
  },
  {
    value: "imagens",
    label: "Imagen",
  },
  {
    value: "Status",
    label: "Status",
  },
];

const Alianzas = () => {
  const [alianzas, setAlianzas] = useState([]);
  const [open, setOpen] = useState(false);

  const cld = new Cloudinary({ cloud: { cloudName: "db90e5e8s" } });

  async function fetchData() {
    await Axios.get("/api/alianzas/all")
      .then((response) => {
        setAlianzas(response.data);
      })
      .catch((error) => {
        console.log(error);
        setAlianzas([]);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const uploadAlianza = async (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });
    try {
      const response = await Axios.post("/api/alianzas/new", formData);
      console.log(response);
      if (response.status === 200) {
        setOpen(false);
        // window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const handleImage = (id) => {
    console.log(id);
    const url = cld.image(id).toURL();
    Swal.fire({
        imageUrl: url,
        imageAlt: "Custom image",
        });

  };

  const handleStatus = async (id, data) => {
    try {
      const response = await Axios.put(`/api/alianzas/update/${id}`, {
        ...data,
      });
      if (response.status === 200) {
        // window.location.reload();
        fetchData();
      }
    } catch (error) {

      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Algo salió mal!",
      });
    } 
  };
  return (
    <Container>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{ modal: "customModal" }}
      >
        <NuevaAlianza onSubmit={uploadAlianza} />
      </Modal>
      <Paper elevation={10} style={paperStyle}>
        <Grid
          container
          className="title-dp fz42 fw500 mb-1 text-center mt-2"
          style={{
            color: "#213970",
          }}
        >
          <Grid item xs={12}>
            Alianzas
          </Grid>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
              style={{
                backgroundColor: "#213970",
                color: "#fff",
                fontFamily: "Metropolis-Regular",
                fontSize: "14px",
                textTransform: "capitalize",
                borderRadius: "20px",
                padding: "10px 20px",
              }}
            >
              Generar Alianza
            </Button>
          </Grid>
        </Grid>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {opcionesAlianzas.map((option) => (
                  <TableCellStyle key={option.value}>
                    {option.label}
                  </TableCellStyle>
                ))}
              </TableRow>
            </TableHead>
            <TableBodyStyle>
              {alianzas.map((alianza) => (
                <AlianzaBoddy key={alianza.id} alianza={alianza} handleImage={handleImage} handleStatus={handleStatus} />
              ))}
            </TableBodyStyle>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default Alianzas;
