import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";
import { Grid, Chip } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { updateLoader } from "../../redux/actions/loaderActions";
import upload from "../../assets/img/underline_men/documentos_banner_upload.svg";
import UploadFile from "./UploadFile";
import { HeaderCard } from "./HeaderCard";
import ReactDOM from "react-dom";
import UploadFileOtros from "./UploadFileOtros";
import UploadFiles from "./UploadFiles";

const isMobile = window.innerWidth < 768;

const handleClick = (e, more, handleOpen) => {
  if (more === true) {
    return;
  }
  const action = e.currentTarget?.id?.split("_")?.[1] || e;
  handleOpen(action);
};

const nameAux = (value) => {
  let nameDoc = "";
  if (value.name === undefined) {
    let aux = value.split("-");
    for (let i = 6; i < aux.length; i++) {
      nameDoc += aux[i];
    }
    if (nameDoc === "") {
      for (let i = 5; i < aux.length; i++) {
        nameDoc += aux[i];
      }
    }
  } else {
    let aux = value.name.replace(" ", "");
    nameDoc = aux;
  }
  nameDoc.length > 20 && (nameDoc = nameDoc.substring(0, 20) + "...");
  return nameDoc;
};

const IconMedia = ({ length, typePerson, step, icon, id, cls }) => {
  if (length === null || length === undefined) {
    length = 0;
  }
  let iconCase =
    // step === 0 && typePerson === "PM"
    //   ? length > 1
    //     ? true
    //     : false
    length > 0 ? true : false;
  switch (iconCase) {
    case true:
      return (
        <FontAwesomeIcon
          icon={faCheck}
          id={id}
          alt="icon"
          className={cls}
          style={{ color: "#06c79c", fontSize: "5rem" }}
        />
      );

    default:
      return (
        <img
          src={icon}
          id={id}
          alt="icon"
          style={{
            verticalAlign: "baseline",
          }}
          className={cls}
          onClick={handleClick}
        />
      );
  }
};

const TagList = ({ tag, user, step, typePerson, req }) => {
  // console.log(req);
  return tag.map((t, index) => {
    return (
      <div
        key={t.name === undefined ? nameAux(t) + index : t.name + index}
        style={{ overflow: "hiden" }}
      >
        {step === 0 && req.name === "rfc" && typePerson === "PM" && (
          <span className="metropolisLight fz10 " style={{ color: "#c6c6c6" }}>
            {`socio ${tag.indexOf(t) + 1}`}
          </span>
        )}
        <span
          style={{
            color: "#c6c6c6",
            textAlign: "right",
            padding: "0.2rem 0.5rem",
          }}
          className="metropolisLight fz10 "
        >
          {t.name === undefined ? nameAux(t) : t.name}
        </span>
      </div>
    );
  });
};

const PracticalSpan = (props) => {
  const history = useHistory();
  const handleCiec = () => {
    history.push({
      pathname: `/ciec/${props.userID}`,
      state: { from: history.location.pathname },
      search: `?from=${history.location.pathname}`,
    });
  };
  return (
    <>
      <span
        style={{
          color: "white",
          padding: "0.5rem 0.7rem",
          borderRadius: "0.5rem",
          textAlign: "right",
          cursor: "pointer",
          display: "inline-block",
          backgroundColor: "var(--segundo-color)",
          fontSize: "0.8rem",
          fontWeight: "bold",
        }}
        id={"more_" + props.name}
        onClick={() => handleClick(props.name, null, props.handleOpen)}
        className="metropolisLight fz10 mt-3"
      >
        + agregar más documentos
      </span>
      {props.name === "lastDeclarations" && (
        <span
          style={{
            // color: "#c6c6c6",
            textAlign: "center",
            cursor: "pointer",
            padding: "0.2rem 0.5rem",
            display: "block",
            color: "white",
            borderRadius: "0.5rem",
            maxWidth: `${isMobile ? "80%" : "35%"}`,
            backgroundColor: "var(--primer-color)",
          }}
          id={"ciec_" + props.name}
          onClick={handleCiec}
          className="metropolisLight fz10 mt-2"
        >
          prefiero compartir CIEC {""}
        </span>
      )}
    </>
  );
};

const Requirement = ({
  req,
  tag,
  user,
  typePerson,
  handleOpen,
  handleOpen1,
  handleOpen2,
}) => {
  const userID = user !== null ? user._id : null;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleOpenController =
    req.name === "others"
      ? handleOpen2
      : req.name === "lastDeclarations"
      ? handleOpen1
      : handleOpen;

  return (
    <Grid
      container
      align="center"
      alignItems="center"
      style={{ flexWrap: "nowrap", marginBottom: "1rem" }}
      className="tarjeta"
    >
      {!isMobile && (
        <Grid
          item
          xs={12}
          md={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconMedia
            cls="circule_btn"
            id={"upload_" + req.name}
            icon={upload}
            step={req.step}
            length={tag.length}
            typePerson={typePerson}
          />
        </Grid>
      )}

      <Grid item xs={12} md={10}>
        <Grid container align="center" alignItems="center">
          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              onClick={(e) => {
                let more = tag.length > 0 ? true : false;
                handleClick(e, more, handleOpenController);
              }}
              id={"req_" + req.name}
            >
              <HeaderCard number={1} title={req.description} />
            </p>
          </Grid>
          {tag !== false && tag.length > 0 && (
            <Grid item xs={12} md={12}>
              <TagList
                tag={tag}
                user={user}
                step={req.step}
                typePerson={typePerson}
                req={req}
              />
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            {tag.length > 0 ? (
              <>
                {req.name === "rfc" || req.name === "oficialID" ? (
                  user.idClient.type === "PM" ? (
                    <PracticalSpan
                      name={req.name}
                      userID={userID}
                      handleOpen={handleOpenController}
                    />
                  ) : null
                ) : (
                  <PracticalSpan
                    name={req.name}
                    userID={userID}
                    handleOpen={handleOpenController}
                  />
                )}
              </>
            ) : (
              <PracticalSpan
                name={req.name}
                userID={userID}
                handleOpen={handleOpenController}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const process = (values) => {
  let { title } = values;
  return title;
};

const BodyCard = (props) => {
  const [step, setStep] = useState(props.step);
  const flujo = props.user?.flujo;
  const handleTamaño = (t) => {
    let total = 12 / t;

    return total;
  };

  const $requirements = (props, more) => {
    let tags = props.tags;
    let reqPF = [
      // {
      //   name: "oficialID",
      //   flow: "62f15ad24621d7001caa5471",
      //   description:
      //     " identificación oficial y comprobante de domicilio particular",
      //   step: 0,
      // },
      {
        name: "bankStatements",
        flow: "62f197e88a9445001c6bc082",
        description: "estados de cuenta bancarios",
        step: 1,
      },
    ];

    if (flujo === 1) {
      reqPF.push({
        name: "others",
        flow: "62fb0cb37b0f3a001ce0cd21",
        description: "otros documentos",
        step: 1,
      });
    }

    let $ciec = props.ciec
      ? [
          {
            name: "lastDeclarations",
            flow: "62fae060fe2073001c8ea616",
            description: "documentación fiscal",
            step: 1,
          },
        ]
      : [];
    let reqPFAE = [
      // ...$ciec,
      ...reqPF,
    ];
    let reqPM = [
      {
        name: "rfc",
        description: "constancia de situación fiscal",
        flow: "62f41fce34cd3c001cbd8120",
        step: 0,
      },
      ...$ciec,
      {
        name: "proofAddress",
        flow: "62fb09fa249da5001d41ce7e",
        description: "comprobante de domicilio de la empresa o negocio",
        step: 1,
      },
      {
        name: "constitutiveAct",
        description: "acta constitutiva, asamblea y poderes",
        flow: "62f56b0f235dfd001ed2a123",
        step: 1,
      },
      ...reqPFAE,
    ];
    let reqWarranty = [
      {
        name: "guaranteeStatement",
        description: "garantía inmobiliaria",
        flow: "62fe7fc0017992001cd8b11f",
        step: 2,
      },
      {
        name: "guaranteeFixedAssets",
        description: "activo fijo como garantía",
        flow: "62fc6cb8d9d2ed001cbe392d",
        step: 2,
      },
    ];

    let arrayType =
      props.typePerson === "PF"
        ? reqPF
        : props.typePerson === "PFAE"
        ? reqPFAE
        : reqPM;
    let warranty =
      props.warranty === 1
        ? [reqWarranty[0]]
        : props.warranty === 2
        ? [reqWarranty[1]]
        : props.warranty === 3
        ? reqWarranty
        : [];

    let array = [...arrayType, ...warranty];

    let arrayPrueba = [];

    array.map((req, index) => {
      if (tags.hasOwnProperty(req.name)) {
        if (tags[req.name].length > 0) {
          arrayPrueba.push(tags[req.name]);
        }
      }
    });

    if (more) {
      return array;
    }

    return array.map((req, index) => {
      if (req.step !== step) {
        return null;
      }

      let tag = tags.hasOwnProperty(req.name) ? tags[req.name] : false;
      if (req.name === "lastDeclarations") tag = tags["rfc"];
      return (
        <Grid item xs={12} md={5} alignItems="stretch" key={req.name}>
          <Card
            style={{ border: "none" }}
            className="h-100 shadow2"
            key={req.name}
          >
            <Requirement
              key={req.name}
              req={req}
              tag={tag}
              socketId={props.socketId}
              user={props.user}
              typePerson={props.typePerson}
              setShow={props.setShow}
              setUser={props.setUser}
              setLoader={props.setLoader}
              dispatch={props.dispatch}
              handleOpen={props.handleOpen}
              handleOpen1={props.handleOpen1}
              handleOpen2={props.handleOpen2}
            />
          </Card>
        </Grid>
      );
    });
  };

  useEffect(() => {
    setStep(props.step);
  }, [props.step]);

  return (
    <Grid
      container
      spacing={5}
      align="center"
      alignItems="center"
      className="d-flex justify-content-center"
      style={{
        backgroundColor: "initial",
        paddingTop: "0",
        paddingBottom: "0",
        border: "none",
        alignItems: "stretch",
      }}
    >
      {$requirements(props, false)}
    </Grid>
  );
};

const TarjetaDoc = (props) => {
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState(props.initialValues);
  const [flujo, setFlujo] = useState("");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [action, setAction] = useState("");
  const dispatch = useDispatch();

  const { ciec } = props;

  useEffect(() => {
    const flujo = sessionStorage.getItem("flujo");

    if (flujo) {
      setFlujo(flujo);
    }
  }, []);

  useEffect(() => {
    switch (props.index) {
      case 0:
        props.typePerson === "PM" ? setTitle("socios") : setTitle("personales");
        break;
      case 1:
        setTitle("empresa");
        break;
      case 2:
        setTitle("garantías");
        break;
      default:
        props.typePerson === "PM" ? setTitle("socios") : setTitle("personales");
        break;
    }
  }, [props.index, props.typePerson]);

  useEffect(() => {
    if (props.socket) {
      props.socket.on("updateUser", (data) => {
        dispatch(updateLoader(false));
      });
    }
    return () => props.socket?.off("updateUser");
  }, [dispatch, props.socket]);

  useEffect(() => {
    setTags(props.initialValues);
  }, [props.initialValues]);

  const handleClose = () => {
    const modal = document.getElementById("modal-ext");
    ReactDOM.unmountComponentAtNode(modal);
    setOpen(false);
  };

  const handleOpen = (a) => {
    setAction(a);
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleOpen1 = () => {
    setOpen1(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const fileHandler = async (component, key, e) => {
    let value;
    let limitSize = 10000000; //10MB

    if (e.target.files[0].size > limitSize) {
      dispatch(updateLoader(true, "El archivo excede el tamaño permitido"));
      return;
    }
  };

  return (
    <>
      <UploadFileOtros
        fileHandler={fileHandler}
        handleClose={handleClose2}
        id="modal-ext"
        show={open2}
        socketId={props.socketId}
        tags={tags}
      />
      <UploadFile
        fileHandler={fileHandler}
        handleClose={handleClose1}
        id="modal-ext"
        show={open1}
        socketId={props.socketId}
      />
      <UploadFiles
        action={action}
        fileHandler={fileHandler}
        handleClose={handleClose}
        id="modal-ext"
        show={open}
        socketId={props.socketId}
      />
      <BodyCard
        ciec={!ciec}
        dispatch={dispatch}
        flujo={flujo}
        handleOpen={handleOpen}
        handleOpen1={handleOpen1}
        handleOpen2={handleOpen2}
        setLoader={props.setLoader}
        setShow={props.setShow}
        setUser={props.setUser}
        socketId={props.socketId}
        status={props.setStatus}
        step={props.index}
        tags={tags}
        typePerson={props.typePerson}
        user={props.user}
        warranty={props.warranty}
      />
    </>
  );
};

export default TarjetaDoc;
