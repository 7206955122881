import { useEffect, useRef } from "react";
import Loading from "./Loading";

const CURP = ({ incode, onSuccess, onError, session }) => {
  const containerRef = useRef();
  const isMounted = useRef(false);

  const handleOnSuccess = (event) => {
    onSuccess(event, "curp");
  };

  const handleOnError = (error) => {
    onError(error, "curp");
  };

  useEffect(() => {
    if (isMounted.current) {
      return <Loading />;
    }
    incode.renderEnterCurp(containerRef.current, {
      token: session.token,
      onSuccess: handleOnSuccess,
      onError: handleOnError,
    });
    isMounted.current = true;
  });

  return (
    <>
      <div className="container text-center">
        <h3>
          Tuvimos problemas para obtener tu CURP, por favor ingrésalo aquí:
        </h3>
      </div>
      <div
        style={{ height: "100vh", position: "relative" }}
        ref={containerRef}
      />
    </>
  );
};

export default CURP;
