import React from 'react';
import {Button} from 'react-bootstrap'
import { withFormik, Form } from 'formik';
import { FieldText, FieldPassword } from '../components/Generic/Fields';

const validate = values => {
	const errors = {}

	if (!values.email) {
    errors.email = 'Ingresa un correo'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Ingresa un correo válido'
	}
	if(!values.token){
		errors.token = "Ingresa token"
	} 
	return errors;
}

let DeleteForm = props => {

	const { handleSubmit, submitting} = props
 	const submitButtonClass = (submitting) ? "btn-blue-general mt-30 disabled" : "btn-blue-general mt-30";

	return (
		<div className="container">
			<Form onSubmit={handleSubmit} className="mr-auto ml-auto" style={{maxWidth : '690px'}}>
			<input style={{display:'none'}}/>
			<input type="password" style={{display:'none'}}/>

				<FieldText name="email" placeholder="Correo electrónico" normalize="onlyTextAndNumbersWithoutSpace"/>
				<FieldPassword name="token" placeholder="token"/>
				<div className="text-center">
					<Button type="submit" className={submitButtonClass} disabled={submitting} style={{ width: '250px' }}>continuar</Button>
				</div>
			</Form>
		</div>
	)
}

export default withFormik({
	mapPropsToValues: () => ({ 
	  email: '',
	  token: ''
	}),
	validate: validate, 
	enableReinitialize: true,
	handleSubmit(values, formikBag){
		formikBag.setSubmitting(false);
		formikBag.props.handleSubmit(values);
	},
	displayName: 'DeleteForm'
  })(DeleteForm);
