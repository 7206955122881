import queryString from "query-string";
import { useEffect, useState } from "react";
import Steps from "../Appliance/Steps";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";


const QR = () => {
  const [step, setStep] = useState(false);
  const [qrImage, setQrImage] = useState(null);
  const [copy, setCopy] = useState(false);
  const url = window.location.href;

  const history = useHistory();

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    if (parsed.source && parsed.source === "appliance") {
      setStep(true);
    }
  }, []);

  useEffect(() => {
    if (!url) return;
    const handleQR = async () => {
      const response = await fetch(
        `https://api.qrserver.com/v1/create-qr-code/?data=${url}&size=256x256`
      );
      const blob = await response.blob();
      setQrImage(URL.createObjectURL(blob));
    };
    handleQR();
  }, [url]);

  const handleClick = () => {
    const user = JSON.parse(sessionStorage.getItem("user"));

    Swal.fire({
      title: "¿Estás seguro de omitir la verificación?",
      text: "Podrás continuar con la verificación en cualquier momento",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn-gray-general",
        cancelButton: "btn-blue-documents",
        container: "metropolisRegForce",
        actions: "actionsSwal",
      },
      confirmButtonText: "Omitir",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        if(!copy){
          handleTost();
        }
        history.push(`/auth/${user._id}`);
      }
    });

    // history.push(`/auth/${user._id}`);
  };

  const handleTost = () => {
    navigator.clipboard.writeText(url);
    Swal.fire({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      icon: "success",
      title: "Enlace copiado al portapapeles",
      customClass: {
        container: "metropolisRegForce",
      },
    });
    setCopy(true);
  };
  return (
    <div
      className="container d-flex flex-column m-5 text-center align-items-center"
      style={{ height: "100%" }}
    >
      {step && <Steps />}
      <span className="fz20">
        Vamos a verificar tu identidad. <br />
        Para continuar con la verificación, por favor escanea el código QR desde
        tu celular.
      </span>
      {/* <img
        alt="qr-code"
        src={`https://api.qrserver.com/v1/create-qr-code/?data=${url}&size=256x256`}
        className="mt-4"
      /> */}

      <div
        style={{
          // boxShadow: '0 7px 20px rgba(0,0,0,.4)',
          boxShadow: "1px 1px 17px #929292 ,-7px -7px 27px #ffffff",
          borderRadius: "3rem",
          backgroundColor: "white",
          padding: "3rem",
        }}
        className="mt-4 mb-4"
      >
        {qrImage ? (
          <img alt="qr-code" src={qrImage} className="" />
        ) : (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        )}
      </div>
      {step && (
        <>
          <Button
            type="button"
            className=" btn btn-primary btn-blue-documents mt-4"
            onClick={() => handleTost()}
          >
            <i class="fas fa-link" /> Copiar enlace
          </Button>
          <Button
            type="button"
            className="btn-gray-general mt-4"
            onClick={handleClick}
          >
            Omitir por ahora
          </Button>
        </>
      )}
    </div>
  );
};

export default QR;
