import { useEffect, useState, useRef } from "react";


const useKissmetrics = () => {
    const kissmetricsRef = useRef(() => {});
    const [kissmetricsLoaded, setKissmetricsLoaded] = useState(false);
    const [data, setData] = useState([]);
    
    useEffect(() => {
        if (typeof window !== "undefined" && window._kmq) {
            kissmetricsRef.current = window._kmq;
            setKissmetricsLoaded(true);
        }
    }, []);

    useEffect(() => {
        if (kissmetricsLoaded && data.length> 0) {

            window._kmq.push(data);
        }
    }, [kissmetricsLoaded, data]);

    const logParams = (acction, event, data) => {
        let payload = [acction, event, data];
        payload = payload.filter(
          (item) => item !== null && item !== undefined && item !== ""
        );
        setData(payload);
    };
    
    return logParams;
};

export default useKissmetrics;
