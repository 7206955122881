import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import comparativeImage from "../../assets/img/carousel/POPUP_DOCUMENTOS_FINSUS-01.webp";
import { Grid } from "@material-ui/core";
import HubspotCalendar from "../../utils/hubspotCalendar";
import isAuthenticated from "../../utils/isAuthenticated";

function ProposalModal({ show, close, approved }) {
  // const [name, setName] = useState("");
  const [openCalendar, setOpenCalendar] = useState(false);

  // useEffect(() => {
  //   const user = JSON.parse(sessionStorage.getItem("user"));
  //   const name = user?.nombre + " " + user?.apellido || "{{Nombre Comercial}}";
  //   setName(name);
  // }, []);

  const handleClose = () => {
    close();
    // setOpenCalendar(true);
  };

  const handleOpenCalendar = () => {
    setOpenCalendar(true);
  };

  const proposal = useSelector((state) => state.simulator);

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };

 const getUsername = () => {
  const user = JSON.parse(sessionStorage.getItem("user")) || {};

  if(!user.idClient) return "";

  if(!user.idClient.appliance) return "";

  if(!user.idClient.appliance[0]) return "";

  if(!user.idClient.appliance[0].idGeneralInfo) return "";

  const general = user.idClient.appliance[0].idGeneralInfo;

  const name = "{{Nombre Comercial}}";

  return name;
};

const getTitle = () => {
  const name = getUsername();

  if (approved) {
    return "Propuesta de crédito";
  } else {
    return `Felicidades ${name} tu solicitud se encuentra Pre-Autorizada con las siguientes características`;
  }
};


  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        className="proposalModal"
      >
        <Modal.Body>
          <Grid
            container
            spacing={2}
            className="subtitle-dp fz20 fw500 text-center"
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="title-dp fw600 fz32 mb-16 text-center">
                {getTitle()}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={2} justify="center" alignItems="center">
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  style={{ textAlign: "right" }}
                >
                  Monto :
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  style={{ textAlign: "left" }}
                >
                  {proposal.proposal?.monto
                    ? `$${formatNumber(proposal.proposal?.monto)}`
                    : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={2} justify="center" alignItems="center">
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  style={{ textAlign: "right" }}
                >
                  Plazo :
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  style={{ textAlign: "left" }}
                >
                  {proposal.proposal?.plazo}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={2} justify="center" alignItems="center">
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  style={{ textAlign: "right" }}
                >
                  Tasa :
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  style={{ textAlign: "left" }}
                >
                  {proposal.proposal?.tasa}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={2} justify="center" alignItems="center">
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  style={{ textAlign: "right" }}
                >
                  Comisión por apertura :
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  style={{ textAlign: "left" }}
                >
                  {proposal.proposal?.comision}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className="fz12">
              {approved
                ? "sujeto a terminos y condiciones"
                : "Tu crédito está listo, solo necesitamos validar que la información de tu solicitud sea la misma de tu documentacion (asegúrate de compartirnos tu expediente completo)"}
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <div
            className="meetings-iframe-container hubspot hubspot-disable-focus-styles"
            style={{ width: "100%" } }
            id="hubspotCalendar"
            data-src="https://meetings.hubspot.com/luistiradordp/finsus?embed=true"
            // data-src="https://meetings.hubspot.com/jonathanvillegas?embed=true"
          ></div>
          <Button
            variant="secondary"
            className="btn-blue-documents"
            onClick={handleOpenCalendar}
            style={{ width: "100%" }}
          >
            {!approved ? "Quiero mi crédito, agendar entrevista" : "Firmar"}
          </Button>
        </Modal.Footer>
      </Modal>
      {openCalendar && (
        <HubspotCalendar show={openCalendar} close={setOpenCalendar} />
      )}
    </>
  );
}

export default ProposalModal;

/*
<img
src={comparativeImage}
className="modal-comparative--image"
alt="Comparativo de imagenes en buena calidad"
/>
<Button
onClick={() => setShow(false)}
className="btn-blue-documents"
block
>
Entiendo
</Button> */
