import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk'
import reducer from './reducers';

const ENVIRONMENTS = ['localhost', 'development'];

const store = createStore(
    reducer,
    compose( applyMiddleware(thunk),
        window && ENVIRONMENTS.includes(process.env.REACT_APP_CONFIGURATION) && 
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : compose
    )
);

export default store;