import React, { useEffect, useState } from "react";
import Title from "../Generic/Title";
import "../../css/signup.css";
import registerImage from "../../assets/img/enviado_chava-01.webp";
import Loader from "../Loader/Loader";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import useLocation from "../../utils/hooks/useLocation";
import useKissmetrics from "../../utils/hooks/useKissmetrics";

const Copy = ({ location, error }) => {
  return (
    <>
      <Title className="title-dp fz42 mb-18 fw500" title="Registro Exitoso" />
      <p className="text-dp text-center">
        Bienvenido a la #comunidaddecrédito más grande de México.
      </p>
      <p className="text-dp">¡es hora de iniciar tu solicitud!</p>
      <p className="text-dp text-center mb-50">
        te recomendamos tener a la mano CIEC, INE, Comprobante de Domicilio y
        Estados de <br /> Cuenta bancarios para poder ofrecerte la mejor
        propuesta de crédito en menos de 30 minutos
      </p>
    </>
  );
  // ) : (
  // 	<>
  // 	<Title className="title-dp fz42 mb-18 fw500" title="Ago paso" />
  // 	<p className="text-dp text-center">{error}</p>
  // </>
  // )
};

const RegistroExitoso = (props) => {
  const [button, setButton] = useState(false);
  const logParams = useKissmetrics();
  const history = useHistory();
  // const { location, error, permissions } = useLocation();

  // useEffect(() => {
  // 	if (!navigator.geolocation) {
  // 	  setError("Geolocalización no soportada en tu navegador, seras redirigido a la página de inicio");

  // 	  setTimeout(() => {
  // 		sessionStorage.clear();
  // 		window.location.reload()
  // 	  } , 3000);
  // 	  return;
  // 	}

  // 	const successCallback = (position) => {
  // 	  setLocation(position.coords);
  // 	};

  // 	const errorCallback = (error) => {
  // 	  setError("Para continuar con el registro, debes permitir el acceso a tu ubicación, seras redirigido a la página de inicio");
  // 	  console.log(error.message);
  // 	  setTimeout(() => {
  // 		sessionStorage.clear();
  // 		window.location.reload()
  // 	  }
  // 	  , 3000);
  // 	};

  // 	navigator.permissions.query({ name: "geolocation" }).then((result) => {
  // 	  if (result.state === "denied") {
  // 		setError("Para continuar con el registro, debes permitir el acceso a tu ubicación, seras redirigido a la página de inicio");
  // 		setTimeout(() => {
  // 			sessionStorage.clear();
  // 			window.location.reload()
  // 		  }
  // 		  , 3000);
  // 	  } else {
  // 		navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  // 	  }
  // 	});
  //   }, []);

  useEffect(() => {
    logParams("record", "Visita", { page: "Registro Exitoso" });
    if (
      sessionStorage.getItem("token") &&
      props.match.params.partner === undefined
    ) {
      const user = JSON.parse(sessionStorage.getItem("user"));
      let { prefix, flujo } = user;

      if (!prefix) {
        prefix = "";
      }

      if(!flujo) {
        flujo = 0;
      }

      if (prefix === "" || flujo === 0) {
        setTimeout(() => {
          history.push(`/ciec/${user._id}`);
        }, 4500);
      } else {
        setTimeout(() => {
          history.push(`/datos-comerciales/${user._id}`);
        }, 4500);
      }
    } else {
      setButton(true);
    }
  }, []);

  return (
    <div className="container mt-30 registro-exitoso">
      <Loader />
      <img src={registerImage} alt="registerimage" style={{ width: "250px" }} />
      {/* <MapContainer /> */}

      <Copy />
      {button ? (
        <Button
          className="btn-blue-general"
          onClick={() => history.push("/login")}
        >
          iniciar sesión
        </Button>
      ) : null}
    </div>
  );
};

export default RegistroExitoso;
