import { Form, withFormik } from "formik";
import {
  FieldText,
  FieldDate,
  FieldSelect,
} from "../components/Generic/Fields";
import SubtitleForm from "../components/Generic/SubtitleForm";
import { Col, Row } from "react-bootstrap";
import generalInfoOptions from "../models/GeneralInfoModels";
import { ButtonSubmit } from "../components/Generic/ButtonSubmit";
import { ValidateDataConfirm } from "../components/Validate/DataConfirmValidations";
import { useEffect, useMemo, useState } from "react";
import Axios from "../utils/axios";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { updateLoader } from "../redux/actions/loaderActions";

const processedValues = (values) => {
  const { postalCode } = values;
  return postalCode;
};

const ConfirmDataForm = (props) => {
  const {
    availableSteps,
    initialValues,
    isSubmitting,
    isValid,
    setAvailableSteps,
    setFieldValue,
    values,
  } = props;
  const [colonias, setColonias] = useState([]);
  const pValues = useMemo(() => processedValues(values), [values]);
  const dispatch = useDispatch();

  const setColony = (colonias) => {
    const col = initialValues.colony;
    colonias.includes(col)
      ? setFieldValue("colony", col)
      : setFieldValue("colony", "");
  };

  const handleColonySearch = async (init) => {
    try {
      const cp = !!init ? initialValues.postalCode : values.postalCode;
      if (cp.length === 5) {
        dispatch(updateLoader(true));
        const res = await Axios.get(`/private/api/info/copomex/${cp}`, {
          headers: {
            tokensecret: process.env.REACT_APP_TOKEN_BACK,
          },
        });
        if (!res.data.success) {
          let err = new Error(res.data.message);
          err.response = res;
          throw err;
        }
        const { estado, municipio } = res.data.info;
        const colonias = res.data.info.asentamiento.map((datos) => datos);
        setColonias(colonias);
        setColony(colonias);
        setFieldValue("city", municipio);
        setFieldValue("state", estado);
      }
    } catch (err) {
      Swal.fire({
        title: "¡Ups!",
        text:
          err.response?.data?.err?.error_message ||
          "Algo salió mal durante la transacción, por favor ingresa el código postal de nuevo.",
        icon: "error",
      });
    } finally {
      dispatch(updateLoader(false));
    }
  };

  if (values.civilStatus === "MARRIED_IN_COMMUNITY") {
    const steps = [...availableSteps];
    console.log(steps);
    if (!availableSteps.includes("marriagecertificate")) {
      steps.unshift("marriagecertificate");
      setAvailableSteps([...steps]);
    }
  }

  useEffect(() => {
    if (pValues.length === 5) handleColonySearch();
    else setColonias([]);
  }, [pValues]);

  return (
    <Form className="ml-auto mr-auto" style={{ maxWidth: "690px" }}>
      <div className="container">
        <SubtitleForm
          subtitle={"Confirma tus datos personales:"}
          className="mb-10 text-center"
        />
        <Row className="d-flex justify-content-center">
          <Col lg={4} md={4} sm={12}>
            <FieldText
              name={`name`}
              placeholder="Nombre(s)"
              normalize="onlyLirycs"
              label={"Nombre(s)"}
              disabled={!!props.initialValues?.name}
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <FieldText
              name={`lastname`}
              placeholder="Apellido paterno"
              normalize="onlyLirycs"
              label={"Apellido paterno"}
              disabled={!!props.initialValues?.lastname}
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <FieldText
              name="secondLastname"
              placeholder="Apellido materno"
              normalize="onlyLirycs"
              label={"Apellido materno"}
              disabled={!!props.initialValues?.secondLastname}
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-start">
          <Col lg={4} md={4} sm={12}>
            <FieldDate
              name="birthDate"
              placeholder="Fecha de nacimiento"
              label={"Fecha de nacimiento"}
              disabled={!!props.initialValues?.birthDate}
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <FieldText
              name="curp"
              placeholder="CURP"
              normalize="OnlyLirycsNumberstoUpper"
              label={"CURP"}
              disabled={props.initialValues?.curp.length === 18}
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <FieldText
              name="rfcPerson"
              placeholder="RFC"
              normalize="OnlyLirycsNumberstoUpper"
              maxLength="13"
              label={"RFC"}
              disabled={
                props.initialValues?.rfcPerson.length === 13 &&
                !!!props.errors.rfcPerson
              }
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-start">
          <Col lg={12} md={12} sm={12}>
            <FieldText
              name="email"
              placeholder="Correo electrónico"
              normalize="onlyTextAndNumbersWithoutSpace"
              label={"Correo electrónico"}
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-start">
          <Col lg={12} md={12} sm={12}>
            <FieldSelect name="civilStatus" className="mt-24">
              <option value="">Estado civil...</option>
              {Object.keys(generalInfoOptions.civilStatusOptions).map(
                (key, index) => (
                  <option value={key} key={index}>
                    {generalInfoOptions.civilStatusOptions[key].value}
                  </option>
                )
              )}
            </FieldSelect>
          </Col>
        </Row>
        {values.civilStatus === "MARRIED_IN_COMMUNITY" && (
          <Row className="d-flex justify-content-start">
            <Col lg={12} md={12} sm={12}>
              <FieldText
                name="spousesEmail"
                placeholder="Correo electrónico del cónyuge"
                normalize="onlyTextAndNumbersWithoutSpace"
                label={"Correo electrónico del cónyuge"}
              />
            </Col>
          </Row>
        )}
        <SubtitleForm
          subtitle={"Confirma tu dirección:"}
          className="mt-2 text-center"
        />
        <Row className="d-flex justify-content-start">
          <Col lg={12} md={12} sm={12}>
            <FieldSelect name="typeOfProperty" className="mt-5">
              <option value="">Tipo de Vivienda...</option>
              {Object.entries(generalInfoOptions.typeOfProperty).map(
                ([key, value], index) => (
                  <option value={key} key={index}>
                    {value.value}
                  </option>
                )
              )}
            </FieldSelect>
          </Col>
        </Row>
        <Row className="d-flex justify-content-start">
          <Col lg={12} md={12} sm={12}>
            <FieldText name="street" placeholder="Calle" label={"Calle"} />
          </Col>
        </Row>
        <Row className="d-flex justify-content-start">
          <Col lg={4} md={4} sm={12}>
            <FieldText
              name="exteriorNumber"
              placeholder="Exterior"
              label={"Exterior"}
              normalize="LirycsNumbersDotComa"
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <FieldText
              name="interiorNumber"
              placeholder="Opcional"
              label={"Interior"}
              normalize="LirycsNumbersDotComa"
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <FieldText
              name="postalCode"
              placeholder="Código postal"
              normalize="onlyNumbers"
              label={"Código postal"}
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-start">
          <Col lg={6} md={6} sm={12}>
            <FieldSelect name="colony" className="mt-5">
              <option value={""} disabled>
                Seleccione una colonia...
              </option>
              {colonias.map((colonia, index) => (
                <option value={colonia} key={colonia + index}>
                  {colonia}
                </option>
              ))}
            </FieldSelect>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <FieldText
              name="city"
              placeholder="Delegación o municipio"
              label={"Delegación o municipio"}
              disabled
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-start">
          <Col lg={12} md={12} sm={12}>
            <FieldText
              name="state"
              placeholder="Estado"
              label={"Estado"}
              disabled
            />
          </Col>
        </Row>
        <div className="text-center" style={{ marginBottom: "50px" }}>
          <ButtonSubmit
            isSubmitting={isSubmitting}
            isValid={isValid}
            id={"dataConfirmBtn"}
          />
        </div>
      </div>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    name: props.initialValues?.name || "",
    lastname: props.initialValues?.paternal_surname || "",
    secondLastname: props.initialValues?.mothers_maiden_name || "",
    birthDate: props.initialValues?.birthDate || "",
    rfcPerson: props.initialValues?.rfcPerson || "",
    curp: props.initialValues?.curp || "",
    email: props.initialValues?.email || "",
    phone: props.initialValues?.phone || "",
    street: props.initialValues?.street || "",
    exteriorNumber: props.initialValues?.exteriorNumber || "",
    interiorNumber: props.initialValues?.interiorNumber || "",
    colony: props.initialValues?.colony || "",
    city: props.initialValues?.city || "",
    postalCode: props.initialValues?.postalCode || "",
    state: props.initialValues?.state || "",
    civilStatus: props.initialValues?.civilStatus || "",
    typeOfProperty: "",
  }),
  mapPropsToTouched: () => ({
    name: true,
    paternal_surname: true,
    mothers_maiden_name: true,
    birthDate: true,
    rfcPerson: true,
    curp: true,
    email: true,
    phone: true,
    street: true,
    exteriorNumber: true,
    interiorNumber: true,
    colony: true,
    city: true,
    postalCode: true,
    state: true,
    civilStatus: true,
    typeOfProperty: true,
  }),
  validateOnMount: true,
  validate: ValidateDataConfirm,
  enableReinitialize: true,
  handleSubmit: (values, { props }) => {
    values.birthDate = values.birthDate.split("-").reverse().join("/");
    props.handleSuccess(values);
  },
})(ConfirmDataForm);
