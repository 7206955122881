import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Grid,
  Box,
} from "@material-ui/core";
import Title from "../Generic/Title";
import SubtitleForm from "../Generic/SubtitleForm";
import Modal from "react-responsive-modal";
import ContactWithForm from "../../forms/ContactWithForm";
import generalInfoOptions from "../../models/GeneralInfoModels";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateLoader } from "../../redux/actions/loaderActions";
import Loader from "../Loader/Loader";
import Axios from "../../utils/axios";
import { Button } from "react-bootstrap";
import useKissmetrics from "../../utils/hooks/useKissmetrics";

const ordernFamily = ["name", "phone", "relative"];
const ordernClient = ["comercialName", "contactName", "phone"];

const ContactWith = (props) => {
  let { members, headers, handleForm, type } = props;

  const [typ, setTyp] = useState("FAMILY");
  const [orrder, setOrrder] = useState([]);

  useEffect(() => {
    setTyp(type);
    setOrrder(type === "FAMILY" ? ordernFamily : ordernClient);
  }, [type]);

  useEffect(() => {}, [members]);

  return (
    <TableContainer
      component={Paper}
      className="mb-3 shadow2"
      style={{
        borderRadius: "10px",
        border: "none",
        height: "90%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow style={{ backgroundColor: "#F2F2F2" }}>
            {headers.map((header, index) => (
              <TableCell
                key={header + index}
                align="center"
                className="title-dp_clamp fz16"
              >
                {header}
              </TableCell>
            ))}
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {members &&
            members.length > 0 &&
            members.map((member, index) => (
              <TableRow key={index}>
                {Object.keys(member).map(
                  (key, index) =>
                    orrder.includes(key) && (
                      <TableCell key={index} align="center" className="text-dp">
                        {key === "relative" ? (
                          <Chip
                            className="text-dp"
                            label={
                              generalInfoOptions.familyMemberOptions[
                                member[key]
                              ] &&
                              generalInfoOptions.familyMemberOptions[
                                member[key]
                              ].value
                            }
                            color="primary"
                          />
                        ) : (
                          member[key]
                        )}
                      </TableCell>
                    )
                )}
                <TableCell align="center">
                  <Button
                    variant="secondary"
                    className="btn-blue-documents"
                    style={{ width: "100%", maxWidth: "100px" }}
                    // className="btn btn-primary"

                    onClick={() => {
                      handleForm({
                        type: typ,
                        index: index,
                        member: member,
                      });
                    }}
                  >
                    Editar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <div
        className="text-center"
        style={{ padding: "10px", marginTop: "auto" }}
      >
        <Button
          variant="secondary"
          className="btn-blue-documents"
          style={{ width: "30%", maxWidth: "200px" }}
          // className="btn btn-primary"
          onClick={() => handleForm()}
        >
          Agregar
        </Button>
      </div>
    </TableContainer>
  );
};

const ContactWithView = (props) => {
  const [clientes, setClientes] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [familiares, setFamiliares] = useState([]);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("FAMILY");
  const [valid, setValid] = useState(false);
  const [index, setIndex] = useState(0);
  const [initialValues, setInitialValues] = useState({});
  const [change, setChange] = useState(false);
  const [url, setUrl] = useState("/micuenta");

  const history = useHistory();
  const dispatch = useDispatch();
  const logParams = useKissmetrics();

  const onCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const idClient = user.idClient;
    const type = idClient.type;
    const app = idClient.appliance[0];
    const generalInfo = app.idGeneralInfo;
    const contactWith = generalInfo.contactWith;
    if (contactWith === undefined) {
      return;
    }
    let clientes = contactWith.filter(
      (contact) => contact.type === "CLIENT" || contact.relative === "CLIENT"
    );
    let proveedores = contactWith.filter(
      (contact) =>
        contact.type === "PROVIDER" || contact.relative === "PROVIDER"
    );
    let familiares = contactWith.filter(
      (contact) => contact.type === "FAMILY" || contact.relative === "FAMILY"
    );

    let goTo = type === "PM" ? `/kyc/${user._id}` : `/micuenta`;

    setClientes(clientes);
    setProveedores(proveedores);
    setFamiliares(familiares);
    setUrl(goTo);
  }, [change]);

  useEffect(() => {
    const clientesLength = clientes.length;
    const proveedoresLength = proveedores.length;
    const familiaresLength = familiares.length;

    if (
      clientesLength >= 2 &&
      proveedoresLength >= 2 &&
      familiaresLength >= 3
    ) {
      setValid(true);
    }
  }, [clientes, proveedores, familiares, props]);

  async function handleSubmit(values) {
    logParams("record", "Envío de referencias", null);
    setOpen(false);
    dispatch(updateLoader(true));
    const user = JSON.parse(sessionStorage.getItem("user"));
    const id = user._id;
    if (initialValues !== undefined && initialValues._id !== undefined) {
      values.idContactWith = initialValues._id;
    }
    await Axios.post(`/api/info-general/contactWith/${id}`, values)
      .then((res) => {
        const data = res.data;
        if (data.user !== undefined) {
          sessionStorage.setItem("user", JSON.stringify(data.user));
          setChange(!change);
        }
      })
      .finally(() => {
        dispatch(updateLoader(false));
      });
  }

  function handleNext() {
    const user = JSON.parse(sessionStorage.getItem("user"));
    history.push(`/kyc/${user._id}`);
  }

  return (
    <div className="container mt-3">
      <Loader />
      <div className="text-center mb-2">
        <Title title="Referencias" className="title-dp fz42" />
        <div className="mt-1 text-dp fw300 fz13 text-center mb-1">
          <div className="blackNavBlue">
            pára poder continuar con tu solicitud, necesitamos que nos
            proporciones por lo menos <span className="title-dp fw600 fz18">2</span>{" "}
            referencias de clientes, <span className="title-dp fw600 fz18">2</span>{" "}
            referencias de provedores y <span className="title-dp fw600 fz18">3</span>{" "}
            referencias familiares, asegurate de que los datos sean correctos ya que se realizará una validación de los mismos.
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{ modal: "customModal" }}
      >
        <ContactWithForm
          type={type}
          handleSubmit={handleSubmit}
          index={index}
          initialValues={initialValues}
        />
      </Modal>

      <Grid container justify="center" className="mt-3" spacing={6}>
        
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box className="text-center" style={{ height: "100%" }}>
            <SubtitleForm
              subtitle="Clientes"
              className="mb-10 mt-24 text-center"
            />
            <ContactWith
              members={clientes}
              headers={["Empresa", "Contacto", "Teléfono"]}
              handleForm={(props) => {
                setType("CLIENT");
                setOpen(true);
                if (props !== undefined) {
                  setInitialValues(props.member);
                  setIndex(props.index);
                } else {
                  setInitialValues({});
                  setIndex(clientes.length);
                }
              }}
              type="CLIENT"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box className="text-center" style={{ height: "100%" }}>
            <SubtitleForm
              subtitle="Proveedores"
              className="mb-10 mt-24 text-center"
            />
            <ContactWith
              members={proveedores}
              headers={["Empresa", "Contacto", "Teléfono"]}
              handleForm={(props) => {
                setType("PROVIDER");
                setOpen(true);
                if (props !== undefined) {
                  setInitialValues(props.member);
                  setIndex(props.index);
                } else {
                  setInitialValues({});
                  setIndex(proveedores.length);
                }
              }}
              type="PROVIDER"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box className="text-center" style={{ height: "100%" }}>
            <SubtitleForm
              subtitle="Familiares"
              className="mb-10 mt-24 text-center"
            />
            <ContactWith
              members={familiares}
              headers={["Nombre", "Relación", "Teléfono"]}
              handleForm={(props) => {
                setType("FAMILY");
                setOpen(true);
                if (props !== undefined) {
                  setInitialValues(props.member);
                  setIndex(props.index);
                } else {
                  setInitialValues({});
                  setIndex(familiares.length);
                }
              }}
              type="FAMILY"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box className="text-center" style={{ height: "100%", padding: "2rem" }}>
          {valid ? (
            <Button
              variant="secondary"
              className="btn-blue-documents"
              style={{ width: "50%" }}
              // className="btn btn-primary"
              onClick={() => handleNext()}
              id="Envío_de_referencias"
            >
              Continuar
            </Button>
          ) : (
            <Button
              variant="secondary"
              className="btn_doc_disabled fz16 bluePrimary btn btn-primary"
              style={{ width: "50%" }}
              // className="btn btn-primary"
              // disabled
              onClick={() => history.push(url)}
            >
              Continuar Después
            </Button>
          )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactWithView;
