const Stepper = ({ steps }) => {
  const labels = [
    "Comprobación de identidad",
    "Videoselfie",
    "Comprobante de domicilio",
    "Constancia de Situación fiscal",
    "Términos y condiciones",
    "Comprobación de teléfono",
  ];

  const indexes = [];

  if (steps.includes("facematch")) indexes.push(0);
  if (steps.includes("selectedaddress")) indexes.push(2);
  if (steps.includes("csf")) indexes.push(3);
  if (steps.includes("otp")) indexes.push(5);
  if (steps.includes("videoselfie")) indexes.push(1);
  if (steps.includes("phone")) indexes.push(4);

  return (
    <>
      <div className="step-container">
        <ul className="progress-tracker progress-tracker--center progress-tracker--spaced">
          {labels.map((label, index) => {
            const active = indexes.includes(index);
            return (
              <li
                key={`${label}-${index}`}
                className={`progress-step${active ? " is-complete" : ""}`}
              >
                <span className="progress-marker custom" />
                <span className="progress-text">
                  <h5
                    className={`metropolisMed fw500 step-text${
                      active ? "-complete" : ""
                    }`}
                  >
                    {label}
                  </h5>
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default Stepper;
