import React, { useState } from 'react';
import { Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { withFormik, Form } from 'formik';
import { FieldText, FieldPassword } from '../components/Generic/Fields';
import { LoginValidation } from "../components/Validate/LoginValidation";

const LoginForm = (props) => {

	const { isSubmitting } = props;
 	const submitButtonClass = (isSubmitting) ? "btn-blue-general mt-30 disabled" : "btn-blue-general mt-30";
	const [showPassword, setShowPassword] = useState(false);
	const ShowHide = () => setShowPassword(visible => !visible);

	return (
		<Form className="container mr-auto ml-auto" style={{maxWidth : '690px'}}>
			<FieldText name="email" placeholder="Correo electrónico" normalize="onlyTextAndNumbersWithoutSpace" csty="loginInput"/>
			<FieldPassword name="password" placeholder="Contraseña" setShowPassword={ShowHide} showPassword={showPassword} csty="loginInput"/>
			
			<div className="mt-4 text-center">
					<Link to="/reset-password" className="fw500 fz16 blue-primary text-dp bgWhite">¿olvidaste tu contraseña?</Link>
				</div>
			<div className="text-center">
				<Button type="submit" className={submitButtonClass} style={{ width: '250px' }}>Continuar</Button>
			</div>
		</Form>
	);
}

export default withFormik({
	mapPropsToValues: () => ({ email: '', password: '' }),
	validate: LoginValidation, 
	handleSubmit(values, formikBag){
		formikBag.setSubmitting(false);
		formikBag.props.handleSubmit(values);
	},
	displayName: 'LoginForm'
})(LoginForm);