import { Box, CircularProgress } from "@material-ui/core";

const Loading = () => {
  return (
    <div
      className="container d-flex flex-column m-5 text-center align-items-center"
      style={{ height: "100%" }}
    >
      <h2>Espere un momento...</h2>
      <CircularProgress
        variant="indeterminate"
        disableShrink
        size={40}
        thickness={4}
      />
    </div>
  );
};

export default Loading;
