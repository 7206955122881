import React, { useLayoutEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import tito from '../../assets/img/estatus_solicitud/status-solicitud_01.png';
import { dataFinancialFormalization } from '../../utils/Financials';
import SolicitudBox from '../Generic/SolicitudBox';

const Formalizacion = ({properties}) => {

    return(
        <Row>
            <Col lg={8} md={8} sm={12}>
                <div className="title-dp fz42 fw500">
                    Autorizado / Formalización
                </div>

                <div className="text-dp mb-18">
                    <strong>¡Muchas Felicidades!</strong> Tu Crédito ha sido autorizado, solo hace falta
                    firmar contrato. Muy pronto tu crédito será depositado.
                </div>

            </Col>
            <Col lg={4} md={4} sm={12}>
                <div className='text-center'>
                    <img src={tito} alt="tito" style={{ width: '250px' }}/>
                </div>
            </Col>
        </Row>
    );
}

export default Formalizacion;