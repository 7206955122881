import React, {useState} from "react";
import { Modal, Button, Box } from "@material-ui/core";
import {CloseButton} from "react-bootstrap";

export const ModalComponent = ({ children, open, setOpen }) => {
  const [modalStyle] = useState(getModalStyle);

  const handleClose = () => {
    setOpen(true);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "rgba(0, 0, 0, 0.5)",
      }}
    >

      <Box style={modalStyle} className="pruebaww">

        <Button
          onClick={handleClose}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: "1",
          }}
        >
          <CloseButton />
        </Button>
        {children}
      </Box>
    </Modal>
  );
};

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    position: "absolute",
    width: "90%",
    height: "90%",
    maxHeight: "80vh",
    backgroundColor: "#fff",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    border: "none",
    overflow: "auto",
    // boxShadow: 24,
    p: 2,
  };
};

export default ModalComponent;
