import AssetSituationForm from "../../forms/AssetSituationForm";
import capitalize from "../../utils/capitalizeName";

const AssetSituation = ({ member, onSuccess }) => {
  const initialValues = {
    name: capitalize(member.name, member.lastname, member.secondLastname),
    nationality: "",
    birthDate: member.birthDate?.split("/").reverse().join("-") || "",
    civilStatus: member.civilStatus || "",
    spousesName: member.spousesName || "",
    realEstates: member.realEstates || [
      {
        type: null,
        m2: "",
        buildM2: "",
        value: "",
        address: "",
        letter: "",
        folio: "",
        gravamen: false,
      },
    ],
    stocks: member.stocks || [
      {
        enterprise: "",
        nominalValue: "",
        commercialValue: "",
        appasialBasis: "",
      },
    ],
    art: member.art || [
      {
        description: "",
        estimatedValue: "",
        insuredBy: "",
        insuredQuantity: "",
      },
    ],
    vehicles: member.vehicles || [
      { brand: "", type: "", model: "", invoiceValue: "" },
    ],
    debtors: member.debtors || [
      { institution: "", account: "", originalDue: "", currentDue: "" },
    ],
    toShow: {
      realEstates: false,
      stocks: false,
      art: false,
      vehicles: false,
      debtors: false,
    },
  };

  const handleSubmit = (values) => {
    const vehicles = values.vehicles;
    vehicles.forEach((vehicle, index) => {
      vehicles[index].date = vehicle.date.split("-").reverse().join("/");
    });
    values.vehicles = vehicles;
    console.log(values);
    onSuccess(values, "assetsituation");
  };

  return (
    <div className="container d-flex flex-column m-5 text-center align-items-center">
      <AssetSituationForm
        handleSubmit={handleSubmit}
        initialValues={initialValues}
      />
    </div>
  );
};

export default AssetSituation;
