function currentYPosition() {
  // Firefox, Chrome, Opera, Safari
  // eslint-disable-next-line no-restricted-globals
  if (self.pageYOffset) return self.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
      return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

function elmYPosition(eID) {
  var elm = document.getElementById(eID);

  if(elm === null || elm === undefined){
    return;
  }
  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent != document.body) {
      node = node.offsetParent;
      y += node.offsetTop;
  } return y;
}

export function scrollToTop(){
  window.scrollTo({top: 0, behavior: 'smooth'});

}

export default function smoothScroll(eID) {
  var startY = currentYPosition();
  var stopY = elmYPosition(eID) - 200;
  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
      // eslint-disable-next-line no-restricted-globals
      scrollTo(0, elmYPosition(eID)); return;
  }
  var speed = Math.round(distance / 100);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
      for ( var i=startY; i<stopY; i+=step ) {
          // eslint-disable-next-line no-implied-eval
          setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
          leapY += step; if (leapY > stopY) leapY = stopY; timer++;
      } return;
  }
  for ( var i=startY; i>stopY; i-=step ) {
      // eslint-disable-next-line no-implied-eval
      setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
      leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
  }
}