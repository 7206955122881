import { Grid } from "@material-ui/core";
import ColorButton from "./ColorButton";

const ErrorComp = ({
  error,
  setAvailableSteps,
  setStep,
  typeError = "default",
}) => {
  const handleClick = () => {
    if (typeError === "rfcAttempts") {
      setAvailableSteps((prevSteps) => ["dataconfirm", ...prevSteps]);
      setStep("csf");
    } else window.location.reload();
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className="mt-5 text-dp"
      spacing={5}
    >
      <Grid item xs={12} className="text-center" style={{ maxWidth: "800px" }}>
        <h2>Ha ocurrido un error.</h2>
        <p>{error}</p>
      </Grid>
      <Grid item xs={12} className="text-center">
        <ColorButton onClick={handleClick}>Intentar nuevamente</ColorButton>
      </Grid>
    </Grid>
  );
};

export default ErrorComp;
