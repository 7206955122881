import { create } from "@incodetech/welcome";
import Init from "./Init";
import TyCs from "./TyCs";
import Loader from "../Loader/Loader";
import { useDispatch } from "react-redux";
import { updateLoader } from "../../redux/actions/loaderActions";
import { useEffect, useState } from "react";
import ScanDNI from "./ScanDNI";
import Selfie from "./Selfie";
import VideoSelfie from "./VideoSelfie";
import Location from "./Location";
import FaceMatch from "./FaceMatch";
import SelectedAddress from "./SelectedAddress";
import useQuery from "../../utils/useQuery";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import CURP from "./CURP";
import Success from "./Success";
import ErrorComp from "./Error";
import Loading from "./Loading";
import DataConfirm from "./DataConfirm";
import Phone from "./Phone";
import OTP from "./OTP";
import QR from "./QRCode";
import { useHistory } from "react-router-dom";
import AssetSituation from "./AssetSituation";
import UploadDocument from "./UploadDocument";

const eventsEndpoint = "/incode/events";

const steps = [
  "dni",
  "location",
  "selfie",
  "facematch",
  "curp",
  "videoselfie",
  "selectedaddress",
  "addressstatement",
  "dataconfirm",
  "tycs",
  "phone",
  "otp",
  "success",
];
const MAX_DISTANCE = 100;

const Incode = () => {
  const params = useQuery();
  const history = useHistory();
  const id = params.get("id");
  const source = params.get("source");
  const dispatch = useDispatch();
  const [member, setMember] = useState(null);
  const [session, setSession] = useState(null);
  const [availableSteps, setAvailableSteps] = useState([]);
  const [incodeSteps, setIncodeSteps] = useState([]);
  const [message, setMessage] = useState("");
  const [step, setStep] = useState("");
  const [liveness, setLiveness] = useState(null);
  const [existingUser, setExistingUser] = useState(null);
  const [ineAttempts, setINEAttempts] = useState(0);
  const [dniMessage, setDNIMessage] = useState("");
  const [incomplete, setIncomplete] = useState(false);
  const [typeError, setTypeError] = useState(null);
  const [check, setCheck] = useState({
    buro: false,
    tyc: false,
  });

  const date = new Date().toLocaleString("es-MX", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const navBar = document.getElementById("navbar-dp");

  const handleCheck = (e) => {
    setCheck({
      ...check,
      [e.target.name]: e.target.checked,
    });
  };

  const incodeConfig = {
    apiURL: process.env.REACT_APP_INCODE_API_URL,
    apiKey: process.env.REACT_APP_INCODE_CLIENT_ID,
    lang: "es",
    encrypt: true,
  };

  const incode = create(incodeConfig);

  const start = async () => {
    try {
      if (
        availableSteps.length === 0 ||
        (availableSteps.length === 1 && availableSteps.includes("success"))
      ) {
        const user = JSON.parse(sessionStorage.getItem("user"));
        history.push({
          pathname: `/auth/${user._id}`,
        });
      }
      if (!session) {
        dispatch(updateLoader(true));
        const newSession = await incode.createSession("MX", id, {
          configurationId: process.env.REACT_APP_INCODE_CONF_ID,
        });
        setSession(newSession);
        await axios.post(eventsEndpoint, {
          id,
          events: ["init"],
          data: { interviewId: newSession.interviewId },
        });
      }
    } catch (error) {
      console.error("start error: ", error);
      setMessage(error.message);
      setStep("error");
    } finally {
      dispatch(updateLoader(false));
    }
  };

  const next = () => {
    let newStep = null;
    setAvailableSteps((prevSteps) => {
      newStep = prevSteps.shift();
      return prevSteps;
    });
    if (!!newStep) {
      setStep(newStep);
    } else {
      setStep("success");
    }
  };

  const removeStep = (type) => {
    if (availableSteps.indexOf(type) === -1) return;
    setAvailableSteps((prevSteps) => {
      prevSteps.splice(prevSteps.indexOf(type), 1);
      return prevSteps;
    });
  };

  const onSuccess = async (event, type) => {
    try {
      handleNavbar(true);
      dispatch(updateLoader(true));
      let events = [],
        data = {};
      let ineAttemptsAux = ineAttempts;
      events.push(type);
      switch (type) {
        case "dni":
          const resultDni = await incode.processId({ token: session.token });
          if (!resultDni.data.success) {
            handleError(
              {
                message:
                  "Hubo un error al validar tu identificación, por favor recarga la página e intenta de nuevo.",
              },
              type
            );
          }

          if (event.typeOfId !== "VoterIdentification") {
            if (event.typeOfId === "DriversLicense") {
              handleError(
                {
                  message:
                    "No puedes realizar este proceso con licencia de conducir, por favor, recarga la página y escanea tu INE.",
                },
                type
              );
              return;
            }
          }
          break;

        case "selfie":
          const government = await incode.processGovernmentValidation({
            token: session.token,
          });
          const interviewData = await incode.getInterviewData(session.token);
          const { ineValidationResponse } = interviewData;
          let curp = null;
          let dniDistance = NaN;
          let addressFields = null;

          localStorage.setItem("names", government.ocrData?.name?.firstName);

          if (ineValidationResponse) {
            if (
              ineValidationResponse.succes === false ||
              ineValidationResponse.result === "not_valid"
            ) {
              if (ineAttemptsAux >= 1) ineAttemptsAux = 0;
              else {
                ineAttemptsAux++;
                setINEAttempts(ineAttemptsAux);
                setAvailableSteps((prev) => ["selfie", ...prev]);
                setDNIMessage(
                  ineValidationResponse.resultDetails ||
                    "Hemos detectado errores con tu INE, por favor, escanea nuevamente.."
                );
                setStep("dni");
                return;
              }
            } else ineAttemptsAux = 0;
            data.ineScrapingValidation = ineValidationResponse;
          }

          if (!!government?.ocrData) {
            try {
              curp = await incode.addCurp(
                government.ocrData.curp,
                session.token
              );
            } catch (err) {
              curp = null;
              console.error("curp error: ", err);
            }
            addressFields = government.ocrData?.addressFields;

            if (curp?.success) {
              events.push("curp");
              data.curpVerification = curp;
              removeStep("curp");
            }
          }

          if (!!government.deviceInfo) {
            dniDistance =
              government?.deviceInfo?.geolocationToIdLocationDistance * 1000 ||
              200;
          }

          if (dniDistance <= MAX_DISTANCE && !!addressFields?.street) {
            console.warn("Distancia mínima aprovada.");
            events.push("selectedaddress", "addressstatement");
            data.selectedAddress = "dni";
            removeStep("selectedaddress");
            removeStep("addressstatement");
          } else if (!!!addressFields?.street) {
            removeStep("selectedaddress");
            events.push("selectedaddress");
          }

          data.deviceInfo = government.deviceInfo;
          data.registralSituation = government.registralSituation;

          setLiveness(event.liveness);
          setExistingUser(event.existingUser);
          break;

        case "selectedaddress":
          if (event) {
            events.push("addressstatement");
            data.selectedAddress = "dni";
            removeStep("addressstatement");
          }
          break;

        case "addressstatement":
          if (event.skipped) {
            setIncomplete(true);
            events.splice(events.indexOf("addresstatement"), 1);
          } else {
            events.push("selectedaddress");
            data.selectedAddress = "addressStatement";
          }
          break;

        case "csf":
          if (event.skipped) {
            setIncomplete(true);
            events.splice(events.indexOf("csf"), 1);
          }
          break;

        case "dataconfirm":
          data.dataConfirm = event;
          if (member?.rfcPerson !== member?.clientRfc) events.push("csf");
          break;

        case "marriagecertificate":
          if (event.skipped) {
            setIncomplete(true);
            events.splice(events.indexOf("marriagecertificate"), 1);
          }
          break;
        case "assetsituation":
          data.assetSituation = event;
          break;

        case "tycs":
          events.splice(events.indexOf("tycs"), 1);
          break;

        case "phone":
          events.splice(events.indexOf("phone"), 1);
          break;

        case "otp":
          if (event.type === "otpPassed") {
            events.push("tycs", "phone");
            data.interviewId = session.interviewId;
            const { phone } = await incode.getInterviewData(session.token);
            data.phone = phone;
            data.tycs = {
              phoneConfirmation: true,
              consultaBuro: check.buro,
              tycsOnBoarding: check.tyc,
            };
            const scores = await incode.getScore(session);
            const ocrData = await incode.ocrData(session);
            data.scores = scores;
            data.ocrData = ocrData;
            break;
          }
          throw new ErrorComp(event.message);
        case "success":
          if (session) {
            await incode.getFinishStatus(
              process.env.REACT_APP_INCODE_CONF_ID,
              session
            );
          }
          break;
        default:
          break;
      }

      if (session) data.interviewId = session.interviewId;

      await axios.post(eventsEndpoint, {
        id,
        events,
        data,
      });
      // TODO: descomentar
      // if (!responseEvents?.data?.ok) {
      //   throw new Error(responseEvents.data.message);
      // }
      next();
    } catch (err) {
      console.error(err);
      setMessage(err.message);
      setStep("error");
    } finally {
      dispatch(updateLoader(false));
    }
  };

  function handleError(e, type) {
    switch (type) {
      case "permissionDenied":
        // TODO: handle permissionDenied
        break;
      case "location":
        if (e.code === 1)
          return Swal.fire({
            icon: "info",
            title: "Necesitamos permisos",
            text: "Por favor, activa los permisos de ubicación.",
          });
        break;
      case "tycs":
        if (e.message === "OTPError") {
          return setAvailableSteps((prevSteps) => ["phone", ...prevSteps]);
        }
        setMessage(e.message);
        break;
      case "selfie":
        setMessage(
          "No se pudo procesar la selfie. Por favor, recarga la página e intenta de nuevo."
        );
        break;

      case "otp":
        setMessage(e.message);
        break;

      case "dataconfirm":
        setMessage(e.message);
        setTypeError(e.type);
        break;

      default:
        if (e.name === "AxiosError") setMessage(e.response.data.message);
        else setMessage(e.message);
        break;
    }
    setStep("error");
  }

  const handleStartButton = async () => {
    try {
      await start();
      next();
    } catch (error) {
      handleError(error, "start");
    }
  };

  const handleLoading = (value) => {
    dispatch(updateLoader(value));
  };

  const fetchMember = async () => {
    try {
      const response = await axios.get("/incode/getMember", {
        params: { id },
      });
      if (!response.data?.ok) {
        setMember(null);
        return handleError({ message: "Miembro no encontrado." }, null);
      }
      const miembro = response.data.member;
      setMember(miembro);
      const { incodeSteps, emailConfirmation } = miembro;
      if (source === "email" && !emailConfirmation) {
        await axios.post(eventsEndpoint, {
          id,
          events: [],
          data: { emailConfirmation: true },
        });
      }
      if (miembro.isEmpresarial) {
        steps.splice(steps.indexOf("dataconfirm") + 1, 0, "assetsituation");
      }
      if (miembro.rfcPerson !== miembro.clientRfc) {
        steps.splice(steps.indexOf("addressstatement") + 1, 0, "csf");
      }
      if (
        !!miembro.civilStatus &&
        miembro.civilStatus === "MARRIED_IN_COMMUNITY"
      )
        steps.splice(
          steps.indexOf("dataconfirm") + 1,
          0,
          "marriagecertificate"
        );
      // * Obtener los pasos de InterviewData
      // const newSession = await incode.createSession("MX", id, {
      //   configurationId: process.env.REACT_APP_INCODE_CONF_ID,
      // });
      // const interviewData = await incode.getInterviewData(newSession.token);
      // await incode.getFinishStatus(
      //   process.env.REACT_APP_INCODE_CONF_ID,
      //   newSession
      // );
      let stepsDiff = [];
      if (!!incodeSteps) {
        steps.forEach((step) => {
          if (incodeSteps?.indexOf(step) === -1) stepsDiff.push(step);
        });
        if (
          (stepsDiff[0] === "success" || stepsDiff?.length === 0) &&
          !!incodeSteps
        )
          setStep("success");
        setAvailableSteps(stepsDiff);
      } else {
        setAvailableSteps(steps);
      }

      setIncodeSteps(incodeSteps);
      setStep("init");
    } catch (error) {
      console.error(error);
      setStep("error");
      setMessage(error.response?.data.message || error.message);
    }
  };

  const handleNavbar = (display) => {
    navBar.style.display = display ? "block" : "none";
  };

  useEffect(() => {
    if (!!!id || id.length !== 24) {
      handleError(
        { message: "Link inválido. Por favor, solicita uno nuevo." },
        null
      );
    }
    if (process.env.NODE_ENV === "production") {
      if (incode.isDesktop()) setStep("QRCode");
      else fetchMember();
    } else fetchMember();

    return () => {
      setMember(null);
      setSession(null);
      setAvailableSteps([]);
      setStep("init");
      setLiveness(null);
      setExistingUser(null);
      setCheck({
        buro: false,
        tyc: false,
      });
    };
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const stepsObj = {
    init: (
      <Init
        member={member}
        handleNavbar={handleNavbar}
        handleStartButton={handleStartButton}
        source={source}
        steps={incodeSteps}
        type={member?.type}
      />
    ),
    dni: (
      <ScanDNI
        dniMessage={dniMessage}
        handleNavbar={handleNavbar}
        incode={incode}
        ineAttempts={ineAttempts}
        onError={handleError}
        onSuccess={onSuccess}
        scanType={member?.scanType}
        session={session}
      />
    ),
    curp: (
      <CURP
        incode={incode}
        onError={handleError}
        onSuccess={onSuccess}
        session={session}
      />
    ),
    location: (
      <Location
        handleLoading={handleLoading}
        incode={incode}
        onError={handleError}
        onSuccess={onSuccess}
        session={session}
      />
    ),
    selfie: (
      <Selfie
        handleNavbar={handleNavbar}
        incode={incode}
        onError={handleError}
        onSuccess={onSuccess}
        session={session}
      />
    ),
    facematch: (
      <FaceMatch
        existingUser={existingUser}
        incode={incode}
        liveness={liveness}
        onError={handleError}
        onSuccess={onSuccess}
        session={session}
      />
    ),
    videoselfie: (
      <VideoSelfie
        handleNavbar={handleNavbar}
        incode={incode}
        onError={handleError}
        onSuccess={onSuccess}
        session={session}
      />
    ),
    selectedaddress: <SelectedAddress onSuccess={onSuccess} />,
    addressstatement: (
      <UploadDocument
        handleLoading={handleLoading}
        idMember={member?._id}
        next={next}
        onError={handleError}
        onSuccess={onSuccess}
        session={session}
        type={"addressstatement"}
      />
    ),
    csf: (
      <UploadDocument
        handleLoading={handleLoading}
        idMember={member?._id}
        onError={handleError}
        onSuccess={onSuccess}
        session={session}
        type={"csf"}
      />
    ),
    dataconfirm: (
      <DataConfirm
        availableSteps={availableSteps}
        id={id}
        incode={incode}
        onError={handleError}
        onSuccess={onSuccess}
        reqCsf={member?.rfcPerson !== member?.clientRfc}
        session={session}
        setAvailableSteps={setAvailableSteps}
        setStep={setStep}
      />
    ),
    marriagecertificate: (
      <UploadDocument
        handleLoading={handleLoading}
        idMember={member?._id}
        onError={handleError}
        onSuccess={onSuccess}
        session={session}
        type={"marriagecertificate"}
      />
    ),
    assetsituation: <AssetSituation member={member} onSuccess={onSuccess} />,
    tycs: (
      <TyCs
        check={check}
        date={date}
        handleCheck={handleCheck}
        incode={incode}
        onError={handleError}
        onSuccess={onSuccess}
        session={session}
        source={source}
        showBuro={member?.rfcPerson !== member?.clientRfc}
        rfc={member?.rfcPerson}
        clientRfc={member?.clientRfc}
      />
    ),
    phone: (
      <Phone
        handleNavbar={handleNavbar}
        incode={incode}
        onError={handleError}
        onSuccess={onSuccess}
        session={session}
      />
    ),
    otp: (
      <OTP
        handleNavbar={handleNavbar}
        incode={incode}
        onError={handleError}
        onSuccess={onSuccess}
        session={session}
      />
    ),
    success: (
      <Success
        onSuccess={onSuccess}
        incomplete={incomplete}
        source={source}
        type={member?.type}
      />
    ),
    QRCode: <QR />,
    error: (
      <ErrorComp
        error={message}
        setAvailableSteps={setAvailableSteps}
        setStep={setStep}
        typeError={typeError}
      />
    ),
  };

  return (
    <div className="metropolisRegForce">
      <Loader />
      {stepsObj[step] || <Loading />}
    </div>
  );
};

export default Incode;
