import { useEffect, useRef } from "react";
import Loading from "./Loading";

const VideoSelfie = ({ handleNavbar, incode, onError, onSuccess, session }) => {
  const containerRef = useRef();
  const isMounted = useRef(false);

  handleNavbar(false);

  const handleOnSuccess = (event) => {
    onSuccess(event, "videoselfie");
  };

  const handleOnError = (error) => {
    onError(error, "videoselfie");
  };

  useEffect(() => {
    if (isMounted.current) {
      return <Loading />;
    }
    incode.renderVideoSelfie(
      containerRef.current,
      {
        modules: ["selfie"],
        showTutorial: true,
        token: session,
      },
      {
        numberOfTries: 1,
        onError: handleOnError,
        onSuccess: handleOnSuccess,
      }
    );

    isMounted.current = true;
  }, [session, incode]);

  return (
    <div
      style={{ height: "100vh", position: "relative" }}
      ref={containerRef}
    ></div>
  );
};

export default VideoSelfie;
