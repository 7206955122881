import React, { useState, useEffect, useRef, useMemo } from "react";
import { Form, withFormik } from "formik";
import { FieldText, FieldPassword } from "../components/Generic/Fields";
import { ButtonSubmit } from "../components/Generic/ButtonSubmit";
import { validateCiec } from "../components/Validate/validateCiec";

const CiecExtForm = (props) => {
  const [disabler, setDisabler] = useState(false);
  const { isSubmitting, isValid, values } = props;

  const [showPassword, setShowPassword] = useState(false);
  const ShowHide = () => setShowPassword((visible) => !visible);
  const getDisabler = useMemo(() => { return values.dis; }, [values]);

  useEffect(() => {
    setDisabler(getDisabler);
  }, [getDisabler]);

  return (
    <Form
      autoComplete="off"
      id="form-ciec"
    >
      <FieldText
        name="rfc"
        placeholder="RFC de tu empresa o negocio"
        normalize="OnlyLirycsNumberstoUpper"
        minLength="12"
        maxLength="13"
        disabled={disabler}
        center={true}
        csty=" text-center"
        id="CIEC"
      />
      <FieldPassword
        name="ciec"
        placeholder="CIEC"
        minLength="8"
        maxLength="8"
        setShowPassword={ShowHide}
        showPassword={showPassword}
        style={{
          textAlign: "center",
        }}
      />
      <div className="d-flex justify-content-center">
        <ButtonSubmit
          isSubmitting={isSubmitting}
          id="Continuar_CIEC​"
          isValid={isValid}
          onClick={async () => {
            await Object.keys(props.values).forEach((field) =>
              props.setFieldTouched(field, true)
            );
          }}
        />
      </div>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    rfc: props.initialValues.rfc || "",
    ciec: props.initialValues.ciec || "",
    dis: props.initialValues.dis || false,
    tyc: true,
  }),
  validate: validateCiec,
  enableReinitialize: true,
  handleSubmit(values, formikBag) {
    formikBag.setSubmitting(false);
    formikBag.props.handleSubmit(values);
  },
  displayName: "CiecExtForm",
})(CiecExtForm);
