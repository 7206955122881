import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import SubtitleForm from "../Generic/SubtitleForm";
import CiecFormAuth from "../../forms/ciecFormAuth";
import Axios from "../../utils/axios";
import Swal from "sweetalert2";
import Loader from "../Loader/Loader";
import { updateLoader } from "../../redux/actions/loaderActions";
import { useDispatch } from "react-redux";
import { scrollToTop } from "../../utils/scroll";
import useKissmetrics from "../../utils/hooks/useKissmetrics";

const NextPageP = () => {
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const [ciec, setCiec] = useState("");
  const [initialValues, setInitialValues] = useState({});

  const handleNext = (option) => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    option === 1
      ? history.push(`/documentos/${user._id}`)
      : history.push(`/mifiel/${user._id}`);
  };

  const dispatch = useDispatch();
  const logParams = useKissmetrics();

  async function handleSubmit(values) {
    const user = JSON.parse(sessionStorage.getItem("user"));
    
    setCiec("");
    dispatch(updateLoader(true));

    logParams("record", "Aceptar consulta de buró", null);
    async function fetchCiiec() {
      await Axios.post(`/api/ciec/auth/${user._id}`, values)
        .then((res) => {
          const { data } = res;
          const { user } = data;
          if(user) {
            sessionStorage.setItem("user", JSON.stringify(user));
          }
          dispatch(updateLoader(false));
          logParams("record", "Visita", { page: "Consulta de buró exitosa" });
          history.push(`/credito/solicitud/${user._id}`);
        })
        .catch((err) => {
          const status = err.response.status || 500;
          const response = err.response.data || "Error de servidor";

          const { message } = response;
          if (message === "La CIEC no coincide" || status === 422) {
            console.log("La CIEC no coincide");
            setSuccess(false);
            setCiec("La CIEC no coincide");
            Swal.fire({
              title: "Error",
              text: "La CIEC enviada no coincide con la registrada anteriormente",
              icon: "error",
              confirmButtonText: "Aceptar",
              customClass: {
                title: "subtitle form fz42",
                popup: "text-dp fz20",
                confirmButton: "btn-blue-general btn btn-primary",
              },
            });
          } else {
            setSuccess(false);
            setCiec("Ocurrió un error al validar la CIEC");
            Swal.fire({
              title: "Error",
              text: "Ocurrió un error al validar la CIEC",
              icon: "error",
              confirmButtonText: "Aceptar",
              customClass: {
                title: "subtitle form fz42",
                popup: "text-dp fz20",
                confirmButton: "btn-blue-general btn btn-primary",
              },
            });
          }
        }).finally(() => {
          dispatch(updateLoader(false));
        });
    }
    await fetchCiiec();
  }

  useEffect(() => {
    scrollToTop();
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (!user) {
      history.push("/");
    }
    const rfc = user.rfc;
    const id = user._id;
    const idClient = user.idClient;
    const appliance = idClient.appliance[0] || {};
    const idFiscal = appliance.idFiscal;

    const general = appliance.idGeneralInfo;
    const members = general.members;
    let total = 0;

    if (members && members.length > 0) {
      members.forEach((member) => {
        const { percentage } = member;
        total += parseInt(percentage);
      });
    }

    if (total < 51) {
        history.push(`/informacion-general/${user._id}`);
      }

    if(idFiscal && idFiscal.ciecAcepted) {
      history.push(`/documentos/${user._id}`);
    }

    setInitialValues({ rfc, id });
  }, []);

  return (
    <>
    <Loader/>
      <div className="next-page text-center">
        

        <div
          // className="text-center p-3"
          // style={{ maxWidth: "70%", margin: "auto" }}
        >
          <CiecFormAuth
            initialValues={initialValues}
            ciecOrigen={ciec}
            setSuccess={setSuccess}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default NextPageP;
