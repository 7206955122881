import Swal from "sweetalert2";
import Stepper from "./Stepper";

import ColorButton from "./ColorButton";
import { Button, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import capitalize from "../../utils/capitalizeName";

const Init = ({ member, handleStartButton, source, steps, type }) => {
  const { name, lastname, secondLastname } = member;
  const nombre = capitalize(name, lastname, secondLastname);
  const sourceAppliance = source === "appliance";
  const history = useHistory();

  const handleClick = () => {
    return Swal.fire({
      text: "Si estás viendo un nombre que no es el tuyo, por favor contacta a soporte para que te proporcionen un nuevo enlace.",
      icon: "info",
    });
  };

  const handleOmitBtn = async () => {
    const result = await Swal.fire({
      title: "",
      customClass: {
        confirmButton: "btn-gray-general",
        cancelButton: "btn-blue-documents",
        container: "metropolisRegForce",
      },
      text: "Puedes volver a este proceso en cualquier momento.",
      icon: "info",
      confirmButtonText: "Continuar con la solicitud",
      confirmButtonColor: "#F24C5A",
      cancelButtonText: "Realizar validación",
      showCancelButton: true,
    });

    if (result.isConfirmed) {
      try {
        await navigator.clipboard.writeText(window.location.href);
        let timerInterval;
        const timeToSet = 5;
        Swal.fire({
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          html: "<p>El enlace ha sido copiado al portapapeles, también ha sido enviado a tu correo electrónico. Guárdalo para <strong>completar</strong> el proceso de validación de tu identidad cuando quieras.</p><p>Serás redirigido en <b></b> segundos.</p>",
          icon: "success",
          showConfirmButton: false,
          timer: timeToSet * 1000,
          timerProgressBar: true,
          title: "¡Listo!",
          customClass: {
            timerProgressBar: "progressBarSwal",
            container: "metropolisRegForce",
          },
          didOpen: (toast) => {
            const timer = Swal.getPopup().querySelector("b");
            timer.textContent = `${timeToSet}`;
            timerInterval = setInterval(() => {
              if (Swal.isTimerRunning())
                timer.textContent = `${(Swal.getTimerLeft() / 1000).toFixed(
                  1
                )}`;
              else timer.textContent = `Pausado`;
            }, 100);
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          willClose: () => {
            clearInterval(timerInterval);
            const user = JSON.parse(sessionStorage.getItem("user"));
            setTimeout(() => {
              history.push({
                pathname: `/auth/${user._id}`,
              });
            }, 500);
          },
        });
      } catch (err) {
        console.log(err);
        Swal.fire({
          title: "¡Ups!",
          text: "No se pudo copiar el link al portapapeles, por favor, inténtalo de nuevo sin cambiarte de pestaña o ventana.",
          icon: "error",
        });
      }
    }
  };

  const thereAreSteps = !!steps ? steps?.length !== 0 : false;

  return (
    <div
      className="d-flex flex-column m-5 text-center align-items-center"
      style={{ height: "100%" }}
    >
      <h1 className="m-3">¡Hola{!!!nombre ? "" : ", " + nombre}!</h1>
      <h4>
        Estamos emocionados de conocerte.
        <br /> Para que puedas continuar, te pedimos que completes el proceso de
        validación de tu identidad.
      </h4>
      <h4>
        Para completar el proceso, necesitarás tener a la mano los siguientes
        documentos:
      </h4>

      <ul className="text-left m-3">
        <li className="h5">INE o pasaporte.</li>
        <li className="h5">Comprobante de domicilio.*</li>
        {type === "PM" && (
          <li className="h5">Constancia de situación fiscal.*</li>
        )}
      </ul>

      <p>* Si no los tienes a la mano, podrás subirlos después.</p>

      <Grid container spacing={2} className="m-2">
        <Grid
          item
          xs={12}
          sm={12}
          md={sourceAppliance ? 6 : 12}
          lg={sourceAppliance ? 6 : 12}
        >
          <ColorButton variant="contained" onClick={handleStartButton}>
            {thereAreSteps ? "Continuar" : "Empezar"}
          </ColorButton>
        </Grid>
        {sourceAppliance && (
          <Grid
            item
            xs={12}
            sm={12}
            md={sourceAppliance ? 6 : 12}
            lg={sourceAppliance ? 6 : 12}
          >
            <Button variant="contained" onClick={handleOmitBtn}>
              Omitir por ahora
            </Button>
          </Grid>
        )}
      </Grid>

      {thereAreSteps && (
        <div className="mt-5 mb-5">
          <Stepper steps={steps} />
        </div>
      )}

      {!!nombre && (
        <p
          className="mt-3"
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={handleClick}
        >
          {`No soy ${nombre}`}.
        </p>
      )}
    </div>
  );
};

export default Init;
