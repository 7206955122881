import React, {useState, useEffect} from "react";
import Axios from "../../utils/axios";
import { useDispatch } from "react-redux";
import KYCForm from "../../forms/KYCForm";
import { updateLoader } from "../../redux/actions/loaderActions";
import Loader from "../Loader/Loader";
import { useHistory } from "react-router";
import useKissmetrics from "../../utils/hooks/useKissmetrics";

const KYC = () => {
    const [initialValues, setInitialValues] = useState({});

    const dispatch = useDispatch();
    const history = useHistory();
    const logParams = useKissmetrics();

    useEffect(() => {
        const user = JSON.parse(sessionStorage.getItem("user"));
        const idClient = user.idClient;
        const appliance = idClient.appliance[0];
        const generalInfo = appliance.idGeneralInfo;

        if (generalInfo && generalInfo.kyc !== undefined && generalInfo.kyc !== null) {
            // console.log(generalInfo.kyc);
            setInitialValues(generalInfo.kyc);
        } else {
            setInitialValues({});
        }
    }, []);

    const handleSubmit = async (values) => {
        dispatch(updateLoader(true));
        logParams("record", "Envio de PLD", null);
        const user = JSON.parse(sessionStorage.getItem("user"));
        const id = user._id;
        await Axios.post(`/api/info-general/kyc/${id}`, values)
            .then((res) => {
                const { user } = res.data;
                sessionStorage.setItem("user", JSON.stringify(user));
                history.push(`/micuenta`);
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                dispatch(updateLoader(false));
            })
    }

    return (
        <div
            className="container-fluid"
        >
            <Loader />
            <KYCForm initialValues={initialValues} handleSubmit={(data) => handleSubmit(data)} />
        </div>
    )
};

export default KYC;