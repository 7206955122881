import { useEffect, useRef, useState } from "react";
import { FileDrop } from "react-file-drop";
import ColorButton from "./ColorButton";
import Swal from "sweetalert2";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import Axios from "../../utils/axios";
import {
  CloudUpload,
  Delete,
  DescriptionOutlined,
  ImageOutlined,
  InfoRounded,
} from "@material-ui/icons";
import { Alert } from "react-bootstrap";

const UploadDocument = ({
  handleLoading,
  onError,
  onSuccess,
  session,
  type,
  idMember,
}) => {
  const inputRef = useRef(null);
  const [base64, setBase64] = useState([]);
  const [typeDocuments, setTypeDocuments] = useState([]);
  const [documents, setDocuments] = useState([]);
  const titles = {
    csf: "Constancia de Situación Fiscal",
    addressstatement: "Comprobante de Domicilio",
    marriagecertificate: "Acta de matrimonio",
  };
  const showAlertImages = () => {
    return typeDocuments.includes("image") && type === "csf";
  };

  const showAlertTypes = () => {
    return typeDocuments.length > 1 && type === "csf";
  };

  const disableInput = () => {
    if (type === "csf")
      return !(typeDocuments.includes("pdf")
        ? documents.length > 0
        : documents.length > 1);
    else return documents.length < 1;
  };

  const handleOnSuccess = (event) => {
    onSuccess(event, type);
  };

  const handleOnError = (error) => {
    onError(error, type);
  };

  const handleSkip = () => {
    onSuccess({ skipped: true }, type);
  };

  const sendDocument = async () => {
    try {
      handleLoading(true);
      if (base64) {
        const body = {
          token: session.token,
          type: typeDocuments[0],
          step: type,
          base64,
          idMember,
          filename: document.name,
          names: localStorage.getItem("names") || undefined,
        };
        const documentResponse = await Axios.post("/incode/sendDocument", body);
        if (!documentResponse.data.ok)
          throw new Error(documentResponse.data?.message);
        handleOnSuccess(documentResponse);
      } else
        Swal.fire({
          icon: "info",
          title: "No hay documento",
          text: "Por favor, primero selecciona un archivo pdf o imagen.",
        });
    } catch (err) {
      console.error(err);
      handleOnError(err);
    } finally {
      handleLoading(false);
      if (!!inputRef.current) {
        inputRef.current.value = "";
        setDocuments([]);
        setBase64(null);
        handleLoading(false);
      }
    }
  };

  const readFile = (file) => {
    const maxWidth = 1200,
      maxHeight = 1200;
    return new Promise((resolve) => {
      const readerImg = new FileReader();
      readerImg.onload = (e) => {
        const type = file.type.split("/")[1] === "pdf" ? "pdf" : "image";
        if (e.total < 2 * 1024 * 1024) {
          resolve({
            base64String: e.target.result.split(",")[1],
            type,
            typeDocument: file.type,
          });
        }
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob((blob) => {
            const readerWeb = new FileReader();
            readerWeb.onloadend = () => {
              const base64data = readerWeb.result.split(",")[1];
              resolve({
                base64String: base64data,
                type,
                typeDocument: "image/jpeg",
              });
            };
            readerWeb.readAsDataURL(blob);
          }, "image/jpeg");
        };
        img.src = e.target.result;
      };
      readerImg.readAsDataURL(file);
    });
  };

  const onFileInputChange = async (event) => {
    const { files } = event.target;
    const filesArray = Array.from(files);
    if (filesArray?.length > 0) {
      handleLoading(true);
      if (filesArray[0].type !== "application/pdf") {
        const types = [],
          bases = [];
        for (const file of files) {
          const { base64String, type, typeDocument } = await readFile(file);
          bases.push({ base64: base64String, typeDocument });
          types.push(type);
        }
        setTypeDocuments((prevTypes) => [...new Set([...prevTypes, ...types])]);
        setDocuments((prevArray) => [...prevArray, ...filesArray]);
        setBase64((prevBase) => [...prevBase, ...bases]);
      } else {
        setDocuments([filesArray[0]]);
        const base64Aux = await readFile(filesArray[0]);
        setBase64([
          { base64: base64Aux.base64String, typeDocument: "application/pdf" },
        ]);
        setTypeDocuments([base64Aux.type]);
      }
      handleLoading(false);
    }
  };
  const onTargetClick = (e) => {
    inputRef.current.click(e);
  };

  const onDrop = (e) => {
    const { 0: files } = e;
    if (files) {
      onFileInputChange({ target: { files: e } });
    }
  };

  const handleDelete = (idx) => {
    const docAux = [...documents];
    docAux.splice(idx, 1);
    setDocuments(docAux);
    setBase64((documents) => documents.filter((_, i) => i !== idx));
    const types = [];
    docAux.forEach((doc) =>
      types.push(doc.type.includes("pdf") ? "pdf" : "image")
    );
    if (types.length > 0) setTypeDocuments([...new Set(types)]);
    else setTypeDocuments([]);
  };

  useEffect(() => {
    return () => {
      if (inputRef.current) inputRef.current.value = "";
      setTypeDocuments([]);
      setDocuments([]);
      setBase64([]);
    };
  }, [type]);

  return (
    <div className="container d-flex flex-column m-5 text-center align-items-center">
      <h2 className="mb-5">Sube tu {titles[type]}</h2>
      <input
        className="d-none"
        type="file"
        accept="image/*,application/pdf"
        ref={inputRef}
        onChange={onFileInputChange}
        multiple
      />
      {showAlertImages() && (
        <Alert style={{ maxWidth: "600px" }} variant="info">
          <InfoRounded className="mr-2" />
          Asegúrate de capturar todas las páginas de tu Constancia de Situación
          Fiscal o súbela en formato PDF.
        </Alert>
      )}
      {showAlertTypes() && (
        <Alert style={{ maxWidth: "600px" }} variant="info">
          <InfoRounded className="mr-2" />
          Se detectaron diferentes tipos de archivos, por favor verifica que
          solo se mande un tipo de archivo.
        </Alert>
      )}
      {documents.length > 0 && (
        <List
          dense
          className="metropolisRegForce my-3"
          style={{ width: "100%", maxWidth: "600px" }}
        >
          {documents.map((doc, idx) => (
            <ListItem key={`list-item-${doc.name}-${idx}`}>
              <ListItemAvatar style={{ color: "var(--segundo-color)" }}>
                {doc.type.includes("pdf") ? (
                  <DescriptionOutlined />
                ) : (
                  <ImageOutlined />
                )}
              </ListItemAvatar>
              <ListItemText
                className="text-truncate"
                disableTypography
                primary={doc.name}
              />
              <ListItemSecondaryAction>
                <IconButton
                  style={{ color: "var(--segundo-color)" }}
                  aria-label="delete"
                  onClick={() => handleDelete(idx)}
                >
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
      {(showAlertImages() || documents.length === 0) && (
        <FileDrop onTargetClick={onTargetClick} onDrop={onDrop}>
          <div
            className="dnd"
            style={{
              alignItems: "center",
              border: "2px dashed black",
              borderRadius: "12px",
              cursor: "pointer",
              display: "flex",
              height: "100px",
            }}
          >
            <span className="m-5">
              <CloudUpload className="mr-3" />
              Adjunta tu archivo (o arrastra aquí)
            </span>
          </div>
        </FileDrop>
      )}
      <div className="p-5 mt-3 row" style={{ minWidth: "400px" }}>
        <ColorButton disabled={disableInput()} onClick={sendDocument}>
          Subir
        </ColorButton>
        <Button className="ml-auto" variant="contained" onClick={handleSkip}>
          Subir después
        </Button>
      </div>
    </div>
  );
};

export default UploadDocument;
