import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { validateCiec } from "../components/Validate/validateCiec";
import { Form, withFormik } from "formik";
import {
  FieldText,
  FieldPassword,
  FieldCheck,
} from "../components/Generic/Fields";
import { Row, Col, Button } from "react-bootstrap";
import useThemeData from "../utils/hooks/useThemeData";
import config from "../assets/theme/config";
import { ButtonSubmit } from "../components/Generic/ButtonSubmit";
import Popup from "./PopUp";
import { Video } from "../components/ciec/Video";
import "../css/ciec.css";

let CiecForm = (props) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    valid,
    popup,
    openModal,
    version,
    ciec,
    isValid,
    initialValues,
    isSubmitting,
    changeValid,
    open,
    changeOpen,
    documentos,
    handleDocumentos,
  } = props;

  const [ciecState, setCiecState] = useState(ciec);
  const [validState, setValidState] = useState(" ");
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState(true);
  const [logo, setLogo] = useState(null);
  const [button, setButton] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const ShowHide = () => setShowPassword((visible) => !visible);

  const { theme, themeData } = useThemeData();

  useEffect(() => {
    if (!theme) return;
    setLogo(config(theme).logo);
  }, [theme]);

  useEffect(() => {
    setCiecState(ciec);
  }, [ciec]);

  useEffect(() => {
    if (ciecState === true) {
      setValidState("buscar un crédito personal");
    } else {
      setValidState("continuar con CIEC");
      setError(false);
    }
  }, [ciecState]);

  useEffect(() => {
    if (initialValues.rfc !== undefined) {
      changeValid(initialValues.rfc);
    }
  }, [initialValues]);

  useEffect(() => {
    if (valid === true && error === false) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [valid, error]);

  const currentDate = new Date().toLocaleDateString("es-MX", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const upper = (value) => value && value.toUpperCase();
  const handleText = () => {
    // dispatch({
    //   type: "SET_MODAL",
    //   payload: {
    //     title: "¿Qué es CIEC?",
    //     text: `Es un código de 8 dígitos que te permite realizar trámites y consultas en el SAT sin necesidad de acudir a sus oficinas.
    //     En Finsus lo usamos para obtener tu información fiscal y así poder ofrecerte un crédito personalizado.`,
    //     buttonText: "Entendido",
    //     show: true,
    //     close: true,
    //   },
    // });
    console.log("handleText");
  };

  return initialValues.rfc === undefined ? (
    <div className="altForm"></div>
  ) : (
    <Form autoComplete="off" style={{ width: "100%" }} id="form-ciec">
      <Row className="d-flex justify-content-center">
        {popup && <Popup />}
        {!ciecState && <Popup />}
        <Col
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            padding: "2% 1%",
            flexDirection: "column",
          }}
          md={6}
          xs={12}
          id="videoHowWorks"
          className="text-center"
        >
          <Video version={version} changeOpen={changeOpen} open={open} />
        </Col>
        <Col
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            padding: "2% 1%",
          }}
          md={6}
          xs={12}
        >
          <div className="altForm">
            {logo && (
              <img
                className="mb-4 img-fluid"
                src="https://finsus.mx/wp-content/uploads/2023/09/Logo-Finsus_800x.png"
                alt="LogoEmpresa"
                style={{ width: "20%" }}
              ></img>
            )}
            <div
              className="container mr-auto ml-auto"
              style={{ maxWidth: "690px" }}
            >
              <FieldText
                name="rfc"
                placeholder="RFC de tu empresa o negocio"
                normalize="OnlyLirycsNumberstoUpper"
                minLength="12"
                maxLength="13"
                disabled={ciecState}
                center={true}
                csty=" text-center"
                id="CIEC"
              />
              <FieldPassword
                name="ciec"
                placeholder="CIEC"
                minLength="8"
                maxLength="8"
                setShowPassword={ShowHide}
                showPassword={showPassword}
                style={{
                  textAlign: "center",
                }}
              />
            </div>
            <div className="mt-3 text-center d-flex justify-content-center align-items-center flex-column">
              <Row className="mt-3 flex-column">
                <Col lg={12} xs={6}>
                  <ButtonSubmit
                    isSubmitting={isSubmitting}
                    id="Continuar_CIEC​"
                    isValid={isValid}
                    onClick={async () => {
                      await Object.keys(props.values).forEach((field) =>
                        props.setFieldTouched(field, true)
                      );
                    }}
                  />
                </Col>
              </Row>

              {/* //TODO: Uncomment cuando se tenga el servicio de creditos personales */}
              {
                documentos &&
                <div className="mt-3 text-muted" style={{ maxWidth: "80%" }}>
                  <small>
                    <p
                      style={{
                        display: "inline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleDocumentos();
                      }}
                    >
                      Regresar a los documentos
                    </p>
                  </small>
                </div>
              }
            </div>

            <span
              className={`hvr-underline-from-left mt-4 text-center fz14 blackNav nav-btn heigth-45 solicita-butto metropolisReg`}
              // style={{ width: "250px" }}
              onClick={() => {
                openModal();
              }}
            >
              saber más
            </span>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    rfc: props.initialValues.rfc || "",
    ciec: props.initialValues.ciec || "",
    tyc: true,
  }),
  validate: validateCiec,
  enableReinitialize: true,
  handleSubmit(values, formikBag) {
    formikBag.setSubmitting(false);
    formikBag.props.handleSubmit(values);
  },
  displayName: "CiecForm",
})(CiecForm);
