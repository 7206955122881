import { useEffect, useRef } from "react";
import Loading from "./Loading";

const Phone = ({ handleNavbar, incode, onError, onSuccess, session }) => {
  const containerRef = useRef();
  const isMounted = useRef(false);

  handleNavbar(false);

  const handleOnSuccess = (event) => {
    onSuccess(event, "phone");
  };

  const handleOnError = (error) => {
    onError(error, "phone");
  };

  useEffect(() => {
    if (isMounted.current) {
      return <Loading />;
    }
    console.log("render phone");
    incode.renderPhone(containerRef.current, {
      session,
      onSuccess: handleOnSuccess,
      onError: handleOnError,
      onLog: console.log,
    });
    isMounted.current = true;
  }, [incode, session]);

  return (
    <div style={{ height: "100vh", position: "relative" }} ref={containerRef} />
  );
};

export default Phone;
