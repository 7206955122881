export const validateBuroError = (values) => {
  let errors = {};
  const user = JSON.parse(sessionStorage.getItem("user"));
  const idClient = user.idClient;
  const type = idClient.type;
  const broker = sessionStorage.getItem('broker');

  if (!values.name) {
    errors.name = "Ingresa tu nombre";
  } else if (!/^[A-Za-záéíóúñ\s]+$/g.test(values.name)) {
    errors.name = "Nombre inválido";
  }

  if (!values.lastname) {
    errors.lastname = "Ingresa tu apellido paterno";
  } else if (!/^([a-zñáéíóúü\s]{0,60})$/i.test(values.lastname)) {
    errors.lastname = "Apellido inválido";
  }

  if (!values.secondLastname) {
    errors.secondLastname = "Ingresa tu apellido materno";
  } else if (!/^([a-zñáéíóúü\s]{0,60})$/i.test(values.secondLastname)) {
    errors.secondLastname = "Apellido inválido";
  }

  if (type === "PM") {
    if (!values.rfcPerson) {
      errors.rfcPerson = "Ingresa tu RFC Personal";
    } else {
      if (!/^[A-Z]{4}(\d{6})([A-Z|\d]{3})$/.test(values.rfcPerson)) {
        errors.rfcPerson = "Ingresa un RFC válido";
      }
    }
  }

  if (!values.mortgageCredit) {
    errors.mortgageCredit = "Selecciona una opción";
  }

  if (!values.carCredit) {
    errors.carCredit = "Selecciona una opción";
  }

  if (!values.creditCard) {
    errors.creditCard = "Selecciona una opción";
  }

  if (values.creditCard === "1" && !values.last4) {
    errors.last4 = "Ingresa los números de tu tarjeta";
  } else if (!/^[0-9]{3}\d$/.test(values.last4)) {
    errors.last4 = "Ingresa un número válido";
  }

  if (!values.tyc) {
    errors.tyc = "Debes aceptar para avanzar";
  }

  return errors;
};