export const LoginValidation = values => {
	const errors = {}

	if (!values.email) {
		errors.email = 'Ingresa un correo'
	}
	
	if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		const regex = /^([a-z]{3,4})(\d{2})(\d{2})(\d{2})([0-9a-z]{3})$/i;
		const match = regex.test(values.email);
		
		if(!match){
			errors.email = 'Ingresa un correo válido o un RFC'
		}
	}

	if(!values.password){
		errors.password = "Ingresa una contraseña"
	} 

	return errors;
}