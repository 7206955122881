import React from "react";
import Title from "../../Generic/Title";
import { Card, Row, Col } from "react-bootstrap";
import Icons from "../../Landing/Icons/Icons";

import REGISTRATE from "../../../assets/img/brokers-landing/iconos/Ícono_Registro B.svg";
import FIRMA from "../../../assets/img/brokers-landing/iconos/Ícono_Firma B.svg";
import MEMBRESIA from "../../../assets/img/brokers-landing/iconos/Ícono_Membresía B-01.svg";
import HERRAMIENTAS from "../../../assets/img/brokers-landing/iconos/Ícono_Herramientas B.svg";
import GANAR_DINERO from "../../../assets/img/brokers-landing/iconos/Ícono_Dinero B.svg";

const Cards = () => {
  return (
    <div id="cardsBrokers" className="text-center">
      <div className="justify-content-center container container-xl pt-3">
        <Row className="justify-content-center">
          <Card.Header
            id="header"
            className="title-dp-blue fz42 text-left line-height mb-3"
          >
            <span className="title"> ¿Cómo convertirme</span> en Coach finsus?{" "}
          </Card.Header>
          <Col xl={4} md={5} sm={7} className="mb-4">
            <Card style={{ height: "24rem" }} className="tarjet-info">
              <Icons img={REGISTRATE} alt="registrate" width="100px" />
              <Card.Body>
                <Card.Title>Regístrate</Card.Title>
                <div>
                  <div className="metropolisReg text-center ">
                    Completa tu registro y uno de nuestros asesores se pondrá en
                    contacto contigo para resolver todas tus dudas.
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col xl={4} md={5} sm={7} className="mb-4">
            <Card style={{ height: "24rem" }} className="tarjet-info">
              <Icons img={FIRMA} alt="Firma" width="100px" />
              <Card.Body>
                <Card.Title>Firma tu contrato</Card.Title>
                <div className="metropolisReg text-center fz12">
                  Nuestro proceso de firma es 100% digital, solo necesitarás tener a la mano tu INE.
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col xl={4} md={5} sm={7} className="mb-4">
            <Card style={{ height: "24rem" }} className="tarjet-info">
              <Icons img={MEMBRESIA} alt="Membresia" width="100px" />
              <Card.Body>
                <Card.Title>Coach Oficial</Card.Title>
                <div className="metropolisReg text-center">
                  <p>
                    Al firmar tu contrato y colocar tu primer crédito con
                    nosotros, te haremos llegar tu kit oficial de Coach finsus
                    (chaleco, libreta, pluma premium y promocionales para tus primeros 10 clientes), además de tener acceso
                    total a nuestras herramientas como CRM, programas de
                    incentivos, eventos y mucho más.
                  </p>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col xl={4} md={5} sm={7} className="mb-4">
            <Card style={{ height: "24rem" }} className="tarjet-info">
              <Icons img={HERRAMIENTAS} alt="Herramientas" width="100px" />
              <Card.Body>
                <Card.Title>Recibe tus herramientas</Card.Title>
                <div className="metropolisReg fz12">
                  <ul className="list-broker" style={{ listStyle: "none" }}>
                    <li className="fz21 mb-2">
                      • ID exclusivo que te identifca como Coach
                    </li>
                    <li className="fz21 mb-2">
                      • Acceso único a nuestro CRM
                      <br />
                    </li>
                    <li className="fz21">
                      • Capacitación continua
                      <br />
                    </li>
                  </ul>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col xl={4} md={5} sm={7} className="mb-4">
            <Card style={{ height: "24rem" }} className="tarjet-info">
              <Icons img={GANAR_DINERO} alt="Ganar_dinero" width="100px" />
              <Card.Body>
                <Card.Title>Comienza a ganar dinero</Card.Title>
                <div className="metropolisReg text-center">
                  <p>
                    Ahora estás listo para ofrecer o tus clientes el mejor
                    crédito digital pyme y recibir pre aprobaciones
                  </p>

                  <p className="font-weight-bold">¡en menos de 30 minutos!</p>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Cards;
