import { FieldText } from "../Generic/Fields";
import { Form, withFormik } from "formik";
import { validatePhone } from "../Validate/ValidatePhone";
import { ButtonSubmit } from "../Generic/ButtonSubmit";

const Phone = (props) => {
  const { isSubmitting, isValid, loader } = props;

  return (
    <div style={{ padding: 24 }} className="container mb-5 mt-5">
      <Form className="container mr-auto ml-auto" style={{ maxWidth: "690px" }}>
        <h2>Ingresa tu número de teléfono</h2>
        <FieldText
          name="phoneNumber"
          placeholder="Ingresa tu número de teléfono"
          normalize="onlyNumbers"
          maxLength={10}
        />
        <ButtonSubmit
          isSubmitting={isSubmitting}
          isValid={isValid}
          onClick={() => {
            if (isValid) {
              loader(true);
            }
          }}
        />
      </Form>
    </div>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ phoneNumber: "" }),
  validate: validatePhone,
  enableReinitialize: true,
  handleSubmit(values, formikBag) {
    formikBag.setSubmitting(false);
    formikBag.props.onSubmit(values);
  },
})(Phone);
