import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import Title from "../Generic/Title";
import LinkButton from "../Generic/LinkButton";
import tito from "../../assets/img/enviado_chava-01.webp";
import Steps from "../Appliance/Steps";
import axios from "../../utils/axios";
import PopUp from "../../forms/PopUp";
import { setProposal } from "../../redux/actions/simulatorActions";
import { WebSocketContext } from "../../context/WebSocket";
import useSnack from "../../utils/hooks/useSnack";
import { updateUserData } from "../../redux/actions/userActions";

const credit = {
  greeting: "Hola",
  greeting2: "Conoce el detalle de tu solicitud",
  title: "Completa tu solicitud",
  subtitle: "Completa el 100% de tu solicitud y empieza a recibir las mejores opciones de crédito.",
}

const captacion = {
  greeting: "Hola",
  greeting2: "",
  title: "Solicitud en curso",
  subtitle: "Obten la cuenta ideal para tu empresa o negocio, ¡Asi de Finsus!",
};
const Credit = () => {
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [type, setType] = useState(null);
  const [appliance, setAppliance] = useState(null);
  const [link, setLink] = useState("");
  const [info, setInfo] = useState(null);

  const history = useHistory();

  const dispatch = useDispatch();
  const { notify } = useSnack();

  const { socket } = useContext(WebSocketContext);

  useEffect(() => {
    if (user) {
      if(user.needsAction) {
        history.push(`/ciec/${user._id}`);
      }

      if (user.idClient) {
        if (user.idClient.type) {
          setType(user.idClient.type);
        }
        if (user.idClient.appliance && user.idClient.appliance.length > 0) {
          setAppliance(user.idClient.appliance[0]);
        } else {
          history.push(`/ciec/${user._id}`);
        }
      }

      if(user.flujo === 0 ) {
        setInfo(credit);
      } else {
        setInfo(captacion);
      }
    }
  }, []);

  useEffect(() => {
    if (!appliance) return;

    const switchDocuments = (documents, onbording ) => {
      switch (true) {
        case documents === undefined:
        case documents === null:
        case documents.length === 0:
          setLink(`documentos/${user._id}`);
          break;
        case documents.status === true:
          if(!onbording) {
          setLink(`referencias/${user._id}`);
          } else {
            setLink(`kyc/${user._id}`);
          }
          break;
        default:
          setLink(`documentos/${user._id}`);
          break;
      }
    };

    const nextStep = (fiscal, documents, onbording) => {

      if ((fiscal && fiscal.ciecAcepted === true) || onbording) {
        switchDocuments(documents, onbording);
      } else {
        setLink(`auth/${user._id}`);
      }
    };

    const switchGeneralInfo = (generalInfo, documents, fiscal, prefix) => {

      const onbording = user?.flujo === 1;

      switch (true) {
        case !("members" in generalInfo):
          setLink(`informacion-general/${user._id}`);
          break;
        case generalInfo.members === undefined:
        case generalInfo.members === null:
        case generalInfo.members.length === 0:
          setLink(`informacion-general/${user._id}`);
          break;
        default:
          {
          const members = generalInfo.members;
          let total = 0;

          for (const member of members) {
            total += Number.parseInt(member.percentage);
          }

          if (total >= 51 || type !== "PM") {
            if(type !== "PM") {
              const member = members[0];

              const { phoneConfirmation, incodeSteps} = member;
              if(phoneConfirmation || ("success" in incodeSteps)) {
                nextStep(fiscal, documents, onbording);
              } else {
                setLink(`informacion-general/${member._id}`);
                return;
              }
            } else {
              nextStep(fiscal, documents, onbording);
            }
            
          } else {
            setLink(`informacion-general/${user._id}`);
          }
        }
          break;
      }
    };

    if (appliance) {
      const proiedades = Object.keys(appliance);
      // console.log("proiedades", proiedades);

      if(proiedades.includes("proposals")) {
        if(appliance.proposals.length > 0){
          const lastProposal = appliance.proposals[appliance.proposals.length - 1];
        dispatch(setProposal(lastProposal));
        notify(lastProposal.etapa);
        }
        // console.log("propuestas", appliance.proposals);
      }

      let { prefix } = user;

      if(!prefix) {
        prefix = "";
      }

      if(prefix !== "" && !proiedades.includes("idFiscal")) {
        proiedades.push("idFiscal");
      }

      switch (true) {
        case !proiedades.includes("idFiscal"):
          // history.push(`ciec/${user._id}`);
          console.log("idFiscal");
          break;
        case !proiedades.includes("idComercialInfo"):
          setLink(`datos-comerciales/${user._id}`);
          break;
        case !proiedades.includes("idGeneralInfo"):
          setLink(`informacion-general/${user._id}`);
          break;
        case proiedades.includes("idGeneralInfo"):
          if (!appliance.idGeneralInfo.hasOwnProperty("members")) {
            setLink(`informacion-general/${user._id}`);
          } else {
            switchGeneralInfo(
              appliance.idGeneralInfo,
              appliance.idDocuments,
              appliance.idFiscal,
              prefix
            );
          }
          break;
        case !proiedades.includes("idDocuments"):
          setLink(`documentos/${user._id}`);
          break;
        case proiedades.includes("idDocuments"):
          if (appliance.idDocuments.status === true) {
            setLink(`referencias/${user._id}`);
            break;
          } else {
            setLink(`documentos/${user._id}`);
            break;
          }
        default:
          setLink(`documentos/${user._id}`);
          break;
      }
    }
  }, [appliance, type]);

  if(!info) return null;

  return (
    <div className="mt-72 mb-120 container">
      {/* { modal && (
        <PopUp
          show={show}
          setShow={(value) => setShow(value)}
          isDocuments={true}
        />
      )} */}
      <div>
        <Title
          className="title-dp fw500 fz32 mb-16"
          title={info.greeting}
        />
        <label className="subtitle-dp fz20 fw500">
          {info.greeting2}
        </label>
        <div className="position-relative">
          <img src={tito} className="apply-caracter-img" alt="tito" />
          <div className="step-box text-center">
            <div>
              <Title
                className="title-dp fw600 fz42 mb-16"
                title={info.title}
              />
              <label className="subtitle-dp fz20 fw500">
                {info.subtitle}
              </label>
            </div>
            <div className="mt-50">
              <Steps />
              {/* <Countdown time={1000} onEnd={console.log("end")} /> */}
              {/* <Cards user={user} /> */}
            </div>
            {<LinkButton link={link} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Credit;
