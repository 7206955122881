import React, { useEffect, useState } from "react";
import Loader from "../Loader/Loader";
import { useDispatch } from "react-redux";
import axios from "../../utils/axios";
import { useHistory } from "react-router-dom";
import { updateLoader } from "../../redux/actions/loaderActions";
import MifielWidget from "./MifielWidget";
import { Button, Row } from "react-bootstrap";
import useSnack from "../../utils/hooks/useSnack";
import Swal from "sweetalert2";

const MiFiel = () => {
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState(null); // user is null at the beginning
  const [error, setError] = React.useState(false);
  const [pf, setPf] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [widget, setWidget] = React.useState(false);
  const [widgetData, setWidgetData] = React.useState(null);
  const [pantalla, setPantalla] = React.useState(false);
  const [sandbox, setSandbox] = React.useState(true);
  const [show, setShow] = React.useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  dispatch(updateLoader(true));

  const { notify } = useSnack();

  const verifyAppliance = (array) => {
    if (typeof array !== "object") return false;
    return array.length === 0 ? false : array[array.length - 1];
  };

  const verify = (object, property) => {
    return object.hasOwnProperty(property);
  };

  useEffect(() => {
    if (data === null) return;

    const { widgetId, documento } = data;

    if (documento) {
      const { signed } = documento;
      if (signed) {
        history.push(`/documentos/${user._id}`);
        return;
      }
    }

    // if (!widgetId) {
    //   history.push(`/documentos/${user._id}`);
    //   return;
    // }

    setWidget(true);
    setWidgetData(data);
  }, [data]);

  useEffect(() => {
    const widgetListener = (event) => {
      console.log("event", event);
      if (event.data === "close") {
        history.push(`/documentos/${user._id}`);
      }
    };

    window.EventCounts = widgetListener;
  }, []);

  useEffect(() => {
    let data = sessionStorage.getItem("user");
    if (data) {
      setUser(JSON.parse(data));
    }
  }, []);

  useEffect(() => {
    if (!user) return;
    const idClient = user.idClient;

    if (idClient.type !== "PM") {
      setPf(true);
      return;
    }

    const appliance = verifyAppliance(idClient.appliance);
    const idFiscal = verify(appliance, "idFiscal");

    if (!idFiscal) {
      history.push(`/ciec/${user._id}`);
      return;
    }

    const fiscalInfo = appliance.idFiscal;

    if (!fiscalInfo) {
      dispatch(updateLoader(false));
      history.push(`/ciec/${user._id}`);
      return;
    }

    const { rfcMoral, buroMoral } = fiscalInfo;

    if (!rfcMoral || rfcMoral === "") {
      history.push(`/documentos/${user._id}`);
      return;
    }

    try {
      const fetchData = async () => {
        await axios
          .post(`/api/mifiel/${user._id}`, {
            rfc: rfcMoral,
          })
          .then((result) => {
            if (result.data.code && result.data.code === 2) {
              dispatch(updateLoader(false));
              history.push(`/documentos/${user._id}`);
              return;
            }

            setData(result.data);
          })
          .catch((error) => {
            setError(true);
            console.log(error);
          })
          .finally(() => {
            // setLoading(false);
            dispatch(updateLoader(false));
          });
      };
      fetchData();
    } catch (error) {
      console.log(error);
      setError(true);
      setLoading(false);
      dispatch(updateLoader(false));
    }
  }, [user]);

  useEffect(() => {
    const env = process.env.REACT_APP_CONFIGURATION;
    if (env !== "localhost") {
      setSandbox(false);
    }
  }, []);

  useEffect(() => {
    if (show) {
      notify("mifiel");
      dispatch(updateLoader(false));
      setShow(false);
    }
  }, [show]);

  function handleError(error) {
    console.log("error", error);
    history.push(`/credito`);
  }

  function handleSuccess(data) {
    console.log("data", data);
    history.push(`/documentos/${user._id}`);
  }

  function onLoading(p) {
    console.log("p");
    setLoading(false);
    dispatch(updateLoader(false));
  }

  function handleCopyUrl(id) {
    const url = process.env.REACT_APP_REDIRECT;
    const encrypID = btoa(id);
    const urlWidget = `${url}/widget/${encrypID}`;
    navigator.clipboard.writeText(urlWidget);
    Swal.fire({
      position: "bottom-end",
      text: "El enlace se ha copiado al portapapeles",
      showConfirmButton: false,
      background: "rgba(0,0,0,0.7)",
      backdrop: "transparent",
      timer: 1000,
      color: "white",
    });
  }

  const positionWeb = {
    position: "absolute",
    top: "calc(100vh - 40%)",
    left: "calc(100vw - 76%)",
  };

  const positionMobile = {
    position: "absolute",
    top: "calc(100vh - 40%)",
    left: "calc(100vw - 76%)",
  };

  if (pf) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center">Lo sentimos</h1>
            <p className="text-center">
              En esta parte del proceso solo pueden participar personas morales
            </p>
          </div>
          <div className="col-12 text-center">
            <Button
              onClick={() => history.push(`/documentos/${user._id}`)}
              className="btn-blue-general"
            >
              continuar
            </Button>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center">Lo sentimos</h1>
            <p className="text-center">
              Se ha producido un error, por favor intente más tarde
            </p>
          </div>
          <div className="col-12 text-center">
            <Button
              onClick={() => history.push(`/documentos/${user._id}`)}
              className="btn-blue-general"
            >
              continuar
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <Loader />
      {widget && widgetData && (
        <Row
          style={{ justifyContent: "space-between" }}
          className="container mb-2"
        >
          {/* <Button onClick={() => handleCopyUrl(widgetData.widgetId)} className="btn-blue-general">
              obtener enlace remoto
            </Button> */}
          <Button
            onClick={() => history.push(`/documentos/${user._id}`)}
            className="btn-blue-general"
          >
            firmar después
          </Button>
        </Row>
      )}
      {
        /* <div id="mifiel-widget"></div> */
        widget && widgetData && (
          <div id="mifiel-widget1">
            <MifielWidget
              widgetId={widgetData.widgetId}
              successButtonText="continuar"
              width="100%"
              height="200vh"
              color="023473"
              id="mifiel-widget"
              error={handleError}
              onSuceess={handleSuccess}
              sandbox={sandbox}
              onLoading={onLoading}
            />
          </div>
        )
      }
    </div>
  );
};

export default MiFiel;
