const broker = sessionStorage.getItem('broker');

export const validateComercialInfo = (values) => {
  let errors = {};
  const user = JSON.parse(sessionStorage.getItem("user"));
  const idClient = user.idClient;
  const type = idClient.type;

    if (!values.zipCode) {
    errors.zipCode = "Ingresar código postal";
  } else {
    if (values.zipCode.length < 5) {
      errors.zipCode = "Código postal inválido";
    }
  }
  if (!values.town) {
    errors.town = "Seleccione una colonia";
  }
  

  if (!values.comercialName) {
    errors.comercialName = "Ingresa el nombre de tu negocio";
  }

  if (!values.specific) {
    errors.specific = "Escribe la actividad específica de tu negocio";
  }

  if (!values.street) {
    errors.street = "Ingresa la calle de tu negocio";
  }
  else if(values.street.length > 40){
    errors.street = "Ingresa solamente la calle de tu negocio";
  }

  if (!values.extNumber) {
    errors.extNumber = "Ingresa el número exterior de tu negocio";
  }
  else if(!/^[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$/.test(values.extNumber)){
    errors.extNumber = "Ingresa un número exterior valido";
  }

  if(values.intNumber !== '' && !/^[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$/.test(values.intNumber)){
    errors.intNumber = "Ingresa un número interior valido";
  }
  // console.log(errors);
  return errors;
};
