import { useEffect, useRef } from "react";

const Selfie = ({ handleNavbar, incode, onError, onSuccess, session }) => {
  const containerRef = useRef();
  const isMounted = useRef(false);

  handleNavbar(false);

  const handleOnSuccess = (event) => {
    onSuccess(event, "selfie");
  };

  const handleOnError = (error) => {
    console.log(error);
    onError(error, "selfie");
  };

  useEffect(() => {
    if (isMounted.current) {
      return <></>;
    }
    incode.renderCamera("selfie", containerRef.current, {
      disableFullScreen: true,
      numberOfTries: 2,
      onError: handleOnError,
      onSuccess: handleOnSuccess,
      showPreview: true,
      showTutorial: true,
      timeout: 5000,
      token: session,
    });
    isMounted.current = true;

    return () => {
      if (isMounted.current) {
        isMounted.current = false;
      }
    };
  }, [onSuccess, onError, session]);

  return <div style={{ height: "100vh" }} ref={containerRef}></div>;
};

export default Selfie;
