import React, { useState, useEffect } from "react";
import SubtitleForm from "../components/Generic/SubtitleForm";
import { Row, Col, Button } from "react-bootstrap";
import InputLabel from "../components/Generic/InputLabel";
import { validateBuroError } from "../components/Validate/buroErrorValidate";
import useThemeData from "../utils/hooks/useThemeData";
import { Form, withFormik } from "formik";
import { ButtonSubmit } from "../components/Generic/ButtonSubmit";
import {
  FieldSelect,
  FieldText,
  FieldCheck,
} from "../components/Generic/Fields";

let ErrorBuro = (props) => {

  const {
    isValid,
    isSubmitting,
    initialValues,
  } = props;

  const [creditCard, setCreditCard] = useState("");

  const { theme, themeData } = useThemeData();
  const user = JSON.parse(sessionStorage.getItem("user"));

  function handleCreditCard(e) {
    const { value } = e.target;
    console.log("value", value);
    setCreditCard(value);
  }

  useEffect(() => {
    if(initialValues.creditCard !== "" && initialValues.creditCard === "1") {
      setCreditCard(initialValues.creditCard);
    }
  }, [initialValues]);

  useEffect(() => {
    setTimeout(() => {
      // Validar / marcar con error sólo tyc

      props.setFieldTouched("tyc", true);
      props.setFieldError("tyc", "Debes aceptar para avanzar");

      // Validar todos al cargar (last4 y tyc)

      // Object.keys(props.values).forEach((field) =>
      //   props.setFieldTouched(field, true)
      // );
    }, 1);
  }, []);

  return (

        <Form
          className="ml-auto mr-auto"
          style={{ maxWidth: "90vw", width: "690px" }}
        >
          <SubtitleForm
            subtitle="Sobre ti"
            // className=" text-form-dp"
          />
          <Row className="d-flex justify-content-center">
            <Col lg={4} md={4} sm={12}>
              <FieldText
                name="name"
                placeholder="Nombre(s)"
                type="text"
                normalize="onlyLirycs"
              />
            </Col>
            <Col lg={4} md={4} sm={12}>
              <FieldText
                name="lastname"
                placeholder="Apellido paterno"
                normalize="onlyLirycs"
              />
            </Col>
            <Col lg={4} md={4} sm={12}>
              <FieldText
                name="secondLastname"
                placeholder="Apellido materno"
                normalize="onlyLirycs"
              />
            </Col>
          </Row>
          {user.idClient.type === "PM" ? (
            <Row>
              <Col lg={12} md={12} sm={12}>
                <FieldText name="rfcPerson" placeholder="RFC de la persona" />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col lg={12} md={12} sm={12}>
                <FieldText name="rfc" placeholder="RFC de la persona" />
              </Col>
            </Row>
          )}
          <SubtitleForm
            subtitle="¿Cuentas con alguno?"
            className="mt-30 mb-18"
          />
          <Row>
            <Col>
              <InputLabel
                placeholder="crédito hipotecario"
                class="mt-18 text-msg-dp"
              />
              <FieldSelect name="mortgageCredit" clases="mt-10">
                <option value="">Selecciona...</option>
                <option value="1">Sí</option>
                <option value="0">No</option>
              </FieldSelect>
            </Col>

            <Col>
              <InputLabel
                placeholder="crédito automotriz"
                class="mt-18 text-msg-dp"
              />
              <FieldSelect name="carCredit" clases="mt-10">
                <option value="">Selecciona...</option>
                <option value="YES">Sí</option>
                <option value="NO">No</option>
                <option value="YES2">Cerrado en los ultimos 2 años</option>
              </FieldSelect>
            </Col>

            <Col>
              <InputLabel
                placeholder="tarjeta de crédito"
                class="mt-18 text-msg-dp"
              />
              <FieldSelect
                name="creditCard"
                clases="mt-10"
                onChange={handleCreditCard}
              >
                <option value="">Selecciona...</option>
                <option value="1">Sí</option>
                <option value="0">No</option>
              </FieldSelect>
            </Col>
          </Row>
          {(creditCard === "1" ) && (
            <div>
              <FieldText
                name="last4"
                maxLength="4"
                placeholder="Últimos 4 dígitos de tu tarjeta de crédito"
                normalize="onlyNumbers"
              />
            </div>
          )}
          <div className="mt-3">
            <FieldCheck
              type="checkbox"
              label={`Autorizo a ${
                themeData ? themeData.razonSocial : ""
              } a consultar mis antecedentes crediticios por única ocasión ante las Sociedades de Información Crediticia que estime conveniente, declarando que conozco la naturaleza, alcance y uso que ${
                themeData ? themeData.razonSocial : ""
              } hará de tal información.`}
              name="tyc"
              big={true}
            />
          </div>
          <div className="text-center" style={{ marginBottom: "50px" }}>
          <ButtonSubmit
          isSubmitting={isSubmitting}
          isValid={isValid}
          onClick={async () => {
            await Object.keys(props.values).forEach((field) =>
              props.setFieldTouched(field, true)
            );
          }}
        />
          </div>
        </Form>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    name: props.initialValues.name || "",
    lastname: props.initialValues.lastname || "",
    secondLastname: props.initialValues.secondLastname || "",
    rfc: props.initialValues.rfc || "",
    rfcPerson: props.initialValues.rfcPerson || "",
    mortgageCredit: props.initialValues.mortgageCredit || "",
    carCredit: props.initialValues.carCredit || "",
    creditCard: props.initialValues.creditCard || "",
    last4: props.initialValues.last4 || "",
    tyc: props.initialValues.tyc || "",

  }),
  validate: validateBuroError,
  enableReinitialize: true,
  handleSubmit(values, formikBag) {
    formikBag.setSubmitting(false);
    formikBag.props.handleSubmit(values);
  },
  displayName: "ErrorBuro",
})(ErrorBuro);
