import React from "react";
import { Col, Row, Card } from "react-bootstrap";
import imgHeader from "../../../assets/img/aboutus/Phone_Inicio.webp";
import Title from "../../Generic/Title";

const Info = () => {
  return (
    
      <div className="container">
        <Row className="d-flex align-items-center justify-items-center">
          <Col xl={7} lg={7} md={7} className="mb-3">
            <Card.Header
              id="header"
              className="title-dp-blue fz42 text-left line-height pt-5"
            >
              <span className="title"> Coach </span> Finsus{" "}
            </Card.Header>
            <div
              className="metropolisReg fz21 blackNavBlue text-justify"
              style={{ paddingLeft: "1rem", paddingRight: "1.5rem" }}
            >
              <br />
              Si tienes experiencia en el sector pyme, el Programa de Coaches
              Financieros finsus es tu mejor opción para incrementar tus
              ingresos.
              <br></br>
              <br />
              Nuestro programa es totalmente gratis, además de recibir las
              herramientas tecnológicas necesarias que te ayudarán a colocar más
              créditos y recibir preaprobaciones en menos de 30 min.
              <br></br>
              <br />
              <span style={{color: "var(--coral)"}}>

              ¿Por qué ser Coach Financiero finsus?
              </span>
              <ul className="list-broker">
                <li className="fz21 blackBlue mt-2 mb-2">
                  Solicitud 100% digital
                </li>
                <li className="fz21 blackBlue mb-2">
                  Conexión tecnología al SAT y Buró
                </li>
                <li className="fz21 blackBlue mb-2">
                  Validación de documentos en tiempo real
                </li>
                <li className="fz21 blackBlue mb-2">
                  Hasta $6M sin garantía
                </li>
                <li className="fz21 blackBlue mb-2">
                  Capacitación continua
                </li>
                <li className="fz21 blackBlue mb-2">
                  Bonos, incentivos y eventos
                </li>
                <li className="fz21 blackBlue mb-2">Y más</li>
              </ul>
            </div>
          </Col>

          <Col xl={5} lg={5} md={5}>
            {/* <video
              className="d-block w-100"
              loop={true}
              controls={false}
              muted={true}
              autoPlay={true}
              preload={"true"}
              playsInline={true}
              src="https://distrito-pyme-media.s3.us-west-2.amazonaws.com/Brokers_Digitales_Distrito_Pyme.mp4"
            /> */}
            <img src={imgHeader} alt="imgHeader" className="img-fluid" />
          </Col>
        </Row>
      </div>
    
  );
};

export default Info;
