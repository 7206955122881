import { Checkbox, FormControlLabel, withStyles } from "@material-ui/core";

import ColorButton from "./ColorButton";
import ColorCheckbox from "./ColorCheckBox";
import { Col, Row } from "react-bootstrap";

const TyCs = ({ check, date, handleCheck, onSuccess, showBuro }) => {
  const disabled = !showBuro ? !check.tyc : !check.buro || !check.tyc;
  const handleOnClick = async () => {
    onSuccess(null, "tycs");
  };

  return (
    <>
      <div
        className="container justify-content-center m-5 p-5"
        style={{ height: "100%" }}
      >
        <Row>
          {showBuro && (
            <Col lg={12} md={12} sm={12}>
              <FormControlLabel
                required
                labelPlacement="end"
                control={
                  <ColorCheckbox
                    name="buro"
                    checked={check.buro}
                    onChange={handleCheck}
                  />
                }
                label={
                  <span>
                    Hoy siendo {date}, autorizo a Financiera Sustentable de
                    México S.A. de C.V., S.F.P. de C.V. (FINSUS) a consultar mis
                    antecedentes crediticios por única ocasión ante las
                    Sociedades de Información Crediticia que estime conveniente,
                    declarando que conozco la naturaleza, alcance y uso que se
                    hará de tal información.
                  </span>
                }
              />
            </Col>
          )}
          <Col lg={12} md={12} sm={12}>
            <FormControlLabel
              required
              control={
                <ColorCheckbox
                  name="tyc"
                  checked={check.tyc}
                  onChange={handleCheck}
                />
              }
              label={
                <span>
                  Al continuar estás aceptando nuestros
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="App-link"
                    href="https://distrito-pyme-documents-dev.s3.us-west-2.amazonaws.com/1689024421843-TyC_julio%202023%20Finsus%20credito%20digital.pdf"
                  >
                    {" "}
                    términos y condiciones de uso
                  </a>{" "}
                  y nuestro
                  <a
                    className="App-link"
                    href="https://finsus.mx/aviso-de-privacidad/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    aviso de privacidad
                  </a>
                  .
                </span>
              }
            />
          </Col>
        </Row>
        <Row className="mt-5 justify-content-center">
          <ColorButton
            onClick={handleOnClick}
            disabled={disabled}
            variant="contained"
            color="secondary"
          >
            {showBuro ? "Firmar con NIP" : "Continuar"}
          </ColorButton>
        </Row>
      </div>
    </>
  );
};

export default TyCs;
