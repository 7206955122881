import { useState, useEffect, useRef } from "react";
import { useHistory } from 'react-router-dom';

function useLocation() {
  const [location, setLocation] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [error, setError] = useState("Antes de continuar con el, debes permitir el acceso a tu ubicación");
  const watchIdRef = useRef("");

  const history = useHistory();

  useEffect(() => {
    if (!navigator.geolocation) {
      setError("Geolocalización no soportada en tu navegador, seras redirigido a la página de inicio");

      setTimeout(() => {
        sessionStorage.clear();
        // window.location.reload()
        history.push('/');
      } , 3000);
      return;
    }

    const successCallback = (position) => {
        // console.log(position.coords);
      setLocation(position.coords);
    };

    const errorCallback = (error) => {
      setError("Para continuar con el registro, debes permitir el acceso a tu ubicación, seras redirigido a la página de inicio");
      console.log(error.message);
      setTimeout(() => {
        sessionStorage.clear();
        // window.location.reload()
        history.push('/');
      }
      , 3000);
    };

    navigator.permissions.query({ name: "geolocation" }).then((result) => {
        setPermissions(result);
      if (result.state === "denied") {
        setError("Para continuar con el registro, debes permitir el acceso a tu ubicación, seras redirigido a la página de inicio");
        setTimeout(() => {
            sessionStorage.clear();
            window.location.reload()
          }
          , 3000);
      } else {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
      }
    });
  }, []);

  useEffect(() => {
    if(location) {
        console.log(location);
        // sessionStorage.setItem("lat", location.latitude);
        // sessionStorage.setItem("lng", location.longitude);
    }
  } , [location]);

  return { location, error, permissions };
}

export default useLocation;