import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import tito from "../../assets/img/estatus_solicitud/status-solicitud_01.png";
import { useHistory } from "react-router-dom";

const CursoCaptacion = () => {
  const history = useHistory();

  const handleRedirect = () => {
    history.push("/credito");
  };

  return (
    <Row>
      <Col lg={8} md={8} sm={12}>
        <div className="title-dp fz42 mb-18 fw500">Solicitud en curso</div>
        <div className="text-dp mb-18">
            ¡Ya recibimos tu solicitud! Estamos revisando la información que nos proporcionaste. En breve te contactaremos para continuar con el proceso.
        </div>
        {/* <div className={"text-center text-md-left"}> */}
        <Button
          className={"btn-blue-status mb-5"}
          onClick={() => handleRedirect()}
        >
          Regresar
        </Button>
        {/* </div> */}
      </Col>
      <Col lg={4} md={4} sm={12}>
        <div className="text-center">
          <img src={tito} alt="tito" style={{ width: "250px" }} />
        </div>
      </Col>
    </Row>
  );
};

export default CursoCaptacion;
