import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "../../utils/axios";
import { useHistory } from "react-router-dom";
import { updateLoader } from "../../redux/actions/loaderActions";
import MifielWidget from "./MifielWidget";

const MiFielExt = () => {
  const [loading, setLoading] = useState(true);
  const [document, setDocument] = useState(null);
  const [error, setError] = useState(false);
  const [sandbox, setSandbox] = React.useState(true);

  const id = window.location.pathname.split("/")[2];
  const descryptId = atob(id);



  useEffect(() => {
    const env = process.env.REACT_APP_CONFIGURATION;
    if (env !== "localhost") {
      setSandbox(false);
    }
  }, []);
  
  // useEffect(() => {
  //   if (!id) return;
  //   dispatch(updateLoader(true))
  //   try {
  //       const fetchData = async () => {
  //         const result = await axios.get(`mifiel/data/${id}`);
  //         console.log(result);
  //         setDocument(result.data);
  //         setLoading(false);
  //       };
  //       fetchData();
  //     } catch (error) {
  //       console.log(error);
  //       setError(true);
  //       setLoading(false);
  //     }
  //     dispatch(updateLoader(false));
  //   }, []);

  const history = useHistory();

  const dispatch = useDispatch();

  function handleError(error) {
    console.log("error", error);
    history.push(`/`);
  }

  function handleSuccess(data) {
    console.log("data", data);
    history.push(`/login`);
  }

  return (
    <div>
      <MifielWidget
        widgetId={descryptId}
        successButtonText="continuar"
        width="100%"
        height="200vh"
        color="023473"
        id="mifiel-widget"
        error={handleError}
        onSuceess={handleSuccess}
        sandbox={sandbox}
      />
    </div>
  );
};

export default MiFielExt;
