import { useState, useEffect } from "react";
import config from "../../assets/theme/config";

const useThemeData = () => {
    const [themeData, setThemeData] = useState(null);
    const [theme, setTheme] = useState(null);

    useEffect(() => {
		const theme = sessionStorage.getItem("theme");
		if (!theme) {
		  setInterval(() => {
			const theme = sessionStorage.getItem("theme");
			if (theme) {
			  setTheme(theme);
			}
		  }, 100);
		}
		setTheme(theme);
	  }, []);

    useEffect(() => {
        if (theme) {
            setThemeData(config(theme));
        }
    }
    , [theme]);

    return {theme, themeData};
}

export default useThemeData;