import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateSnackbar } from "../../redux/actions/snackActions";
import smileEmoji from "../../assets/img/logo_dp/emoji.svg";
import manoEmoji from "../../assets/img/logo_dp/iconMano.svg";
import rocketEmoji from "../../assets/img/logo_dp/roket.svg";
import factoryEmoji from "../../assets/img/logo_dp/factory.svg";
import faceMenEmoji from "../../assets/img/logo_dp/faceMen.svg";
import faceWomenEmoji from "../../assets/img/logo_dp/faceWomen.svg";
import hourGlassEmoji from "../../assets/img/logo_dp/hourGlass.svg";
import writingEmoji from "../../assets/img/logo_dp/writingHand.svg";
import cardIndexEmoji from "../../assets/img/logo_dp/cardIndex.svg";

const useSnack = () => {
  const dispatch = useDispatch();
  const snack = useSelector((state) => state.snack);
  const [show, setShow] = useState(false);

  const closeSnack = () => {
    setShow(false);
    dispatch(updateSnackbar(false));
  };

  const titleEmoji = (title, emoji, emoji2) => {
    return (
      <div className="d-flex justify-content-center p-2">
        <p style={{ margin: 0 }}>
          {title}
          <img
            src={emoji}
            alt="emoji"
            className="ml-2"
            width={25}
            height={25}
          />
          {emoji2 ? (
            <img
              src={emoji2}
              alt="emoji"
              className="ml-2"
              width={25}
              height={25}
            />
          ) : null}
        </p>
      </div>
    );
  };
  const msWelcome = () => {
    return (
      <div className="d-flex justify-content-center p-2">
        <p style={{ margin: 0 }}>
          En este momento estamos viajando
          <img
            src={rocketEmoji}
            alt="emoji"
            className="ml-1 mr-1"
            width={20}
            height={20}
          />
          al SAT de manera tecnológica y segura para extraer tu info y hacerte
          la vida más fácil
          <img
            src={smileEmoji}
            alt="emoji"
            className="ml-2"
            width={20}
            height={20}
          />
        </p>
      </div>
    );
  };
  const msFiel = () => {
    return (
      <div className="d-flex justify-content-center p-2">
        <p style={{ margin: 0 }}>
          {" "}
          Estamos en los últimos detalles de revisión, mientras, puedes ir
          subiendo tus documentos
          <img
            src={cardIndexEmoji}
            alt="emoji"
            className="ml-1 mr-1"
            width={20}
            height={20}
          />
          para integrar 100% tu expediente
        </p>
      </div>
    );
  };

  const data = {
    ciec: {
      title: "¡Bienvenido a Finsus Crédito Digital!",
      msg: msWelcome(),
    },
    monto: {
      title: "",
      msg: "¡Comencemos! Cuéntanos un poco sobre cuánto dinero necesitas y para que lo vas a utilizar",
    },
    negocio: {
      title: titleEmoji(
        "¡Ahora llegó el momento de saber un poco más sobre tu empresa o negocio",
        factoryEmoji
      ),
      msg: "Intenta ser lo más transparente con nosotros, queremos ayudarte.",
    },
    persona: {
      title: "",
      msg: titleEmoji(
        "Sigámonos conociendo, platicanos más sobre ti",
        faceMenEmoji,
        faceWomenEmoji
      ),
    },
    buro: {
      title: titleEmoji(
        "Despegando a consutar tu buró en 3, 2 , 1",
        rocketEmoji
      ),
      msg: titleEmoji(
        "Estamos revisando tu historial crediticio y estamos muy cerca de poder darte una respuesta sobre tu crédito",
        hourGlassEmoji
      ),
    },
    mifiel: {
      title: titleEmoji(
        "La firma de tu buró de crédito persona moral ha sido firmada",
        writingEmoji
      ),
      msg: msFiel(),
    },
    documentosInicio: {
      title: "Ayudanos a subir tu documentación",
      msg: titleEmoji(
        "¡Crearemos tu expediente de crédito digital!",
        rocketEmoji
      ),
    },
    documentos: {
      title: "¡Vamos!, solo quedan un cuantos documentos más",
      msg: titleEmoji(
        "Ayúdanos a subir los documentos que faltan y tener todo listo para tu crédito",
        manoEmoji
      ),
    },
    documentosError: {
      title: "¡Lo sentimos!",
      msg: "Estamos experimentando dificultades  con la carga de documentos. Mientras lo solucionamos, por favor envíalos por correo a creditodigital@finsus.mx. Gracias por tu paciencia y comprensión. #SoporteTécnico",
      close: false,
    },
    preAprobado: {
      title: titleEmoji(
        "¡Vamos!, solo quedan un cuantos documentos más",
        smileEmoji
      ),
      msg: titleEmoji(
        "Ayúdanos a subir los documentos que faltan y tener todo listo para tu crédito",
        smileEmoji
      ),
    },
    pre_autorizados: {
      title: "¡Enhorabuena!",
      msg: "Tu solicitud de crédito ha sido pre aprobado. Puedes revisar la propuesta y aceptarla para que tu crédito y continuar con el proceso.",
    },
    autorizados: {
      title: "¡Grandes noticias!",
      msg: "Tu crédito ha sido autorizado. Solo acepta la propuesta y en menos de 24 horas podrás tener el dinero en tu cuenta Finsus.",
    },
    constancia: {
      title: "",
      msg: "Ya tenemos tu constancia de situación fiscal, un paso más cerca de tu crédito",
    },
    declaraciones: {
      title: "",
      msg: "Ya tenemos tus declaraciones de impuestos, con esto tu crédito será aprobado más rápido",
    },
    facturacion: {
      title: "",
      msg: "Ya tenemos tu facturación electrónica, para que tu crédito sea aprobado más rápido",
    },
  };

  const notify = (pos) => {
    if (data[pos] === undefined) {
      console.log("No existe el mensaje" + pos);
      return;
    }

    const { title: t, msg: m, close: c } = data[pos];
    dispatch(updateSnackbar(true, m, t, c));
    setShow(true);
  };

  return { closeSnack, notify };
};

export default useSnack;
