import {
    SET_USER_DATA,
    UPDATE_USER_DATA
} from '../types/userTypes';
import Axios from '../../utils/axios';

const getUserDataAction = () => {
    return async(dispatch) => {
        try {        
            const { _id } = JSON.parse(sessionStorage.getItem("user"));
        
            const response = await Axios.get(`api/user/${_id}`);
    
            if(response.data.code === 200) {
                dispatch( updateUserData(response.data.user) );
            }
        } catch (error) {
            console.log(error);
        }
    }
}

const setUserData = (data) => ({
    type: SET_USER_DATA,
    payload: data
});

const updateUserData = (data) => ({
    type: UPDATE_USER_DATA,
    payload: data
});

export {
    getUserDataAction,
    setUserData,
    updateUserData
}