let civilStatusOptions = {
	"SINGLE" : { value : "Soltero(a)"},   
	"MARRIED": { value : "Casado(a) bienes separados"},
	"MARRIED_IN_COMMUNITY" : { value : "Casado(a) bienes mancomunados"},
}

let realtiveOptions = {
	"FAMILY" : { value : "Familiar"},
	// "FRIEND" : { value : "Amigo"},
	"CLIENT" : { value: "Cliente" },
	"PROVIDER" : { value: "Proveedor"} 

}

let familyMemberOptions = {
	"1" : { value : "Padre"},
	"2" : { value : "Madre"},
	"3" : { value : "Hermano(a)"},
	"4" : { value : "Hijo(a)"},
	"5" : { value : "Esposo(a)"},
	"6" : { value : "Otro"}
}

let roles = {
	"0" : { value : "Representante Legal/Apoderado"},
	"1": { value: "Accionista"},
	"2" : { value : "Director/Funcionario"},
	"3" : { value : "Propietario Real"},
}

let getRole = (role) => {
	return roles[role].value || ""
}

let carYear = {
	"MORE4" : 'Hace 4 años o más',
	"YES" : "Sí",
	"NO" : "No"

}

let bankAccount = {
	'1': {value: "Sí, ahí recibo mis ventas"},
	'2': {value: "Sí, pero no la uso mucho"},
	'0': {value: "No tengo"}
}

let typeOfProperty = {
	'1': {value: "Propia"},
	'2': {value: "Familiar"},
	'3': {value: "Rentada"},
	'4': {value: "Hipotecada"},
	'5': {value: "Otro"}
}

const realEstateTypes = ["Casa", "Departamento", "Local", "Nave", "Edificio"];

let generalInfoOptions = {
	civilStatusOptions,
	realtiveOptions,
	carYear,
	bankAccount,
	typeOfProperty,
	familyMemberOptions,
	roles,
	getRoles: getRole,
  realEstateTypes,
}



export default generalInfoOptions