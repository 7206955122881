import React, { useState, useEffect } from "react";
import { FieldArray, Form, withFormik } from "formik";
import { ButtonSubmit } from "../components/Generic/ButtonSubmit";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FieldText, FieldPorcentaje, FieldCheck } from "../components/Generic/Fields";
import SubtitleForm from "../components/Generic/SubtitleForm";
import { Col, Row } from "react-bootstrap";
import generalInfoOptions from "../models/GeneralInfoModels";

const MemberForm = (props) => {
  const { values, setFieldValue, valorPrev } = props;
  const { roles } = generalInfoOptions;
  const dispatch = useDispatch();
  const history = useHistory();

  const [porcentajeError, setPorcentajeError] = useState("");

  useEffect(() => {
    if (valorPrev && values.percentage) {
      let { percentage } = values;
      let total = 0;
    }
  }, []);
  return (
    <Form className="ml-auto mr-auto" style={{ maxWidth: "690px" }}>
      <div>
        <SubtitleForm
          subtitle={"Datos Personales"}
          className="mb-10 text-center"
        />
        <Row className="d-flex justify-content-center">
          <Col lg={4} md={4} sm={12}>
            <FieldText
              name={`name`}
              placeholder="Nombre(s)"
              normalize="onlyLirycs"
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <FieldText
              name={`firstLastname`}
              placeholder="Apellido paterno"
              normalize="onlyLirycs"
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <FieldText
              name="secondLastname"
              placeholder="Apellido materno"
              normalize="onlyLirycs"
            />
          </Col>

          <Col lg={12} md={12} sm={12}>
            <FieldText
              name="email"
              placeholder="Correo electrónico"
              normalize="onlyLirycs"
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-start">
          <Col lg={4} md={4} sm={12}>
            <FieldText
              name="phone"
              placeholder="Teléfono"
              normalize="onlyNumbers"
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <FieldPorcentaje
              name="percentage"
              placeholder={"Porcentaje de participación"}
              normalize="onlyNumbers"
              maxLength={4}
              customError={porcentajeError}
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
            
          <SubtitleForm subtitle="Rol en la empresa" className="mb-4 mt-24" />
          <FieldArray name="roles" id="Rol_en_la_empresa">
            {({ push, remove }) => (
              <div className="d-flex justify-content-around">
                <Row className="d-flex justify-content-center">
                    {Object.keys(roles).map((key, index) => {
                        return (
                            <Col
                                lg={6}
                                md={6}
                                sm={12}
                                key={index}
                                style={{
                                    display: "flex",
                                    textAlign: "center",

                                }}
                            >
                                <FieldCheck
                                    key={index}
                                    type="checkbox"
                                    label={roles[key].value}
                                    name={`roles.${key}`}
                                    big={true}
                                />
                            </Col>
                        );
                    })}
                </Row>
              </div>
            )}
          </FieldArray>
        </Row>
      </div>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    name: props.initialValues.name || "",
    firstLastname: props.initialValues.firstLastname || "",
    secondLastname: props.initialValues.secondLastname || "",
    email: props.initialValues.email || "",
    phone: props.initialValues.phone || "",
    percentage: props.initialValues.percentage || "",
  }),
  handleSubmit: (values, { setSubmitting }) => {
    console.log(values);
    setSubmitting(false);
  },
})(MemberForm);
