import { useState, useEffect } from 'react';
import axios from 'axios';

const useAxios = (id, initialData = []) => {
  const [data, setData] = useState(initialData);
  const [error, setError] = useState(null);
  const [cargando, setCargando] = useState(true);
//   const [loading, setLoading] = useState(true);

  let url = `${process.env.REACT_APP_BACKEND}/telemetry/${id}`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url);
        setData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setCargando(false);
      }
    };

    fetchData();
  }, [url]);

  return { data, error, cargando };
};

export default useAxios;