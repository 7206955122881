import { useEffect, useRef, useState } from "react";
import Loading from "./Loading";
import ColorButton from "./ColorButton";
import { Col, Row } from "react-bootstrap";

const ScanDNI = ({
  dniMessage,
  handleNavbar,
  incode,
  ineAttempts,
  onError,
  onSuccess,
  scanType = "front",
  session,
}) => {
  if (ineAttempts > 0) scanType = "init";
  const [selected, setSelected] = useState(
    scanType === "front" ? "front" : "init"
  );

  const handleOnSuccess = async (event, type) => {
    onSuccess(event, "dni");
  };

  const handleOnError = (error) => {
    onError(error, "dni");
  };

  const cases = {
    front: (
      <Front
        handleNavbar={handleNavbar}
        handleOnError={handleOnError}
        handleOnSuccess={handleOnSuccess}
        incode={incode}
        session={session}
        setSelected={setSelected}
      />
    ),

    back: (
      <Back
        handleOnError={handleOnError}
        handleOnSuccess={handleOnSuccess}
        incode={incode}
        session={session}
      />
    ),

    passport: (
      <Passport
        handleNavbar={handleNavbar}
        handleOnError={handleOnError}
        handleOnSuccess={handleOnSuccess}
        incode={incode}
        session={session}
      />
    ),

    init: (
      <div className="text-center">
        <Col>
          {scanType === "passport" && <h2>¿Qué documento deseas escanear?</h2>}
          {ineAttempts > 0 && (
            <div className="mt-5">
              <h5>Tuvimos problemas para obtener tus datos del INE.</h5>
              <h5>Por favor, verifica que sea tu última credencial.</h5>
              <p>Respuesta del INE: {dniMessage}</p>
            </div>
          )}
          <Row className="justify-content-around mt-5">
            <ColorButton onClick={() => setSelected("front")}>
              Subir INE
            </ColorButton>
            {scanType === "passport" && (
              <ColorButton onClick={() => setSelected("passport")}>
                Subir Pasaporte
              </ColorButton>
            )}
          </Row>
        </Col>
      </div>
    ),

    default: <Loading />,
  };

  return (
    <div
      id="scan-dni"
      className="d-flex justify-content-center align-items-center"
    >
      {cases[selected]}
    </div>
  );
};

const Front = ({
  handleNavbar,
  handleOnError,
  incode,
  session,
  setSelected,
}) => {
  const containerRef = useRef();
  const isMounted = useRef(false);

  handleNavbar(false);

  const handleSuccess = (event) => {
    setSelected("back");
  };

  const handleError = (error) => {
    handleOnError(error, "front");
  };

  useEffect(() => {
    if (isMounted.current) return;

    incode.renderCamera("front", containerRef.current, {
      assistedOnboarding: true,
      disableFullScreen: false,
      numberOfTries: 5,
      onError: handleError,
      onSuccess: handleSuccess,
      showAutoCaptureIDOverlay: true,
      showPreview: true,
      showTutorial: true,
      timeout: 5000,
      token: session,
    });
    isMounted.current = true;

    return () => {
      if (isMounted.current) {
        isMounted.current = false;
      }
    };
  });

  return <div id="frontIne" ref={containerRef} />;
};
const Back = ({ handleOnError, handleOnSuccess, incode, session }) => {
  const containerRef = useRef();
  const isMounted = useRef(false);

  const handleSuccess = (event) => {
    handleOnSuccess(event, "back");
  };

  const handleError = (error) => {
    handleOnError(error, "back");
  };

  useEffect(() => {
    if (isMounted.current) return;
    incode.renderCamera("back", containerRef.current, {
      assistedOnboarding: true,
      disableFullScreen: true,
      numberOfTries: 5,
      onError: handleError,
      onSuccess: handleSuccess,
      showAutoCaptureIDOverlay: true,
      showPreview: true,
      showTutorial: true,
      timeout: 5000,
      token: session,
    });
    isMounted.current = true;

    return () => {
      if (isMounted.current) {
        isMounted.current = false;
      }
    };
  });

  return <div ref={containerRef} />;
};
const Passport = ({
  handleNavbar,
  handleOnError,
  handleOnSuccess,
  incode,
  session,
}) => {
  const containerRef = useRef();
  const isMounted = useRef(false);

  handleNavbar(false);

  const handleSuccess = (event) => {
    handleOnSuccess(event, "passport");
  };

  const handleError = (error) => {
    handleOnError(error, "passport");
  };

  useEffect(() => {
    if (isMounted.current) {
      return;
    }

    incode.renderCamera("passport", containerRef.current, {
      assistedOnboarding: true,
      disableFullScreen: true,
      numberOfTries: 5,
      onError: handleError,
      onSuccess: handleSuccess,
      showAutoCaptureIDOverlay: true,
      showPreview: true,
      showTutorial: true,
      timeout: 5000,
      token: session,
    });

    isMounted.current = true;

    return () => {
      if (isMounted.current) {
        isMounted.current = false;
      }
    };
  });

  return <div ref={containerRef} />;
};

export default ScanDNI;
