import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../Loader/Loader";
import { useHistory } from "react-router-dom";
import CiecExtForm from "../../forms/ciecExtForm";
import { Container, Grid } from "@material-ui/core";
import { Image } from "react-bootstrap";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { updateLoader } from "../../redux/actions/loaderActions";
import Axios from "../../utils/axios";
import Swal from "sweetalert2";

const CIECcomponent = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const [initialValues, setInitialValues] = useState({});
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function fetchUser() {
      const { id } = params;
      dispatch(updateLoader(true));
      try {
        const { data } = await Axios.get(`ciec/rfc/${id}`);

        if (data.success) {
          setInitialValues({
            rfc: data.rfc,
            dis: data.success,
          });
          setUser(data._id);
        }
        dispatch(updateLoader(false));
      } catch (error) {
        dispatch(updateLoader(false));
        console.log(error);
      }
    }
    if (!params.id) {
      return;
    }
    fetchUser();
  }, [params]);

  const handleSuccess = (data) => {
    Swal.fire({
      title: "¡CIEC registrado con éxito!",
      text: "¡Tu CIEC ha sido registrado con éxito!",
      icon: "success",
      confirmButtonText: "Continuar",
      customClass: {
        title: "title-dp fz42",
        confirmButton: "btn-blue-general btn btn-primary p-0",
        container: "metropolisForget",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        history.push("/login");
      }
    });
  };
  const handleErrors = (error) => {
    const response = error.response;

    if (!response) {
      return Swal.fire({
        title: "¡Error!",
        text: "¡Ocurrió un error inesperado!",
        icon: "error",
        confirmButtonText: "Continuar",
        customClass: {
          title: "title-dp fz42",
          confirmButton: "btn-blue-general btn btn-primary p-0",
          container: "metropolisForget",
        },
      });
    }

    if (response.status === 429) {
      return Swal.fire({
        title: "¡Error!",
        text: "¡Demasiados intentos, intenta más tarde!",
        icon: "error",
        confirmButtonText: "Continuar",
        customClass: {
          title: "title-dp fz42",
          confirmButton: "btn-blue-general btn btn-primary p-0",
          container: "metropolisForget",
        },
      });
    }

    const { data } = response;

    if (data.error) {
      return Swal.fire({
        title: "¡Error!",
        text: data.error,
        icon: "error",
        confirmButtonText: "Continuar",
        customClass: {
          title: "title-dp fz42",
          confirmButton: "btn-blue-general btn btn-primary p-0",
          container: "metropolisForget",
        },
      });
    }

    Swal.fire({
      title: "¡Error!",
      text: "¡Ocurrió un error inesperado!",
      icon: "error",
      confirmButtonText: "Continuar",
      customClass: {
        title: "title-dp fz42",
        confirmButton: "btn-blue-general btn btn-primary p-0",
        container: "metropolisForget",
      },
    });
  };

  const onFormSubmit = async (dataForm) => {
    dispatch(updateLoader(true));
    try {
      const { data } = await Axios.post(`/ciec/ext/${user}`, dataForm);
      if (data.success) {
        // console.log(data);
        dispatch(updateLoader(false));
        handleSuccess(data);
        // history.push(`/ciec/${user}`);
      }
    } catch (error) {
      dispatch(updateLoader(false));
      handleErrors(error);
      // console.log(error);
    }
  };
  return (
    <Container
      maxWidth="sm"
      className="mt-3 mb-3"
      style={{
        // boxShadow: '0 7px 20px rgba(0,0,0,.4)',
        boxShadow: "1px 1px 17px #929292 ,-7px -7px 27px #ffffff",
        borderRadius: "3rem",
        backgroundColor: "white",
        paddingTop: "3rem",
        paddingBottom: "3rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Loader />
      <div className="text-center mt-2 mb-2">
        <Image
          src={
            "https://finsus.mx/wp-content/uploads/2023/09/Logo-Finsus_800x.png"
          }
          fluid
          style={{ width: "30%", textAlign: "center", margin: "auto" }}
        />
        <div style={{ maxWidth: "80%", margin: "auto" }} className="mt-4">
          <CiecExtForm
            onSubmit={onFormSubmit}
            initialValues={initialValues}
            handleSubmit={onFormSubmit}
          />
        </div>
      </div>
    </Container>
  );
};
export default CIECcomponent;
