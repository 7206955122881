const altisonantes = [
  "BACA",
  "BAKA",
  "BUEI",
  "BUEY",
  "CACA",
  "CACO",
  "CAGA",
  "CAGO",
  "CAKA",
  "CAKO",
  "COGE",
  "COGI",
  "COJA",
  "COJE",
  "COJI",
  "COJO",
  "COLA",
  "CULO",
  "FALO",
  "FETO",
  "GETA",
  "GUEI",
  "GUEY",
  "JETA",
  "JOTO",
  "KACA",
  "KACO",
  "KAGA",
  "KAGO",
  "KAKA",
  "KAKO",
  "KOGE",
  "KOGI",
  "KOJA",
  "KOJE",
  "KOJI",
  "KOJO",
  "KOLA",
  "KULO",
  "LILO",
  "LOCA",
  "LOCO",
  "LOKA",
  "LOKO",
  "MAME",
  "MAMO",
  "MEAR",
  "MEAS",
  "MEON",
  "MIAR",
  "MION",
  "MOCO",
  "MOKO",
  "MULA",
  "MULO",
  "NACA",
  "NACO",
  "PEDA",
  "PEDO",
  "PENE",
  "PIPI",
  "PITO",
  "POPO",
  "PUTA",
  "PUTO",
  "QULO",
  "RATA",
  "ROBA",
  "ROBE",
  "ROBO",
  "RUIN",
  "SENO",
  "TETA",
  "VACA",
  "VAGA",
  "VAGO",
  "VAKA",
  "VUEI",
  "VUEY",
  "WUEI",
  "WUEY",
];

const quitarAltisonante = (palabra) => {
  if (altisonantes.includes(palabra)) {
    return palabra.replace(/^(\w)\w/, "$1X");
  }
  return palabra;
};

const msgLongitudError = (rfcPart = "") => {
  return rfcPart.length < 10 ? "RFC incompleto" : "RFC invalido";
};

const regex = /^([a-z&]{3,4})(\d{2})(\d{2})(\d{2})([0-9a-z]{3})$/i;
const _rfc_pattern_pf_alt = /^([A-ZÑ\x26]{3,4})([0-9]{6})$/;

/*
 * Se encarga de validar y extraer meta información del RFC:
 *
 * @link: https://www.elcontribuyente.mx/rfc/
 */
const metaRFC = (rfcInputTxt) => {
  const rfcInputText = String(rfcInputTxt).toUpperCase();

  const metaInfo = {
    rfc_input: rfcInputText,
    nombre_parte: null,
    fecha_nacimiento_anio: null,
    fecha_nacimiento_mes: null,
    fecha_nacimiento_dia: null,
    homoclave: null,
  };
  const metaKeys = Object.keys(metaInfo);
  metaInfo.tipo_persona = null; // moral | físico
  // obtiene los datos
  const values = rfcInputText.match(regex);
  const rfcSinHomo = rfcInputText.match(_rfc_pattern_pf_alt);
  metaInfo.valido = values !== null;
  if (rfcInputText.length === 10) {
    metaInfo.valido = false;
    metaInfo.msg = "rfc incompleto";
    return metaInfo;
  }
  if (metaInfo.valido) {
    for (const i in metaKeys) {
      metaInfo[metaKeys[i]] = values[i];
    }
    const nombre = metaInfo.nombre_parte;
    // metaInfo.nombre_parte = quitarAltisonante(nombre);
    metaInfo.valido = nombre === metaInfo.nombre_parte;
    metaInfo.tipo_persona = rfcInputText.length === 13 ? "física" : "moral";
    metaInfo.longitud = rfcInputText.length;
  }
  if (metaInfo.valido) {
    metaInfo.msg = "✅ RFC valido";
  }
  metaInfo.msg = msgLongitudError(rfcInputText);
  return metaInfo;
};

const RFCValido = (rfcInputTxt) => {
  const meta = metaRFC(rfcInputTxt);
  return meta.valido;
};

function RFCFiltraAcentos(strTexto) {
    strTexto = strTexto.toLowerCase();
  return strTexto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function RFCFiltraNombres(strTexto) {
  let i = 0;
  let strArPalabras = [".", ",", "de ", "del ", "la ", "los ", "las ", "y ", "mc ", "mac ", "von ", "van "];
  for (let i = 0; i <= strArPalabras.length; i++) {
      //alert(strArPalabras[i]);
      strTexto = strTexto.replace(strArPalabras[i], "");
  }

  strArPalabras = ["jose ", "maria ", "j ", "ma "];
  for (let i = 0; i <= strArPalabras.length; i++) {
      //alert(strArPalabras[i]);
      strTexto = strTexto.replace(strArPalabras[i], "");
  }

  switch (strTexto.substr(0, 2)) {
      case 'ch':
          strTexto = strTexto.replace('ch', 'c')
          break;
      case 'll':
          strTexto = strTexto.replace('ll', 'l')
          break;
          default:
              break;
  }
  console.log(strTexto);
  return strTexto
}

function homonimia(ap_paterno, ap_materno, nombre) {

  const nombre_completo = `${ap_paterno.trim()} ${ap_materno.trim()} ${nombre.trim()}`;
  let numero = '0';
  let letra = null;
  let numero1 = null;
  let numero2 = null;
  let numeroSuma = 0;

  for (let i = 0; i <= nombre_completo.length; i++) {
      letra = nombre_completo.substr(i, 1);

      switch (letra) {
          case 'ñ':
              numero = `${numero}10`
              break;
          case 'ü':
              numero = `${numero}10`
              break;
          case 'a':
              numero = `${numero}11`
              break;
          case 'b':
              numero = `${numero}12`
              break;
          case 'c':
              numero = `${numero}13`
              break;
          case 'd':
              numero = `${numero}14`
              break;
          case 'e':
              numero = `${numero}15`
              break;
          case 'f':
              numero = `${numero}16`
              break;
          case 'g':
              numero = `${numero}17`
              break;
          case 'h':
              numero = `${numero}18`
              break;
          case 'i':
              numero = `${numero}19`
              break;
          case 'j':
              numero = `${numero}21`
              break;
          case 'k':
              numero = `${numero}22`
              break;
          case 'l':
              numero = `${numero}23`
              break;
          case 'm':
              numero = `${numero}24`
              break;
          case 'n':
              numero = `${numero}25`
              break;
          case 'ñ':
              numero = `${numero}40`
              break;
          case 'o':
              numero = `${numero}26`
              break;
          case 'p':
              numero = `${numero}27`
              break;
          case 'q':
              numero = `${numero}28`
              break;
          case 'r':
              numero = `${numero}29`
              break;
          case 's':
              numero = `${numero}32`
              break;
          case 't':
              numero = `${numero}33`
              break;
          case 'u':
              numero = `${numero}34`
              break;
          case 'v':
              numero = `${numero}35`
              break;
          case 'w':
              numero = `${numero}36`
              break;
          case 'x':
              numero = `${numero}37`
              break;
          case 'y':
              numero = `${numero}38`
              break;
          case 'z':
              numero = `${numero}39`
              break;
          case ' ':
              numero = `${numero}00`
              break;
              default:
              numero = `${numero}00`
      }
  }
  // alert(numero);
  for (let i = 0; i <= numero.length + 1; i++) {
      numero1 = numero.substr(i, 2);
      numero2 = numero.substr(i + 1, 1);
      numeroSuma = numeroSuma + (numero1 * numero2);

  }
  //alert(numeroSuma);
  const numero3 = numeroSuma % 1000;
  //alert(numero3);
  const numero4 = numero3 / 34;
  const numero5 = numero4.toString().split(".")[0];

  //alert(numero5);
  const numero6 = numero3 % 34;
  //alert(numero6);
  let homonimio = '';
  switch (numero5) {
      case '0':
          homonimio = '1'
          break;
      case '1':
          homonimio = '2'
          break;
      case '2':
          homonimio = '3'
          break;
      case '3':
          homonimio = '4'
          break;
      case '4':
          homonimio = '5'
          break;
      case '5':
          homonimio = '6'
          break;
      case '6':
          homonimio = '7'
          break;
      case '7':
          homonimio = '8'
          break;
      case '8':
          homonimio = '9'
          break;
      case '9':
          homonimio = 'A'
          break;
      case '10':
          homonimio = 'B'
          break;
      case '11':
          homonimio = 'C'
          break;
      case '12':
          homonimio = 'D'
          break;
      case '13':
          homonimio = 'E'
          break;
      case '14':
          homonimio = 'F'
          break;
      case '15':
          homonimio = 'G'
          break;
      case '16':
          homonimio = 'H'
          break;
      case '17':
          homonimio = 'I'
          break;
      case '18':
          homonimio = 'J'
          break;
      case '19':
          homonimio = 'K'
          break;
      case '20':
          homonimio = 'L'
          break;
      case '21':
          homonimio = 'M'
          break;
      case '22':
          homonimio = 'N'
          break;
      case '23':
          homonimio = 'P'
          break;
      case '24':
          homonimio = 'Q'
          break;
      case '25':
          homonimio = 'R'
          break;
      case '26':
          homonimio = 'S'
          break;
      case '27':
          homonimio = 'T'
          break;
      case '28':
          homonimio = 'U'
          break;
      case '29':
          homonimio = 'V'
          break;
      case '30':
          homonimio = 'W'
          break;
      case '31':
          homonimio = 'X'
          break;
      case '32':
          homonimio = 'Y'
          break;
      case '33':
          homonimio = 'Z'
          break;
          default:
          homonimio = '0'

  }
  switch (numero6.toString()) {
      case '0':
          homonimio = `${homonimio}1`
          break;
      case '1':
          homonimio = `${homonimio}2`
          break;
      case '2':
          homonimio = `${homonimio}3`
          break;
      case '3':
          homonimio = `${homonimio}4`
          break;
      case '4':
          homonimio = `${homonimio}5`
          break;
      case '5':
          homonimio = `${homonimio}6`
          break;
      case '6':
          homonimio = `${homonimio}7`
          break;
      case '7':
          homonimio = `${homonimio}8`
          break;
      case '8':
          homonimio = `${homonimio}9`
          break;
      case '9':
          homonimio = `${homonimio}A`
          break;
      case '10':
          homonimio = `${homonimio}B`
          break;
      case '11':
          homonimio = `${homonimio}C`
          break;
      case '12':
          homonimio = `${homonimio}D`
          break;
      case '13':
          homonimio = `${homonimio}E`
          break;
      case '14':
          homonimio = `${homonimio}F`
          break;
      case '15':
          homonimio = `${homonimio}G`
          break;
      case '16':
          homonimio = `${homonimio}H`
          break;
      case '17':
          homonimio = `${homonimio}I`
          break;
      case '18':
          homonimio = `${homonimio}J`
          break;
      case '19':
          homonimio = `${homonimio}K`
          break;
      case '20':
          homonimio = `${homonimio}L`
          break;
      case '21':
          homonimio = `${homonimio}M`
          break;
      case '22':
          homonimio = `${homonimio}N`
          break;
      case '23':
          homonimio = `${homonimio}P`
          break;
      case '24':
          homonimio = `${homonimio}Q`
          break;
      case '25':
          homonimio = `${homonimio}R`
          break;
      case '26':
          homonimio = `${homonimio}S`
          break;
      case '27':
          homonimio = `${homonimio}T`
          break;
      case '28':
          homonimio = `${homonimio}U`
          break;
      case '29':
          homonimio = `${homonimio}V`
          break;
      case '30':
          homonimio = `${homonimio}W`
          break;
      case '31':
          homonimio = `${homonimio}X`
          break;
      case '32':
          homonimio = `${homonimio}Y`
          break;
      case '33':
          homonimio = `${homonimio}Z`
          break;
          default:
          homonimio = `${homonimio}0`

  }
  return homonimio;
}

function RFCDigitoVerificador(rfc) {
  const rfcsuma = [];
  let nv = 0;
  let y = 0;
  for (let i = 0; i <= rfc.length; i++) {
      const letra = rfc.substr(i, 1);
      switch (letra) {
          case '0':
              rfcsuma.push('00')
              break;
          case '1':
              rfcsuma.push('01')
              break;
          case '2':
              rfcsuma.push('02')
              break;
          case '3':
              rfcsuma.push('03')
              break;
          case '4':
              rfcsuma.push('04')
              break;
          case '5':
              rfcsuma.push('05')
              break;
          case '6':
              rfcsuma.push('06')
              break;
          case '7':
              rfcsuma.push('07')
              break;
          case '8':
              rfcsuma.push('08')
              break;
          case '9':
              rfcsuma.push('09')
              break;
          case 'A':
              rfcsuma.push('10')
              break;
          case 'B':
              rfcsuma.push('11')
              break;
          case 'C':
              rfcsuma.push('12')
              break;
          case 'D':
              rfcsuma.push('13')
              break;
          case 'E':
              rfcsuma.push('14')
              break;
          case 'F':
              rfcsuma.push('15')
              break;
          case 'G':
              rfcsuma.push('16')
              break;
          case 'H':
              rfcsuma.push('17')
              break;
          case 'I':
              rfcsuma.push('18')
              break;
          case 'J':
              rfcsuma.push('19')
              break;
          case 'K':
              rfcsuma.push('20')
              break;
          case 'L':
              rfcsuma.push('21')
              break;
          case 'M':
              rfcsuma.push('22')
              break;
          case 'N':
              rfcsuma.push('23')
              break;
          case '&':
              rfcsuma.push('24')
              break;
          case 'O':
              rfcsuma.push('25')
              break;
          case 'P':
              rfcsuma.push('26')
              break;
          case 'Q':
              rfcsuma.push('27')
              break;
          case 'R':
              rfcsuma.push('28')
              break;
          case 'S':
              rfcsuma.push('29')
              break;
          case 'T':
              rfcsuma.push('30')
              break;
          case 'U':
              rfcsuma.push('31')
              break;
          case 'V':
              rfcsuma.push('32')
              break;
          case 'W':
              rfcsuma.push('33')
              break;
          case 'X':
              rfcsuma.push('34')
              break;
          case 'Y':
              rfcsuma.push('35')
              break;
          case 'Z':
              rfcsuma.push('36')
              break;
          case ' ':
              rfcsuma.push('37')
              break;
          case 'Ñ':
              rfcsuma.push('38')
              break;
          default:
              rfcsuma.push('00');
      }
  }

  for (let i = 13; i > 1; i--) {
      nv = nv + (rfcsuma[y] * i);
      y++;
  }
  nv = nv % 11;

  let newRfc = null;

  if (nv === 0) {
    newRfc = rfc + nv;
  } else if (nv <= 10) {
      nv = 11 - nv;
      if (nv === '10') {
          nv = 'A';
      }
      newRfc = rfc + nv;
  } else if (nv === '10') {
      nv = 'A';
      newRfc = rfc + nv;
  }
  return newRfc
}

export { RFCValido, metaRFC, homonimia, RFCFiltraNombres, RFCFiltraAcentos, RFCDigitoVerificador };

