import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import SubtitleForm from "../components/Generic/SubtitleForm";
import { validateComercialInfo } from "../components/Validate/ValidateComercialInfo";
import { ButtonSubmit } from "../components/Generic/ButtonSubmit";
import { execToast } from "../utils/ToastUtils";
import { updateLoader } from "../redux/actions/loaderActions";
import scroll from "../utils/scroll";
import Axios from "../utils/axios";
import { Form, withFormik } from "formik";
import { FieldSelect, FieldText } from "../components/Generic/Fields";

let ComercialInfoForm = (props) => {
  const dispatch = useDispatch();
  const toast = useSelector((state) => state.app.toast);
  const { modalCiec: {refDocuments}, user: {user} } = useSelector((state) => state);

  const [colonias, setColonias] = useState([]);
  const [zipCodeError, setZipCodeError] = useState("");
  const [person, setPerson] = useState("");

  const { isSubmitting, isValid } = props;

  const handleChange = async (event) => {
    const zipCode = event.target.value;
    dispatch(updateLoader(true));
    if (zipCode.length === 5) {
      try {
        const res = await Axios.get(`/api/info/${zipCode}`);
        const copycolonias = [];
        if (res.data.success) {
          res.data.info.asentamiento.map((datos) => {
            copycolonias.push(datos);
          });
          copycolonias.sort();
          setColonias(copycolonias);
        }
        const estado = res.data.info.estado;
        const municipio = res.data.info.municipio;
        props.setState(estado);
        props.setMunicipality(municipio);
        setZipCodeError("");
      } catch (error) {
        setZipCodeError(error.response.data.message);
        props.setState("");
        props.setMunicipality("");
        setColonias([]);
      }
    } else {
      setZipCodeError("");
      props.setState("");
      props.setMunicipality("");
      setColonias([]);
    }
    dispatch(updateLoader(false));
  };

  useEffect(() => {
    let idClient = user.idClient;
    setPerson(idClient.type);
  }, []);

  useEffect(() => {
    if (!toast.second) {
      execToast("second");
      // dispatch(updateToast(toast, "second"));
    }

    const getData = async () => {
      dispatch(updateLoader(true));
      const idClient = user.idClient;
      // Si ya tienen una solicitud, se actualiza
      if (idClient.appliance.length > 0) {
        const appliance = idClient.appliance[idClient.appliance.length - 1];

        if (appliance.hasOwnProperty("idComercialInfo")) {
          const comercial = appliance.idComercialInfo;
          const address = comercial.address;
          const zipCode = address.zipCode;
          try {
            const res = await Axios.get(`/api/info/${zipCode}`);
            const copycolonias = [];
            if (res.data.success) {
              res.data.info.asentamiento.map((datos) => {
                copycolonias.push(datos);
              });
              copycolonias.sort();
              setColonias(copycolonias);
            }
            const estado = res.data.info.estado;
            const municipio = res.data.info.municipio;
            props.setState(estado);
            props.setMunicipality(municipio);
            setZipCodeError("");
          } catch (error) {
            props.setState("");
            props.setMunicipality("");
            setColonias([]);
            setZipCodeError("Código postal no encontrado");
          }
        }
      }
      dispatch(updateLoader(false));
    };

    getData();

    if (!refDocuments) {
      window.scrollTo(0, 0);
    } else {
      const inputCiec = document.getElementById("CIEC");
      inputCiec.focus();
      scroll("CIEC");
    }
  }, []);

  const { type } = user.idClient;

  return (
    <Form className="ml-auto mr-auto" style={{ maxWidth: "690px" }} id="comercial-info-form">
      
      <SubtitleForm subtitle="Sobre tu negocio" className="mb-3" />

      <FieldText
        className="mb-3"
        name="comercialName"
        normalize="LirycsNumbersDotComa"
        placeholder="Nombre Comercial"
        id="Nombre_del_negocio​"
      />

      <FieldText
        placeholder="Cuentanos a qué se dedica tu negocio"
        normalize="LirycsNumbersDotComa"
        name="specific"
        className="mb-3"
      />

      <SubtitleForm subtitle="Domicilio Operativo" className="mt-11 mb-3" />

      <Row className="d-flex justify-content-center">
        <Col lg={12} md={12} sm={12}>
          <FieldText
            placeholder="Calle"
            name="street"
            className="mb-3"
            normalize="LirycsNumbersDotComa"
          />
        </Col>
        <Col lg={6} md={6} sm={12}>
          <FieldText
            placeholder="Ext"
            name="extNumber"
            className="mb-3"
            normalize="onlyTextAndNumbersWithoutSpace"
          />
        </Col>

        <Col lg={6} md={6} sm={12}>
          <FieldText
            placeholder="Int"
            name="intNumber"
            className="mb-3"
            normalize="LirycsNumbersDotComa"
          />
        </Col>

        <Col lg={6} md={6} sm={12}>
          <FieldText
            placeholder="CP"
            normalize="onlyNumbers"
            name="zipCode"
            className="mb-3"
            customChange={handleChange}
            customError={zipCodeError} 
            setCustomError={setZipCodeError}
          />
        </Col>
        <Col
          lg={6}
          md={6}
          sm={12}

        >
          <FieldSelect name="town" className={`mt-24`} >
            <option className="metropolisReg" value="">
              Selecciona tu colonia
            </option>
            {colonias.map((colonia, index) => {
              return (
                <option value={colonia} key={colonia + index}>
                  {colonia}
                </option>
              );
            })}
          </FieldSelect>
        </Col>

        <Col lg={6} md={6} sm={12}>
          <FieldText
            placeholder="Estado"
            name="state"
            value={props.state}
            normalize="onlyNumbersDotComa"
            disabled={true}
            readOnly={true}
            className="mb-3 mt-1 mb-0 input-readonly"
          />
        </Col>

        <Col lg={6} md={6} sm={12}>
          <FieldText
            placeholder="Municipio"
            name="municipality"
            normalize="onlyNumbersDotComa"
            value={props.municipality}
            disabled={true}
            readOnly={true}
            className="mb-3 mt-1 mb-0 input-readonly"
          />
        </Col>
      </Row>

      <div className="text-center" style={{ marginBottom: "50px" }}>
        <ButtonSubmit isSubmitting={isSubmitting} id={"Continuar_datos_del_negocio"} isValid={isValid} onClick={async() => {
        await Object.keys(props.values).forEach((field) => props.setFieldTouched(field, true));
        }}/>
      </div>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    comercialName: props.initialValues.comercialName || "",
    specific: props.initialValues.specific || "",
    phone: props.initialValues.phone || "",
    street: props.initialValues.street || "",
    extNumber: props.initialValues.extNumber || "",
    intNumber: props.initialValues.intNumber || "",
    zipCode: props.initialValues.zipCode || "",
    town: props.initialValues.town || "",
    state: props.initialValues.state || "",
    municipality: props.initialValues.municipality || "",
  }),
  validate: validateComercialInfo,
  enableReinitialize: true,
  handleSubmit(values, formikBag) {
    formikBag.setSubmitting(false);
    formikBag.props.handleSubmit(values);
  },
  displayName: "ComercialInfoForm",
})(ComercialInfoForm);
