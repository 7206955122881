import { Radio, withStyles } from "@material-ui/core";

const ColorRadio = withStyles((theme) => ({
  root: {
    "color": "#FF5F54",
    "&$checked": {
      color: "#FF5F54",
    },
  },
}))(Radio);

export default ColorRadio;
