import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Title from "../Generic/Title";
import { execToast } from "../../utils/ToastUtils";
import ComercialInfoForm from "../../forms/ComercialInfoForm";
import axiosBase from "axios";
import axios from "../../utils/axios";

// Components
import Steps from "./Steps";
import CustomModal from "../Generic/CustomModal";
import Loader from "../Loader/Loader";
import { updateLoader } from "../../redux/actions/loaderActions";
import { updateRefDocuments } from "../../redux/actions/modalCiecActions";
import useSnack from "../../utils/hooks/useSnack";
import {WebSocketContext} from "../../context/WebSocket";
import { updateUserData } from "../../redux/actions/userActions";
import { scrollToTop } from "../../utils/scroll";
import useAxios from "../../utils/hooks/useStatus";
import useKissmetrics from "../../utils/hooks/useKissmetrics";

const ComercialInfo = (props) => {
  const dispatch = useDispatch();
  // Redux state
  const { app: { toast }, modalCiec: { refDocuments }, user: { user } } = useSelector((state) => state);

  const [initialValues, setInitialValues] = useState({});
  const [municipality, setMunicipality] = useState("");
  const [state, setState] = useState("");
  const [showToast, setShowToast] = useState(false);
  const history = useHistory();
  const logParams = useKissmetrics();

  const onFormSubmit = async (dataForm) => {

    dispatch(updateLoader(true));
    logParams("record", "Continuar datos del negocio", null);
    setShowToast(true);
    const user = JSON.parse(sessionStorage.getItem("user"));
    const id = user._id;
    const idClient = user.idClient;
    const data = {
      ...dataForm,
      state,
      municipality
    };
    if (idClient.appliance.length > 0) {
      
        try {
          const res = await axios.post(`api/info-comercial/${id}`, data);
          sessionStorage.setItem("user", JSON.stringify(res.data.user));
          logParams("record", "Nombre del negocio", { 'Nombre del negocio': dataForm.comercialName });
          dispatch( updateUserData(res.data.user) );

          if (!refDocuments) {
            history.push(`/informacion-general/${user._id}`);
          } else {
            history.push(`/documentos/${user._id}`);
          }
        } catch (error) {
          
          console.log("Error de servicio", error);
        }
      
    }
    dispatch(updateRefDocuments(false));
    dispatch(updateLoader(false));
  };

  const {notify} = useSnack();
  let $user = JSON.parse(sessionStorage.getItem("user"));
  const cargando  = useAxios($user._id);

  useEffect(() => {
    
    scrollToTop();
    if (!toast.second) {
      execToast("second");
    }

    const getData = async () => {
      dispatch(updateLoader(true));

      const { idClient } = user;
      // Si ya tienen una solicitud, se actualiza
      if (idClient.appliance.length > 0) {
        const appliance = idClient.appliance[idClient.appliance.length - 1];
        if (appliance.hasOwnProperty("idComercialInfo")) {
          const comercial = appliance.idComercialInfo;
          const address = comercial.address;
          const paymentsMoreThan30 = comercial.paymentsMoreThan30 ? "1" : "0";
          const terminal = comercial.terminal ? "1" : "0";
          const exportation = comercial.exportation ? "1" : "0";
          let colonias = [];
          try {
            setInitialValues({ ...comercial, ...address, paymentsMoreThan30, terminal, exportation, colonias });
          } catch (error) {
            console.log("Error de servicio", error);
              setInitialValues({ ...comercial, ...address, paymentsMoreThan30, terminal, exportation, colonias });
          }
        }
      }
      else {
        setInitialValues({ ...initialValues, businessName: "" });
      }

      dispatch(updateLoader(false));
    };

      getData();
  }, []);

  useEffect(() => {
    if (showToast) {
      notify("persona")
      setShowToast(false);
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [showToast]);

  return (
    <div className="container mt-3">
      <Loader />
      <Steps />
      {/* <ToastContainer /> */}
      <div className="text-center mb-2">
        <Title title="Datos del negocio" className="title-dp fz42" />
      </div>
      <CustomModal
        modalName="comercialInfoError"
        message="Error al subir los archivos. Favor de regresar a la pantalla de inicio y continúa tu solicitud."
      />
      <ComercialInfoForm
        handleSubmit={onFormSubmit}
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        setState={setState}
        setMunicipality={setMunicipality}
        state={state}
        municipality={municipality}
      ></ComercialInfoForm>
    </div>
  );
};

export default ComercialInfo;
