export const validatePhone = (values) => {
  let errors = {};
  const isNumber = /^\d*$/;

  if (!values.phoneNumber) {
    errors.phoneNumber = "Ingresa tu número telefónico";
  } else if (!isNumber.test(values.phoneNumber)) {
    errors.phoneNumber = "Sólo se pueden ingresar números";
  } else if (values.phoneNumber.length !== 10) {
    errors.phoneNumber = "Ingresa un número telefónico de 10 dígitos";
  }

  return errors;
};
