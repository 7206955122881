import React, { useLayoutEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import tito from '../../assets/img/estatus_solicitud/status-solicitud_03.png';

const AnalisisCredito = ({properties}) => {
    const [proposals, setProposals] = useState(null);

    return(
        <Row>
            <Col lg={8} md={8} sm={12}>
                <div className="title-dp fz42 fw500">
                    En Análisis de Crédito
                </div>

                <div className="text-dp mb-18">
                    <strong>¡Genial!</strong> Tu solicitud está siendo analizada por nuestros analistas de crédito.
                    , en breve un asesor se pondrá en contacto contigo para poder agenda una cita y continuar con el proceso.
                </div>

                
            </Col>
            <Col lg={4} md={4} sm={12}>
                <div className='text-center'>
                    <img src={tito} alt="tito" style={{ width: '250px' }}/>
                </div>
            </Col>
        </Row>
    );
}

export default AnalisisCredito;