import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { Button } from "react-bootstrap";
import axios from "axios";
import Signature from "signature_pad";

export const Signatures = () => {
  const [signaturePad, setSignaturePad] = useState();
  const [savedSignature, setSavedSignature] = useState("");
  const [responseSignature, setResponseSignature] = useState("");
  const [file, setFile] = useState(null);
  const [body, setBody] = useState(null);
  const [rfc, setRfc] = useState("");

  useEffect(() => {
    const rfc = window.location.pathname.split("/")[3];

    const post = async (data) => {
      const url = `${process.env.REACT_APP_BACKEND}`;
      axios.post(`${url}/credit/signature/${rfc}`, data).then((res) => {
        console.log(res.data);
      }
      ).catch((err) => {
        console.log(err);
      }
      );
    };

    if (rfc) {
      setRfc(rfc);

      const data = {
        entro: true,
      };

      post(data);
    }
  }, []);

  const readyPad = () => {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    let wrapper = document.getElementById("signature-pad");
    let canvas = wrapper?.querySelector("canvas");

    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;

    canvas.getContext("2d").scale(1, 1);
    let tempSignaturePad = new Signature(canvas, {
      minWidth: 3,
      backgroundColor: "rgb(234, 246, 255)",
    });
    setSignaturePad(tempSignaturePad);
  };

  const handleSave = () => {
    setSavedSignature(signaturePad.toDataURL());
  };

  const handleClear = () => {
    signaturePad.clear();
  };

  useEffect(() => {
    if (savedSignature) {
      const data_uri = savedSignature;
      const encoded_image = data_uri.split(",")[1];
      // const decoded_image = atob(encoded_image);
      console.log(encoded_image);
      const blob = new Blob([encoded_image], { type: "image/png" });
      const fd = new FormData();
      fd.append("file", blob);
      setBody(fd);
    }
  }, [savedSignature]);

  useEffect(() => {
    const post = async () => {
      const url = `${process.env.REACT_APP_BACKEND}`;

      axios
        .post(`${url}/credit/signature/${rfc}`, body, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          //   console.log(res.data);
          const request = res.data;
          const { file } = request;
          console.log(file);
          setFile(file);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    if (body) {
      post();
    }
  }, [body]);

  useEffect(() => {
    if (file !== null) {
      const BufferToString = (buffer) => {
        return new Uint8Array(buffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        );
      };

      const base64 = BufferToString(file.data.data);
      const img = `data:image/png;base64,${base64}`;
      setResponseSignature(img);
    }
  }, [file]);

  useEffect(() => {
    readyPad();
  }, []);

  if (responseSignature !== "") return (
    <Grid container>
      <Grid item xs={12} className="text-center">
        <h1 style={{ fontSize: "2.5rem" }}>¡Gracias por firmar!</h1>
        <div className="text-dp-gray fw300 fz20 mb-3" style={{ textAlign: "center" }}>
Tu firma ha sido guardada
        </div>

        <div className="signature__preview">
          <img src={responseSignature} alt="" style={{ width: "50%" }} />
        </div>

        <Button
          type="button"
          className={"mt-50 btn-blue-general"}
          style={{ width: "30%" }}
          onClick={() => window.location.replace("/")}
        >
          Continuar
        </Button>

        <Button
          type="button"
          className={"mt-50 btn-blue-general"}
          style={{ width: "30%" }}
          onClick={() => window.location.reload()}
        >
          Volver a intentar
        </Button>
      </Grid>
    </Grid> );

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        className="text-center"
        style={{ maxWidth: "90%", margin: "auto" }}
      >
        <h1 style={{ fontSize: "2.5rem" }}>Firma dentro de la linea</h1>
        <h3 style={{ fontSize: "1.5rem" }}>firma lo mas parecido a tu ine</h3>
      </Grid>
      <Grid
        item
        xs={12}
        className="text-center"
        style={{ maxWidth: "90%", margin: "auto" }}
        id="signature-pad"
      >
        <div
          id="signature-pad"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <canvas className="signature-canvas"></canvas>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        className="text-center"
        style={{
          display: "flex",
          justifyContent: "space-around",
          margin: "auto",
          maxWidth: "80%",
        }}
      >
        <Button
          type="button"
          className={"mt-50 btn-blue-general"}
          style={{ width: "30%" }}
          onClick={() => handleClear()}
        >
          Volver a intentar
        </Button>
        <Button
          type="button"
          className={"mt-50 btn-blue-general"}
          style={{ width: "30%" }}
          onClick={() => handleSave()}
        >
          Continuar
        </Button>
      </Grid>
      {/* {savedSignature && (
        <div className="signature__preview">
          <img src={savedSignature} alt="" />
        </div>
      )}
      {responseSignature && (
        <div className="signature__preview">
          <img src={responseSignature} alt="" />
        </div>
      )} */}
    </Grid>
  );
};
