import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Members from "../../actions/members.js";
import MemberForm from "../../forms/MemberForm.js";
import { updateLoader } from "../../redux/actions/loaderActions";
import Loader from "../Loader/Loader";
import GeneralInfo from "./GeneralInfo.js";
import queryString from 'query-string';

const MembersInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [members, setMembers] = useState([]);
  const [general, setGeneral] = useState(false);

  useEffect(() => {
    dispatch(updateLoader(true));
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      let { idClient } = user;
      let type = idClient.type;
      if (type === "PM") {
        dispatch(updateLoader(false));
        setGeneral(true)
      } else {
        Members.getMembers(user._id).then((response) => {
          if (user.idClient.type !== "PM") {
            dispatch(updateLoader(false));
            let member = response[0];
            history.push({
              pathname: "/verification",
              // search: `?id=${member._id}&source=appliance`,
              search: `?${queryString.stringify({
                id: member._id,
                source: "appliance",
              })}`,
            });
          } else {
            setMembers(response);
            dispatch(updateLoader(false));
          }
        });
      }
    } else {
      history.push("/login");
    }
  }, []);

  if(general) return <GeneralInfo />;

  return (
    <div>
        <Loader />
      {/* <MemberForm initialValues={{}} /> */}
    </div>
  );
};

export default MembersInfo;
