import React, { useState, useEffect } from "react";
import { Field } from "redux-form";
import SubtitleForm from "../components/Generic/SubtitleForm";
import { Row, Col, Button, Collapse } from "react-bootstrap";
import generalInfoOptions from "../models/GeneralInfoModels";
import InputLabel from "../components/Generic/InputLabel";
import { useDispatch } from "react-redux";
import {
  renderFieldFull,
  renderSelectFieldFull,
  renderField,
  renderSelectField,
} from "../components/Generic/Fields";
import { updateLoader } from "../redux/actions/loaderActions";
import scroll from "../utils/scroll";
import useThemeData from "../utils/hooks/useThemeData";
import { porcentajeMask } from "./GeneralInfoFormNew";
import {
  RFCDigitoVerificador,
  RFCFiltraAcentos,
  homonimia,
} from "../components/Validate/validateRFC";
import useSnack from "../utils/hooks/useSnack";
import Swal from "sweetalert2";
import Axios from "../utils/axios";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
} from "@material-ui/core";

const TableMembers = ({ members }) => {
  const headers = [
    "Nombre",
    "RFC",
    "E-mail",
    "Teléfono",
    "Participación Accionaria",
  ];

  const user = JSON.parse(sessionStorage.getItem("user"));
  return (
    <TableContainer component={Paper} className="mb-5">
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={header + index} align="center">
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {members &&
            members.length > 0 &&
            members.map((member, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  {member.name} {member.lastname} {member.secondName}{" "}
                  {member.secondLastname}
                </TableCell>
                <TableCell align="center">{member.rfcPerson}</TableCell>
                <TableCell align="center">
                  <Chip
                    label={index === 0 ? user.email : member.email}
                    style={{
                      backgroundColor: index === 0 ? "#CFF9D1" : "#F9F871",
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <Chip
                    label={member.phone ? member.phone : "Sin teléfono"}
                    color={member.phone ? "success" : "error"}
                  />
                </TableCell>
                <TableCell align="center">{member.percentage}</TableCell>
                <TableCell align="center">
                  <button
                    className="btn btn-primary"
                    // onClick={() => handleForm(index)}
                  >
                    Editar
                  </button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const RenderEvents = ({
  fields,
  meta: { error, submitFailed, touched },
  ...props
}) => {
  const { initialValues, setInitialValues, porcentaje } = props;

  const { theme, themeData } = useThemeData();
  const [currentDate, setCurrentDate] = useState("");
  const [members, setMembers] = useState([]);
  const [colonias, setColonias] = useState({});
  const [cpError, setCpError] = useState({});
  const [CollapseState, setCollapseState] = useState({
    0: true,
  });
  const [buroError, setBuroError] = useState({});
  const length = fields.length;
  const [values, setValues] = useState({});
  const [consulta, setConsulta] = useState({});
  const [erros, setErrors] = useState({});
  const [buro, setBuro] = useState({});

  const { notify } = useSnack();

  const dispatch = useDispatch();

  const getAddressByZipCode = async (zipCode, index) => {
    dispatch(updateLoader(true));

    if (zipCode.length === 5) {
      try {
        const res = await Axios.get(`/api/info/${zipCode}`);
        if (res.data.success) {
          const { estado, municipio } = res.data.info;
          const coloniasRes = res.data.info.asentamiento.map((datos) => datos);
          coloniasRes.sort();
          setColonias((C) => ({ ...C, [index]: coloniasRes }));
          setInitialValues({
            ...initialValues,
            members: [
              ...initialValues.members.slice(0, index),
              {
                ...initialValues.members[index],
                state: estado,
                municipality: municipio,
                zipCode: zipCode,
                colonias: coloniasRes,
              },
              ...initialValues.members.slice(index + 1),
            ],
          });
          setCpError((C) => ({ ...C, [index]: false }));
        } else if (res.error) {
          setCpError((C) => ({ ...C, [index]: true }));
          setInitialValues({
            ...initialValues,
            members: [
              ...initialValues.members.slice(0, index),
              {
                ...initialValues.members[index],
                state: "",
                municipality: "",
                zipCode: zipCode,
                colonias: [],
              },
              ...initialValues.members.slice(index + 1),
            ],
          });
        }
      } catch (error) {
        setCpError((C) => ({ ...C, [index]: true }));
        console.log("No hay CP");
      }
    } else {
      setCpError((C) => ({ ...C, [index]: true }));

      setInitialValues({
        ...initialValues,
        members: [
          ...initialValues.members.slice(0, index),
          {
            ...initialValues.members[index],
            state: "",
            municipality: "",
            zipCode: zipCode,
            colonias: [],
          },
          ...initialValues.members.slice(index + 1),
        ],
      });
    }
    dispatch(updateLoader(false));
  };

  useEffect(() => {
    if (initialValues.members && initialValues.members.length > 0) {
      const newValues = {};
      initialValues.members.forEach((member, index) => {
        newValues[index] = member.zipCode;
      });
      setValues(newValues);
    }
  }, [initialValues]);

  useEffect(() => {
    const newArray = Array.from({ length }, (_, index) => index);
    newArray.forEach((index) => {
      if (
        initialValues.members[index].zipCode &&
        cpError[index] === undefined
      ) {
        setCpError((C) => ({ ...C, [index]: false }));
        setColonias((C) => ({ ...C, [index]: [] }));
        getAddressByZipCode(initialValues.members[index].zipCode, index);
      }
    });
  }, [values, cpError, colonias]);

  useEffect(() => {
    const date = new Date();
    const currentDate = date.toISOString().slice(0, 10);
    const dateArray = currentDate.split("-");
    const newDate = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
    // console.log(newDate);
    setCurrentDate(newDate);
  }, []);

  useEffect(() => {
    if (initialValues.hasOwnProperty("members")) {
      setMembers(initialValues.members);
    }
  }, [initialValues]);

  const user = JSON.parse(sessionStorage.getItem("user"));
  const userType = user.idClient.type;

  const getSubtitles = (index) => {
    return user.idClient.type !== "PM" ? "Sobre ti" : getTitle(index);
  };

  const getTitle = (index) => {
    return index === 0
      ? userType === "PM"
        ? "Principal accionista"
        : " verificacion "
      : `Accionista ${index + 1}`;
  };

  function getErros() {
    const elements = document.getElementsByClassName("error");
    const errors = [];
    for (let elemnet of elements) {
      if (
        elemnet.innerText !== "" &&
        elemnet.parentElement.id !== "porcentaje-error"
      ) {
        errors.push(elemnet);
      }
    }
    return errors;
  }

  function scrollToError(id) {
    // console.log("id", id);
    const element = document.getElementById(id);
    const parent = element?.parentElement;
    parent?.scrollIntoView({ behavior: "smooth" });
  }

  function getAge(birthDte) {
    const today = new Date();
    const birthDate = new Date(birthDte);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age--;

    return age;
  }

  const onlyLirycs = (nextValue, previousValue) =>
    /^([a-zñáéíóúü\s]{0,60})$/i.test(nextValue) ? nextValue : previousValue;
  const onlyNumbers = (nextValue, previousValue) =>
    /^\d+$/.test(nextValue) || nextValue.length === 0
      ? nextValue
      : previousValue;
  const onlyNumbersDot = (nextValue, previousValue) =>
    /^\d+$/.test(nextValue) ||
    nextValue === "." ||
    nextValue.length === 0 ||
    nextValue === "0"
      ? nextValue
      : previousValue;

  const LirycsNumbersDotComa = (nextValue, previousValue) =>
    /^([a-z ñáéíóú0-9,.]{0,45})$/i.test(nextValue) ? nextValue : previousValue;
  const upper = (value) => value && value.toUpperCase();
  const validateEmail = (nextValue, previousValue) =>
    /^$|^[^\s]*[\w-\.\@]+$/i.test(nextValue) ? nextValue : previousValue;

  const handleAddMember = () => {
    const errors = getErros();
    if (errors.length > 0) {
      // console.log("errors", errors);
      const parent = errors[0].parentElement;
      // console.log("parent", parent);
      scrollToError(parent.id);
      return;
    }

    fields.push({
      colonias: [],
    });
    setInitialValues({
      ...initialValues,
      members: [
        ...initialValues.members,
        {
          name: "",
          lastName: "",
          secondLastName: "",
          zipCode: "",
        },
      ],
    });

    scroll("acc" + (fields.length - 1));

    for (const key in CollapseState) {
      setCollapseState((C) => ({ ...C, [key]: false }));
    }

    setCollapseState((C) => ({ ...C, [fields.length]: true }));
  };

  async function deleteMember(index) {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const id = user._id;

    await Axios.get(`/api/buro/delete/${user._id}/${index}`)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err.response.data);
      })
      .finally(() => {
        dispatch(updateLoader(false));
      });
  }

  const handleRemoveMember = (index) => {
    fields.remove(index);

    setInitialValues({
      ...initialValues,
      members: [
        ...initialValues.members.slice(0, index),
        ...initialValues.members.slice(index + 1),
      ],
    });

    scroll("acc" + (fields.length - 1));
    dispatch(updateLoader(true));
    const colapse = document.getElementById("btnAcc" + index - 1);

    if (colapse !== null && colapse !== undefined) {
      colapse.click();
    }

    setCollapseState((C) => ({ ...C, [index]: false }));
    setConsulta((C) => ({ ...C, [index]: false }));
    setColonias((C) => ({ ...C, [index]: [] }));
    setCpError((C) => ({ ...C, [index]: false }));
    deleteMember(index);
  };

  function rfcGenerator(curpRes, index) {
    let rfc = curpRes.slice(0, 10);
    const nombre = RFCFiltraAcentos(
      initialValues.members[index].name?.toLowerCase()
    );
    const apellidoPaterno = RFCFiltraAcentos(
      initialValues.members[index].lastname?.toLowerCase()
    );
    const apellidoMaterno = RFCFiltraAcentos(
      initialValues.members[index].secondLastname?.toLowerCase()
    );

    rfc = rfc + homonimia(apellidoPaterno, apellidoMaterno, nombre);
    rfc = RFCDigitoVerificador(rfc);

    const homoclave = rfc.slice(10, 13);
    const rfcString = rfc.slice(0, 10);

    return { rfc, homoclave, rfcString };
  }

  function handleNameChange(event, newValue, previousValue, index, action) {
    for (const key in action) {
      if (key === "curp") {
        const curp = action[key];
        const curpRes = curp.toUpperCase();

        if (curpRes.length <= 10) {
          action = {
            ...action,
            rfcString: curpRes,
            homoclave: "",
            rfcPerson: "",
          };
        } else {
          const { rfc, homoclave, rfcString } = rfcGenerator(curpRes, index);
          let birthDate =
            curpRes.substring(8, 10) +
            "/" +
            curpRes.substring(6, 8) +
            "/" +
            curpRes.substring(4, 6);
          let fecha = new Date(birthDate);
          let edad = getAge(fecha);
          action = {
            ...action,
            homoclave: homoclave,
            rfcString: rfcString,
            rfcPerson: rfc,
            birthDate: birthDate,
            age: edad,
          };
        }
      }

      if (key === "name" || key === "lastname" || key === "secondLastname") {
        const curp = initialValues.members[index].curp;
        if (curp !== undefined && curp !== null && curp !== "") {
          const curpRes = curp.toUpperCase();
          const { rfc, homoclave, rfcString } = rfcGenerator(curpRes, index);
          let birthDate =
            curpRes.substring(8, 10) +
            "/" +
            curpRes.substring(6, 8) +
            "/" +
            curpRes.substring(4, 6);
          let fecha = new Date(birthDate);
          let edad = getAge(fecha);
          action = {
            ...action,
            homoclave: homoclave,
            rfcString: rfcString,
            rfcPerson: rfc,
            birthDate: birthDate,
            age: edad,
          };
        }
      }
    }

    setInitialValues({
      ...initialValues,
      members: [
        ...initialValues.members.slice(0, index),
        {
          ...initialValues.members[index],
          ...action,
        },
        ...initialValues.members.slice(index + 1),
      ],
    });
  }

  const handleColapsable = (index) => {
    setCollapseState((C) => ({ ...C, [index]: !CollapseState[index] }));
  };

  function checkScore(score, index, res) {
    const colapse = document.getElementById("btnAcc" + index);
    const errorScore =
      score !== undefined &&
      score !== null &&
      score !== "" &&
      (score.includes("ERROR") || score.includes("Error"));
    const rechazoScore =
      score !== undefined &&
      score !== null &&
      score !== "" &&
      score.includes("RECHAZO");

    if (rechazoScore || parseInt(score) < 600) {
      Swal.fire({
        title: "Error",
        text: "Lo lamentamos, no podemos continuar con tu solicitud ya que tu historial crediticio no es el adecuado para este producto.",
        icon: "error",
      });
      dispatch(updateLoader(false));
      return false;
    }

    if (errorScore) {
      Swal.fire({
        title: "Error",
        text: `Por favor, verifica que los datos de ${getTitle(
          index
        )} sean correctos.`,
        icon: "error",
      });
      dispatch(updateLoader(false));
      setConsulta((C) => ({ ...C, [index]: false }));
      // setCollapseState((C) => ({ ...C, [index]: false }));
      setInitialValues({
        ...initialValues,
        members: [
          ...initialValues.members.slice(0, index),
          {
            ...initialValues.members[index],
            score: "",
            tyc: false,
          },
          ...initialValues.members.slice(index + 1),
        ],
      });

      if (colapse !== null && colapse !== undefined && userType === "PM") {
        colapse.click();
      }
      return false;
    }

    const scorePositivo =
      score !== undefined &&
      score !== null &&
      score !== "" &&
      parseInt(score) >= 600;

    if (scorePositivo) {
      console.log("scorePositivo", consulta[index]);
      setCollapseState((C) => ({ ...C, [index]: true }));
      dispatch(updateLoader(false));
      if (colapse !== null && colapse !== undefined) {
        colapse.click();
      }
      if (res === "buroPositivo") {
        notify("buroPositivo");
      }
      return false;
    }

    setConsulta((C) => ({ ...C, [index]: true }));
    return true;
  }

  useEffect(() => {
    async function getBuroScore(member, index) {
      const score = member.score;

      if (typeof score === "number") {
        return;
      }

      const chekScore = checkScore(score, index);

      if (!chekScore) {
        setConsulta((C) => ({ ...C, [index]: false }));
        console.log("chekScore", chekScore);
        return;
      }

      const data = {
        ...member,
      };

      notify("buro");

      await Axios.post(`/api/buro/new/${user._id}/${index}`, data)
        .then((res) => {
          // console.log(index, res.data);
          const user = res.data.user;
          sessionStorage.setItem("user", JSON.stringify(user));
          const score = res.data.score;
          const dataGuardada = res.data.dataGuardada;

          setConsulta((C) => ({ ...C, [index]: false }));

          if (score !== undefined && score !== null && score !== "") {
            const chekScore = checkScore(score, index, "buroPositivo");

            const scoreError = score.includes("ERROR");
            const scoreRechazo = score.includes("RECHAZO");

            if (scoreError || scoreRechazo) {
              return;
            }

            if (!chekScore) {
              return;
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log(err);
            console.log(err.response.data);
          } else {
            console.log(err);
          }

          if (err.response?.status === 429) {
            const title =
              index === 0 ? "Principal Accionista" : `Accionista ${index + 1}`;
            Swal.fire({
              title: "Error",
              text: `Lo sentimos, has excedido el número de consultas permitidas del ${title}. Por favor, intenta más tarde.`,
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          }
          setInitialValues({
            ...initialValues,
            members: [
              ...initialValues.members.slice(0, index),
              {
                ...initialValues.members[index],
                buroScore: "ERROR",
                tyc: false,
              },
              ...initialValues.members.slice(index + 1),
            ],
          });
          setConsulta((C) => ({ ...C, [index]: false }));
        })
        .finally(() => {
          dispatch(updateLoader(false));
        });
    }

    function ValidateTyc(m, ind, newValue) {
      if (m === undefined || m === null) {
        return;
      }

      const member = m;

      if (
        member.mortgageCredit === undefined ||
        member.mortgageCredit === null ||
        member.carCredit === undefined ||
        member.carCredit === null ||
        member.creditCard === undefined ||
        member.creditCard === null
      ) {
        return;
      }

      if (
        member.rfcPerson &&
        member.rfcPerson.length === 13 &&
        member.tyc === true
      ) {
        const errors = getErros();

        if (errors.length > 0) {
          return;
        }

        dispatch(updateLoader(true));
        getBuroScore(member, ind);
      } else if (
        member.curp !== undefined &&
        member.curp !== null &&
        member.curp.length === 18 &&
        member.tyc === true
      ) {
        const action = {
          curp: member.curp,
        };

        handleNameChange(null, null, null, ind, action);
      }
    }

    if (initialValues.members && initialValues.members.length > 0) {
      initialValues.members.map((m, ind) => {
        if (m !== undefined && m !== null && m.tyc === true) {
          if (consulta[ind] === undefined || consulta[ind] === null) {
            // setConsulta((C) => ({ ...C, [ind]: true }));
            ValidateTyc(m, ind);
          } else if (consulta[ind] === false) {
            // setConsulta((C) => ({ ...C, [ind]: true }));
            ValidateTyc(m, ind);
          }
        }
      });
    } else {
      return;
    }
  }, [initialValues]);

  useEffect(() => {
    console.log("members", members);
    // console.log("initialValues", initialValues);
  }, [members]);

  return (
    <div>
      {fields.map((member, index) => (
        <div
          key={index}
          className={index === 0 ? "" : "mt-3"}
          id={`acc${index}`}
        >
          <SubtitleForm subtitle={getSubtitles(index)} />

          {user.idClient.type === "PM" && (
            <Button
              className="btn btn-danger"
              onClick={() => handleColapsable(index)}
              id={`btnAcc${index}`}
            >
              despelgar
            </Button>
          )}

          <Collapse in={CollapseState[index]}>
            <div id={`acc${index}`}>
              <Row className="d-flex justify-content-center">
                <Col lg={4} md={4} sm={12}>
                  <Field
                    name={`${member}.name`}
                    label="Nombre(s)"
                    type="text"
                    component={renderFieldFull}
                    normalize={onlyLirycs}
                    onChange={(event, newValue, previousValue, name) => {
                      handleNameChange(event, newValue, previousValue, index, {
                        name: newValue,
                      });
                    }}
                    readOnly={consulta[index]}
                  />
                </Col>
                <Col lg={4} md={4} sm={12}>
                  <Field
                    name={`${member}.lastname`}
                    label="Apellido Paterno"
                    type="text"
                    component={renderFieldFull}
                    normalize={onlyLirycs}
                    onChange={(event, newValue, previousValue, name) => {
                      handleNameChange(event, newValue, previousValue, index, {
                        lastname: newValue,
                      });
                    }}
                    readOnly={consulta[index]}
                  />
                </Col>
                <Col lg={4} md={4} sm={12}>
                  <Field
                    name={`${member}.secondLastname`}
                    label="Apellido materno"
                    component={renderFieldFull}
                    normalize={onlyLirycs}
                    onChange={(event, newValue, previousValue, name) => {
                      handleNameChange(event, newValue, previousValue, index, {
                        secondLastname: newValue,
                      });
                    }}
                    readOnly={consulta[index]}
                  />
                </Col>
              </Row>

              {index === 0 ? (
                <Field
                  component={renderSelectFieldFull}
                  name={`${member}civilStatus`}
                  clases="mt-24"
                  onChange={(event, newValue, previousValue, name) => {
                    handleNameChange(event, newValue, previousValue, index, {
                      civilStatus: newValue,
                    });
                  }}
                  disabled={consulta[index]}
                >
                  <option value="">Estado civil...</option>
                  {Object.keys(generalInfoOptions.civilStatusOptions).map(
                    (key, index) => (
                      <option value={key} key={index}>
                        {generalInfoOptions.civilStatusOptions[key].value}
                      </option>
                    )
                  )}
                </Field>
              ) : (
                <Field
                  name={`${member}.email`}
                  label="Correo electrónico"
                  component={renderFieldFull}
                  normalize={validateEmail}
                  onChange={(event, newValue, previousValue, name) => {
                    handleNameChange(event, newValue, previousValue, index, {
                      email: newValue,
                    });
                  }}
                  readOnly={consulta[index]}
                />
              )}

              <Row className="d-flex justify-content-center">
                <Col>
                  <Field
                    component={renderFieldFull}
                    label="CURP"
                    name={`${member}.curp`}
                    cls="mb-3 mt-24"
                    normalize={upper}
                    onChange={(event, newValue, previousValue, name) => {
                      handleNameChange(event, newValue, previousValue, index, {
                        curp: newValue,
                      });
                    }}
                    readOnly={consulta[index]}
                  />
                </Col>

                <Col>
                  {/* <label className="label-style mt-24">
                    El número telefónico debe tener 10 dígitos
                  </label> */}

                  <Field
                    component={renderFieldFull}
                    label="Teléfono Personal"
                    type="text"
                    name={`${member}.phone`}
                    maxLength={10}
                    minLength={10}
                    normalize={onlyNumbers}
                    onChange={(event, newValue, previousValue, name) => {
                      handleNameChange(event, newValue, previousValue, index, {
                        phone: newValue,
                      });
                    }}
                    disabled={consulta[index]}
                  />
                </Col>
              </Row>

              {user.idClient.type === "PM" && (
                <>
                  <Row>
                    <Col>
                      <Field
                        component={renderFieldFull}
                        name={`${member}.rfcString`}
                        label="rfc sin"
                        normalize={upper}
                        readOnly={true}
                        onChange={(event, newValue, previousValue, name) => {
                          handleNameChange(
                            event,
                            newValue,
                            previousValue,
                            index,
                            {
                              rfcString: newValue,
                            }
                          );
                        }}
                      />
                    </Col>
                    <Col>
                      <Field
                        component={renderFieldFull}
                        name={`${member}.homoclave`}
                        label="Homoclave"
                        normalize={upper}
                        maxLength={3}
                        onChange={(event, newValue, previousValue, name) => {
                          const rfc = `${initialValues.members[index].rfcString}${newValue}`;
                          handleNameChange(
                            event,
                            newValue,
                            previousValue,
                            index,
                            {
                              homoclave: newValue,
                              rfcPerson: rfc,
                            }
                          );
                        }}
                        readOnly={consulta[index]}
                      />
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <Field
                        component={renderFieldFull}
                        name={`${member}.percentage`}
                        label="Porcentaje de participación"
                        normalize={onlyNumbers}
                        {...porcentajeMask}
                        onChange={(event, newValue, previousValue, name) => {
                          handleNameChange(
                            event,
                            newValue,
                            previousValue,
                            index,
                            {
                              percentage: newValue,
                            }
                          );
                        }}
                        readOnly={consulta[index]}
                      />
                    </Col>
                  </Row>
                  <span className="text-dp-blue-2 fw300 fz10">
                    por favor, revisa que el RFC sea correcto
                  </span>
                </>
              )}

              {(index === 0 ||
                parseInt(member.percentage) > 25 ||
                parseInt(initialValues.members[index].percentage) > 25) && (
                <>
                  <SubtitleForm
                    subtitle="Domicilio particular"
                    className="mb-10 mt-24"
                  />

                  <Row className="d-flex justify-content-center">
                    <Col lg={12} md={12} sm={12}>
                      <Field
                        component={renderFieldFull}
                        label="Calle"
                        name={`${member}.street`}
                        normalize={LirycsNumbersDotComa}
                        onChange={(event, newValue, previousValue, name) => {
                          handleNameChange(
                            event,
                            newValue,
                            previousValue,
                            index,
                            {
                              street: newValue,
                            }
                          );
                        }}
                        readOnly={consulta[index]}
                      />
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <Field
                        component={renderFieldFull}
                        label="Ext"
                        name={`${member}.extNumber`}
                        onChange={(event, newValue, previousValue, name) => {
                          handleNameChange(
                            event,
                            newValue,
                            previousValue,
                            index,
                            {
                              extNumber: newValue,
                            }
                          );
                        }}
                        readOnly={consulta[index]}
                      />
                    </Col>

                    <Col lg={6} md={6} sm={12}>
                      <Field
                        component={renderFieldFull}
                        label="Int"
                        name={`${member}.intNumber`}
                        onChange={(event, newValue, previousValue, name) => {
                          handleNameChange(
                            event,
                            newValue,
                            previousValue,
                            index,
                            {
                              intNumber: newValue,
                            }
                          );
                        }}
                        readOnly={consulta[index]}
                      />
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <Field
                        component={renderFieldFull}
                        label="CP"
                        name={`${member}.zipCode`}
                        normalize={onlyNumbers}
                        onChange={(event, newValue, previousValue, name) => {
                          if (
                            newValue !== undefined &&
                            newValue.hasOwnProperty("length") &&
                            newValue.length === 5
                          ) {
                            dispatch(updateLoader(true));
                            getAddressByZipCode(newValue, index);
                          }
                        }}
                        disabled={consulta[index]}
                      />
                      {cpError[index] && (
                        <span id="zipCode-error">
                          <small className="error">
                            Código postal no encontrado.
                          </small>
                        </span>
                      )}
                    </Col>

                    <Col lg={6} md={6} sm={12}>
                      <Field
                        className="form-control custom-form-input brandonReg mt-24 mb-0"
                        component={renderSelectField}
                        name={`${member}.town`}
                        onChange={(event, newValue, previousValue, name) => {
                          handleNameChange(
                            event,
                            newValue,
                            previousValue,
                            index,
                            {
                              town: newValue,
                            }
                          );
                        }}
                        cls="mb-3 mt-24"
                        readOnly={consulta[index]}
                      >
                        <option value="" selected disabled>
                          Selecciona una colonia
                        </option>
                        {colonias[index] &&
                          colonias[index].map((colonia, index) => {
                            return (
                              <option value={colonia} key={colonia + index}>
                                {colonia}
                              </option>
                            );
                          })}
                      </Field>
                    </Col>

                    <Col lg={6} md={6} sm={12}>
                      <Field
                        className="form-control custom-form-input mt-24 mb-0"
                        component={renderFieldFull}
                        name={`${member}.municipality`}
                        cls="mb-3 mt-24"
                        label="Municipio"
                        readOnly={true}
                      />
                    </Col>

                    <Col lg={6} md={6} sm={12}>
                      <Field
                        className="form-control custom-form-input mt-24 mb-0"
                        component={renderFieldFull}
                        cls="mb-3 mt-24"
                        label="Estado"
                        name={`${member}.state`}
                        readOnly={true}
                      />
                    </Col>
                  </Row>

                  <SubtitleForm
                    subtitle="¿Cuentas con alguno?"
                    className="mt-30 mb-18"
                  />
                  <Row>
                    <Col>
                      <InputLabel
                        label="Crédito hipotecario"
                        class="mt-18 text-msg-dp"
                      />
                      <Field
                        component={renderSelectFieldFull}
                        name={`${member}.mortgageCredit`}
                        clases="mt-10"
                        onChange={(event, newValue, previousValue, name) => {
                          handleNameChange(
                            event,
                            newValue,
                            previousValue,
                            index,
                            {
                              mortgageCredit: newValue,
                            }
                          );
                        }}
                        disabled={consulta[index]}
                      >
                        <option value="">Selecciona...</option>
                        <option value="1">Sí</option>
                        <option value="0">No</option>
                      </Field>
                    </Col>
                    <Col>
                      <InputLabel
                        label="Crédito automotriz"
                        class="mt-18 text-msg-dp"
                      />
                      <Field
                        component={renderSelectFieldFull}
                        name={`${member}.carCredit`}
                        clases="mt-10"
                        onChange={(event, newValue, previousValue, name) => {
                          handleNameChange(
                            event,
                            newValue,
                            previousValue,
                            index,
                            {
                              carCredit: newValue,
                            }
                          );
                        }}
                        disabled={consulta[index]}
                      >
                        <option value="">Selecciona...</option>
                        <option value="YES">Sí</option>
                        <option value="NO">No</option>
                        <option value="YES2">
                          Cerrado en los ultimos 2 años
                        </option>
                      </Field>
                    </Col>

                    <Col>
                      <InputLabel
                        label="Tarjeta de crédito"
                        class="mt-18 text-msg-dp"
                      />
                      <Field
                        component={renderSelectFieldFull}
                        name={`${member}.creditCard`}
                        clases="mt-10"
                        onChange={(event, newValue, previousValue, name) => {
                          handleNameChange(
                            event,
                            newValue,
                            previousValue,
                            index,
                            {
                              creditCard: newValue,
                            }
                          );
                        }}
                        disabled={consulta[index]}
                      >
                        <option value="">Selecciona...</option>
                        <option value="1">Sí</option>
                        <option value="0">No</option>
                      </Field>
                    </Col>
                  </Row>

                  {(member[index]?.creditCard === "1" ||
                    initialValues.members[index]?.creditCard === "1") && (
                    <Row>
                      <Col>
                        <Field
                          component={renderFieldFull}
                          name={`${member}.last4`}
                          maxLength="4"
                          normalize={onlyNumbers}
                          onChange={(event, newValue, previousValue, name) => {
                            handleNameChange(
                              event,
                              newValue,
                              previousValue,
                              index,
                              {
                                last4: newValue,
                              }
                            );
                          }}
                          disabled={consulta[index]}
                          label="Últimos 4 dígitos de tu tarjeta de crédito"
                        />
                      </Col>
                    </Row>
                  )}

                  <div className="mt-3">
                    <Field
                      component={renderFieldFull}
                      type="checkbox"
                      label={`Hoy siendo ${currentDate}. Autorizo a ${
                        themeData ? themeData.razonSocial : ""
                      } a consultar mis antecedentes crediticios por única ocasión ante las Sociedades de Información Crediticia que estime conveniente, declarando que conozco la naturaleza, alcance y uso que ${
                        themeData ? themeData.razonSocial : ""
                      } hará de tal información.`}
                      name={`${member}.tyc`}
                      onChange={(event, newValue, previousValue, name) => {
                        handleNameChange(
                          event,
                          newValue,
                          previousValue,
                          index,
                          {
                            tyc: newValue,
                          }
                        );
                      }}
                      big={true}
                      disabled={consulta[index]}
                    />
                  </div>
                </>
              )}

              {index !== 0 && (
                <div className="d-flex justify-content-lg-end justify-content-md-center justify-content-sm-center">
                  <Button
                    type="submit"
                    className="mt-50 btn-blue-general"
                    style={{ width: "250px" }}
                    onClick={() => handleRemoveMember(index)}
                  >
                    Eliminar accionista
                  </Button>
                </div>
              )}
            </div>
          </Collapse>
        </div>
      ))}

      {user.idClient.type === "PM" && porcentaje < 100 && (
        <div>
          <Button
            type="submit"
            className="mt-4 btn-blue-general"
            style={{ width: "250px" }}
            onClick={() => handleAddMember()}
          >
            Agregar accionista
          </Button>
          {submitFailed && error && <span>{error}</span>}
        </div>
      )}
    </div>
  );
};
