import React, { useEffect, useState } from "react";
import { useNotificationCenter } from "react-toastify/addons/use-notification-center";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
// import Mano from "../assets/img/logo-finsus.png";
import { updateSnackbar } from "../redux/actions/snackActions";
import { useSelector, useDispatch } from "react-redux";
import ConfettiExplosion from 'react-confetti-explosion';
// import ProposalModal from "../components/Appliance/ProposalModal";
import { useHistory } from "react-router";
import { type } from "jquery";
import { Box } from "@material-ui/core";
import useKissmetrics from "./hooks/useKissmetrics";

const Msg = ({ title, text, showSwal, count }) => {
  const [isExplosion, setIsExplosion] = useState(true);
  const logParams = useKissmetrics();

  const handleOferta = () => {
    logParams("record", "Ver oferta", null);
    showSwal();
  };

  useEffect(() => {
    setTimeout(() => {
      setIsExplosion(false);
    }, 5000);
  }, []);
  if (count % 2 === 0) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          padding: "0.5rem 0.5rem",
          margin: "0",
          width: "100%",
          height: "100%",
        }}
        className="metropolisReg"
      >
        <h1
          style={{
            fontSize: "1rem",
            fontWeight: "bold",
            padding: "0.1rem 0",
            margin: "0",
          }}
        >
          {title}
        </h1>
        <div
          style={{
            fontSize: "0.8rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {text}
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          //   alignItems: "center",
          //   justifyContent: "center",
          padding: "0.5rem 0.5rem",
          textAlign: "center",
          width: "100%",
          height: "100%",
        }}
        className="metropolisReg"
      >
        
        <h1
          style={{
            fontSize: "2rem",
            padding: "0.1rem 0",
            margin: "0",
            marginBottom: "0.5rem",
            color: "#1a1a25 !important",
            clipPath: "polygon(0 0, 100% 0, 100% 75%, 0 100%)",
            background: "#fff",
          }}
          className="fw600"
        >
          {title}
        </h1>
        {
          isExplosion && <ConfettiExplosion duration={3000} floorheight={200} floorwidth={200} />
        }
        <p
          style={{
            fontSize: "0.85rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          {text}
        </p>
        <Button className="btn-blue-general mt-1" onClick={handleOferta} id="Ver_Oferta">
          Ver oferta
        </Button>
      </div>
    );
  }
};

const Icon = ({ type, size }) => {
  
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "60px",
        height: "60px",
        borderRadius: "10px",
        padding: "0.5rem",
        backgroundColor: "#fff",
        background: "url(https://finsus.mx/wp-content/uploads/2023/09/finsus-192x192-1.jpg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        // border: "1px solid #000",
        color: "#000",
        boxShadow: "-2px 10px 10px 2px rgba(0, 0, 0, 0.13)",
        // boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
      }}
    >
      {/* <img
        src={
          "https://finsus.mx/wp-content/uploads/2023/09/finsus-192x192-1.jpg"
        }
        alt="icon"
        width={50}
        height={50}
      /> */}
    </Box>
  );
};

const NotificationCenter = () => {
  const { notifications, clear, markAllAsRead, markAsRead } =
    useNotificationCenter();

  const [show, setShow] = useState(false);
  const history = useHistory();

  const { msg, open, title, closeT } = useSelector((state) => state.snack);
  const data = useSelector((state) => state.snack);
  const snack = useSelector((state) => state.snack);

  const dispatch = useDispatch();

  const close = () => {
    setShow(false);
  };

  const showSwal = () => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const rfc = user.rfc;
    history.push(`/proposal/${rfc}`);
  };

  const showToasts = ({ title, msg, time, closeT }) => {
    // console.log(title, msg, time)
    let oferta = null;
    let $r = false;

    if (typeof title.title === "object") {
      oferta = ["hola"];
    } else {
      console.log(title.title);
      if (title.title === "¡Enhorabuena!") {
        oferta = ["noticias!"];
      }
      // oferta = title.title.split(" ");
    }

    oferta && oferta.map((item) => {
      if (item === "noticias!") {
        toast.info(
          <Msg
            title={title.title}
            text={msg.msg}
            showSwal={showSwal}
            count={1}
          />,
          {
            autoClose: false,
            icon: ({ theme, type }) => <Icon type={type} />,
            theme: "colored",
          }
        );
        $r = true;
      }
    });

    if ($r) return;

    toast.info(
      <Msg title={title.title} text={msg.msg} showSwal={showSwal} count={0} />,
      {
        autoClose: closeT ? 8000: false,
        icon: ({ theme, type }) => <Icon type={type} />,
        data: {
          title: "Hello World",
          text: "This is a toastify notification",
        },
      }
    );
  };

  useEffect(() => {

    const hideDuration = 1000;
    if (msg) {
      setTimeout(() => {
        snack.open = false;
        dispatch(updateSnackbar(false, "", ""));
      }, hideDuration);

      showToasts({ title: { title }, msg: { msg }, time: 3000, closeT });
    }
  }, [msg, data]);


  return (
    <>
      {/* <ProposalModal show={show} close={close} /> */}
      <ToastContainer position="top-right" />
    </>
  );
};

export default NotificationCenter;
