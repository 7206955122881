import { Form, withFormik } from "formik";
import { FieldText, FieldDate } from "../components/Generic/Fields";
import SubtitleForm from "../components/Generic/SubtitleForm";
import { Col, Row } from "react-bootstrap";
import generalInfoOptions from "../models/GeneralInfoModels";
import { ButtonSubmit } from "../components/Generic/ButtonSubmit";
import ColorRadio from "../components/Incode/ColorRadio";
import ColorButton from "../components/Incode/ColorButton";
import { RadioGroup, FormControlLabel, IconButton } from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import Swal from "sweetalert2";

const AssetSituationForm = (props) => {
  const { isSubmitting, isValid, values, initialValues, setFieldValue } = props;

  const handleDelete = async (idx, type) => {
    const result = await Swal.fire({
      title: "¿Está seguro de eliminar este inmueble?",
      icon: "question",
      showCancelButton: true,
    });
    if (result.isConfirmed) {
      const aux = values[type];
      aux.splice(idx, 1);
      setFieldValue(type, aux);
    }
  };

  const handleAdd = (type, data) => {
    const aux = values[type];
    aux.push(data);
    setFieldValue(type, aux);
  };

  return (
    <Form className="text-left" style={{ maxWidth: "690px" }}>
      <div className="container">
        <Row className="justify-content-start">
          <SubtitleForm subtitle={"Estado de Situación Patrimonial"} />
        </Row>
        <RealEstateForm
          handleAdd={handleAdd}
          handleDelete={handleDelete}
          setFieldValue={setFieldValue}
          values={values}
        />
        <Stocks
          handleAdd={handleAdd}
          handleDelete={handleDelete}
          setFieldValue={setFieldValue}
          values={values}
        />
        <Art
          handleAdd={handleAdd}
          handleDelete={handleDelete}
          setFieldValue={setFieldValue}
          values={values}
        />
        <Vehicles
          handleAdd={handleAdd}
          handleDelete={handleDelete}
          values={values}
          setFieldValue={setFieldValue}
        />
        <Debtors
          handleAdd={handleAdd}
          handleDelete={handleDelete}
          values={values}
          setFieldValue={setFieldValue}
        />
        <Row className="justify-content-center">
          <ButtonSubmit
            isSubmitting={isSubmitting}
            isValid={isValid}
            id={"dataConfirmBtn"}
          />
        </Row>
      </div>
    </Form>
  );
};

const RealEstateForm = ({ handleAdd, handleDelete, values, setFieldValue }) => {
  const data = {
    type: null,
    m2: "",
    buildM2: "",
    value: "",
    address: "",
    letter: "",
    folio: "",
    gravamen: false,
  };

  return (
    <>
      <Subtitle
        subtitle="Bienes raíces"
        question="¿Posees inmuebles?"
        type="realEstates"
        setFieldValue={setFieldValue}
        values={values}
      />
      {values.toShow.realEstates &&
        values.realEstates.map((_, index) => (
          <BoxDashed
            handleDelete={handleDelete}
            index={index}
            key={`div-realEstate-${index}`}
            name="Inmueble"
            type="realEstates"
            values={values}
          >
            <Row
              className="justify-content-center"
              key={`row-realEstate-${index}`}
            >
              <Col lg={12} md={12} sm={12}>
                <label className="label-style">Tipo de inmueble:</label>
                <RadioGroup
                  row
                  aria-label="realEstate"
                  name="realEstate.type"
                  key={"radiogroup-realEstate-" + index}
                  onChange={(e) =>
                    setFieldValue(`realEstates.${index}.type`, e.target.value)
                  }
                >
                  {generalInfoOptions.realEstateTypes.map((type, idx) => (
                    <FormControlLabel
                      key={"formcontrol-realEstateType-" + idx}
                      value={generalInfoOptions.realEstateTypes[idx]}
                      control={<ColorRadio size="small" />}
                      label={generalInfoOptions.realEstateTypes[idx]}
                    />
                  ))}
                </RadioGroup>
              </Col>
            </Row>
            <Row className="d-flex justify-content-start">
              <Col lg={4} md={4} sm={12}>
                <FieldText
                  name={`realEstates.${index}.m2`}
                  placeholder="750"
                  normalize="onlyNumbers"
                  label="Metros cuadrados"
                  key={"realEstate-m2-" + index}
                />
              </Col>
              <Col lg={5} md={5} sm={12}>
                <FieldText
                  name={`realEstates.${index}.buildM2`}
                  placeholder="350"
                  normalize="onlyNumbers"
                  label="Metros cuadrados de construcción"
                  key={"realEstate-buildm2-" + index}
                />
              </Col>
              <Col lg={3} md={3} sm={12}>
                <FieldText
                  name={`realEstates.${index}.value`}
                  placeholder="1500000"
                  normalize="onlyNumbers"
                  label="Valor"
                  key={"realEstate-value-" + index}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <FieldText
                  name={`realEstates.${index}.address`}
                  placeholder="Av. Reforma #123, Col. Juárez, Cuauhtémoc, CDMX."
                  label="Ubicación"
                  key={"realEstate-address-" + index}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <FieldText
                  key={"realEstate-letter-" + index}
                  label="Escritura"
                  name={`realEstates.${index}.letter`}
                  normalize="UpperCase"
                  placeholder="Escritura"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <FieldText
                  key={"realEstate-folio-" + index}
                  label="Folio"
                  name={`realEstates.${index}.folio`}
                  normalize={"UpperCase"}
                  placeholder="Folio"
                />
              </Col>
            </Row>
            <Row className="my-3 align-items-center">
              <Col lg={6} md={6} sm={12} className="align-self-center">
                <p>¿Está libre de gravamen?</p>
              </Col>
              <Col lg={6} md={6} sm={12} className="align-self-center">
                <RadioGroup
                  row
                  aria-label="showRealEstates"
                  name="toShow.realEstates"
                  value={values.realEstates[index].gravamen ? "yes" : "no"}
                  onChange={(e) =>
                    setFieldValue(
                      `realEstates.${index}.gravamen`,
                      e.target.value === "yes"
                    )
                  }
                >
                  <FormControlLabel
                    value="yes"
                    labelPlacement="start"
                    label="Sí"
                    control={<ColorRadio />}
                  />
                  <FormControlLabel
                    value="no"
                    labelPlacement="start"
                    label="No"
                    control={<ColorRadio />}
                  />
                </RadioGroup>
              </Col>
            </Row>
            {index === values.realEstates.length - 1 && (
              <Row className="d-flex justify-content-center mt-3">
                <ColorButton
                  startIcon={<Add />}
                  onClick={() => handleAdd("realEstates", data)}
                >
                  Añadir inmueble
                </ColorButton>
              </Row>
            )}
          </BoxDashed>
        ))}
    </>
  );
};

const Stocks = ({ handleAdd, handleDelete, values, setFieldValue }) => {
  const data = {
    type: null,
    m2: "",
    buildM2: "",
    value: "",
    address: "",
    letter: "",
    folio: "",
    gravamen: false,
  };

  return (
    <>
      <Subtitle
        question="¿Posees acciones en alguna empresa?"
        setFieldValue={setFieldValue}
        subtitle="Acciones y valores"
        type="stocks"
        values={values}
      />
      {values.toShow.stocks &&
        values.stocks.map((_, index) => (
          <BoxDashed
            handleDelete={handleDelete}
            index={index}
            key={`div-stocks-${index}`}
            name="Acciones"
            type="stocks"
            values={values}
          >
            <Row className="d-flex justify-content-start">
              <Col lg={4} md={4} sm={12}>
                <FieldText
                  name={`stocks.${index}.enterprise`}
                  placeholder="Finsus"
                  label="Empresa"
                  key={"stocks-enterprise-" + index}
                />
              </Col>
              <Col lg={4} md={4} sm={12}>
                <FieldText
                  name={`stocks.${index}.nominalValue`}
                  placeholder="64000"
                  normalize="onlyNumbers"
                  label="Valor nominal"
                  key={"stocks-nominalvalue-" + index}
                />
              </Col>
              <Col lg={4} md={4} sm={12}>
                <FieldText
                  name={`stocks.${index}.commercialValue`}
                  placeholder="120000"
                  normalize="onlyNumbers"
                  label="Valor comercial"
                  key={"stocks-comercialvalue-" + index}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <FieldText
                  name={`stocks.${index}.appasialBasis`}
                  placeholder="Financieros"
                  label="Base de avalúo"
                  key={"stocks-address-" + index}
                />
              </Col>
            </Row>
            {index === values.stocks.length - 1 && (
              <Row className="d-flex justify-content-center mt-3">
                <ColorButton
                  startIcon={<Add />}
                  onClick={() => handleAdd("stocks", data)}
                >
                  Añadir empresa
                </ColorButton>
              </Row>
            )}
          </BoxDashed>
        ))}
    </>
  );
};

const Art = ({ handleAdd, handleDelete, values, setFieldValue }) => {
  const data = {
    description: "",
    estimatedValue: "",
    insuredBy: "",
    insuredQuantity: "",
  };

  return (
    <>
      <Subtitle
        question="¿Posees obras de arte?"
        setFieldValue={setFieldValue}
        subtitle="Obras de arte"
        type="art"
        values={values}
      />
      {values.toShow.art &&
        values.art.map((_, index) => (
          <BoxDashed
            handleDelete={handleDelete}
            index={index}
            key={`div-art-${index}`}
            name="Arte"
            type="art"
            values={values}
          >
            <Row>
              <Col lg={12} md={12} sm={12}>
                <FieldText
                  name={`art.${index}.description`}
                  placeholder="Cuadro de..."
                  label="Descripción"
                  key={"art-description-" + index}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4} md={4} sm={12}>
                <FieldText
                  name={`art.${index}.estimatedValue`}
                  placeholder="65000"
                  normalize={"onlyNumbers"}
                  label="Valor aproximado"
                  key={"art-estimatedvalue-" + index}
                />
              </Col>
              <Col lg={4} md={4} sm={12}>
                <FieldText
                  name={`art.${index}.insuredBy`}
                  placeholder="Metlife"
                  normalize={"onlyLirycs"}
                  label="Asegurado por"
                  key={"art-insuredby-" + index}
                />
              </Col>
              <Col lg={4} md={4} sm={12}>
                <FieldText
                  name={`art.${index}.insuredQuantity`}
                  placeholder="60000"
                  normalize={"onlyNumbers"}
                  label="Cantidad asegurada"
                  key={"art-insuredquantity-" + index}
                />
              </Col>
            </Row>
            {index === values.art.length - 1 && (
              <Row className="d-flex justify-content-center mt-3">
                <ColorButton
                  startIcon={<Add />}
                  onClick={() => handleAdd("art", data)}
                >
                  Añadir arte
                </ColorButton>
              </Row>
            )}
          </BoxDashed>
        ))}
    </>
  );
};

const Vehicles = ({ handleAdd, handleDelete, values, setFieldValue }) => {
  const data = { brand: "", type: "", model: "", invoiceValue: "", date: "" };

  return (
    <>
      <Subtitle
        question={"¿Posees vehículos?"}
        setFieldValue={setFieldValue}
        subtitle="Vehículos"
        type={"vehicles"}
        values={values}
      />
      {values.toShow.vehicles &&
        values.vehicles.map((_, index) => (
          <BoxDashed
            handleDelete={handleDelete}
            index={index}
            key={"div-vehicle-" + index}
            name="Vehículo"
            type="vehicles"
            values={values}
          >
            <Row>
              <Col lg={12} md={12} sm={12}>
                <FieldText
                  name={`vehicles.${index}.brand`}
                  placeholder="Sedan Toyota"
                  label="Marca y tipo"
                  key={"vehicles-brand-" + index}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <FieldText
                  name={`vehicles.${index}.model`}
                  placeholder="Corolla 2020"
                  label="Modelo"
                  key={"vehicles-model-" + index}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <FieldText
                  name={`vehicles.${index}.invoiceValue`}
                  placeholder="166000"
                  normalize={"onlyNumbers"}
                  label="Valor factura"
                  key={"vehicles-invoicevalue-" + index}
                />
              </Col>
            </Row>
            {index === values.vehicles.length - 1 && (
              <Row className="d-flex justify-content-center mt-3">
                <ColorButton
                  startIcon={<Add />}
                  onClick={() => handleAdd("vehicles", data)}
                >
                  Añadir vehículo
                </ColorButton>
              </Row>
            )}
          </BoxDashed>
        ))}
    </>
  );
};

const Debtors = ({ handleAdd, handleDelete, values, setFieldValue }) => {
  const data = {
    institution: "",
    account: "",
    originalDue: "",
    currentDue: "",
  };

  return (
    <>
      <Subtitle
        question={"¿Tienes adeudos bancarios?"}
        setFieldValue={setFieldValue}
        subtitle="Adeudos bancarios y comerciales"
        type={"debtors"}
        values={values}
      />
      {values.toShow.debtors &&
        values.debtors.map((_, index) => (
          <BoxDashed
            handleDelete={handleDelete}
            index={index}
            key={"div-debtor-" + index}
            name="Adeudo bancario"
            type="debtors"
            values={values}
          >
            <Row>
              <Col lg={12} md={12} sm={12}>
                <FieldText
                  key={"debtors-institution-" + index}
                  label="Institución"
                  name={`debtors.${index}.institution`}
                  placeholder="Banamex"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <FieldText
                  key={"debtors-account`}-" + index}
                  label="No. de cuenta"
                  name={`debtors.${index}.account`}
                  normalize={"onlyNumbers"}
                  placeholder="99999999"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12}>
                <FieldText
                  key={"debtors-originaldue`}-" + index}
                  label="Adeudo original"
                  name={`debtors.${index}.originalDue`}
                  normalize={"onlyNumbers"}
                  placeholder="5000"
                />
              </Col>
              <Col lg={6} md={6} sm={12}>
                <FieldText
                  key={"debtors-currentdue`}-" + index}
                  label="Adeudo a la fecha"
                  name={`debtors.${index}.currentDue`}
                  normalize={"onlyNumbers"}
                  placeholder="10000"
                />
              </Col>
            </Row>
            {index === values.debtors.length - 1 && (
              <Row className="d-flex justify-content-center mt-3">
                <ColorButton
                  startIcon={<Add />}
                  onClick={() => handleAdd("debtors", data)}
                >
                  Añadir deuda
                </ColorButton>
              </Row>
            )}
          </BoxDashed>
        ))}
    </>
  );
};

const Subtitle = ({ subtitle, question, type, setFieldValue, values }) => {
  return (
    <>
      <Row className="justify-content-start">
        <SubtitleForm subtitle={subtitle} className="my-5" />
      </Row>
      <Row className="align-items-center p-0 m-0">
        <Col lg={6} md={6} sm={12}>
          <p className="mr-5">{question}</p>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <RadioGroup
            row
            name={`toShow.${type}`}
            value={values.toShow[type] ? "yes" : "no"}
            onChange={(e) =>
              setFieldValue(`toShow.${type}`, e.target.value == "yes")
            }
          >
            <FormControlLabel
              value="yes"
              labelPlacement="start"
              label="Sí"
              control={<ColorRadio />}
            />
            <FormControlLabel
              value="no"
              labelPlacement="start"
              label="No"
              control={<ColorRadio />}
            />
          </RadioGroup>
        </Col>
      </Row>
    </>
  );
};

const BoxDashed = ({ index, type, name, handleDelete, children, values }) => {
  return (
    <div
      className="p-5 my-3"
      key={`div-${type}-${index}`}
      style={{ border: "1px dashed var(--segundo-color)" }}
    >
      <Row className="justify-content-between align-items-center">
        <SubtitleForm
          subtitle={`${name} ${index + 1}`}
          key={`subtitleform-art-${index}`}
        />
        <DeleteButton
          handleDelete={handleDelete}
          index={index}
          type={type}
          values={values}
        />
      </Row>
      {children}
    </div>
  );
};

const DeleteButton = ({ index, handleDelete, type, values }) => {
  const length = values[type].length;
  const show = length > 1;
  return (
    <>
      {show && (
        <IconButton onClick={() => handleDelete(index, type)}>
          <Delete color="error" />
        </IconButton>
      )}
    </>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    name: props.initialValues.name || "",
    nationality: props.initialValues.nationality || "",
    birthDate: props.initialValues.birthDate || "",
    civilStatus: props.initialValues.civilStatus,
    spousesName: props.initialValues.spousesName || "",
    realEstates: props.initialValues.realEstates,
    stocks: props.initialValues.stocks,
    art: props.initialValues.art,
    vehicles: props.initialValues.vehicles,
    debtors: props.initialValues.debtors,
    toShow: props.initialValues.toShow,
  }),
  // validateOnMount: true,
  // validate: ValidateDataConfirm,
  enableReinitialize: true,
  handleSubmit: (values, { props }) => {
    values.birthDate = values.birthDate.split("-").reverse().join("/");
    props.handleSubmit(values);
  },
})(AssetSituationForm);
