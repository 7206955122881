import { useEffect, useState } from "react";
import Loading from "./Loading";
import ConfirmDataForm from "../../forms/ConfirmDataForm";
import Axios from "../../utils/axios";
import Swal from "sweetalert2";

const DataConfirm = ({
  availableSteps,
  id,
  incode,
  onError,
  onSuccess,
  session,
  setAvailableSteps,
  setStep,
  reqCsf,
}) => {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [interviewData, setInterviewData] = useState({});

  const handleSuccess = (data) => {
    data.interviewData = interviewData;
    localStorage.removeItem("attempts");
    onSuccess(data, "dataconfirm");
  };

  const fetchDataInterview = async () => {
    try {
      const attempts = parseInt(localStorage.getItem("attempts")) || 0;
      if (attempts >= 1) reqCsf = false;
      else localStorage.setItem("attempts", attempts + 1);
      if (session) {
        const response = await Axios.get("/incode/getMember", {
          params: { id },
        });
        if (!response.data.ok) {
          throw new Error("Miembro no encontrado.");
        }
        const member = response.data.member;
        const dataInterview = await incode.getInterviewData(session.token);
        const rfc = dataInterview.additionalDocumentAttempts?.findLast(
          (el) => el.documentType === "MEXICAN_TAX_STATUS"
        );

        if (!dataInterview.curpValidationResponse?.success)
          return setStep("curp");
        if (reqCsf && rfc?.rfcNumber?.length === 12) {
          return Swal.fire({
            title: "¡Ups!",
            html: "<p>Detectamos que la constancia que subiste es de una persona moral. Por favor, sube <b>tu</b> constancia de persona física.</p>",
            icon: "warning",
            confirmButtonText: "Entendido",
          }).then(() => {
            setAvailableSteps((prevSteps) => ["dataconfirm", ...prevSteps]);
            setStep("csf");
          });
        }
        let address = {};
        switch (member.selectedAddress) {
          case "dni":
            address = {
              street: dataInterview?.street,
              exteriorNumber: "",
              interiorNumber: "",
              colony: dataInterview?.colony,
              city: dataInterview?.city,
              postalCode: dataInterview?.postalCode,
              state: dataInterview?.state,
            };
            break;

          case "addressStatement":
            let toExtract = dataInterview.additionalDocumentAttempts?.filter(
              (el) => el.documentType === "POA"
            );
            toExtract = toExtract.slice(-1)[0];
            address = {
              street: toExtract?.addressComponents?.street,
              exteriorNumber: "",
              interiorNumber: "",
              colony: toExtract?.addressComponents?.colony,
              city: toExtract?.addressComponents?.city,
              postalCode: toExtract?.addressComponents?.postalCode,
              state: toExtract?.addressComponents?.state,
            };
            break;

          default:
            address = {
              street: rfc?.addressComponents?.street,
              colony: rfc?.addressComponents?.colony,
              exteriorNumber: "",
              interiorNumber: "",
              city: rfc?.addressComponents?.city,
              postalCode: rfc?.addressComponents?.postalCode,
              state: rfc?.addressComponents?.state,
            };
            break;
        }

        let rfcPerson = !reqCsf
          ? dataInterview?.curpValidationResponse?.curp?.slice(0, 10)
          : rfc?.rfcNumber?.length !== 13
          ? dataInterview?.curpValidationResponse?.curp?.slice(0, 10)
          : rfc?.rfcNumber;

        const initial = {
          name: dataInterview?.curpValidationResponse?.names,
          paternal_surname:
            dataInterview.curpValidationResponse.paternal_surname,
          mothers_maiden_name:
            dataInterview.curpValidationResponse.mothers_maiden_name,
          birthDate: dataInterview?.curpValidationResponse?.birthdate
            ?.split("/")
            .reverse()
            .join("-"),
          email: member?.email,
          phone: member?.phone,
          civilStatus: member?.civilStatus,
          curp: dataInterview?.curpValidationResponse?.curp,
          rfcPerson,
          street: address?.street,
          exteriorNumber: address?.exteriorNumber,
          colony: "",
          city: address?.city,
          postalCode: address?.postalCode,
          state: address?.state,
        };
        setInitialValues(initial);
        setInterviewData(dataInterview);
        setLoading(false);
      } else {
        throw new Error("No se encontró sesión activa.");
      }
    } catch (error) {
      console.log(error);
      onError(error, "dataconfirm");
      return null;
    }
  };

  useEffect(() => {
    if (reqCsf) {
      setTimeout(() => {
        fetchDataInterview();
      }, 7500);
    } else {
      setLoading(false);
    }
  }, [session]);

  if (loading) return <Loading />;
  return (
    <ConfirmDataForm
      availableSteps={availableSteps}
      initialValues={initialValues}
      handleSuccess={handleSuccess}
      setAvailableSteps={setAvailableSteps}
    />
  );
};

export default DataConfirm;
