import React, { lazy, Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { whatIsClient, whatIsClientSync } from "./utils/whatIsClient.js";
import FinsusIcon from "./assets/img/finsus-favicon.webp";
import ModalComponent from "./components/ModalTemp/ModalTemp";
import { Grid } from "@material-ui/core";
import { Button } from "react-bootstrap";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import { get } from "jquery";

const Text = () => {
  const [solicitud, setSolicitud] = useState(false);
  const [id, setId] = useState("");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      {/* <h1>hola</h1> */}
      <p
        style={{
          // textAlign: "center",
          fontSize: "1.2rem",
          fontFamily: "Metropolis-Regular",
          padding: "20px 20px 0px 20px",
        }}
      >
        <p>
          fue una gran aventura hacer esta plataforma diseñada para tu empresa o
          negocio, esperamos que hayas disfrutado este viaje tanto como
          nosotros.
          <br />
        </p>
        <p>
          llegó el momento de decir adiós,<strong> distrito pyme </strong>ahora
          es <strong>Finsus Crédito Digital</strong>, y podrás seguir obteniendo
          ese crédito que tanto necesitas dando clic en el botón de abajo, te
          llevaremos a la mejor experiencia de solicitud de crédito.
          <br />
        </p>
        juntos seguimos siendo la #comunidaddecrédito más grande de México.
        <br />
        <br />
        <p>¡gracias por ser parte de dp!</p>
        <br />
        <small style={{ fontSize: "0.8rem" }}>
          <strong>
            este sitio estará disponible hasta el 30 de Noviembre de 2023.
          </strong>
        </small>
      </p>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={solicitud ? 4 : 6}
          md={solicitud ? 4 : 6}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            className="text-center fz16 nav-btn nav-btn-rad primary heigth-95 w-360 ml-auto mr-auto nav-link"
            href="https://creditodigital.finsus.mx/"
          >
            Ir a Finsus
          </Button>
        </Grid>
        {solicitud && id !== "" && (
          <Grid
            item
            xs={12}
            sm={solicitud ? 4 : 6}
            md={solicitud ? 4 : 6}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              className="text-center fz16 nav-btn nav-btn-rad primary heigth-95 w-360 ml-auto mr-auto nav-link"
              href={`https://creditodigital.finsus.mx/solicitud/${id}`}
            >
              Ir a mi solicitud
            </Button>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={solicitud ? 4 : 6}
          md={solicitud ? 4 : 6}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            className="text-center fz16 nav-btn nav-btn-rad primary heigth-95 w-360 ml-auto mr-auto nav-link"
            href="mailto:contacto@distritopyme.com"
          >
            Contáctanos
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export const ThemeSelector = ({ children }) => {
  const [client, setClient] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [url, setUrl] = React.useState("");

  useEffect(() => {
    const theme = sessionStorage.getItem("theme");
    if (theme) {
      setClient(theme);
    } else {
      whatIsClient().then((res) => {
        setClient(res);
        sessionStorage.setItem("theme", res);
      });
    }
  }, []);

  useEffect(() => {

    const baseUrls = [
      "https://distritopyme.com/",
      "https://distritopyme.com/",
      "distritopyme.com/",
      "https://www.distritopyme.com/",
      "red.distritopyme.com/",
      "http://localhost:3000/",
      "http://localhost:3001/",
    ];

    const OnbordingUrls = [
      "http://localhost:3002/",
    ];

    const initialUrl = window.location.href;

    if(initialUrl.includes("dev")){
      setUrl("dev");
      return;
    }

    let urlFind = baseUrls.find((baseUrl) => initialUrl.includes(baseUrl));
    let urlFindOnbording = OnbordingUrls.find((baseUrl) =>
      initialUrl.includes(baseUrl)
    );

    if(process.env.REACT_APP_CONFIGURATION !== 'production'){
      // console.log("development", urlFind);
    }

    if (urlFind !== undefined && urlFind !== null) {
      setUrl("dev");
    } else if (urlFindOnbording !== undefined && urlFindOnbording !== null) {
      setUrl("onbording");
    } else {
      setUrl("");
    }
  }, []);

  useEffect(() => {
    if (url === "onbording") {
      sessionStorage.setItem("flujo", "onbording");
    } else if (url !== "" && url !== "https://creditodigital.finsus.mx/" && url !== "dev" ) {
      setOpen(true);
    } 
  }, [url]);

  useEffect(() => {
    if (client) {
      if (client === "distrito pyme") {
        setClient("pyme");
        changeTitle("DistritoPyme");
      } else {
        changeTitle(client);
      }
    }
  }, [client]);

  const Styles = client
    ? lazy(() => import(`./assets/fonts/${client}.js`))
    : null;
  return (
    <>
      <ModalComponent open={open} setOpen={setOpen} children={<Text />} />
      <Suspense fallback={<div>Loading...</div>}>
        {Styles ? <Styles /> : null}
      </Suspense>
      {children}
    </>
  );
};

function changeTitle(caseTheme) {
  switch (caseTheme) {
    case "finsus":
      document.title =
        "Financiera Sustentable. FINSUS App - Piensa Digital, Piensa FINSUS";
      // document.getElementById("favicon").href = FinsusIcon;
      break;
    case "impulsomx":
      document.title = "ImpulsoMX";
      break;
    default:
      document.title = "DistritoPyme";
      break;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeSelector>
      <App />
    </ThemeSelector>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
