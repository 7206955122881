import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { validateCiec } from "../components/Validate/validateCiec";
import { Form, withFormik } from "formik";
import {
  FieldText,
  FieldPassword,
  FieldCheck,
} from "../components/Generic/Fields";
import { Row, Col, Button } from "react-bootstrap";
import useThemeData from "../utils/hooks/useThemeData";
import Axios from "../utils/axios";
import { ButtonSubmit } from "../components/Generic/ButtonSubmit";
import "../css/ciec.css";
import { Link } from "@material-ui/core";
import SubtitleForm from "../components/Generic/SubtitleForm";
import { useHistory } from "react-router";
import { updateLoader } from "../redux/actions/loaderActions";
import Loader from "../components/Loader/Loader";

const Terminos = () => {
  return (
    <span>
      Acepto los{" "}
      <Link
        href="https://distrito-pyme-documents-dev.s3.us-west-2.amazonaws.com/1695935778182-TyC_DistritoPyme%20sept%202023%20Finsus%20credito%20digital.pdf"
        target="_blank"
      >
        Términos y Condiciones{" "}
      </Link>
      y Cláusula de medios electrónicos tales como CIEC
    </span>
  );
};

let CiecFormAuth = (props) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    valid,
    ciecOrigen,
    isValid,
    initialValues,
    isSubmitting,
    values,
    setFieldTouched,
    setFieldError,
    setFieldValue,
    handleReset,
  } = props;

  const [error, setError] = useState(true);
  const [date, setDate] = useState("");
  const [razonSocial, setRazonSocial] = useState("");
  const history = useHistory();

  const verifyAppliance = (array) => {
    if (typeof array !== "object") return false;
    return array.length === 0 ? false : array[array.length - 1];
  };

  const verify = (object, property) => {
    return object.hasOwnProperty(property);
  };

  useEffect(() => {
    if (ciecOrigen !== undefined && ciecOrigen !== "") {
      console.log(ciecOrigen);
      handleReset();
    }
  }, [ciecOrigen]);

  useEffect(() => {
    if (!error) {
      const UNIX = new Date().getTime();
      setDate(UNIX);
    }
  }, [error]);

  useEffect(() => {
    if (date !== "") {
      // console.log(date);
      setFieldValue("date", date);
    }
  }, [date]);

  useEffect(() => {
    const { tyc, date } = values;
    
    if (tyc && date === '') {
      const utcUNIX = new Date().getTime();
      const utcUNIX2 = utcUNIX.toString();

      setFieldValue("date", utcUNIX2);
    }

    if (!tyc && date !== '') {
      setFieldValue("date", '');
    }
  }, [values]);

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (!user) return;
    const idClient = user.idClient;

    const appliance = verifyAppliance(idClient.appliance);
    const idFiscal = verify(appliance, "idFiscal");
    const idComercialInfo = verify(appliance, "idComercialInfo");

    if (!idFiscal) {
      history.push(`/ciec/${user._id}`);
      return;
    }

    const fiscalInfo = appliance.idFiscal;
    const comercialInfo = appliance.idComercialInfo || null;

    if (!fiscalInfo) {
      dispatch(updateLoader(false));
      history.push(`/ciec/${user._id}`);
      return;
    }

    const { rfcMoral, buroMoral } = fiscalInfo;

    try {
      const fetchData = async () => {
        await Axios
          .post(`/api/razon/${user._id}`, {
            rfc: rfcMoral,
          })
          .then((result) => {
            if (result.data.code && result.data.code === 2) {
              dispatch(updateLoader(false));
              history.push(`/documentos/${user._id}`);
              return;
            }
            setRazonSocial(result.data.razon.razonSocial || "");
            console.log(result.data.razon);
          })
          .catch((error) => {
            setRazonSocial(comercialInfo.comercialName);
            console.log(error.response || error);
          })
          .finally(() => {
            dispatch(updateLoader(false));
          });
      };
      fetchData();
    } catch (error) {
      console.log(error);
      setRazonSocial(comercialInfo.comercialName || "");
      setError(true);
      dispatch(updateLoader(false));
    }
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const ShowHide = () => setShowPassword((visible) => !visible);

  const { theme, themeData } = useThemeData();

  return initialValues.rfc === undefined ? (
    <div className="altForm"></div>
  ) : (
    <Form autoComplete="off" id="ciecForm">
      <SubtitleForm subtitle="Autorización para Consulta de Historial Crediticio" />
      <div className="mt-4" style={{ maxWidth: "85%", margin: "auto", border: "1px solid #ccc", borderRadius: "5px", padding: "1%" }}>
        <FieldCheck
          type="checkbox"
          label={<Terminos />}
          name="tyc"
          big={true}
        />
      </div>

      <div
        className="text-center p-3 text-dp fz16 fw500 ml-auto mt-2 mb-1"
        style={{ maxWidth: "85%", margin: "auto" }}
      >
        <p>
          Por este conducto autorizo expresamente a{" "}
          <span className="title-dp">
            Financiera Sustentable de México S.A. de C.V., S.F.P.
          </span>{" "}
          , para que por conducto de sus funcionarios facultados lleve a
          cabo Investigaciones, sobre mi comportamiento crediticio en las
          sociedades de informacion crediticia que estime conveniente.
        </p>

        <p>
          Así mismo, declaro que conozco la naturaleza y alcance de la
          información que se solicitará, del uso que{" "}
          <span className="title-dp">
            Financiera Sustentable de México S.A. de C.V., S.F.P.
          </span>{" "}
          hará de tal información y de que ésta podrá realizar consultas
          periódicas de mi historial crediticio, consintiendo que esta
          autorización se encuentre vigente por un período de 3 años contandos a
          partir de la fecha de su expedición y en todo caso durante el tiempo
          que mantengamos relación jurídica.
        </p>

        <p>
          Acepto que este documento quede bajo custodia de{" "}
          <span className="title-dp">
            Financiera Sustentable de México S.A. de C.V., S.F.P.
          </span>{" "}
          para efectos de control y cumplimiento del artículo 28 de la LRSIC
        </p>
      </div>
      <Row className="d-flex justify-content-center">
        <Col
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            padding: "2% 1%",
          }}
          md={6}
          xs={12}
        >
          <div className="altForm">
            <div className="text-center">
              <p className="title-dp">
                {razonSocial !== "" ? razonSocial : ""}
                </p>
            </div>
            <div
              className="container mr-auto ml-auto"
              style={{ maxWidth: "690px" }}
            >
              <FieldText
                name="rfc"
                placeholder="RFC de tu empresa o negocio"
                normalize="OnlyLirycsNumberstoUpper"
                minLength="12"
                maxLength="13"
                disabled={true}
                center={true}
                csty=" text-center"
              />
              <FieldPassword
                name="ciec"
                placeholder="CIEC"
                minLength="8"
                maxLength="8"
                setShowPassword={ShowHide}
                showPassword={showPassword}
                style={{
                  textAlign: "center",
                }}
              />
            </div>
            <div className="text-center d-flex justify-content-center align-items-center flex-column">
              <Row className=" flex-column">
                <Col lg={6} xs={6}>
                  <ButtonSubmit
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    id={"Aceptar_consulta_de_buró"}
                    onClick={async () => {
                      await Object.keys(props.values).forEach((field) =>
                        props.setFieldTouched(field, true)
                      );
                    }}
                  />
                </Col>
              </Row>
              {/* //TODO: Uncomment cuando se tenga el servicio de creditos personales */}
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    rfc: props.initialValues.rfc || "",
    ciec: props.initialValues.ciec || "",
    ciecOrigen: "",
    checkCiec: true,
    date: "",
    tyc: false,
  }),
  validate: validateCiec,
  enableReinitialize: true,
  handleSubmit(values, formikBag) {
    formikBag.setSubmitting(false);
    formikBag.props.handleSubmit(values);
  },
  displayName: "CiecFormAuth",
})(CiecFormAuth);
