import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const Success = ({ onSuccess, incomplete, source }) => {
  const sourceAppliance = source === "appliance";
  const history = useHistory();

  useEffect(() => {
    if (sourceAppliance) {
      const user = JSON.parse(sessionStorage.getItem("user"));
      history.push({
        pathname: `/auth/${user._id}`,
      });
    } else if (!incomplete) {
      localStorage.clear();
      onSuccess(null, "success");
    }
  }, []);

  return (
    <div
      className="container d-flex text-center justify-content-center align-items-center"
      style={{ height: "75vh" }}
    >
      {!incomplete ? (
        <div>
          <h1 className="mb-3">¡Gracias por ser parte de Finsus!</h1>
          <h3>El proceso ha terminado.</h3>
          <h3>Pronto recibirás noticias nuestras.</h3>
        </div>
      ) : (
        <div>
          <h1 className="mb-3">¡Ya casi!</h1>
          <h3>Recuerda volver pronto para completar el proceso.</h3>
        </div>
      )}
    </div>
  );
};

export default Success;
