import { Checkbox, withStyles } from "@material-ui/core";

const ColorCheckbox = withStyles((theme) => ({
  root: {
    "color": "#FF5F54",
    "&:hover": {
      backgroundColor: "#ff5f544d",
    },
  },
  colorSecondary: {
    "&.Mui-checked": {
      color: "#FF5F54",
    },
  },
}))(Checkbox);

export default ColorCheckbox;
