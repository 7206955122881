import React from "react";
import "../../css/progress-tracker.css";
import "../../css/custom-progress.css";
import { Link } from "react-router-dom";

const StepSignup = (props) => {

  const first = "";
  const second = "";
  const third = "";
  const fourth = "";

  return (
    <div className="step-container">
      <ul className="progress-tracker progress-tracker--text progress-tracker--center progress-tracker--spaced">
        <li className="progress-step is-complete">
          <span className="progress-marker custom"></span>
          <span className="progress-text">
            <h5 className="step-text-muestra  metropolisMed fw500 step-text">
              registro
            </h5>
          </span>
        </li>

        <li className={second ? `progress-step ` : `progress-step`}>
          {second !== undefined && second !== null && second !== "" ? (
            <Link className="form-link">
              <span className="progress-marker custom">
                <span className="imagen3" style={{ width: "200px" }}></span>
              </span>
            </Link>
          ) : (
            <span className="progress-marker custom"></span>
          )}
          <span className="progress-text">
            <h5
              className="progress-title metropolisMed fw500 step-text"
              style={{ color: "#4f4f4f" }}
            >
              {second !== undefined && second !== null && second !== "" ? (
                <Link className="form-link step-text-complete">
                  datos del negocio
                </Link>
              ) : (
                <label className="form-link step-text">datos del negocio</label>
              )}
            </h5>
          </span>
        </li>

        <li className={props.third ? `progress-step ` : "progress-step"}>
          {third !== undefined && third !== null && third !== "" ? (
            <Link className="form-link">
              <span className="progress-marker custom ">
                <span className="imagen4" style={{ width: "200px" }}></span>
              </span>
            </Link>
          ) : (
            <span className="progress-marker custom "></span>
          )}
          <span className="progress-text">
            <h5
              className="progress-title metropolisMed fw500 step-text"
              style={{ color: "#4f4f4f" }}
            >
              {third !== undefined && third !== null && third !== "" ? (
                <Link className="form-link step-text-complete">
                  datos generales
                </Link>
              ) : (
                <label className="form-link step-text">datos generales</label>
              )}
            </h5>
          </span>
        </li>

        <li className={fourth ? `progress-step ` : "progress-step"}>
          {fourth !== undefined && fourth !== null && fourth !== "" ? (
            <Link className="form-link">
              <span className="progress-marker custom">
                <span className="imagen5" style={{ width: "200px" }}></span>
              </span>
            </Link>
          ) : (
            <span className="progress-marker custom"></span>
          )}
          <span className="progress-text">
            <h5
              className="progress-title metropolisMed fw500"
              style={{ color: "#4f4f4f" }}
            >
              {fourth !== undefined && fourth !== null && fourth !== "" ? (
                <Link className="form-link step-text-complete">documentos</Link>
              ) : (
                <label className="form-link step-text">documentos</label>
              )}
            </h5>
          </span>
        </li>
      </ul>
      <br />
    </div>
  );
};

export default StepSignup;
