import React, { useEffect } from "react";
import Title from "../Generic/Title";
import "../../css/signup.css";
import registerImage from "../../assets/img/enviado_chava-01.webp";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";

let ThankyouPage = () => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mt-30 registro-exitoso">
      {/* <Loader /> */}
      <img src={registerImage} alt="registerimage" style={{ width: "250px" }} />
      <div
        className=" fz32 mt-4 fw500 text-center"
        style={{ color: "var(--navy)", fontFamily: "coolvetica_rg" }}
      >
        <p style={{ lineHeight: "1" }}>
          ¡mantente atento a tu correo!
          <p
            className="text-center fz22  pt-0 p-2"
            style={{ color: "000", fontFamily: "Metropolis-Regular" }}
          >
            En las próximas horas recibirás tu contrato para firma.
          </p>
        </p>
      </div>
      <div>
        <Button
          className={"btn-coral-general mb-5 pl-5 pr-5"}
          onClick={() => history.push("/")}
        >
          cerrar
        </Button>
      </div>
    </div>
  );
};

export default ThankyouPage;
