import React from 'react';
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import useKissmetrics from '../../utils/hooks/useKissmetrics';

const LinkButton = props => {
	const logParams = useKissmetrics();

	const handleClick = () => {
		logParams("record", "Completar Solicitud", { 'Página': props.link });
	}

	return(
		<div className="mt-45">
			<Link to={`/${props.link}`}>
				<div className="mt-45">
					<Button className="btn-blue-general fz21" style={{ width: '250px' }} id='Completar_Solicitud​' onClick={handleClick}>
						Completar
					</Button>
				</div>
			</Link>
		</div>
	)
}

export default LinkButton 