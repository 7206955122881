import React, { useEffect, useRef, useState } from "react";
import { Cloudinary } from "@cloudinary/url-gen";
import { AdvancedImage, lazyload, placeholder } from "@cloudinary/react";
import { thumbnail } from "@cloudinary/url-gen/actions/resize";

export const Banner = ({ baners = "", children }) => {
  const cld = new Cloudinary({ cloud: { cloudName: "db90e5e8s" } });
  const [banner, setBanner] = useState("");
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const divRef = useRef(null);

  useEffect(() => {
    if (divRef.current) {
      setDimensions({
        width: divRef.current.offsetWidth,
        height: divRef.current.offsetHeight
      });
    }
  }, [divRef.current]);

  useEffect(() => {
    if (baners) {
      const { width, height } = dimensions;
      setBanner(cld.image(baners).toURL());

    }
  }, [baners, dimensions]);

  return (
    <div
      ref={divRef}
      className="d-flex justify-content-center"
      style={{
        maxWidth: "100%",
        position: "relative",
        height: "65vh",
        backgroundImage: `linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.4) 3%, rgba(255,255,255,0.2) 6%, rgba(255,255,255,0) 10%), url(${banner})`,
        backgroundSize: "cover",
        backgroundPosition: "calc(50%) calc(10%)",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* <AdvancedImage cldImg={cld.image(banner)} alt="banner" style={{ maxWidth: "100%", backgorundColor: "000000ab" }} plugins={[lazyload()]} /> */}
      {children}
    </div>
  );
};
