import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Loader from "../Loader/Loader";
import { updateLoader } from "../../redux/actions/loaderActions";
import CiecForm from "../../forms/ciecForm";
import Axios from "../../utils/axios";
import Swal from "sweetalert2";
import { RFCValido } from "../Validate/validateRFC";
import useSnack from "../../utils/hooks/useSnack";
import "../../css/ciec.css";
import { set } from "lodash";
import { Video, ModalComponent } from "./Video";
import { updateUserData } from "../../redux/actions/authActions";
import useAxios from "../../utils/hooks/useStatus";
import useKissmetrics from "../../utils/hooks/useKissmetrics";
import { useLocation } from 'react-router-dom';

const getSize = () => {
  const currentSize = document.getElementsByTagName("body")[0].clientWidth;
  return currentSize < 775 ? 1 : 0;
};

const CiecWait = () => {
  const [version, setVersion] = useState(getSize());
  const [wait, setWait] = useState(false);
  const [open, setOpen] = useState(false);
  const [ciec, setCiec] = useState(true);
  const [response, setResponse] = useState({});
  const [valid, setValid] = useState(false);
  const [show, setShow] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [documentos, setDocumentos] = useState(false);

  const { isLoading, msg } = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  let history = useHistory();
  const logParams = useKissmetrics();
  const location = useLocation();

  const { notify } = useSnack();
  let $user = JSON.parse(sessionStorage.getItem("user"));
  const cargando  = useAxios($user._id);
  useEffect(() => {
    logParams("record", "Visita", { page: "CIEC" });
    const user = sessionStorage.getItem("user");
    if (!user) {
      history.push("/");
    }
    let rfc = JSON.parse(user).rfc;
    const id = JSON.parse(user)._id;

    if (!rfc) {
      if (JSON.parse(user).idClient.appliance === undefined) {
        rfc = false;
      } else {
        let comercio = JSON.parse(user).idClient.appliance[0]
          ? JSON.parse(user).idClient.appliance[0].idComercialInfo
          : false;
        if (!comercio) {
          rfc = "";
        }
        rfc = comercio.rfc;
      }
      if (!rfc) {
        rfc = "";
      }
    }

    setInitialValues({ rfc, id });
  }, []);

  useEffect(() => {
    let { state, search } = location;
    if (state) {
      setDocumentos(true);
    } 
    
  }, [location]);

  let CiecStatus = (st) => {
    if (st === true) {
      setCiec(st);
      return;
    }
    Swal.fire({
      title: "¿estás seguro?",
      text: "sin la CIEC las opciones de financiamiento se reducen considerablemente, pero buscaremos una opción de crédito personal, que puede ser por un monto pequeño, o que requerirá de una garantia inmobiliaria",
      icon: "warning",
      customClass: {
        title: "title-dp fz42",
        popup: "text-dp fz20",
        confirmButton: "btn-blue-general btn-gray-general btn btn-primary CIEC_exitosa",
        cancelButton: "btn-blue-general btn btn-primary",
      },
      confirmButtonText: "aceptar",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    })
      .then((result) => {
        if (result.isConfirmed) {
          setCiec(st);
        } else {
          setCiec(!st);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoader(false));
      });
  };

  const defaultCase = "Error en el servidor";

  const cases = {
    200: {
      title: "¡listo!",
      text: "gracias por tu paciencia, conserva tu ciec a la mano ya que más adelante la requerirás para consultar tu historial crediticio, ahora puedes continuar con tu solicitud",
      icon: "success",
    },
    400: {
      title: "¡error!",
      text: "No encontramos tu usuario, intenta más tarde",
      icon: "error",
    },
    404: {
      title: "¡error!",
      text: "el RFC o la CIEC no son válidos, por favor verifica tus datos",
      icon: "error",
    },
    405: {
      title: "Servicio no disponible",
      text: "Tenemos problemas con el servicio de consulta del SAT, por favor intenta más tarde",
      icon: "warning",

    },
    429: {
      title: "¡error!",
      text: "lo lamentamos, pero ya rebasaste el límite de solicitudes diarias para este RFC, por lo mientras revisa tu información e intenta más tarde",
      icon: "error",
    },
    500: {
      title: "¡error!",
      text: "lo lamentamos, pero no pudimos procesar tu solicitud, por favor intenta más tarde",
      icon: "error",
    },
  };

  const onSubmit = async (values) => {
    dispatch(
      updateLoader(
        true,
        "Estamos procesando tu información y en breve te daremos una respuesta"
      )
    );
    logParams("record", "Continuar CIEC", { rfc: values.rfc });
    let CiecStatus = ciec;
    let user = JSON.parse(sessionStorage.getItem("user"));
    let id = user._id;
    let documentosData = documentos;
    values = { ...values, CiecStatus, documentosData };
    await Axios.post(`/api/ciec/${id}`, values)
      .then((res) => {
        if (res.status === 200) {
          // console.log(res);
          setShow(true);
          const user = JSON.parse(sessionStorage.getItem("user"));
          sessionStorage.setItem("user", JSON.stringify(res.data.user));
          dispatch(updateUserData(res.data.user));
          let message = cases[res.status] || defaultCase;
          logParams("record", "CIEC Exitosa", { rfc: values.rfc });
          Swal.fire({
            title: message.title,
            text: message.text,
            icon: message.icon,
            customClass: {
              title: "title-dp fz42",
              popup: "text-dp fz20",
              confirmButton: "btn-blue-general btn btn-primary p-0",
            },
            confirmButtonText: "aceptar",
          })
            .then((result) => {
              if (result.isConfirmed) {
                dispatch(updateLoader(false));
                setShow(false);

                window.location.replace(`/informacion-comercial/${user._id}`);
              }
            })
            .catch((err) => {
              console.log(err);
              dispatch(updateLoader(false));
            });
        }
      })
      .catch((err) => {
        console.log(err || err.response);
        let msg = cases[err.response?.status] || defaultCase;
        Swal.fire({
          title: msg.title,
          text: msg.text,
          icon: msg.icon,
          customClass: {
            title: "subtitle form fz42",
            popup: "text-dp fz20",
            confirmButton: "btn-blue-general btn btn-primary",
          },
          confirmButtonText: "aceptar",
        });

        dispatch(updateLoader(false));
      });
  };

  useEffect(() => {
    window.addEventListener("resize", () => setVersion(getSize()));
    return () => {
      window.removeEventListener("resize", () => setVersion(getSize()));
    };
  }, []);

  useEffect(() => {
    if (show) {
      notify("ciec");
      setShow(false);
    }
  }, [show]);

  const OpenModal = () => {
    setOpen(true);
  };

  function changeValue(e) {
    let value = e.target.value;
    let name = e.target.name;
    setValid(RFCValido(value));
    setInitialValues({ ...initialValues, [name]: value });
  }

  function changeValid(value) {
    setValid(RFCValido(value));
    // console.log(RFCValido(value));
  }

  function changeOpen(value) {
    setOpen(value);
  }

  function handleDocumentos() {
    history.push(location.state?.from);
  }

  return (
    <div>
      {isLoading && <Loader />}
      {wait && <h1>Espera un momento...</h1>}
      {!wait && (
        <Row style={{ maxWidth: "100%", margin: "auto", padding: "0 2%" }}>

            <CiecForm
              handleSubmit={onSubmit}
              initialValues={initialValues}
              changeValue={changeValue}
              valid={valid}
              popup={true}
              video={true}
              version={version}
              openModal={OpenModal}
              CiecStatus={CiecStatus}
              changeValid={changeValid}
              open={open}
              changeOpen={changeOpen}
              ciec={ciec}
              documentos={documentos}
              handleDocumentos={handleDocumentos}
            ></CiecForm>
          
        </Row>
      )}
    </div>
  );
};

export default CiecWait;


