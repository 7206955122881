import React, { useState, useEffect, useRef } from "react";
import { reduxForm, FieldArray, getFormValues } from "redux-form";
import { Button } from "react-bootstrap";

import helper from "../models/DateModels";
import { validateGeneralInfo } from "../components/Validate/ValidateGeneralInfo";
import { createNumberMask } from "redux-form-input-masks";
import { RenderEvents } from "./RenderEvents";
import Swal from "sweetalert2";

export const porcentajeMask = createNumberMask({
  suffix: "%",
  prefix: "",
  decimalPlaces: 2,
  locale: "en-US",
});

let GeneralInfoForm = ({
  handleSubmit,
  valid,
  changeAddress,
  initialValues,
  setInitialValues,
  positionRef,
  getRFCPerson,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [currentDate, setCurrentDate] = useState("");
  const [porcentaje, setPorcentaje] = useState(0);
  const [porcentajeError, setPorcentajeError] = useState(true);

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const idClient = user.idClient;
    const type = idClient.type;

    if (type === "PM") {
      Array.isArray(initialValues.members) &&
        setPorcentaje(
          initialValues.members.reduce(
            (acc, cur) => acc + Number(cur?.percentage || 0),
            0
          )
        );
    } else {
      setPorcentaje(100);
    }
  }, [initialValues]);

  useEffect(() => {
    if (porcentaje > 100) {
      setPorcentajeError(true);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "La suma de los porcentajes no puede ser mayor a 100",
      });
    } else if (porcentaje < 50) {
      setPorcentajeError(true);
    } else {
      setPorcentajeError(false);
    }

    if(initialValues.member && initialValues.members.length !== 0 && porcentaje !== 0){

    setInitialValues((I) => ({
      ...I,
      porcentaje: porcentaje,
    }));
    }
  }, [porcentaje]);

  /**Intentar pasar la direccion por aqui y/o buscar por que no se pasa */
  //let lastAddress = props.currentAddress;

  function getErros() {
    const elements = document.getElementsByClassName("error");
    const errors = [];
    for (let elemnet of elements) {
      if (elemnet.innerText !== "") {
        errors.push(elemnet);
      }
    }
    return errors;
  }

  function scrollToError() {
    const errors = getErros();

    if (errors.length > 0) {
      const before = errors[0].parentElement || errors[0].parentNode;
      const element = document.getElementById(before.id);
      const parent = element?.parentElement;
      parent?.scrollIntoView({ behavior: "smooth" });
    }
  }

  useEffect(() => {
    const errors = getErros();

    if (errors.length > 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [initialValues]);

  useEffect(() => {
    const date = new Date();
    const currentDate = date.toISOString().slice(0, 10);
    const dateArray = currentDate.split("-");
    const newDate = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
    // console.log(newDate);
    setCurrentDate(newDate);
  }, []);

  let dias = helper.diasMes(initialValues.month, initialValues.year);

  const onlyLirycs = (nextValue, previousValue) =>
    /^([a-zñáéíóúü\s]{0,60})$/i.test(nextValue) ? nextValue : previousValue;
  const onlyNumbers = (nextValue, previousValue) =>
    /^\d+$/.test(nextValue) || nextValue.length === 0
      ? nextValue
      : previousValue;
  const LirycsNumbersDotComa = (nextValue, previousValue) =>
    /^([a-z ñáéíóú0-9,.]{0,45})$/i.test(nextValue) ? nextValue : previousValue;
  const upper = (value) => value && value.toUpperCase();



  return (
    <div>
      <form
        className="ml-auto mr-auto"
        // style={{ maxWidth: "690px" }}
        onSubmit={handleSubmit}
      >
        <div className="mt-1 text-dp fw300 fz14 text-center mb-1">
          <p className="gray50">
            realiza el registro con la información del principal accionista y/o
            representante legal
          </p>
        </div>

        <FieldArray
          name={`members`}
          component={RenderEvents}
          props={{
            onlyLirycs,
            onlyNumbers,
            LirycsNumbersDotComa,
            upper,
            dias,
            initialValues,
            setInitialValues,
            setDisabled,
            disabled,
            porcentaje,
          }}
        />
        {porcentajeError && (
          <div className="text-center" style={{ marginTop: "30px" }}>
          <span id={"porcentaje-error"} className={porcentajeError ? "d-block" : "d-none"}>
            <small className={porcentajeError ? "error" : ""}>
              {porcentaje < 50 ? (
                <span>
                  <i className="fas fa-exclamation-circle"></i> Necesitas tener información de al menos el 50% de los accionistas
                </span>
              ) : porcentaje > 100 ? (
                <span>
                  <i className="fas fa-exclamation-circle"></i> La suma de los
                  porcentajes no puede ser mayor a 100
                </span>
              ) : (
                ""
              )}
            </small>
          </span>
          </div>
        )}
        <div className="text-center" style={{ marginBottom: "50px" }}>
          <Button
            type={(disabled || porcentajeError) ? "button" : "submit"}
            className={
              "mt-50 btn-blue-general" + ((disabled || porcentajeError) ? " btn-gray-general" : "")
            }
            style={{ width: "250px" }}
            // disabled={disabled || porcentajeError}
            onClick={scrollToError}
          >
            Continuar
          </Button>
        </div>
      </form>
    </div>
  );
};

GeneralInfoForm = reduxForm({
  form: "generalInfoForm", // a unique identifier for this form
  validate: validateGeneralInfo, // <--- validation function given to redux-form
  enableReinitialize: true,
})(GeneralInfoForm);

export default GeneralInfoForm;
