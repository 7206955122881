export const validateAmount = values => {
    let errors = {};

    if (!values.howMuch) {
      errors.howMuch = "Ingresa la cantidad que necesitas";
    } else if (values.howMuch < 50000) {
      errors.howMuch = "Ingresa una cantidad mayor a $50,000";
    } else if (values.howMuch >= 100000000) {
      errors.howMuch = "Ingresa una cantidad no maxima a $100,000,000";
    }
  
    if(!values.yearSales) {
        errors.yearSales = "Ingresa tus ventas anuales"
    }
  
    if (!values.whyNeed) {
      errors.whyNeed = "Elige para qué lo necesitas";
    }
  
    return errors;
  };
  