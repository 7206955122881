import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";

const CreditoCopia = () => {
  const [html, setHtml] = useState("https://finsus.mx/");
  const [loading, setLoading] = useState(true);

  return (
    <div
      width="100%"
      height="100%"
      style={{ padding: "100px 30px 100px 30px", backgroundColor: "#f2f2f2" }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={3} style={{ height: "100%" }}>
            <Grid item xs={12} sm={12} style={{ display: "flex", justifyContent: "end", flexDirection: "column" }}></Grid>
            <Grid item xs={12} sm={12} style={{ display: "flex", justifyContent: "end", flexDirection: "column" }}>
              <h1
                style={{
                  fontSize: "40px",
                  color: "#3f3d56",
                  textAlign: "left",
                }}
                className="metropolisLight"
              >
                ¿Aun no la tines? 
              </h1>
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#3f3d56",
                  textAlign: "left",
                }}
                className="metropolisLight"
              >
                ¡Descarga la app de Finsus y descubre todo lo que puedes hacer con un solo click!
              </p>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6}>

                <a
                  class="fusion-no-lightbox"
                  href="https://finsus.onelink.me/8KZ9/79runo2n"
                  target="_self"
                >
                  <img
                    decoding="async"
                    width="85%"
                    alt="Disponible en Google Play"
                    src="https://finsus.mx/wp-content/uploads/2023/08/android.png"
                    data-orig-src="https://finsus.mx/wp-content/uploads/2023/08/android.png"
                    class="img-responsive wp-image-5239 lazyautosizes ls-is-cached lazyloaded"
                    srcset="https://finsus.mx/wp-content/uploads/2023/08/android-200x59.png 200w, https://finsus.mx/wp-content/uploads/2023/08/android-400x119.png 400w, https://finsus.mx/wp-content/uploads/2023/08/android.png 600w"
                    data-srcset="https://finsus.mx/wp-content/uploads/2023/08/android-200x59.png 200w, https://finsus.mx/wp-content/uploads/2023/08/android-400x119.png 400w, https://finsus.mx/wp-content/uploads/2023/08/android.png 600w"
                    data-sizes="auto"
                    data-orig-sizes="(max-width: 1024px) 100vw, (max-width: 640px) 100vw, 400px"
                    sizes="180px"
                  />
                </a>
              </Grid>
              <Grid item xs={6} sm={6}>

                <a
                  class="fusion-no-lightbox"
                  href="https://finsus.onelink.me/8KZ9/rlo7pbyd"
                  target="_self"
                >
                  <img
                    decoding="async"
                    width="85%"
                    alt="Descarga en App Store"
                    src="https://finsus.mx/wp-content/uploads/2023/08/ios.png"
                    data-orig-src="https://finsus.mx/wp-content/uploads/2023/08/ios.png"
                    class="img-responsive wp-image-5240 lazyautosizes ls-is-cached lazyloaded"
                    srcset="https://finsus.mx/wp-content/uploads/2023/08/ios-200x60.png 200w, https://finsus.mx/wp-content/uploads/2023/08/ios-400x120.png 400w, https://finsus.mx/wp-content/uploads/2023/08/ios.png 600w"
                    data-srcset="https://finsus.mx/wp-content/uploads/2023/08/ios-200x60.png 200w, https://finsus.mx/wp-content/uploads/2023/08/ios-400x120.png 400w, https://finsus.mx/wp-content/uploads/2023/08/ios.png 600w"
                    data-sizes="auto"
                    data-orig-sizes="(max-width: 1024px) 100vw, (max-width: 640px) 100vw, 400px"
                    sizes="180px"
                  />
                </a>

              </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <img
            decoding="async"
            width="640"
            height="640"
            src="https://finsus.mx/wp-content/uploads/2023/09/Banner-home-1.gif"
            data-orig-src="https://finsus.mx/wp-content/uploads/2023/09/Banner-home-1.gif"
            alt=""
            class="img-responsive wp-image-6152 ls-is-cached lazyloaded"
          ></img>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreditoCopia;
