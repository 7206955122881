import { useEffect, useRef, useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { HeaderCard } from "./HeaderCard";
import { FileDrop } from "react-file-drop";
import { create } from "@incodetech/welcome";
import Swal from "sweetalert2";
import { Grid } from "@material-ui/core";
import Axios from "../../utils/axios";
import ConfirmCSFData from "./ConfirmCSFData";

const twelveMonths = 1000 * 60 * 60 * 24 * 365;

const PreviewDoc = ({ doc, content }) => {
  if (!doc || !content) return <></>;
  const isPdf = doc.type === "application/pdf";

  return (
    <Grid item xs={12}>
      {isPdf ? (
        <embed src={content} width="100%" height="500" />
      ) : (
        <img src={content} alt={doc.name} style={{ width: "100%" }} />
      )}
    </Grid>
  );
};

const UploadFile = ({ show, handleClose, socketId }) => {
  const [doc, setDoc] = useState(null);
  const [session, setSession] = useState(null);
  const [base64, setBase64] = useState(null);
  const [content, setContent] = useState(null);
  const [sending, setSending] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [typeDocument, setTypeDocument] = useState(null);
  const [modalStep, setModalStep] = useState(0);
  const [initialValues, setInitialValues] = useState({});
  const inputRef = useRef(null);

  const onFileInputChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        const base64 = content.split(",")[1];
        const type = file.type.split("/")[1];
        setContent(content);
        setBase64(base64);
        setTypeDocument(type);
      };
      reader.readAsDataURL(file);
      setDoc(file);
    }
  };
  const incodeConfig = {
    apiURL: process.env.REACT_APP_INCODE_API_URL,
    apiKey: process.env.REACT_APP_INCODE_CLIENT_ID,
    lang: "es",
    encrypt: true,
  };

  const user = JSON.parse(sessionStorage.getItem("user"));
  const id = user?._id;
  const idDocuments = user?.idClient?.appliance[0]?.idDocuments?._id;
  const incode = create(incodeConfig);

  const start = async () => {
    try {
      const newSession = await incode.createSession("MX", null, {
        configurationId: process.env.REACT_APP_INCODE_FLOWCSF,
        externalCustomerId: id,
      });
      setSession(newSession);
    } catch (err) {
      console.error(err);
      Swal.fire({
        title: "Ha ocurrido un error",
        text: "Tuvimos problemas para conectarnos con el servidor, por favor, recarga la página e intenta de nuevo.",
        icon: "error",
      });
    }
  };

  const clean = () => {
    setAttempts(0);
    setDoc(null);
    setBase64(null);
    setSending(false);
    setContent(null);
    setModalStep(0);
    setInitialValues({});
    inputRef.current && (inputRef.current.value = null);
  };

  const getData = async () => {
    try {
      if (attempts > 3) {
        clean();
        return Swal.fire({
          title: "Ha ocurrido un error",
          text: "No hemos podido leer tu Constancia de Situación Fiscal, por favor, intenta subirla nuevamente.",
          icon: "error",
        });
      }
      const interviewData = await incode.getInterviewData(session.token);
      const rfc = interviewData.additionalDocumentAttempts?.findLast(
        (el) => el.documentType === "MEXICAN_TAX_STATUS"
      );
      if (!rfc) {
        throw new Error(`RFC no encontrado. Intento: ${attempts}`);
      }
      const today = new Date();
      const csfDate = new Date(rfc.issueDate);
      if (today - csfDate > twelveMonths) {
        clean();
        return Swal.fire({
          title: "Ha ocurrido un error",
          text: "El documento no tiene que ser mayor a 1 año.",
          icon: "error",
          confirmButtonText: "Subir nuevamente",
        });
      }
      if (rfc.rfcNumber?.length > 12) {
        clean();
        return Swal.fire({
          title: "Ha ocurrido un error",
          text: "Hemos detectado que el documento no pertenece a una persona moral.",
          icon: "error",
          confirmButtonText: "Subir nuevamente",
        });
      }
      incode.getFinishStatus(process.env.REACT_APP_INCODE_CONF_ID, session);
      setInitialValues({ ...rfc });
      setModalStep(1);
      setSending(false);
    } catch (err) {
      console.error(err);
      setAttempts((prev) => prev + 1);
    }
  };

  const handleUpload = async ({ values }) => {
    try {
      setSending(true);
      if (!values) {
        const body = {
          base64,
          filename: doc.name,
          socketId,
          token: session.token,
          type: doc.type,
        };
        const user = JSON.parse(sessionStorage.getItem("user"));
        const resDoc = await Axios.post(`/api/documents/csf/${user._id}`, body);
        if (!resDoc?.data?.ok) {
          const error = new Error(
            "No se pudo subir el documento, por favor, inténtalo de nuevo."
          );
          error.response = resDoc;
          throw error;
        }
        setAttempts(1);
      } else {
        if (!doc)
          return Swal.fire({
            title: "Por favor, sube un archivo",
            icon: "error",
            toast: true,
          }).then(() => setSending(false));
        values.files = {
          name: doc.name,
          base64,
          typeDocument,
        };
        const resData = await Axios.post(`/api/documents/csf/${id}`, {
          values,
          socketId,
          idDocuments,
        });
        if (!resData.data?.ok)
          throw new Error(
            resData.data?.message ||
              "Hemos perdido la conexión con el servidor, por favor inténtalo nuevamente."
          );
        setSending(false);
        clean();
        handleClose();
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        title: "Ha ocurrido un error",
        text: err.message,
        icon: "error",
      }).then(() => setSending(false));
    }
  };

  const steps = [
    {
      component: (
        <FileUpload
          clean={clean}
          content={content}
          doc={doc}
          inputRef={inputRef}
          onFileInputChange={onFileInputChange}
        />
      ),
      stepTitle: "Sube tu constancia de situación fiscal",
    },
    {
      component: (
        <ConfirmCSFData
          initialValues={initialValues}
          handleUpload={handleUpload}
          sending={sending}
        />
      ),
      stepTitle: "Confirma los datos de la constancia",
    },
  ];

  useEffect(() => {
    start();

    return clean;
  }, []);

  useEffect(() => {
    if (attempts > 0 && attempts < 4) {
      setTimeout(() => getData(), 10000);
    } else if (attempts >= 4) {
      Swal.fire({
        title: "Ha ocurrido un error",
        text: "No hemos podido leer tu Constancia de Situación Fiscal, por favor, intenta subirla nuevamente.",
        icon: "error",
      }).then(() => {
        setAttempts(0);
        setSending(false);
      });
    }
  }, [attempts]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "rgba(0, 0, 0, 1)",
      }}
      centered
      contentClassName="modal-ext"
      id="modal-ext"
    >
      <HeaderCard title={steps[modalStep].stepTitle} oneLine={true} />
      <Modal.Body className="text-center">
        {steps[modalStep].component}
      </Modal.Body>
      <Modal.Footer>
        {!!doc && modalStep === 0 && (
          <Button variant="primary" onClick={handleUpload} disabled={sending}>
            {sending ? (
              <Spinner animation="border" role="status" size="sm"></Spinner>
            ) : (
              "Subir"
            )}
          </Button>
        )}
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const FileUpload = ({ inputRef, onFileInputChange, doc, content, clean }) => (
  <>
    <input
      className="d-none"
      type="file"
      accept="image/*,application/pdf"
      ref={inputRef}
      onChange={onFileInputChange}
    />
    {!!!doc && (
      <FileDrop onDrop={(e) => onFileInputChange({ target: { files: e } })}>
        <div className="dnd">
          <label
            className="drop-content"
            onClick={(e) => inputRef.current.click(e)}
          >
            Adjunta tu archivo{" "}
            <span className="d-none d-lg-inline">(o arrastra aquí)</span>
          </label>
        </div>
      </FileDrop>
    )}
    {!!doc && (
      <div className="text-center mt-3">
        <>
          <Grid container justifyContent="center" alignContent="center">
            <Grid item xs={10} className="text-left">
              <p
                // className="text-dp"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "clip",
                  textOverflow: "ellipsis",
                }}
              >
                <span className="text_title_altblue">
                  {doc.type === "application/pdf" ? (
                    <i className="fas fa-file-pdf"></i>
                  ) : (
                    <i className="fas fa-image"></i>
                  )}
                </span>
                <span className="ml-2">{doc.name}</span>
              </p>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="danger"
                size="sm"
                onClick={() => {
                  clean();
                  inputRef.current.click();
                }}
              >
                Eliminar
              </Button>
            </Grid>
            {!!doc && <PreviewDoc doc={doc} content={content} />}
          </Grid>
        </>
      </div>
    )}
  </>
);

export default UploadFile;
