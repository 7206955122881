export const dataFinancial = (financial, properties) => {//Propuestas
    // console.log('properties', properties);
    return {
        financiera: 'FINSUS',
        monto: properties.monto_preaut.value,
        plazo: '12 meses',
        tasa: '1.2% semanal',
        logo: "",
        class: 'ASPIRIA'
    };
}

export const dataFinancialFormalization = (properties) => {

    return {
        financiera: 'FINSUS',
        monto: properties.n12_1_monto_autorizado.value,
        plazo: properties.n12_3_plazo_autorizado.value + " meses",
        tasa: properties.n12_2_tasa_autorizada.value + "% mensual",
        logo: "",
        class: 'ASPIRIA'
    };
}