import React from "react";

import "../css/general.css";
import { reason } from "../models/AmountModels";
import Title from "../components/Generic/Title";
import InputLabel from "../components/Generic/InputLabel";
import { validateAmount } from "../components/Validate/ValidateAmount";
import { Form, withFormik } from "formik";
import {
  FieldSelect,
  FieldTextArea,
  FieldCleave,
} from "../components/Generic/Fields";
import { ButtonSubmit } from "../components/Generic/ButtonSubmit";

const AmountForm = (props) => {
  const { isSubmitting, isValid } = props;

  return (
    <Form className="ml-auto mr-auto" style={{ maxWidth: "690px" }}>
      <div className="text-center">
        <Title title="Elige tu monto" className="title-dp fz42" />
      </div>
      <div className="text-center">
        <label className="text-dp fz20 fw500 ml-auto mt-2 mb-1">
          cuéntanos un poco más sobre el monto que necesitas
        </label>
      </div>
      <InputLabel label="¿Cuánto necesitas?" class="mt-18" />
      <FieldCleave
        normalize="onlyNumbers"
        name="howMuch"
        placeholder="Ej. 500000"
        className="text-dp"
      />

      <InputLabel label="Ventas anuales" class="mt-18" />
      <FieldCleave
        normalize="onlyNumbers"
        name="yearSales"
        placeholder="Ej. 500000"
        className="text-dp"
      />

      <strong>
        <span style={{ color: "var(--primary-color)", fontSize: "0.7em" }}>
          Ventas totales en un año
        </span>
      </strong>

      <InputLabel label="¿Para qué lo necesitas?" class="mt-18" />
      <FieldSelect name="whyNeed" className="text-dp">
        <option value="">Elige...</option>
        {Object.keys(reason).map((value, i) => (
          <option value={value} key={i}>
            {reason[value]}
          </option>
        ))}
      </FieldSelect>

      <FieldTextArea
        name="whyNeedDetails"
        placeholder="Platicanos más a detalle para qué lo necesitas"
      />

      <div className="text-center" style={{ marginBottom: "50px" }}>
      <ButtonSubmit
                isSubmitting={isSubmitting}
                isValid={isValid}
                onClick={async () => {
                  await Object.keys(props.values).forEach((field) =>
                    props.setFieldTouched(field, true)
                  );
                }}
              />
      </div>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    howMuch: props.initialValues.howMuch || 0,
    yearSales: props.initialValues.yearSales || 0,
    whyNeed: props.initialValues.whyNeed || "",
    whyNeedDetails: props.initialValues.whyNeedDetails || "",
    personType: props.initialValues.personType || ""
  }),
  validate: validateAmount,
  enableReinitialize: true,
  handleSubmit(values, formikBag) {
    formikBag.setSubmitting(false);
    formikBag.props.handleSubmit(values);
  },
  displayName: "AmountForm",
})(AmountForm);
