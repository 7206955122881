import React, { Component } from "react";

// CSS
import "./App.css";
import "react-responsive-modal/styles.css";
import "./css/account.css";
import "./css/apply.css";
import "./css/Chip.css";
import "./css/custom-progress.css";
import "./css/datepicker.css";
import "./css/dnd.css";
import "./css/file-row.css";
import "./css/general.css";
import "./css/land-simulator.css";
import "./css/loader.css";
import "./css/progress-tracker.css";
import "./css/signup.css";
import "./css/tabs.css";
import "./css/toast.css";
import "./css/type-card.css";
import "./css/brokers-landing.css";
import "./css/custumInput.css";
import "./css/incode.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import NavBar from "./components/Navbar/Navbar";
import Footer from "./components/Landing/Footer/Footer";

// ROUTER & REDUX
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import { WebSocketProvider } from "./context/WebSocket";

// UTILS
import NotificationCenter from "./utils/NotificationCenter";
import Routes from "./routes";

function App() {
  return (
    <Router>
      <div
        className="divContainer d-flex flex-column justify-content-between"
        style={{ height: "100vh" }}
      >
        <Provider store={store}>
          <WebSocketProvider>
            <NavBar />
            <div className="flex-grow-1" style={{ height: "auto", paddingBottom: "50px" }}>
              <NotificationCenter />
              <Routes />
            </div>
            <div className="">
              <Footer />
            </div>
          </WebSocketProvider>
        </Provider>
      </div>
    </Router>
  );
}

export default App;
