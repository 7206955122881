import React, { useEffect, useState, lazy } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../../css/footer.css";
import useThemeData from "../../../utils/hooks/useThemeData";
import config from "../../../assets/theme/config";
import { indexOf } from "lodash";
import Modal from "react-responsive-modal";
import { Grid } from "@material-ui/core";
import IconoUNE from "../../../assets/img/redes_sociales/icono_une.svg";
import Contacto from "../../../assets/img/redes_sociales/icono_contacto.svg";
import Horarios from "../../../assets/img/redes_sociales/icono_horarios.svg";
import Mail from "../../../assets/img/redes_sociales/icono_mail.svg";
import Facebook from "../../../assets/img/redes_sociales/icono_facebook.svg";
import X from "../../../assets/img/redes_sociales/icono_x.svg";
import Youtube from "../../../assets/img/redes_sociales/icono_youtube.svg";
import Instagram from "../../../assets/img/redes_sociales/icono_instagram.svg";
import Tiktok from "../../../assets/img/redes_sociales/icono_tiktok.svg";
import Linkedin from "../../../assets/img/redes_sociales/icono_linkedin.svg";
import Whatsapp from "../../../assets/img/redes_sociales/icono_WA.svg";
import Ubicacion from "../../../assets/img/redes_sociales/icono_ubicación.svg";
import GooglePlay from "../../../assets/img/redes_sociales/icono_google_play.svg";
import AppStore from "../../../assets/img/redes_sociales/icono_tienda_ios.svg";

//import Whatsapp from '../../../assets/img/redes_sociales/whatsapp.png';

const getSize = () => {
  const currentSize = document.getElementsByTagName("body")[0].clientWidth;
  return currentSize < 775 ? 1 : 0;
};

const Logo = (props) => {
  return (
    <div
      className="col-xs-12 col-lg-3 text-left mt-3 mb-lg-0 pl-5 footer__logo"
      style={{ textTransform: "lowercase" }}
    >
      <img
        src="https://finsus.mx/wp-content/uploads/2023/09/Logo-Finsus_800x.png"
        alt={"logoFinsus"}
        className="nav-logo mb-1 mb-lg-0"
      />
    </div>
  );
};

const UNE = () => {
  const info = [
    {
      icon: IconoUNE,
      text: "55 5661 4915",
      key: "PHONE",
    },
    {
      icon: Mail,
      text: "une@finsus.mx",
      key: "MAIL",
      accion: "mailto:une@finsus.mx",
    },
    {
      icon: Horarios,
      text: "Lunes a Viernes de 9:00 a 18:00 hrs.",
      key: "HORARIOS",
    },
  ];

  return (
    <Grid container className="footer__une">
      <Grid item xs={12} className="text-center coolvetica fz16">
        <p>Unidad Especializada de Atención a Usuarios de FINSUS (UNE)</p>
      </Grid>
      {info.map((item, index) => (
        <Grid item xs={12} sm={4} key={index}>
          <div className="footer__une__item">
            <img src={item.icon} alt={item.key} width="50" />
            <p>{item.text}</p>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

const EndFooter = () => {
  return (
    <Grid container className="footer__end">
      <Grid item xs={12} className="text-center coolvetica fz16">
        <p>
          <span style={{ fontWeight: "bold" }}>finsus. </span>Finanzas
          Transparentes - {new Date().getFullYear()}
          <span
            style={{
              borderLeft: "1.5px solid #23223f",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          />
          www.finsus.mx
        </p>
      </Grid>
    </Grid>
  );
};

const Social = () => {
  const redes = [
    {
      icon: Facebook,
      link: "https://www.facebook.com/profile.php?id=61558559890538",
      key: "FACEBOOK",
    },
    {
      icon: X,
      link: "https://twitter.com/FinsusApp",
      key: "TWITTER",
    },
    {
      icon: Youtube,
      link: "https://www.youtube.com/@FinsusDigital",
      key: "YOUTUBE",
    },
    {
      icon: Instagram,
      link: "https://www.instagram.com/finsusapp/",
      key: "INSTAGRAM",
    },
    {
      icon: Tiktok,
      link: "https://www.tiktok.com/@finsusapp",
      key: "TIKTOK",
    },
    {
      icon: Linkedin,
      link: "https://www.linkedin.com/company/finsus/",
      key: "LINKEDIN",
    },
  ];

  return (
    <Grid container className="">
      <Grid item xs={12} className="text-center coolvetica fz16">
        {redes.map((item, index) => (
          <a
            href={item.link}
            target="_blank"
            key={index}
            className="cml clipImg"
          >
            <img src={item.icon} alt={item.key} width="40" />
          </a>
        ))}
      </Grid>
    </Grid>
  );
};

const Contact = ({version}) => {
  const info = [
    {
      icon: Contacto,
      text: "55 3603 7000",
      key: "PHONE",
    },
    {
      icon: Whatsapp,
      text: "55 51438536",
      key: "WHATSAPP",
      accion: "https://wa.me/525551438536",
    },
    {
      icon: Mail,
      text: "info@finsus.mx",
      key: "MAIL",
      accion: "mailto:info@finsus,mx",
    },
    {
      icon: Ubicacion,
      text: "Av. Insurgentes Sur No 1458 Piso 12, Col.Actipan, C.P. 03920, Alcaldía Benito Juárez, CDMX",
      key: "UBICACION",
      // accion: "https://goo.gl/maps/6Z6Z1Z6Z6Z6Z6Z6Z6",
    },
    {
      icon: Horarios,
      text: "Lunes a Sábado de 8:00 a 20:00 hrs. Domingos y días festivos de 9:00 a 16:00 hrs.",
      key: "HORARIOS",
    },
  ];

  return (
    <Grid container className="footer__">
      {
        version === 0 && (
          <Grid item xs={12} className="text-center coolvetica fz16 fw600">
            <p>Contacto</p>
          </Grid>
        )
      }

      {info.map((item, index) => (
        <Grid item xs={12} key={index}>
          <div
            className="footer__item"
            onClick={() => window.open(item.accion, "_blank")}
            style={{ justifyContent: "flex-start !important" }}
          >
            <img src={item.icon} alt={item.key} width="50" />
            <p>{item.text}</p>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};
const Regulacion = ({ version }) => {

  const [open, setOpen] = useState(false);

  const openHow = () => {
    setOpen(true);
  };

  const info = [
    {
      text: "¿Cómo funciona?",
      key: "HOW",
      accion: openHow,
    },
    {
      text: "Términos y condiciones",
      key: "TERMS",
      accion:
        "https://distrito-pyme-documents-dev.s3.us-west-2.amazonaws.com/1689024421843-TyC_julio%202023%20Finsus%20credito%20digital.pdf",
    },
    {
      text: "Costos y comisiones",
      key: "COMISIONES",
      accion:
        "https://cdn.finsus.mx/embed/46ad22f1976472d6299d0ebf77e31428fe1681892453/Tablas-de-costos-y-comisiones-de-nuestros-Productos-de-Credito.pdf",
    },
    {
      text: "Aviso de privacidad",
      key: "PRIVACY",
      accion: "https://finsus.mx/aviso-de-privacidad/",
    },
  ];

  return (
    <Grid container className="divider footer__">
      <Modal
          onClose={() => setOpen(false)}
          open={open}
          center
          classNames={{ modal: "customModal2" }}
          style={{ 
            padding: "30px 40px !important", 
            width: "80vw !important" 
          }}
        >
          <div class="subtitle form fz24 mb-10 text-center">¿Cómo funciona?</div>
          <video playsinline="true" width="100%" style={{objectFit: "cover"}} loop="true" poster="https://finsus.mx/wp-content/uploads/2023/11/video-imagen-destacada.jpg" preload="auto" controls="1">
            <source src="https://finsus.mx/wp-content/uploads/2023/11/Credito-digital-pyme_finsus.webm" type="video/webm"/>
            <source src="https://finsus.mx/wp-content/uploads/2023/11/Credito-digital-pyme_finsus.mp4" type="video/mp4"/>
                Sorry, your browser doesn't support embedded videos.
          </video>
        </Modal>
      {
        version === 0 && (
          <Grid item xs={12} className="text-center coolvetica fz16 fw600">
            <p>Regulación</p>
          </Grid>
        )
      }

      {info.map((item, index) => (
        <Grid
          item
          xs={12}
          key={index}
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (item.accion) {
              if (typeof item.accion === "function") {
                item.accion();
              } else {
                window.open(item.accion, "_blank");
              }
            }
          } }
        >
          <div className="footer__item">
            {item.icon && <img src={item.icon} alt={item.key} width="50" />}
            <p
              style={{
                paddingLeft: "1rem",
              }}
            >
              {item.text}
            </p>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

const Descarga = ({ version }) => {
  const info = [
    {
      icon: GooglePlay,
      text: "Google Play",
      key: "GOOGLE",
      accion:
        "https://play.google.com/store/apps/details?id=com.finsusapp.msantana.dimmerdevelopment&hl=es",
    },
    {
      icon: AppStore,
      text: "App Store",
      key: "APPSTORE",
      accion: "https://apps.apple.com/mx/app/finsus/id1510473524",
    },
  ];
  return (
    <Grid container className="divider footer__">
      <Grid item xs={12} className="text-center coolvetica fz16 fw600" style={version === 1 ? {color: "var(--segundo-color)"} : {}}>
        <p>Descarga finsus</p>
      </Grid>
      {info.map((item, index) => (
        <Grid item xs={12} key={index}>
          <div
            className="footer__item fw600"
            onClick={() => window.open(item.accion, "_blank")}
            style={{ cursor: "pointer", fontSize: "1.5rem" }}
          >
            {version === 1 ? (
              <img src={item.icon} alt={item.key} width="100%" />
            ) : (
              <p>{item.text}</p>
            )}
          </div>
        </Grid>
      ))}
      {version === 0 && (
        <Grid item xs={12} className="text-center coolvetica fz16 fw600">
          <Social />
        </Grid>
      )}
    </Grid>
  );
};

const WebVersion = ({ version }) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={4}>
        <Contact version={version} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Regulacion version={version} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Descarga version={version} />
      </Grid>
    </Grid>
  );
};

const MobileVersion = ({ version }) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={4}>
        <Grid container className="footer__">
        <Social />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Regulacion version={version}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Descarga version={version} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Contact version={version} />
      </Grid>
    </Grid>
  );
};

const Footer = (props) => {
  const [version, setVersion] = useState(getSize());
  const [show, setShow] = useState(true); //paths.indexOf(location.pathname) > -1 ? false : true
  const location = useLocation();

  const { theme, themeData } = useThemeData();

  useEffect(() => {
    window.addEventListener("resize", () => setVersion(getSize()));
    return () => {
      window.removeEventListener("resize", () => setVersion(getSize()));
    };
  }, []);

  
  const url =
  indexOf(location.pathname.split("/"), "buro") > -1 ? "buro" : "other";

  useEffect(() => {
    const paths = ["buro", "solicitudBrokers/322586238", "dashboard" ];
    let pathname = location.pathname.split("/").filter((item) => item !== "");
    pathname = pathname.length > 0 ? pathname[0] : "";
    setShow(paths.indexOf(pathname) > -1 ? false : true);
  }, [location]);

  if (!show) return null;

  return (
    <Grid container className="footer">
      <Grid item xs={12} className="footer__gray">
        {version === 1 && (
          <Grid item xs={12} style={{ position: "relative" }}>
            <Logo
              version={version}
              src="https://finsus.mx/wp-content/uploads/2023/09/Logo-Finsus_800x.png"
              theme={theme}
              themeData={themeData}
            />
          </Grid>
        )}
        {
          version === 1 ? (
            <MobileVersion version={version} />
          ) : (
            <WebVersion version={version} />
          )
        }
      </Grid>
      <Grid item xs={12} className="footer__une">
        <UNE />
      </Grid>
      <Grid item xs={12} className="footer__end">
        <EndFooter />
      </Grid>
    </Grid>
  );
};
export default Footer;
