import { useEffect, useState, useRef } from "react";
import Axios from "../axios";

const useAlianza = () => {
    const [alianzas, setAlianzas] = useState({});
    const [alianza, setAlianza] = useState("");

    useEffect(() => {
        
        if(alianza === "") {
            return;
        }

        async function searchData() {
            await Axios.post("/alianzas/search", { url: alianza })
                .then((response) => {
                    const data = response.data;
                    const { alianza } = data;
                    setAlianzas(alianza);
                })
                .catch((error) => {
                    console.log(error);
                    setAlianzas([]);
                });
        }
        searchData();
    }, [alianza]);

    const handleSearch = (ali) => {
        setAlianza(ali);
    };

    return [alianzas, handleSearch];
};

export default useAlianza;
