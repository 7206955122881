import { useEffect, useRef, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { HeaderCard } from "./HeaderCard";
import { FileDrop } from "react-file-drop";
import Axios from "../../utils/axios";
import Swal from "sweetalert2";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import {
  Delete,
  DescriptionOutlined,
  ImageOutlined,
  ReplayOutlined,
} from "@material-ui/icons";

const humanReadable = {
  lastDeclarations: "la constancia de situación fiscal",
  proofAddress: "el comprobante de domicilio operativo",
  constitutiveAct: "tus actas constitutivas",
  bankStatements: "tus estados de cuenta",
};

const UploadFiles = ({ action, show, handleClose, socketId }) => {
  const [docFiles, setDocFiles] = useState([]);
  const [banksList, setBanksList] = useState([]);
  const customError =
    "Error al obtener la lista de bancos. Por favor presiona el botón de refresco. Si el problema persiste, contacta a soporte.";
  const [currentBank, setCurrentBank] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);

  const isMulti = ["constitutiveAct", "bankStatements"].includes(action);

  const onFileInputChange = (e) => {
    const { files } = e.target;
    if (files?.length > 0) {
      if (isMulti)
        setDocFiles((prevDocs) => [...prevDocs, ...Array.from(files)]);
      else setDocFiles([files[0]]);
    }
  };

  const handleUpload = async () => {
    try {
      handleLoader(true);
      const user = JSON.parse(sessionStorage.getItem("user"));
      const appliance = user.idClient.appliance[0];
      const idDocuments = appliance?.idDocuments?._id;

      const formData = new FormData();
      for (const doc of docFiles) {
        formData.append(action, doc);
      }

      formData.append("idDocuments", idDocuments || "");
      formData.append("socketId", socketId);
      if (action === "bankStatements") formData.append("bankId", currentBank);
      formData.append("action", action);

      const endpoint =
        action === "lastDeclarations"
          ? `api/documents/csf/${user._id}`
          : `api/documents/s3andmetamap/${user._id}`;
      const response = await Axios.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (!response.data.ok) {
        const error = new Error(
          "No se subieron los documentos de manera correcta."
        );
        error.response = response;
        throw error;
      }
      clean();
      await Swal.fire({
        title: "¡Éxito!",
        text: "Tus documentos se han subido correctamente.",
        icon: "success",
        timer: 3000,
        timerProgressBar: true,
      }).then(() => handleClose());
    } catch (err) {
      console.error(err);
      await Swal.fire({
        title: "¡Ups!",
        text: `Tuvimos problemas para subir tus documentos. Error: ${
          err.response?.data?.message || err.message
        }.`,
        icon: "error",
        confirmButtonText: "Reintentar",
      });
    } finally {
      handleLoader(false);
    }
  };

  const handleChangeBank = (event) => {
    setCurrentBank(event.target.value);
  };

  const clean = () => {
    setDocFiles([]);
    if (inputRef.current) inputRef.current.value = "";
  };

  const handleLoader = (value) => setIsLoading(value);

  useEffect(() => {
    if (action === "bankStatements") fetchBanks(setBanksList, handleLoader);

    return () => {
      clean();
    };
  }, [action]);

  useEffect(() => {
    clean();
  }, [action]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "rgba(0, 0, 0, 0.5)",
      }}
      centered
      contentClassName="modal-ext modalDocs"
      id="modal-ext"
    >
      <HeaderCard title={`Sube ${humanReadable[action]}`} oneLine={true} />
      <Modal.Body className="text-center">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {action === "bankStatements" && (
              <FormControl
                fullWidth
                className="my-5 text-left"
                error={banksList?.length === 0}
              >
                <Grid container spacing={2}>
                  <Grid item xs={11}>
                    <InputLabel id="demo-simple-select-label">
                      Selecciona el banco correspondiente
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      className="p-3"
                      id="demo-simple-select"
                      value={currentBank?.id}
                      label="Banco"
                      disabled={banksList?.length === 0}
                      onChange={handleChangeBank}
                    >
                      {banksList?.map((bank, index) => (
                        <MenuItem value={bank.id} key={`${bank.id}-${index}`}>
                          <img
                            src={bank.iconUrl}
                            alt={bank.name}
                            style={{
                              width: "1.5rem",
                              marginRight: "0.5rem",
                              borderRadius: "8px",
                            }}
                          />
                          {bank.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {banksList?.length === 0 && (
                      <FormHelperText>{customError}</FormHelperText>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    className="d-dlex flex-column align-items-center"
                  >
                    <IconButton
                      onClick={() => fetchBanks(setBanksList, handleLoader)}
                    >
                      <ReplayOutlined />
                    </IconButton>
                  </Grid>
                </Grid>
              </FormControl>
            )}
            <input
              className="d-none"
              type="file"
              accept={action === "proofAddress" ? ".pdf,image/*" : ".pdf"}
              ref={inputRef}
              onChange={onFileInputChange}
              multiple={isMulti}
            />
            {(isMulti || docFiles.length === 0) && (
              <FileDrop
                onDrop={(e) => onFileInputChange({ target: { files: e } })}
              >
                <div className="dnd">
                  <label
                    className="drop-content"
                    onClick={(e) => inputRef.current.click(e)}
                  >
                    Adjunta tu archivo{" "}
                    <span className="d-none d-lg-inline">
                      (o arrastra aquí)
                    </span>
                  </label>
                </div>
              </FileDrop>
            )}

            {docFiles.length > 0 && (
              <Grid
                className="mt-3"
                container
                alignContent="center"
                direction="column"
              >
                {docFiles.map((doc, idx) => {
                  return (
                    <>
                      <Grid
                        item
                        zeroMinWidth
                        key={`bankstatement-${idx}`}
                        style={{
                          width: "75%",
                        }}
                      >
                        <div
                          className="d-flex flex-row align-items-center justify-content-start"
                          key={`bankstatement-container-${idx}`}
                        >
                          {doc.type === "application/pdf" ? (
                            <DescriptionOutlined
                              style={{ color: "var(--segundo-color)" }}
                            />
                          ) : (
                            <ImageOutlined
                              style={{ color: "var(--segundo-color)" }}
                            />
                          )}
                          <Typography className="ml-3" noWrap>
                            {doc.name}
                          </Typography>
                          <IconButton
                            key={`bankstatement-delete-btn-${idx}`}
                            className="ml-auto"
                            onClick={() =>
                              setDocFiles((prevDocs) => {
                                const aux = [...prevDocs];
                                aux.splice(idx, 1);
                                return aux;
                              })
                            }
                          >
                            <Delete color="error" />
                          </IconButton>
                        </div>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="btn-gray-general"
          onClick={handleClose}
        >
          Cerrar
        </Button>
        {docFiles.length > 0 && (
          <Button
            variant="primary"
            style={{
              minWidth: "300px",
            }}
            className="btn-blue-documents"
            onClick={() => handleUpload(docFiles)}
          >
            Subir
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const fetchBanks = async (setBanks, handleLoader) => {
  try {
    handleLoader?.(true);
    const responseBanks = await Axios.get("api/meta/banksList");
    if (!responseBanks.data?.ok) {
      const cust = new Error("No se pudo obtener la lista de bancos");
      cust.response = responseBanks;
      throw cust;
    }
    setBanks(responseBanks.data.banks);
  } catch (err) {
    console.error(err.response?.data || err);
    setBanks([]);
  } finally {
    handleLoader?.(false);
  }
};

export default UploadFiles;
