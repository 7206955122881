import {
    START_REGISTRATION,
    REGISTRATION_SUCCESS,
    REGISTRATION_FAILED,
    START_LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAILED
} from '../types/authTypes';
import Axios from '../../utils/axios';
import { setUserData } from './userActions';

export function singUpAction(data){
    return async(dispatch) => {
        dispatch( startRegistration() );

        try{
			dispatch( registrationSuccess() );
			
            const credentials = {
				email: data.email,
				password: data.password
			}
		
			const loginRequest = await Axios.post('login', credentials);
			
			if (loginRequest.data.code === 200){
				dispatch( loginAction(loginRequest.data) );
			}
			
			setTimeout( () => {
				window.location.href = `${process.env.REACT_APP_REDIRECT}/registroexitoso`;
			}, 100)

        }
        catch(error){
            dispatch( registrationFailed('Some error') );
        }
    }
}

const startRegistration = () => ({
    type: START_REGISTRATION
});

const registrationSuccess = () => ({
    type: REGISTRATION_SUCCESS
});

const registrationFailed = error => ({
    type: REGISTRATION_FAILED,
    payload: error
});

export function loginAction(data){
    return async(dispatch) => {
        dispatch( startLogin() );

        try{
            const { token, user, Brokertelefono} = data;

			sessionStorage.setItem("token", token);
			dispatch( loginSuccess(token) );

			sessionStorage.setItem('user', JSON.stringify(user));
            dispatch( setUserData(user) );

			sessionStorage.setItem("broker", Brokertelefono);

        }
        catch(error){
			console.log(error);
            dispatch( loginFailed('Some error') );
        }
    }
}

const getUser = async (id) => {
    await Axios.get(`api/user/${id}`).then((response) => {
        sessionStorage.setItem("user", JSON.stringify(response.data.user));
    }).catch((error) => {
        console.log(error);
    }
    );
};

export function updateUserData(){
    const user = JSON.parse(sessionStorage.getItem("user"));
    if(!user) {
        return;
    }
    const id = user._id;
    return async(dispatch) => {
        try{
            await getUser(id);
        }
        catch(error){
            console.log(error);
        }
    }
}

const startLogin = () => ({
    type: START_LOGIN
});

const loginSuccess = (token) => ({
    type: LOGIN_SUCCESS,
    payload: token
});

const loginFailed = error => ({
    type: LOGIN_FAILED,
    payload: error
});
