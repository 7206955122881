import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Switch,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
} from "@material-ui/core";
import { ProductCard } from "./data-card";
import Modal from "react-responsive-modal";
import { useDispatch } from "react-redux";
import Axios from "../../utils/axios";
import { updateLoader } from "../../redux/actions/loaderActions";
import Loader from "../Loader/Loader";
import { SettingsPassword } from "./SettingsPassword";

const SettingsCards = () => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [datos, setDatos] = useState({});
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  const [unykoo, setUnykoo] = useState(false);
  const [ciec, setCiec] = useState("");
  const [theme, setTheme] = useState("distrito");

  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    if (user === null) {
      window.location.replace("/login");
    }
    if (user.access !== "ADMIN") {
      window.location.replace("/login");
    }
  }, [user]);

  useEffect(() => {
    const theme = sessionStorage.getItem("theme");
    if (theme !== null) {
      setTheme(theme);
    }
  }, []);

  const onCloseModal = () => {
    setOpen(false);
  };

  const handleChange = (name) => {
    products.map((product) => {
      if (product.name === name) {
        setDatos(product);
      }
    });
    setOpen(true);
  };

  useEffect(() => {
    dispatch(updateLoader(true));
    if (data.length > 0) {
      setProducts(data);
      dispatch(updateLoader(false));
    }
  }, [data, dispatch]);

  useEffect(() => {
    async function fetchData() {
      await Axios.get("/control/buro")
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, [reload]);

  useEffect(() => {
    async function fetchData() {
      await Axios.get("/control/buro/unykoo")
        .then((res) => {
          setUnykoo(res.data.unykoo);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, [reload]);

  useEffect(() => {
    async function fetchData() {
      await Axios.post("/control/ciec")
        .then((res) => {
          setCiec(res.data.passwordBuro);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, [reload]);

  const handleReload = () => {
    setReload(!reload);
  };

  const handleCiec = () => {
    setDatos({
      name: "ciec",
      userBuro: "dpyme",
      passwordBuro: ciec,
    });
    setOpen(true);
  };

  const handleUnykoo = () => {
    dispatch(updateLoader(true));
    async function fetchData() {
      await Axios.post("/control/unykoo", { update: !unykoo })
        .then((res) => {
          let data = res.data;
          setUnykoo(data.unykoo);
          alert("Se ha actualizado correctamente");
          dispatch(updateLoader(false));
        })
        .catch((err) => {
          alert("Ha ocurrido un error");
          dispatch(updateLoader(false));
        })
        .finally(() => {
          dispatch(updateLoader(false));
        });
    }
    fetchData();
  };

  const handleTheme = () => {
    dispatch(updateLoader(true));
    async function fetchData() {
      Axios.post("/theme/change", { update: true })
        .then((res) => {
          let data = res.data;
          setTheme(data.theme);
          sessionStorage.setItem("theme", data.theme);
          alert("Se ha actualizado correctamente");
          dispatch(updateLoader(false));
        })
        .catch((err) => {
          alert("Ha ocurrido un error");
          dispatch(updateLoader(false));
        })
        .finally(() => {
          dispatch(updateLoader(false));
        });
    }
    fetchData();
  };
  return (
    <>
      {data.length <= 0 ? (
        <Loader />
      ) : (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}
        >
          <Loader />
          <Modal
            open={open}
            onClose={onCloseModal}
            center
            classNames={{ modal: "customModal" }}
          >
            <SettingsPassword datos={datos} handleReload={handleReload} />
          </Modal>
          <Container maxWidth="lg">
            <div
              className="title-dp fz42 fw500 mb-1 text-center mt-2"
              style={{ color: "#213970" }}
            >
              <span style={{ color: "var(--segundo-color)" }}> Usuarios </span>{" "}
              para buro
            </div>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
              style={{
                fontFamily: "Metropolis-Regular",
                textAlign: "center",
              }}
              className="mb-4"
            >
              selecciona el usuario que deseas editar
            </Typography>
            <Box sx={{ pt: 3 }}>
              <Grid container spacing={3}>
                {products.map((product) => (
                  <Grid item key={product._id} lg={4} md={6} xs={12}>
                    <ProductCard product={product} setOpen={handleChange} />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box sx={{ pt: 3 }}>
              <div
                className="title-dp fz42 fw500 mb-1 text-center mt-2"
                style={{ color: "#213970" }}
              >
                <span style={{ color: "var(--segundo-color)" }}>
                  {" "}
                  consulta{" "}
                </span>{" "}
                de buro y ciec
              </div>
              <Grid container spacing={3}>
                <Grid item lg={4} md={6} xs={12}>
                  <Card
                    elevation={5}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      padding: "1rem",
                    }}
                  >
                    <CardHeader title="consulta de buro" />
                    <Divider />
                    <CardContent
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h4 className="mb-0 font-weight-bold">
                        {unykoo ? "Desactivada" : "Activada"}
                      </h4>

                      <Switch
                        checked={!unykoo}
                        onChange={handleUnykoo}
                        name="checkedB"
                        color="primary"
                      />
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item lg={4} md={6} xs={12}>
                  <Card
                    elevation={5}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      padding: "1rem",
                    }}
                  >
                    <CardHeader title="contraseña ciec" />
                    <Divider />
                    <CardContent
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h4 className="mb-0 font-weight-bold">{ciec}</h4>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleCiec()}
                      >
                        Actualizar
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <Card
                    elevation={5}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      padding: "1rem",
                    }}
                  >
                    <CardHeader title="cambiar tema" />
                    <Divider />
                    <CardContent
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h4 className="mb-0 font-weight-bold">{theme}</h4>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleTheme()}
                      >
                        cambiar
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
};
export default SettingsCards;
