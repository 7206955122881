import {
  Grid,
  TableRow,
  withStyles,
  TableCell,
  Chip,
  Switch,
} from "@material-ui/core";

const TableCellStyle = withStyles({
  root: {
    fontFamily: "Metropolis-Regular",
    fontSize: "14px",
    color: "#000000",
    textAlign: "center",
    maxWidth: "300px",
  },
})(TableCell);

const AlianzaBoddy = ({ alianza, handleImage, handleStatus }) => {
  return (
    <TableRow key={alianza.id}>
      <TableCellStyle>{alianza.nombreAlianza}</TableCellStyle>
      <TableCellStyle>{alianza.brokerCode}</TableCellStyle>
      <TableCellStyle>{alianza.url}</TableCellStyle>
      <TableCellStyle>{alianza.prefijo}</TableCellStyle>
      <TableCellStyle>{alianza.descripcion}</TableCellStyle>
      <TableCellStyle>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Chip
              label="Web"
              style={{
                backgroundColor: "#23223F",
                color: "#fff",
                fontFamily: "Metropolis-Regular",
              }}
              onClick={() => handleImage(alianza.imagenWeb)}
            />
          </Grid>
          <Grid item xs={6}>
            <Chip
              label="App"
              style={{
                backgroundColor: "#ff5f54",
                color: "#fff",
                fontFamily: "Metropolis-Regular",
              }}
              onClick={() => handleImage(alianza.imagenApp)}
            />
          </Grid>
        </Grid>
        {/* <img
  src={alianza.imagen}
  alt="imagen"
  style={{ width: "50px", height: "50px" }}
/> */}
      </TableCellStyle>
      <TableCellStyle>
        <Switch checked={alianza.status} onChange={() => handleStatus(alianza._id, { status: !alianza.status })} />
        <span>{alianza.status ? "Activo" : "Inactivo"}</span>
      </TableCellStyle>
    </TableRow>
  );
};

export default AlianzaBoddy;