import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./auth.css";
import AuthCode from "./AuthCode";
import Loader from "../Loader/Loader";
import { useDispatch } from "react-redux";
import { updateLoader } from "../../redux/actions/loaderActions";
import { Modal } from 'react-responsive-modal';
import axios from 'axios';



const PantallaAuthCode = () => {
  const [code, setCode] = useState(null)
  const [data, setData] = useState(null)
  const[user, setUser] = useState(null)
  const [open, setOpen] = useState(false);
  const [mensaje , setMensaje] = useState(null);
  const phone = '55106007568'
  const history = useHistory();
  const dispatch = useDispatch();

  const onOpenModal = (bool, cases, phone) => {
    
    switch (cases) {
      case "code":
        setMensaje(
          <div className="text-center metropolisReg">
            <h2 className="text-center title-dp fw500">¡codigo incorrecto!</h2>
            <p> el codigo proporcionado no es correcto vuelvelo a intentar</p>
            <button className="formFieldButton" onClick={() => setOpen(false)}>intentarlo de nuevo</button>
          </div>
        )
        return setOpen(true);
      case "phone":
        setMensaje(
          <div className="text-center metropolisReg">
            <h2 className="text-center title-dp fw500">porfavor revisa tu numero</h2>
            <p>{
                `+52 ${phone} es correcto?`
              }</p>
            <div className="d-flex justify-content-around">
              <button className="formFieldButton" onClick={() => setOpen(false)}>si</button>

              <button className="formFieldButton" onClick={() => setOpen(false)}>cambiar</button>
              </div>
          </div>
        )
        return setOpen(true);
        case "sendCode": 
        setMensaje(
          <div className="text-center metropolisReg">
            <h2 className="text-center title-dp fw500">¡bienvenido broker a distrito pyme!</h2>
            <p> te hemos enviado un codigo de verificación a tu telefono, por favor ingresa el codigo para poder continuar</p>
            <p>¿quieres cambiar tu numero? <Link className="text-primary formFieldLink" onClick={() => setOpen(false)} >cambiar</Link></p>
            <button className="formFieldButton" onClick={() => changeCode(code, user)}>continuar</button>
          </div>
        )
        return setOpen(true);
        default:
        break;
  }
}
  const resendCode = (code) => {
    code ? console.log("codigo enviado") : console.log("no hay codigo")
  }
  const codeFalse = (code, user) => {
    if(code && user){
      setCode(code)
      setUser(user)
    }
  }

  const changeCode = (code, data) => {
    setData(data)
    setCode(code)
  }

  const loader = (load) => {
    dispatch(updateLoader(load));
  };

  useEffect(() => {
    const fetchCode = async () => {
      const response = await axios.post(`${process.env.SMS_API_URL}/smsotp/service/generateotp`, {
        phone
      })
    }
  }, [])

  
    return (
        <div className="metropolisReg" style={{minHeight:"50vh"}}>
        <Loader/>
        <Modal open={open} onClose={() => setOpen(false)} center classNames={{modal:'modalcontentAdmin'}} >
          {mensaje}
        </Modal>
        <AuthCode changeCode={changeCode} code={code} loader={loader} onOpenModal={onOpenModal} resendCode={resendCode}/>
        </div>
    );
};

export default PantallaAuthCode;